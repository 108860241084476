export default function(location) {
  if (location.includes("/dashboard/movies/stats")) {
    return "Movies Stats";
  } else if (location.includes("/dashboard/movies")) {
    return "Movies";
  } else if (location.includes("/dashboard/merchandise")) {
    return "Merchandise";
  } else if (location.includes("/dashboard/promotion-ads")) {
    return "Promotion ads";
  } else if (location.includes("/dashboard/users/churn")) {
    return "Churn";
  } else if (location.includes("/dashboard/users/unsubscribers")) {
    return "Unsubscribed Users";
  } else if (location.includes("/dashboard/users/guests")) {
    return "Guest Users";
  } else if (location.includes("/dashboard/users")) {
    return "Active Users";
  } else if (location.includes("/dashboard/tiers")) {
    return "Tiers";
  } else if (location.includes("/dashboard/fandb")) {
    return "Food & Beverages";
  } else if (location.includes("/dashboard/referral-links")) {
    return "Referral Codes";
  } else if (location.includes("/dashboard/bulk-vouchers")) {
    return "Bulk Vouchers";
  } else if (location.includes("/dashboard/promotions")) {
    return "Promotion";
  } else if (location.includes("/dashboard/hero-banners")) {
    return "Hero Banners";
  } else if (location.includes("/dashboard/admin/roles")) {
    return "Admin Roles";
  } else if (location.includes("/dashboard/admin")) {
    return "Admin";
  } else if (location.includes("/dashboard/categories")) {
    return "Categories";
  } else if (location.includes("/dashboard/payment-logs")) {
    return "Payment Logs";
  } else if (location.includes("/dashboard/error-logs")) {
    return "Error Logs";
  } else if (location.includes("/dashboard/concessions")) {
    return "Concessions";
  } else if (location.includes("/dashboard/purchased-tickets")) {
    return "Tickets Log";
  } else if (location.includes("/dashboard/notifications")) {
    return "Notifications";
  } else {
    return "Dashboard";
  }
}
