import React from "react";
import UserListPage from "./UserList.js";
import UserProfilePage from "./UserProfile.js";
// import UserChurn from "./UserChurn.js";
import UnsubscriberUserListPage from "./UnsubscriberUserList.js";
import GuestUserListPage from "./GuestUserList.js";
import GuestProfile from "./GuestProfile.js";

export default [
  {
    path: "/dashboard/users",
    exact: true,
    component: () => <UserListPage />
  },
  // {
  //   path: "/dashboard/users/churn",
  //   exact: true,
  //   component: () => <UserChurn />
  // },
  {
    path: "/dashboard/users/unsubscribers",
    exact: true,
    component: () => <UnsubscriberUserListPage />
  },
  {
    path: "/dashboard/users/guests",
    exact: true,
    component: () => <GuestUserListPage />
  },
  {
    path: "/dashboard/users/:id",
    exact: true,
    component: () => <UserProfilePage />
  },
  {
    path: "/dashboard/users/guests/:id",
    exact: true,
    component: () => <GuestProfile />
  }
];
