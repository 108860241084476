import React, { useEffect, useState } from "react";
import statsApi from "../../../../api/stats-api";
import { getRequestError } from "../../../../utils/functions";
import moment from "moment";
import useStyles from "../MainDashboard/MainDashboard.styles";
import DateSelector from "../../../../components/Inputs/DateSelector";
import Chart from "../../../../components/Chart";
import generateChart from "../utils/graphs";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";

const Users = ({ jwt }) => {
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(1, "months")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [users, setUsers] = useState(null);
  const [usersCSV, setUsersCSV] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");

  const { filter, filterContainer, overviewTab } = useStyles({
    active:
      activeFilter === "Active users"
        ? "first"
        : activeFilter === "Inactive users"
        ? "second"
        : "all"
  });

  useEffect(() => {
    setIsLoadingUsers(true);
    statsApi
      .getUsers(jwt, startDate, endDate)
      .then(res => {
        setUsersCSV(res.data?.data ?? null);
        const chart = generateChart(
          res.data?.data?.map(item => [item?.activeUsers, item?.inactiveUsers]),
          ["Active users", "Inactive users"],
          res.data?.data?.map(item => item?.date)
        );
        setUsers({
          labels: chart.labels,
          datasets: chart.datasets
        });
        setIsLoadingUsers(false);
      })
      .catch(e => getRequestError(e));
  }, [endDate, jwt, startDate]);

  return (
    <div>
      <div>
        <Chart
          data={
            users
              ? {
                  labels: users.labels,
                  datasets: users.datasets.filter(item =>
                    activeFilter === "all" ? true : item.label === activeFilter
                  )
                }
              : null
          }
          type="users"
          title="Users"
          height={80}
          xLabel="Months"
          yLabel="Number of users"
          hasFullReport={false}
          loading={isLoadingUsers}
          filterComponent={
            <div className={filterContainer}>
              <CSVLink
                data={
                  usersCSV.map(item =>
                    activeFilter === "all"
                      ? {
                          date: item.date,
                          "Inactive users": item.inactiveUsers,
                          "Active users": item.activeUsers
                        }
                      : activeFilter === "Inactive users"
                      ? {
                          date: item.date,
                          "Inactive users": item.inactiveUsers
                        }
                      : {
                          date: item.date,
                          "Active users": item.activeUsers
                        }
                  ) ?? []
                }
                filename={"users.csv"}
              >
                <button disabled={isLoadingUsers} className="export-btn">
                  <DownloadIcon color="#A233E2" />
                  EXPORT REPORT
                </button>
              </CSVLink>
              <article className={overviewTab}>
                <button className="all" onClick={() => setActiveFilter("all")}>
                  All
                </button>
                <button
                  className="first"
                  onClick={() => setActiveFilter("Active users")}
                >
                  <span className="dot first"></span>Active
                </button>
                <button
                  className="second"
                  onClick={() => setActiveFilter("Inactive users")}
                >
                  <span className="dot second"></span>Inactive
                </button>
              </article>
              <div className={filter}>
                <DateSelector
                  value={startDate}
                  onChange={e => setStartDate(moment(e).format("YYYY-MM-DD"))}
                />
              </div>
              <div className={filter}>
                <DateSelector
                  value={endDate}
                  onChange={e => setEndDate(moment(e).format("YYYY-MM-DD"))}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Users;
