import React from "react";
import api from "../../api/users-api";
import sheet from "./styles";
import lodash from "lodash";
import FormControl from "@material-ui/core/FormControl";

import SlideIn from "../../components/Drawer";
import {
  List,
  ListItem,
  Typography,
  Button,
  TextField,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import Back from "../../components/Button/Back";
import validator from "validator";
import PasswordShowHide from "../../components/Forms/PasswordShowHide/PasswordShowHide";
import { connect } from "react-redux";
import types from "../../redux/types";
import { getRequestError } from "../../utils/functions";

//passwords might have been updated to: Password1!

class EditAdminSlideIn extends React.Component {
  state = {
    form: {
      firstName: { value: "", error: { isError: false, message: "" } },
      lastName: { value: "", error: { isError: false, message: "" } },
      email: { value: "", error: { isError: false, message: "" } },
      mobile: { value: "", error: { isError: false, message: "" } },
      role: { value: "", error: { isError: false, message: "" } },
      password: { value: "", error: { isError: false, message: "" } }
    },
    status: {
      loading: false,
      required: [
        "firstName",
        "lastName",
        "email",
        "mobile",
        "role",
        "password"
      ],
      missing: []
    }
  };

  componentDidMount() {
    this.baseState = lodash.cloneDeep(this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    const { open, isNewTier, data } = this.props;
    if (prevProps.open !== open) {
      if (isNewTier) {
        this.setState({
          status: {
            ...this.state.status
          }
        });
      }
    }

    if (prevProps.data !== data) {
      const { data } = this.props;
      this.setState({
        form: {
          ...this.state.form,
          firstName: {
            value: data.firstName ?? "",
            error: { isError: false, message: "" }
          },
          lastName: {
            value: data.lastName ?? "",
            error: { isError: false, message: "" }
          },
          email: {
            value: data.email ?? "",
            error: { isError: false, message: "" }
          },
          mobile: {
            value: data.mobile ?? "",
            error: { isError: false, message: "" }
          },
          role: {
            value: data.userType ?? "",
            error: { isError: false, message: "" }
          }
        }
      });
    }
  }

  handleChange = ({ target }, _validator) => {
    const { name, value } = target;
    const checkedValidator = _validator
      ? _validator
      : value =>
          validator.isEmpty(value)
            ? { isError: true, message: "Field is empty" }
            : { isError: false, message: "" };
    if (!validator.isEmpty(value)) {
      this.setState({
        status: {
          ...this.state.status,
          missing: this.state.status.missing.filter(item => name !== item)
        }
      });
    }
    this.setState({
      form: {
        ...this.state.form,
        [name]: { value: value, error: checkedValidator(value) }
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      role,
      mobile,
      password
    } = this.state.form;
    const {
      close,
      jwt,
      refreshList,
      isNewTier,
      adminId: id,
      dispatch
    } = this.props;

    const { ALERT_OPEN } = types;

    let missing = new Set();
    this.state.status.required.forEach(item => {
      if (validator.isEmpty(this.state.form[item].value)) {
        missing.add(item);
      }
    });
    this.setState({ status: { ...this.state.status, missing: [...missing] } });
    if (isNewTier) {
      if ([...missing].length) return;
    } else {
      if ([...missing].length !== 1 && ![...missing].includes("password"))
        return;
    }

    this.setState({
      status: {
        ...this.state.status,
        loading: true
      }
    });

    const createPayload = () => {
      const payload = {
        email: email.value,
        mobile: mobile.value,
        userType: role.value
      };
      if (isNewTier) {
        payload.password = password.value;
        payload.first_name = firstName.value;
        payload.last_name = lastName.value;
      } else {
        payload.firstName = firstName.value;
        payload.lastName = lastName.value;
      }

      return payload;
    };
    console.log(isNewTier);
    let userRequest = isNewTier
      ? api.createAdmin(`Bearer ${jwt}`, createPayload())
      : api.editAdmin(`Bearer ${jwt}`, createPayload(), id);
    userRequest
      .then(res => {
        if (isNewTier) {
          this.setState({ ...this.baseState });
        }
        this.setState({
          status: {
            ...this.state.status,
            loading: false
          }
        });
        console.log(res.data);
        close();
        refreshList();
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        this.setState({
          status: {
            ...this.state.status,
            loading: false
          }
        });
      });
  };

  resetOnClose = e => {
    const { onClose, isNewTier } = this.props;
    if (isNewTier) {
      this.setState({ ...this.baseState });
    }
    onClose(e);
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      role,
      mobile,
      password
    } = this.state.form;
    const { loading, missing } = this.state.status;
    const { btnTitle, open, title, roles, isNewTier } = this.props;
    const { classes } = sheet;

    return (
      <SlideIn open={open} onClose={this.resetOnClose} from="right">
        <List className={classes.mainContainer}>
          <ListItem className={classes.container}>
            <Back onClick={this.resetOnClose} />
          </ListItem>
          <form onSubmit={this.handleSubmit}>
            <ListItem className={classes.container}>
              <Typography variant="h3">{title}</Typography>
            </ListItem>
            <ListItem className={classes.container}>
              <div className={classes.section}>
                <Typography variant="h4" className={classes.title}>
                  First Name
                </Typography>
                <TextField
                  id="outlined-password-input"
                  name="firstName"
                  label="First Name"
                  type="text"
                  autoComplete="current-password"
                  margin="normal"
                  variant="outlined"
                  className={classes.txtField}
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={firstName.value}
                  error={
                    firstName.error.isError || missing.includes("firstName")
                  }
                />

                <Typography variant="h4" className={classes.title}>
                  Last Name
                </Typography>
                <TextField
                  name="lastName"
                  label="Last Name"
                  type="text"
                  autoComplete="current-password"
                  margin="normal"
                  variant="outlined"
                  className={classes.txtField}
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={lastName.value}
                  error={lastName.error.isError || missing.includes("lastName")}
                />

                <Typography variant="h4" className={classes.title}>
                  Email
                </Typography>
                <TextField
                  name="email"
                  label="Email"
                  type="email"
                  margin="normal"
                  variant="outlined"
                  className={classes.txtField}
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={email.value}
                  error={email.error.isError || missing.includes("email")}
                />

                <Typography variant="h4" className={classes.title}>
                  Phone Number
                </Typography>
                <TextField
                  name="mobile"
                  label="Phone Number"
                  type="number"
                  margin="normal"
                  variant="outlined"
                  className={classes.txtField}
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={mobile.value}
                  error={mobile.error.isError || missing.includes("mobile")}
                />
                {isNewTier ? (
                  <>
                    <Typography variant="h4" className={classes.title}>
                      Password
                    </Typography>
                    <PasswordShowHide
                      variant="outlined"
                      className={classes.txtField}
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      onChange={this.handleChange}
                      onBlur={this.handleChange}
                      id="off"
                      value={password.value}
                      error={
                        password.error.isError || missing.includes("password")
                      }
                    />
                  </>
                ) : null}

                <Typography variant="h4" className={classes.title}>
                  Roles
                </Typography>
                <FormControl variant="outlined" className={classes.dropDown}>
                  <InputLabel> Roles </InputLabel>
                  <Select
                    value={role.value}
                    onChange={e => this.handleChange(e, null)}
                    onBlur={e => this.handleChange(e, null)}
                    name="role"
                    error={role.error.isError || missing.includes("role")}
                  >
                    {roles.length > 0 &&
                      roles.map(({ name, value }, i) => {
                        return (
                          <MenuItem key={i} value={name}>
                            {name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

                <div>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    className={classes.createBtn}
                    disabled={loading}
                  >
                    {!loading ? (
                      btnTitle
                    ) : (
                      <CircularProgress color="inherit" size={24} />
                    )}
                  </Button>
                </div>
              </div>
            </ListItem>
          </form>
        </List>
      </SlideIn>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(null, mapDispatchToProps)(EditAdminSlideIn);
