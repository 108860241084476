import React from "react";
import ThumbsDown from "../../vectors/ThumbsDown";
import ThumbsUp from "../../vectors/ThumbsUp";

const useComingSoonListColumn = () => {
  const comingSoonListColumns = [
    { field: "movieTitle", headerName: "Movie Title", width: 250 },
    { field: "genre", headerName: "Genre", width: 200 },
    { field: "releaseDate", headerName: "Release Date", width: 200 },
    { field: "id", headerName: "ID", width: 100 },
    { field: "is_available_for_display", headerName: "Status", width: 130 },
    {
      field: "has_movie_db_details",
      headerName: "Database",
      width: 130,
      renderCell: params => {
        return params.row.has_movie_db_details ? <ThumbsUp /> : <ThumbsDown />;
      }
    }
  ];

  return comingSoonListColumns;
};

export default useComingSoonListColumn;
