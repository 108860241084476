import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import { Typography, TextField } from "@material-ui/core";
import movieApi from "../../../api/movie-entry-api";
import { Autocomplete } from "@material-ui/lab";
import empty from "../../../assets/images/empty-concessions.png";
import { useDispatch } from "react-redux";
import { debounce, getRequestError, slugify } from "../../../utils/functions";
import types from "../../../redux/types";
import referralApi from "../../../api/referral-links-api";
import { uid } from "uid";
import validator from "validator";

const AddReferralLink = ({ open, handleClose, add, edit, jwt, type, data }) => {
  const { referralLinkDialog, pair, characterField, movieList } = useStyles();
  const [title, setTitle] = useState({
    value: "",
    error: { isError: false, message: "" }
  });
  const [name, setName] = useState({
    value: "",
    error: { isError: false, message: "" }
  });
  const [rosettaMovieID, setRosettaMovieID] = useState({
    value: "",
    error: { isError: false, message: "" }
  });
  const [loading, setLoading] = useState();
  const [searchedMovie, setSearchedMovie] = useState([]);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const onTextChange = (value, setter) => {
    if (!validator.isEmpty(value)) {
      setter({ value: value, error: { isError: false, error: "" } });
    } else {
      setter({
        value,
        error: { isError: true, message: "Field is empty" }
      });
    }
  };

  const handleMovieSearch = async search => {
    try {
      const res = await movieApi.searchMovieFHDB(search, jwt);
      if (res.data.data) {
        setSearchedMovie(
          res?.data?.data?.map(item => ({
            id: item?.id,
            rosettaId: item?.rosetta_movie_id,
            movieTitle: item?.movie_title,
            label: item?.movie_title,
            img: item?.small_poster ?? empty
          })) ?? []
        );
      } else {
        setSearchedMovie([]);
      }
    } catch (e) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(e)
        }
      });
    }
  };

  useEffect(() => {
    if (!open) {
      setTitle({
        value: "",
        error: { isError: false, message: "" }
      });
      setName({
        value: "",
        error: { isError: false, message: "" }
      });
      setRosettaMovieID({
        value: "",
        error: { isError: false, message: "" }
      });
    }
    setLoading(false);
  }, [open]);

  useEffect(() => {
    if (data) {
      setName({
        value: data.name,
        error: { isError: false, message: "" }
      });
      setRosettaMovieID({
        value: data.movieId,
        error: { isError: false, message: "" }
      });
      setTitle({
        value: data.movieTitle,
        error: { isError: false, message: "" }
      });
    }
  }, [data]);

  const extHandleee = () => {
    setTitle({
      value: "",
      error: { isError: false, message: "" }
    });
    setName({
      value: "",
      error: { isError: false, message: "" }
    });
    setRosettaMovieID({
      value: "",
      error: { isError: false, message: "" }
    });
    handleClose();
  };

  const validateInputs = () => {
    if (
      validator.isEmpty(title.value) ||
      validator.isEmpty(name.value) ||
      validator.isEmpty(rosettaMovieID.value)
    ) {
      validator.isEmpty(title.value) &&
        setTitle({
          value: title.value,
          error: { isError: true, message: "This field is empty" }
        });
      validator.isEmpty(name.value) &&
        setName({
          value: name.value,
          error: { isError: true, message: "This field is empty" }
        });
      !validator.isEmail(rosettaMovieID.value) &&
        setRosettaMovieID({
          value: rosettaMovieID.value,
          error: { isError: true, message: "This field is empty" }
        });
      return true;
    }
    return false;
  };

  const handleSubmit = async e => {
    console.log("eee");
    e.preventDefault();
    try {
      if (validateInputs()) return;
      setLoading(true);
      const code = uid(8);
      let referralLinkPayload;
      if (type === "add") {
        const referralRes = await referralApi.getShortLink(
          `https://www.filmhouseng.com/movie/${rosettaMovieID.value}?referalCode=${code}`
        );
        console.log(referralRes);
        referralLinkPayload = {
          name: name.value,
          movie_id: rosettaMovieID.value,
          referral_code: code,
          short_referral_link: referralRes?.data?.link,
          long_referral_link: `https://www.filmhouseng.com/movie/${rosettaMovieID.value}?referalCode=${code}`
        };
      } else {
        const referralRes = await referralApi.getShortLink(
          `https://www.filmhouseng.com/movie/${rosettaMovieID.value}?referalCode=${code}`
        );
        console.log(referralRes);
        referralLinkPayload = {
          name,
          movie_id: rosettaMovieID.value,
          short_referral_link: referralRes?.data?.link,
          long_referral_link: `https://www.filmhouseng.com/movie/${rosettaMovieID.value}?referalCode=${code}`
        };
      }
      if (type === "add") {
        const res = await add(referralLinkPayload);
        setLoading(false);
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "success",
            message: res?.data?.message ?? "Created referral link"
          }
        });
      } else if (type === "edit") {
        const res = await add(referralLinkPayload);
        setLoading(false);
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "success",
            message: res?.data?.message ?? "Updated referral link"
          }
        });
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(e)
        }
      });
    }
  };

  return (
    <Dialog
      fullWidth="xl"
      open={open}
      onClose={extHandleee}
      aria-labelledby="max-width-dialog-title"
    >
      <div className={referralLinkDialog}>
        <DialogTitle id="max-width-dialog-title">
          <Typography variant="h4">
            {type === "add" ? "New" : "Edit"} Referral Link
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form
              id="referral-link-form"
              style={{ marginTop: "20px" }}
              onSubmit={handleSubmit}
            >
              <div className={pair}>
                <TextField
                  name="name"
                  onChange={e => onTextChange(e.target.value, setName)}
                  value={name.value}
                  variant="outlined"
                  label="Name"
                  className={characterField}
                  error={name.error.isError}
                  helperText={name.error.message}
                />
              </div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={searchedMovie}
                getOptionLabel={option => option.label}
                onChange={(_, value) => {
                  if (value) {
                    onTextChange(value.movieTitle, setTitle);
                    onTextChange(value.rosettaId.toString(), setRosettaMovieID);
                  }
                }}
                style={{ flex: 1 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Movie Title"
                    name="title"
                    value={title.value}
                    variant="outlined"
                    error={title.error.isError ?? rosettaMovieID.error.isError}
                    helperText={
                      title.error.message ?? rosettaMovieID.error.message
                    }
                    onChange={e => {
                      e.persist();
                      if (e.target.value) {
                        debounce(() => handleMovieSearch(e.target.value), 1000);
                      }
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li className={movieList}>
                    <img
                      loading="lazy"
                      width="20"
                      height="20"
                      src={props?.img}
                      srcSet={props?.img}
                      alt=""
                    />
                    <span>{props?.label ?? ""}</span>
                  </li>
                )}
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            CLOSE
          </Button>
          <Button
            form="referral-link-form"
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {type === "add"
              ? loading
                ? "ADDING..."
                : "ADD"
              : loading
              ? "EDITING"
              : "EDIT"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AddReferralLink;
