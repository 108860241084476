export default [
  {
    id: "companyName",
    numeric: false,
    disablePadding: true,
    label: "Company Name"
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "No of Vouchers"
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created on"
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: false,
    label: "Created by"
  },
  {
    id: "generatedById",
    numeric: false,
    disablePadding: false,
    label: "Approved by"
  },
  {
    id: "comments",
    numeric: false,
    disablePadding: false,
    label: "Comments"
  },
  {
    id: "download",
    numeric: false,
    disablePadding: false,
    label: ""
  }
];
