import React from "react";
import Promotions from "./Promotions";
import AddPromotion from "./AddPromotion";
import EditPromotion from "./EditPromotion";

export default [
  {
    path: "/dashboard/promotions",
    exact: true,
    component: () => <Promotions />
  },
  {
    path: "/dashboard/promotions/add",
    exact: true,
    component: () => <AddPromotion />
  },
  {
    path: "/dashboard/promotions/:id",
    exact: true,
    component: () => <EditPromotion />
  }
];
