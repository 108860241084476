import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reverse } from "lodash";
import CategoryList from "./List";
import EditCategorySlideIn from "./EditCategorySlideIn";
import sheet from "./styles";
import api from "../../api/category-api";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import PaperedInput from "../../components/Inputs/PaperedInput";
import userListParser from "../../components/functions/parseList";
import sorting from "../../components/functions/sorting";
import { CircularLoad } from "../../components/Loader";

const CategoryListPage = ({ jwt }) => {
  const [loading, setLoading] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [panelTitle, setPanelTitle] = useState("Create new tier");
  const [btnTitle, setBtnTitle] = useState("Create");
  const [categoryList, setCategoryList] = useState();
  const [filteredData, setFilteredList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [editCategoryData, setEditCategoryData] = useState();
  const [newCategory, setNewCategory] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    api.getCategoryList(`Bearer ${jwt}`).then(res => {
      const [categories] = res;
      setCategoryList(categories.data.data);
      setFilteredList(categories.data.data);
    });
  }, [jwt]);

  const refreshList = () => {
    setLoading(true);
    api.getCategoryList(`Bearer ${jwt}`).then(res => {
      const [categories] = res;
      setCategoryList(categories.data.data);
      setFilteredList(categories.data.data);
      setLoading(false);
    });
  };

  const handleSearch = ({ target }) => {
    const { value } = target;
    const newData = categoryList.filter(item => sorting.search(value, item));
    setFilteredList(newData);
  };

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowPanel(open);
  };

  const editCategorySlideIn = (e, id) => {
    setNewCategory(false);
    setCategoryId(id);
    setPanelTitle("Edit category");
    setBtnTitle("Submit");
    setShowPanel(true);
    api
      .getCategory(id, `Bearer ${jwt}`)
      .then(res => {
        setEditCategoryData(res[0].data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const addAdminSlideIn = () => {
    setNewCategory(true);
    setBtnTitle("Create");
    setPanelTitle("Create new category");
    setShowPanel(true);
  };
  const closeDialog = () => {
    return setShowDialog(false);
  };
  const showDeleteDialog = (e, id) => {
    setShowDialog(true);
    setCategoryId(id);
  };
  const deleteAdmin = () => {
    setIsDeleting(true);
    api
      .deleteCategory(categoryId, `Bearer ${jwt}`)
      .then(res => {
        setIsDeleting(false);
        closeDialog();
        refreshList();
      })
      .catch(err => setIsDeleting(false));
  };
  const { classes } = sheet;

  return (
    <div>
      <Dialog open={showDialog} onClose={closeDialog}>
        <DialogTitle>Delete Category </DialogTitle>
        <DialogContent>
          {isDeleting ? (
            <CircularLoad show={isDeleting} />
          ) : (
            `Are you sure you want to delete the category with ID of: ${categoryId} ?`
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} disabled={isDeleting}>
            No
          </Button>
          <Button onClick={deleteAdmin} disabled={isDeleting}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.addTier}>
        <div className={classes.addTierBtn} onClick={addAdminSlideIn}>
          <Button color="primary">+ Add Category</Button>
        </div>
      </div>
      <div className={classes.tableHead}>
        <div className={classes.subTier}></div>
        <div>
          <PaperedInput
            onChange={handleSearch}
            placeholder="Search for category"
          />
        </div>
      </div>
      {loading && <CircularLoad />}
      {/** change string uploading to material UI uploading */}
      {!loading && (
        <CategoryList
          data={filteredData}
          openSlideIn={editCategorySlideIn}
          deleteTier={deleteAdmin}
          showDeleteDialog={showDeleteDialog}
        />
      )}

      <EditCategorySlideIn
        title={panelTitle}
        btnTitle={btnTitle}
        open={showPanel}
        close={() => setShowPanel(false)}
        onClose={toggleDrawer(false)}
        benefits={null}
        jwt={jwt}
        refreshList={refreshList}
        data={editCategoryData}
        isNewCategory={newCategory}
        categoryId={categoryId}
        roles={roles}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  jwt: state.user.user.jwt
});
export default connect(mapStateToProps)(CategoryListPage);
