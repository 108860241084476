export default [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name"
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type"
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price"
  },
  {
    id: "unitsSold",
    numeric: false,
    disablePadding: false,
    label: "Units Sold"
  },
  {
    id: "sizes",
    numeric: false,
    disablePadding: false,
    label: "Sizes"
  }
];
