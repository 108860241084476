import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import { useStyles } from "./styles";
import React from "react";
import CircledClose from "../../../vectors/CircledClose";
import { DataGrid } from "@material-ui/data-grid";

const dummyData = [
  {
    id: 0,
    details: "Ticket Purchase for [Movie title]",
    point: "- 15 points",
    time_date: "2021-06-04 13:52:22"
  },
  {
    id: 1,
    details: "Point earned",
    point: "+ 5points",
    time_date: "2021-06-04 13:52:22"
  },
  {
    id: 2,
    details: "Ticket Purchase for [Movie title]",
    point: "- 15 points",
    time_date: "2021-06-04 13:52:22"
  },
  {
    id: 3,
    details: "Point earned",
    point: "+ 5points",
    time_date: "2021-06-04 13:52:22"
  }
];

const TransactionHistory = ({ open, handleClose }) => {
  const classes = useStyles();

  const columns = [
    {
      field: "details",
      headerName: "Details",
      width: 443
    },
    {
      field: "point",
      headerName: "Point",
      width: 115,
      renderCell: params => (
        <button
          onClick={() => {}}
          className={
            params.row.point.includes("-")
              ? classes.decrement
              : classes.increment
          }
        >
          {params.row.point}
        </button>
      )
    },
    {
      field: "time_date",
      headerName: "Time & Date",
      width: 195
    }
  ];
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.historyDialog}
      >
        <DialogTitle id="max-width-dialog-title">
          <span className={classes.dialogHeading}>
            Transaction History
            <button onClick={handleClose}>
              <CircledClose />
            </button>
          </span>
        </DialogTitle>
        <DialogContent>
          <DataGrid
            rows={dummyData}
            columns={columns}
            pageSize={15}
            rowHeight={50}
            headerHeight={60}
            disableColumnMenu={true}
            autoHeight={true}
            loading={false}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default TransactionHistory;
