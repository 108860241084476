import types from "../../../types";

const { MAP_SELECTED_MOVIE, CLEAR_MOVIE_DETAIL, GET_CATEGORIES } = types;

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_MOVIE_DETAIL:
      return {};
    case MAP_SELECTED_MOVIE:
      return { ...action.details };
    case GET_CATEGORIES:
      return { ...state, categories: action.categories };
    default:
      return state;
  }
};
