import React from "react";
import { Typography, makeStyles, TextField } from "@material-ui/core";
import SubInputContainer from "../../containers/MovieEntry/SubInputContainer";

const useStyles = makeStyles(theme => ({
  movieTitle: {
    fontSize: "3.75rem",
    fontWeight: 300,
    width: "100%"
  }
}));

const EntryTitle = ({ title, create, error, onChange, missing }) => {
  const classes = useStyles();
  if (create) {
    return (
      <SubInputContainer title="Movie Title">
        <TextField
          error={error.isError || missing.includes("title")}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="title"
          type="text"
          label="Movie title"
          name="title"
          onBlur={onChange}
          onChange={onChange}
          helperText={
            (error.isError || missing.includes("title")) &&
            (error.message || "Title is empty")
          }
          className={classes.movieTitle}
          value={title}
        />
      </SubInputContainer>
    );
  } else {
    return <Typography variant="h2">{title}</Typography>;
  }
};
EntryTitle.defaultProps = {
  title: "n/a"
};
export default EntryTitle;
