import React from "react";

const Plus = props => (
  <svg
    {...props}
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.23047 10.2285H17.2278"
      stroke="#A233E2"
      strokeWidth="2.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M10.2295 17.2278V3.23047"
      stroke="#A233E2"
      strokeWidth="2.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export default Plus;
