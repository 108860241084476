import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4)
  },
  title: {
    color: "rgba(151, 151, 151, 0.57)",
    marginBottom: theme.spacing(1)
  },
  mediaTitle: {
    color: "rgba(255,255,255,0.87)",
    marginBottom: theme.spacing(2),
    fontSize: "30px"
  },
  content: {
    maxWidth: "80%"
  }
}));

export default useStyles;
