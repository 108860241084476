import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 60
  },
  recommendationHeading: {
    color: "#666679",
    marginBottom: "10px",
    fontSize: "16px"
  },
  reccommendationList: {
    marginBottom: "40px",
    "& li": {
      // listStyle: "none",
      color: "#666679",
      marginBottom: "5px",
      marginLeft: "8px"
    }
  },
  sendNotificationsPageHeading: {
    fontWeight: "500",
    fontSize: "32px",
    lineHeight: "47px",
    color: "#FFFFFF",
    margin: "0px 0 20px 0"
  },
  imageUploader: {
    marginBottom: "20px"
  },
  uploadedImage: {
    width: 100,
    height: 100,
    marginBottom: "25px",
    objectFit: "cover"
  },
  btnContainer: {
    display: "flex",
    gap: "14px",
    alignItems: "flex-start",
    justifyContent: "flex-end"
  },
  tablerowBody: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  btn: {
    fontSize: "16px",
    fontWeight: "900",
    lineHeight: "22px",
    borderRadius: "5px"
  },
  sendBtnContainer: {
    position: "relative"
  },
  sendBtn: {
    padding: "8px 36px 8px 24px",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
  },
  cancelBtn: {
    padding: "8px 24px"
  },
  scheduleBtn: {
    backgroundColor: "#404045",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    display: "flex",
    alignItems: "center",
    padding: "8px 24px",
    transform: props =>
      !props.showSchedule ? "translateY(-100%)" : "translateY(0)",
    transition: "transform 0.3s ease-in-out",
    "& svg": {
      marginRight: "10px"
    }
  },
  scheduleBtnContainer: {
    overflow: "hidden",
    marginTop: "8px"
  },
  scheduleArrow: {
    position: "absolute",
    height: "100%",
    width: "36px",
    right: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#682191",
    borderRadius: "0px 5px 5px 0px",
    outline: "none",
    background: "none",
    border: "none",
    cursor: "pointer"
  },
  input: {
    marginBottom: "20px",
    "& .MuiOutlinedInput-input": {
      padding: "15px 14px"
    }
  },
  body: {
    "& .MuiOutlinedInput-multiline": {
      height: "80px"
    }
  },
  copyButton: {
    outline: "none",
    background: "none",
    border: "none",
    cursor: "pointer"
  },
  cancelNotif: {
    outline: "none",
    background: "none",
    border: "none",
    color: " #A233E2",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "500"
  },
  scheduleTitle: {
    fontWeight: "500",
    fontSize: "32px",
    lineHeight: "47px",
    color: "#FFFFFF",
    marginBottom: "2px",
    display: "block"
  },
  scheduleTimeStandard: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#AEAEB1",
    display: "block"
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      "@media screen and (min-width: 1536px)": {
        maxWidth: "909px",
        padding: "47px 37px 30px 37px"
      },
      maxWidth: "609px",
      width: "80%",
      padding: "30px 20px"
    },
    "& .MuiPaper-root": {
      backgroundColor: "#1C1C22"
    }
  },
  date: {
    backgroundColor: "transparent",
    borderRadius: "5px",
    position: "relative",
    cursor: "pointer",
    border: "1px solid #515155",
    "& .MuiInputBase-input": {
      padding: "16px 14px",
      minHeight: "1.1876em",
      lineHeight: "1.5",
      height: "auto",
      cursor: "pointer"
    },
    "& .MuiInput-underline:after": {
      display: "none"
    },
    "& label + .MuiInput-formControl": {
      marginTop: 0
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(4px, -50%) scale(0.75)",
      backgroundColor: "#1C1C22"
    },
    "& .MuiInput-underline:before": {
      display: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
      cursor: "pointer"
    }
  }
}));
