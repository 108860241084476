import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MoviePoster from "../MovieList/MoviePoster";
import useStyles from "./styles";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography
} from "@material-ui/core";
import moviesApi from "../../api/movie-entry-api";
import fileApi from "../../api/file";
import { useDispatch } from "react-redux";
import { getRequestError } from "../../utils/functions";
import types from "../../redux/types";
import { CircularLoad } from "../Loader";

const MovieDBDialog = ({
  title,
  open,
  handleClose,
  storeMovieDetail,
  movies,
  loading,
  jwt
}) => {
  const [fields, setFields] = useState([
    "title",
    "overview",
    "genres",
    "casts",
    "releaseYear",
    "featureLength",
    "backdropImage",
    "posterImage",
    "posterImageSmall",
    "backdropImageSmall"
  ]);
  const [singleMovieLoading, setSingleMovieLoading] = useState(false);
  const [selectedMovieId, setSelectedMovieId] = useState(null);
  const {
    movieContainer,
    dialogContainer,
    dialogDetails,
    emptyMovies
  } = useStyles();

  const dispatch = useDispatch();

  const { ALERT_OPEN } = types;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <div className={dialogContainer}>
        <DialogTitle id="max-width-dialog-title">
          <Typography component="span" variant="h4">
            Movies in DB
          </Typography>
          <div className={dialogDetails}>
            <div>
              <Typography variant="caption">
                Select the field you want to update with the movie DB then click
                on the movie.
              </Typography>
            </div>
            <FormGroup row>
              {[
                { name: "Title", value: "title" },
                { name: "Description", value: "overview" },
                { name: "Genres", value: "genres" },
                { name: "Background Image", value: "backdropImage" },
                { name: "Background Image Small", value: "backdropImageSmall" },
                { name: "Poster Image", value: "posterImage" },
                { name: "Poster Image Small", value: "posterImageSmall" },
                { name: "Film casts", value: "casts" },
                { name: "Release Year", value: "releaseYear" },
                { name: "Runtime", value: "featureLength" }
              ].map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      name={item.value}
                      onChange={e => {
                        e.target.checked
                          ? setFields(prevFields =>
                              !prevFields.includes(item.value)
                                ? [...prevFields, item.value]
                                : prevFields
                            )
                          : fields.includes(item.value) &&
                            setFields(prevFields => [
                              ...prevFields.filter(
                                _item => _item !== item.value
                              )
                            ]);
                      }}
                      value={item.value}
                      checked={fields.includes(item.value)}
                      color="primary"
                    />
                  }
                  label={item.name}
                />
              ))}
            </FormGroup>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <div className={movieContainer}>
              {loading ? (
                <CircularLoad show />
              ) : movies.length ? (
                movies.map(({ id, poster_path, title, release_date }, i) => (
                  <MoviePoster
                    chosen={selectedMovieId}
                    index={i}
                    loading={
                      singleMovieLoading?.id === id
                        ? singleMovieLoading?.loading
                        : false
                    }
                    onClick={e => {
                      setSingleMovieLoading({ loading: true, id });
                      moviesApi
                        .getSingleMovieDB(id)
                        .then(res => {
                          const movie = {
                            title: res.data.data?.title ?? "",
                            overview: res.data.data?.overview ?? "",
                            genres:
                              res.data.data?.genres?.map(item => item.name) ??
                              [],
                            backdropImage: res.data.data.backdrop_path
                              ? `https://image.tmdb.org/t/p/original${res.data.data.backdrop_path}`
                              : "",
                            backdropImageSmall: res.data.data.backdrop_path
                              ? `https://image.tmdb.org/t/p/original${res.data.data.backdrop_path}`
                              : "",
                            posterImage: res.data.data.poster_path
                              ? `https://image.tmdb.org/t/p/original${res.data.data.poster_path}`
                              : "",
                            posterImageSmall: res.data.data.poster_path
                              ? `https://image.tmdb.org/t/p/original${res.data.data.poster_path}`
                              : "",
                            casts: res.data.data["cast-data"]
                              ? res.data.data["cast-data"].cast?.map(item => ({
                                  id: item?.id,
                                  profileImage: item.profile_path
                                    ? `https://image.tmdb.org/t/p/original${item.profile_path}`
                                    : null,
                                  name: item?.name,
                                  order: item?.order,
                                  character: item?.character
                                }))
                              : [],
                            releaseYear:
                              res.data.data?.release_date.slice(0, 4) ?? "",
                            featureLength: res.data.data?.runtime ?? ""
                          };
                          storeMovieDetail(fields, movie).then(res => {
                            setSelectedMovieId(id);
                            handleClose();
                            setSingleMovieLoading(false);
                          });
                        })
                        .catch(err => {
                          dispatch({
                            type: ALERT_OPEN,
                            payload: {
                              severity: "error",
                              message: getRequestError(err)
                            }
                          });
                          setSingleMovieLoading(false);
                        });
                    }}
                    withDetails={true}
                    key={i}
                    id={id}
                    image={`https://image.tmdb.org/t/p/original${poster_path}`}
                    name={title}
                    date={release_date}
                  />
                ))
              ) : (
                <p className={emptyMovies}>No movies available</p>
              )}
            </div>
            <div className=""></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default MovieDBDialog;
