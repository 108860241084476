import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  tabsContainer: {
    display: "flex",
    marginTop: "48px"
  },
  tab: props => ({
    display: "flex",
    gap: "30px",
    alignItems: "center",
    justifyContent: "center",
    padding: "13px 24px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "19px",
    letterSpacing: "0em",
    border: "1px solid #3C2150",
    fontFamily: "Roboto"
  }),
  movieCol: {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  movieImg: {
    width: "34px",
    height: "34px",
    objectFit: "cover"
  },
  view: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0em",
    color: "#ffffff",
    textDecoration: "underline"
  },
  cancel: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0em",
    color: "#D92D20",
    textDecoration: "underline"
  },
  approve: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0em",
    color: "#039855",
    textDecoration: "underline"
  },
  dialog: {
    fontFamily: "Roboto",
    "& span": {
      display: "inline-block"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px",
      width: "80%",
      padding: "32px"
    },
    "& .MuiPaper-root": {
      backgroundColor: "#1C1C22"
    }
  },
  detailsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px"
  },
  detailsHeading: {
    fontSize: "40px",
    fontWeight: "500",
    color: "#FFFFFF",
    lineHeight: "46px"
  },
  detailsContentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "32px",
    backgroundColor: "#232329",
    gap: "24px"
  },
  detailsMovieContainer: {
    display: "flex",
    alignItems: "center",
    gap: "20px"
  },
  detailsMovieImg: {
    width: "131px",
    height: "143px",
    radius: "6px",
    objectFit: "cover"
  },
  detailsMovieTitle: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#FFFFFF",
    lineHeight: "23px"
  },
  detailsMovieMetaContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "12px"
  },
  detailsMovieMeta: {
    fontSize: "18px",
    opacity: "0.6",
    color: "#FFFFFF",
    lineHeight: "21px",
    fontWeight: "500"
  },
  detailsDivider: {
    width: "100%",
    height: "1px",
    opacity: "0.3",
    backgroundColor: "#656569"
  },
  detailsUserRow: {
    display: "flex",
    alignItems: "center",
    gap: "24px"
  },
  detailsUserColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "6px"
  },
  detailsUserTitle: {
    fontSize: "18px",
    opacity: "0.6",
    color: "#FFFFFF",
    lineHeight: "21px",
    fontWeight: "500"
  },
  detailsUserValue: {
    fontSize: "18px",
    color: "#FFFFFF",
    lineHeight: "21px",
    fontWeight: "500"
  },
  detailsClose: {
    padding: "12px 30px",
    fontSize: "16px",
    borderRadius: 0
  },
  bookingForm: {
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    gap: "32px"
  },
  formHeading: {
    fontFamily: "Roboto",
    fontSize: "20px",
    color: "#FFFFFF",
    lineHeight: "23px",
    fontWeight: "500",
    marginBottom: "24px"
  },
  movieformContainer: {
    display: "flex",
    alignItems: "stretch",
    gap: "16px",
    flexDirection: "column"
  },
  contactformContainer: {
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    gap: "25px"
  },
  fullWidthInput: {
    width: "100%"
  },
  formAction: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    justifyContent: "flex-end"
  },
  formBtn: {
    padding: "12px 30px",
    fontSize: "16px",
    borderRadius: 0,
    fontWeight: "600",
    color: "white"
  },
  confirmDialog: {
    backgroundColor: "#1C1C22",
    padding: "32px 8px",
    "& span": {
      display: "inline-block"
    }
  },
  confirmDialogTitle: {
    fontFamily: "Roboto",
    fontSize: "28px",
    fontWeight: "500",
    lineHeight: "33px",
    letterSpacing: "0em"
  },
  confirmDialogText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "21px",
    marginBottom: "48px",
    color: "#AEAEB1"
  },
  confirmDialogAction: {
    display: "flex",
    alignItems: "center",
    gap: "24px"
  },
  confirmDialogBtn: {
    padding: "12px 30px",
    fontSize: "16px",
    borderRadius: 0,
    fontWeight: "600",
    color: "white"
  },
  dangerBtn: {
    backgroundColor: "#D92D20",
    borderColor: "#D92D20",
    "&:hover": {
      backgroundColor: "#b23537",
      borderColor: "#b23537"
    }
  },
  successBtn: {
    backgroundColor: "#039855",
    borderColor: "#039855",
    "&:hover": {
      backgroundColor: "#02512d",
      borderColor: "#02512d"
    }
  },
  copy: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  copyButton: {
    outline: "none",
    background: "none",
    border: "none",
    cursor: "pointer"
  }
}));
