import types from "../../types";

const { START_LOAD_PAGE, STOP_LOAD_PAGE } = types;

const initialState = false

export default (state = initialState, action) => {
    switch (action.type) {
        case START_LOAD_PAGE:
            return true;
        case STOP_LOAD_PAGE:
            return false;
        default:
            return state;
    }
};
