import axios from "axios";

export default {
  getList: token =>
    axios
      .get("/voucher-groups?$include=vouchers,generatedBy", {
        headers: { Authorization: token }
      })
      .then(res => res),
  addBulkVouchers: (token, payload) =>
    axios
      .post("/generate-subscription-vouchers", payload, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
