import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import { Typography, TextField, Checkbox } from "@material-ui/core";
import FormValidator from "./../../../utils/FormValidator";

const AddBulkVoucher = ({ open, handleClose, add }) => {
  const { castsDialog, pair, errorMsgStyle, characterField } = useStyles();
  const [companyName, setCompanyName] = useState();
  const [quantity, setQuantity] = useState();
  const [approvedBy, setApprovedBy] = useState();
  const [comment, setComment] = useState();
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [loading, setLoading] = useState();

  const validator = new FormValidator([
    {
      field: "companyName",
      method: "isEmpty",
      validWhen: false,
      message: "Title cannot be empty."
    },
    {
      field: "quantity",
      method: "isInt",
      validWhen: true,
      message: "Quantity must be a number."
    },
    {
      field: "approvedBy",
      method: "isEmpty",
      validWhen: false,
      message: "Approved by cannot be empty."
    }
  ]);
  const [validation, setValidation] = useState(validator.reset());

  useEffect(() => {
    if (!open) {
      setCompanyName("");
      setQuantity("");
      setApprovedBy("");
      setComment("");
      setPaymentConfirmed("");
    }
    setLoading(false);
  }, [open]);

  const extHandleClose = () => {
    setCompanyName("");
    setQuantity("");
    setApprovedBy("");
    setComment("");
    setPaymentConfirmed("");
    handleClose();
  };

  const handleChange = ({ target }, inputField, validator) => {
    const inputCheck = validator ? validator : () => true;
    const { value } = target;
    if (inputCheck(value)) {
      inputField(value);
    }
  };

  const handleAdd = async e => {
    e.preventDefault();
    const bulkVouchersPayload = {
      companyName,
      quantity: parseInt(quantity),
      approvedBy,
      comment,
      subscriptionId: 401,
      paymentConfirmed
    };
    setLoading(true);

    const _validation = validator.validate(bulkVouchersPayload);

    if (_validation.isValid) {
      setValidation(_validation);
      await add(bulkVouchersPayload).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
      setValidation(_validation);
    }
  };

  const errorMsg = propertyName => {
    return (
      !validation[propertyName].isValid && (
        <p className={errorMsgStyle}>{validation[propertyName].message}</p>
      )
    );
  };

  return (
    <Dialog
      fullWidth="xl"
      open={open}
      onClose={extHandleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <div className={castsDialog}>
        <DialogTitle id="max-width-dialog-title">
          <Typography variant="h4">Create vouchers</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form
              id="form-test"
              style={{ marginTop: "20px" }}
              onSubmit={handleAdd}
            >
              <div className={pair}>
                <TextField
                  name="companyName"
                  onChange={e => handleChange(e, setCompanyName)}
                  value={companyName}
                  variant="outlined"
                  label="Company name"
                  style={{ flex: 1 }}
                />
              </div>
              {errorMsg("companyName")}
              <div className={pair}>
                <TextField
                  name="quantity"
                  onChange={e => handleChange(e, setQuantity)}
                  value={quantity}
                  variant="outlined"
                  label="Number of vouchers"
                  style={{ flex: 1 }}
                />
              </div>
              {errorMsg("quantity")}
              <div className={pair}>
                <TextField
                  name="approvedBy"
                  onChange={e => handleChange(e, setApprovedBy)}
                  value={approvedBy}
                  variant="outlined"
                  label="Approved by"
                  className={characterField}
                />
              </div>
              {errorMsg("approvedBy")}
              <div className={pair}>
                <TextField
                  name="comment"
                  onChange={e => handleChange(e, setComment)}
                  value={comment}
                  multiline
                  rows={3}
                  variant="outlined"
                  label="Comment"
                  className={characterField}
                />
              </div>
              <div className={pair} style={{ display: "block" }}>
                <Checkbox
                  onChange={() => setPaymentConfirmed(!paymentConfirmed)}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                Payment Confirmed
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Close
          </Button>
          <Button
            form="form-test"
            type="submit"
            color="primary"
            disabled={loading || !paymentConfirmed}
          >
            {loading ? "Creating..." : "Create"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AddBulkVoucher;
