import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Grid, Button, makeStyles } from "@material-ui/core";
import api from "../../../api/errors";
import Copy from "../../../vectors/Copy";
import {
  applyEllipsis,
  dateFromISO,
  getRequestError
} from "../../../utils/functions";
import types from "../../../redux/types";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  copy: {
    width: "15px",
    height: "15px",
    marginLeft: "32px",
    cursor: "pointer"
  },
  errorFunction: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  errorFunctionValue: {
    width: "70%"
  },
  button: {
    outline: "none",
    background: "none",
    border: "none",
    width: "20%"
  }
}));

const ErrorLogs = () => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState({ isError: false, message: "" });
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const { copy, button, errorFunction, errorFunctionValue } = useStyles();
  const { jwt } = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  useEffect(() => {
    setLoading(true);
    setError({ isError: false, message: "" });
    api
      .fetchErrors(`Bearer ${jwt}`, page + 1)
      .then(res => {
        const { errors: errorsData = [], totalCount = 0 } = res.data.data;
        setErrors(
          errorsData.map(item => {
            const { hour, minute, second, day, month, year } = dateFromISO(
              item?.createdAt ?? ""
            );
            return {
              date: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
              email: item?.data?.email ?? "_",
              userID: item?.data?.meta?.userId ?? "_",
              id: item?.id ?? "",
              errorMessage: item?.errorStack ?? "",
              function: item?.component ?? ""
            };
          })
        );
        setTotalData(totalCount);
        setLoading(false);
      })
      .catch(e => setError({ isError: false, message: getRequestError(e) }));
  }, [page, jwt]);

  const columns = [
    { field: "date", headerName: "Time & Date", width: 170 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "userID", headerName: "User ID", width: 110 },
    {
      field: "errorMessage",
      headerName: "Error Message",
      width: 300
    },
    {
      field: "function",
      headerName: "Function",
      width: 280,
      renderCell: params => (
        <div className={errorFunction}>
          <p className={errorFunctionValue}>
            {applyEllipsis(params.formattedValue, 30)}{" "}
          </p>
          <button
            onClick={() => {
              navigator.clipboard.writeText(params.formattedValue).then(
                function() {
                  dispatch({
                    type: ALERT_OPEN,
                    payload: {
                      severity: "success",
                      message: "Copied!"
                    }
                  });
                },
                function(err) {
                  console.error("Async: Could not copy text: ", err);
                }
              );
            }}
            className={button}
          >
            <Copy className={copy} />
          </button>
        </div>
      )
    }
  ];

  return (
    <div>
      <>
        <Grid container spacing={3}>
          <Grid item xs={8}></Grid>
          <Grid item xs={4} style={{ textAlign: "right", alignSelf: "center" }}>
            <CSVLink data={errors} filename={"errors-logs.csv"}>
              <Button variant="contained" color="primary">
                <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          {error.isError ? (
            <Alert severity="error">{error.message}</Alert>
          ) : (
            <DataGrid
              rows={errors}
              columns={columns}
              pageSize={10}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              autoHeight={true}
              page={page}
              rowCount={totalData}
              onPageChange={page => {
                setPage(page);
              }}
              paginationMode="server"
              loading={loading}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default ErrorLogs;
