import types from "../../types";

const {
    SEARCH_QUERY,
    SEARCH_RESULTS,
    SEARCH_START,
    SEARCH_STOP,
    CLEAR_SEARCH_RESULTS
} = types;

const initialState = {
    query: "",
    isSearching: false,
    results: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_START:
            return {
                ...state,
                isSearching: true
            };
        case SEARCH_STOP:
            return {
                ...state,
                isSearching: false
            };
        case SEARCH_QUERY:
            return {
                ...state,
                query: action.query
            };
        case SEARCH_RESULTS:
            return {
                ...state,
                results: [...action.results]
            };
        case CLEAR_SEARCH_RESULTS:
            return initialState
        default:
            return state;
    }
};
