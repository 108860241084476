import React from "react";

const Like = props => (
  <svg
    {...props}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.332 5.83398H9.5907L10.3394 3.58932C10.474 3.18398 10.406 2.73465 10.156 2.38798C9.90603 2.04132 9.5007 1.83398 9.07337 1.83398H7.9987C7.8007 1.83398 7.61336 1.92198 7.48603 2.07398L4.3527 5.83398H2.66536C1.93003 5.83398 1.33203 6.43198 1.33203 7.16732V13.1673C1.33203 13.9027 1.93003 14.5007 2.66536 14.5007H11.5367C11.808 14.4998 12.0726 14.4165 12.2955 14.262C12.5185 14.1075 12.6893 13.889 12.7854 13.6353L14.6234 8.73465C14.6513 8.6598 14.6655 8.58054 14.6654 8.50065V7.16732C14.6654 6.43198 14.0674 5.83398 13.332 5.83398ZM2.66536 7.16732H3.9987V13.1673H2.66536V7.16732ZM13.332 8.37998L11.5367 13.1673H5.33203V6.74198L8.3107 3.16732H9.0747L8.03336 6.28932C7.99956 6.38953 7.99013 6.49635 8.00586 6.60094C8.02159 6.70552 8.06202 6.80484 8.12381 6.89068C8.18559 6.97651 8.26695 7.04638 8.36113 7.09449C8.45531 7.1426 8.55961 7.16757 8.66536 7.16732H13.332V8.37998Z"
      fill="white"
    />
  </svg>
);

export default Like;
