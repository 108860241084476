import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import Back from "../../../../components/Button/Back";
import fileApi from "../../../../api/file";
import { useDispatch } from "react-redux";
import types from "../../../../redux/types";
import { getRequestError } from "../../../../utils/functions";
import useStyles from "./style";
import { useHistory } from "react-router-dom";

const FileUpload = ({ description, url, setUrl, placeholder, name }) => {
  const [isUploading, setIsUploading] = useState(false);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const {
    textField,
    fileUploadContainer,
    fileUploadHeading,
    fileUploadBtn,
    inputFile
  } = useStyles();

  const uploadImage = async (type, file) => {
    const payload = new FormData();
    payload.append("file", file);

    setIsUploading(true);

    try {
      const response = await fileApi.uploadImageWithFile(payload);
      const image = response.data.data;
      setUrl(image);
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(error)
        }
      });
    }
  };

  return (
    <div className={fileUploadContainer}>
      <div>
        <div className={fileUploadHeading}>{description}</div>
        <TextField
          disabled
          name={name}
          value={url}
          variant="outlined"
          placeholder={placeholder}
          //   label="Title"
          className={textField}
        />
      </div>
      <label className={fileUploadBtn} disabled={isUploading}>
        {isUploading ? "Uploading" : "Choose file"}
        <input
          type="file"
          className={inputFile}
          onChange={e => {
            e.preventDefault();
            uploadImage("thumbnail", e.target.files[0]);
          }}
          accept="image/x-png,image/gif,image/jpeg,image/jpg"
        />
      </label>
    </div>
  );
};

const ManagePromotion = ({
  handleAction,
  webMedia,
  mobileMedia,
  destinationUrl,
  thumbnailMedia
}) => {
  const [webUrl, setWebUrl] = useState(webMedia);
  const [mobileUrl, setMobileUrl] = useState(mobileMedia);
  const [thumbnailUrl, setThumbnailUrl] = useState(thumbnailMedia);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(destinationUrl);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const history = useHistory();

  const { textField, formBg, heading, submitBtn } = useStyles();

  const handleSubmit = async e => {
    e.preventDefault();
    if (!mobileUrl || !webUrl || !thumbnailUrl) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: "Please upload both images"
        }
      });
      return;
    }
    setLoading(true);
    let payload = {
      web_media: webUrl,
      mobile_media: mobileUrl,
      position: "footer",
      isMovie: false,
      thumbnail: thumbnailUrl
    };
    if (url) payload["url"] = url;
    handleAction(payload)
      .then(res => {})
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Back
        onClick={() => {
          history.goBack();
        }}
      />
      <form onSubmit={handleSubmit} className={formBg} id="manage-promotion">
        <h1 className={heading}>Footer promotion</h1>
        <FileUpload
          name="WebUrl"
          placeholder="Web image url"
          description={
            <p>
              Large Screen Display <span> *Recommended: 3840 by 2160px</span>
            </p>
          }
          url={webUrl}
          setUrl={setWebUrl}
        />
        <FileUpload
          name="MobileUrl"
          placeholder="Mobile image url"
          description={
            <p>
              Mobile Screen Display <span>*Recommended: 375 by 580px</span>
            </p>
          }
          url={mobileUrl}
          setUrl={setMobileUrl}
        />
        <FileUpload
          name="ThumbnailUrl"
          placeholder="Thumbnail image url"
          description={
            <p>
              Thumbnail <span>*Recommended: 371px by 240px</span>
            </p>
          }
          url={thumbnailUrl}
          setUrl={setThumbnailUrl}
        />
        <TextField
          name="Url"
          value={url}
          onChange={e => setUrl(e.target.value)}
          variant="outlined"
          placeholder={"URL link(Optional)"}
          //   label="Title"
          className={textField}
        />
        <div className={submitBtn}>
          <Button
            form="manage-promotion"
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
          >
            SAVE
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ManagePromotion;
