import React from "react";
import Concessions from "./Concessions";
import ComboDeals from "./ComboDeals";

export default [
  {
    path: "/dashboard/concessions",
    exact: true,
    component: () => <Concessions />
  },
  {
    path: "/dashboard/concessions/combo-deals",
    exact: true,
    component: () => <ComboDeals />
  }
];
