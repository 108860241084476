import React from "react";
import DashboardHome from "./DashboardHome";
import MoviesSectionRoute from "./Movies";
import UsersRoute from "./Users";
import TiersRoute from "./Tiers";
import FandBSectionRoute from "./FandB";
import MerchandiseRoute from "./Merchandise";
import AdminRoute from "./Admin";
import NotificationsRoute from "./Notifications";
import CategoryRoute from "./Categories";
import BulkVouchersRoute from "./BulkVouchers";
import HeroBannersRoute from "./HeroBanners";
import ReferralLinksRoute from "./ReferralLinks";
import ErrorLogsRoute from "./ErrorLogs";
import PaymentLogsRoute from "./PaymentLogs";
import ConcessionsRoute from "./Concessions";
import TicketsRoute from "./PurchasedTickets";
import CubeBookingsRoute from "./CubeBookings";
import PointsRoute from "./Points";
import NewHeroBannersRoute from "./NewHeroBanners";
import NewPromotionsRoute from "./NewPromotions";

export default [
  {
    path: "/dashboard/movies",
    component: () => <MoviesSectionRoute />
  },
  {
    path: "/dashboard/tiers",
    component: () => <TiersRoute />
  },
  // {
  //   path: "/dashboard/fandb",
  //   component: () => <FandBSectionRoute />
  // },
  // {
  //   path: "/dashboard/merchandise",
  //   component: () => <MerchandiseRoute />
  // },
  {
    path: "/dashboard/users",
    component: () => <UsersRoute />
  },
  {
    path: "/dashboard/bulk-vouchers",
    component: () => <BulkVouchersRoute />
  },
  {
    path: "/dashboard/referral-links",
    component: () => <ReferralLinksRoute />
  },
  {
    path: "/dashboard/hero-banners",
    component: () => <NewHeroBannersRoute />
  },
  {
    path: "/dashboard/promotions",
    component: () => <NewPromotionsRoute />
  },
  {
    path: "/dashboard/admin",
    component: () => <AdminRoute />
  },
  // {
  //   path: "/dashboard/categories",
  //   component: () => <CategoryRoute />
  // },
  {
    path: "/dashboard/purchased-tickets",
    component: () => <TicketsRoute />
  },
  {
    path: "/dashboard/cube-booking-logs",
    component: () => <CubeBookingsRoute />
  },
  {
    path: "/dashboard/points",
    component: () => <PointsRoute />
  },
  {
    path: "/dashboard/concessions",
    component: () => <ConcessionsRoute />
  },
  {
    path: "/dashboard/error-logs",
    component: () => <ErrorLogsRoute />
  },
  {
    path: "/dashboard/payment-logs",
    component: () => <PaymentLogsRoute />
  },
  {
    path: "/dashboard/notifications",
    component: () => <NotificationsRoute />
  },
  {
    path: "/dashboard",
    component: () => <DashboardHome />
  }
];
