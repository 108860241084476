import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  MenuItem,
  CircularProgress
} from "@material-ui/core";
import api from "../../../api/notifcations";
import { getRequestError, toTitleCase } from "../../../utils/functions";
import validator from "validator";
import { useStyles } from "./styles.js";
import ChevronDown from "../../../vectors/ChevronDown";
import Schedule from "../../../vectors/Schedule";
import ScheduleNotifications from "./ScheduleNotifications";
import types from "../../../redux/types";
import moment from "moment";
import Close from "../../../vectors/Close";
import { uid } from "uid";

const audiences = [
  "TEST-USER",
  "ALL-USERS",
  "SUBSCRIBED-USERS",
  "UNSUBSCRIBED-USERS"
];

const SendNotifications = () => {
  const [audience, setAudience] = useState(audiences[0]);
  const [email, setEmail] = useState({
    value: "",
    error: { isError: false, error: "" }
  });
  const [title, setTitle] = useState({
    value: "",
    error: { isError: false, error: "" }
  });
  const [body, setBody] = useState({
    value: "",
    error: { isError: false, error: "" }
  });
  const [link, setLink] = useState({
    value: "",
    error: { isError: false, error: "" }
  });
  const [name, setName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [showSchedule, setShowSchedule] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(moment());
  const [sendLoading, setSendLoading] = useState(false);
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const { jwt } = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const classes = useStyles({ showSchedule });

  const onTextChange = (e, setter, isValidate = true) => {
    if (!isValidate || !validator.isEmpty(e.target.value)) {
      setter({ value: e.target.value, error: { isError: false, error: "" } });
    } else {
      setter({
        value: e.target.value,
        error: { isError: true, message: "Field is empty" }
      });
    }
  };

  const onEmailChange = e => {
    if (validator.isEmail(e.target.value)) {
      setEmail({ value: e.target.value, error: { isError: false, error: "" } });
    } else {
      setEmail({
        value: e.target.value,
        error: { isError: true, message: "Invalid email" }
      });
    }
  };
  const onTitleChange = e => onTextChange(e, setTitle);
  const onLinkChange = e => onTextChange(e, setLink, false);
  const onBodyChange = e => onTextChange(e, setBody);
  const onNameChange = e => setName(e.target.value);

  const validateInputs = () => {
    if (
      validator.isEmpty(title.value) ||
      validator.isEmpty(body.value) ||
      (audience === audiences[0] && !validator.isEmail(email.value))
    ) {
      validator.isEmpty(title.value) &&
        setTitle({
          value: title.value,
          error: { isError: true, message: "This field is empty" }
        });
      validator.isEmpty(body.value) &&
        setBody({
          value: body.value,
          error: { isError: true, message: "This field is empty" }
        });
      !validator.isEmail(email.value) &&
        setEmail({
          value: email.value,
          error: { isError: true, message: "Invalid email" }
        });
      link.value &&
        !validator.isURL(link.value) &&
        setLink({
          value: link.value,
          error: { isError: true, message: "Invalid url" }
        });
      return true;
    }
    return false;
  };

  const handleScheduleNotification = async (
    is_scheduled = false,
    setLoading
  ) => {
    if (validateInputs()) return;
    setLoading(true);
    try {
      const code = uid(8);
      const formData = new FormData();
      formData.append("title", title.value);
      if (link.value.trim().length >= 1) formData.append("link", link.value);
      formData.append("audience", audience);
      formData.append("body", body.value);
      formData.append("code", code);
      formData.append("schedule_time", moment(scheduleDate).format("HH:mm"));
      formData.append("is_scheduled", is_scheduled);
      formData.append(
        "schedule_date",
        moment(scheduleDate).format("YYYY-MM-DD")
      );
      if (audience === audiences[0]) formData.append("email", email.value);
      if (name.trim().length >= 1) formData.append("name", name);
      if (imageFile) formData.append("file", imageFile);

      const res = await api.scheduleNotification(`Bearer ${jwt}`, formData);
      setLoading(false);
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "success",
          message: res.data.message ?? "Sent notification"
        }
      });
    } catch (err) {
      setLoading(false);
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(err)
        }
      });
    }
  };

  // async/promise function for retrieving image dimensions for a URL
  function imageSize(url) {
    const img = document.createElement("img");

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        // Natural size is the actual image size regardless of rendering.
        // The 'normal' `width`/`height` are for the **rendered** size.
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Resolve promise with the width and height
        resolve({ width, height });
      };

      // Reject promise on error
      img.onerror = reject;
    });

    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;

    return promise;
  }

  const handleUpload = async e => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    if (e.target.files[0]) {
      const imageDimensions = await imageSize(imageUrl);
      console.log(
        e?.target?.files[0]?.size > 1048576,
        e?.target?.files[0]?.size
      );
      if (imageDimensions.width < 300 || imageDimensions.width < 200) {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: "Width must be at least 300px, height at least 200px"
          }
        });
      } else if (e?.target?.files[0]?.size > 1048576) {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: "Image must not be bigger than 1MB"
          }
        });
      } else {
        setImageFile(e.target.files[0]);
      }
      console.info(imageDimensions); // {width: 1337, height: 42}
    }
    // setImageFile(e.target.files[0]);
  };

  return (
    <div>
      <ScheduleNotifications
        open={openScheduleModal}
        handleClose={() => setOpenScheduleModal(false)}
        scheduleDate={scheduleDate}
        setScheduleDate={setScheduleDate}
        handleScheduleNotification={handleScheduleNotification}
        loading={scheduleLoading}
        setLoading={setScheduleLoading}
      />
      <h1 className={classes.sendNotificationsPageHeading}>
        Send Notification
      </h1>
      <TextField
        id="select-change-url"
        select
        variant="outlined"
        label="Select Option"
        name="Select-Option"
        value={audience}
        onChange={e => {
          audiences[0] === e.target.value
            ? !validator.isEmail(email.value) &&
              setEmail({
                value: email.value,
                error: { isError: true, message: "Invalid email" }
              })
            : setEmail({
                value: email.value,
                error: { isError: false, message: "" }
              });
          setAudience(e.target.value);
        }}
        fullWidth
        className={classes.input}
      >
        {audiences.map(item => (
          <MenuItem key={item} value={item}>
            {toTitleCase(item.replace("-", " "))}
          </MenuItem>
        ))}
      </TextField>
      {audience === audiences[0] ? (
        <TextField
          error={email.error.isError}
          variant="outlined"
          required
          fullWidth
          id="email"
          type="text"
          label="Email Address"
          name="email"
          onBlur={onEmailChange}
          onChange={onEmailChange}
          helperText={email.error.message}
          className={classes.input}
          value={email.value}
        />
      ) : null}
      <TextField
        error={title.error.isError}
        variant="outlined"
        required
        fullWidth
        id="title"
        type="text"
        label="Notification Title"
        name="title"
        onBlur={onTitleChange}
        onChange={onTitleChange}
        helperText={title.error.message}
        className={classes.input}
        value={title.value}
      />
      <TextField
        id="outlined-multiline-flexible"
        label="Body"
        name="body"
        multiline
        rowsMax="5"
        fullWidth
        value={body.value}
        onChange={onBodyChange}
        onBlur={onBodyChange}
        variant="outlined"
        error={body.error.isError}
        helperText={body.error.message}
        className={`${classes.input} ${classes.body}`}
      />
      <TextField
        error={link.error.isError}
        variant="outlined"
        required
        fullWidth
        id="link"
        type="text"
        label="Notification Link (Optional)"
        name="link"
        onBlur={onLinkChange}
        onChange={onLinkChange}
        helperText={link.error.message}
        className={classes.input}
        value={link.value}
      />
      <TextField
        variant="outlined"
        required
        fullWidth
        id="name"
        type="text"
        label="Notification Name (Optional)"
        name="name"
        onBlur={onNameChange}
        onChange={onNameChange}
        className={classes.input}
        value={name}
      />
      <div className={`${classes.imageUploader} inline-uploader`}>
        <span className="btn-file">
          CHOOSE NOTIFICATION IMAGE
          <input
            type="file"
            className="custom-file-input"
            onChange={e => {
              e.persist();
              handleUpload(e);
            }}
            accept="image/x-png,image/jpeg,image/jpg"
          />
        </span>
        <span className="side-text">
          {(imageFile && imageFile.name) || "No file chosen"}
        </span>

        {imageFile ? (
          <button onClick={() => setImageFile(null)}>
            <Close />
          </button>
        ) : null}
      </div>
      {imageFile && (
        <img
          className={classes.uploadedImage}
          alt=""
          src={URL.createObjectURL(imageFile)}
        />
      )}
      <p className={classes.recommendationHeading}>
        Here are the recommended format and sizes for images in push
        notifcations
      </p>
      <ul className={classes.reccommendationList}>
        <li>File format: Batch requires a PNG or JPEG file</li>
        <li>
          Size: Width must be at least 300px, height at least 200px. Avoid using
          images wider than 2000 pixels. Also, make sure that your image is not
          bigger than 1MB.
        </li>
        <li>
          Aspect ratio: Use images in landscape format respecting a 2:! ratio
          (e.g. 1000x500px)
        </li>
      </ul>
      <div className={classes.btnContainer}>
        <Button
          className={`${classes.btn} ${classes.cancelBtn}`}
          color="primary"
          variant="outlined"
        >
          CANCEL
        </Button>
        <div>
          <div className={classes.sendBtnContainer}>
            <Button
              disabled={sendLoading}
              onClick={() => handleScheduleNotification(false, setSendLoading)}
              className={`${classes.btn} ${classes.sendBtn}`}
              color="primary"
              variant="contained"
            >
              {sendLoading ? <CircularProgress size={20} /> : "SEND NOW"}
            </Button>
            <button
              onClick={() =>
                setShowSchedule(prevShowSchedule => !prevShowSchedule)
              }
              className={classes.scheduleArrow}
            >
              <ChevronDown />
            </button>
          </div>
          <div className={classes.scheduleBtnContainer}>
            <Button
              onClick={() => {
                if (validateInputs()) return;
                setOpenScheduleModal(true);
              }}
              className={`${classes.btn} ${classes.scheduleBtn}`}
              color="primary"
              variant="contained"
            >
              <Schedule />
              SCHEDULE
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendNotifications;
