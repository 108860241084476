import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularLoad } from "../../../components/Loader";
import { DataGrid } from "@material-ui/data-grid";
import concessionsApi from "../../../api/concessions-api";
import cinemasApi from "../../../api/cinemas";
import EditConcessions from "./EditConcessions";
import { Delete, Edit } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Grid, Button, TextField, MenuItem, Tooltip } from "@material-ui/core";
import { useStyles } from "./styles";
import "./concessions.css";
import { useRef } from "react";
import { useCallback } from "react";
import empty from "../../../assets/images/empty-concessions.png";
import ComboDealForm from "./ComboDealForm";
import Info from "../../../vectors/Info";
import DeleteDialog from "../../../components/DeleteDialog";
import { getRequestError } from "../../../utils/functions";
import types from "../../../redux/types";

const ComboDeals = () => {
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [cinemasLoading, setCinemasLoading] = useState(false);
  const [concessions, setConcessions] = useState([]);
  const [selected, setSelected] = useState({
    type: "add",
    data: {}
  });
  const [selectedState, setSelectedState] = useState({});
  const [selectedCinema, setSelectedCinema] = useState({});
  const [states, setStates] = useState([]);
  const [openDelete, setOpenDelete] = useState({
    isOpen: false,
    id: null,
    name: ""
  });
  const secondRender = useRef(false);

  const { jwt } = useSelector(state => state.user.user);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const [showComboDealForm, setShowComboDealForm] = useState(false);

  const {
    title,
    editButton,
    image,
    ellipsisContainer,
    ellipsis,
    tooltip
  } = useStyles();

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowComboDealForm(open);
  };

  useEffect(() => {
    setCinemasLoading(true);
    cinemasApi.fetchCinemas(jwt).then(res => {
      setStates(res.data.data);
      setSelectedState(res.data.data[0]);
      setSelectedCinema(res.data.data[0].cinemas[0]);
      setCinemasLoading(false);
    });
  }, [jwt]);

  const fetchConcessions = useCallback(() => {
    setLoading(true);
    concessionsApi
      .fetchConcessions(`Bearer ${jwt}`, selectedCinema.id)
      .then(res => {
        setConcessions(
          res.data.data?.concessions
            ?.find(({ categoryName }) => categoryName === "ComboDeals")
            ?.products?.map(
              ({
                product_name,
                display_price,
                product_id,
                product_category_name,
                image_url,
                combo_items
              }) => ({
                product_name,
                product_price: display_price,
                product_id,
                product_category_name,
                id: product_id,
                image_url,
                item: {
                  product_name,
                  product_id,
                  product_price: display_price
                },
                items: combo_items.map(
                  ({ product_name, product_id, display_price }) => ({
                    product_name,
                    product_id,
                    product_price: display_price
                  })
                )
              })
            )
        );
        setLoading(false);
      })
      .catch(e => {});
  }, [jwt, selectedCinema.id]);

  useEffect(() => {
    if (secondRender.current) {
      fetchConcessions();
      return;
    }
    secondRender.current = true;
  }, [jwt, selectedCinema, fetchConcessions]);

  const refreshList = () => {
    fetchConcessions();
  };

  const onSelectRow = (params, type) => {
    console.log(params);
    setSelected({
      type,
      data: params ?? {
        item: null,
        price: null,
        items: []
      }
    });
    setShowComboDealForm(true);
  };

  const handleOpenDeleteModal = (e, id, name) => {
    e.preventDefault();
    setOpenDelete({ isOpen: true, id, name });
  };

  const columns = [
    {
      field: "product_name",
      headerName: "Title",
      width: 400,
      renderCell: params => {
        return (
          <div className={title}>
            <img
              className={image}
              alt={params.row.product_name}
              src={params.row.image_url || empty}
              width={77}
              height={77}
            />
            <p>{params.row.product_name}</p>
          </div>
        );
      }
    },
    {
      field: "product_price",
      headerName: "Price (₦)",
      width: 160
    },
    {
      field: "product_id",
      headerName: "Product ID",
      width: 160
    },
    {
      field: "product_category_name",
      headerName: "Items",
      width: 240,
      renderCell: params => {
        const itemNames = params.row.items
          .map(item => item.product_name)
          .join(", ");
        return (
          <div className={ellipsisContainer}>
            <span className={ellipsis}>{itemNames}</span>
            <Tooltip
              classes={{
                tooltip: tooltip
              }}
              title={itemNames}
              arrow
            >
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  display: "flex"
                }}
              >
                <Info />
              </div>
            </Tooltip>
          </div>
        );
      }
    },
    {
      field: "",
      headerName: " ",
      width: 100,
      renderCell: params => {
        return (
          <Edit
            className={editButton}
            onClick={() =>
              onSelectRow(
                {
                  item: params.row.item,
                  price: params.row.product_price,
                  items: params.row.items
                },
                "edit"
              )
            }
          />
        );
      }
    },
    {
      field: "id",
      headerName: " ",
      width: 100,
      renderCell: params => {
        return (
          <button
            onClick={e =>
              handleOpenDeleteModal(
                e,
                params.row.product_id,
                params.row.product_name
              )
            }
          >
            <Delete color="secondary" />
          </button>
        );
      }
    }
  ];

  const handleDelete = async id => {
    return concessionsApi
      .deleteComboDeal(`Bearer ${jwt}`, id)
      .then(res => {
        setOpenDelete({
          isOpen: false,
          id: null,
          name: ""
        });
        refreshList();
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  const handleOpenClose = (setter, value) => {
    return setter(value);
  };

  return (
    <div>
      <>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={6}>
            {cinemasLoading ? (
              <CircularLoad show={true} />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    id="select-change-url"
                    select
                    variant="outlined"
                    margin="normal"
                    label="Select state"
                    name="Select-State"
                    value={selectedState}
                    onChange={e => {
                      setSelectedState(e.target.value);
                      setSelectedCinema(e.target.value.cinemas[0]);
                    }}
                    fullWidth
                  >
                    {states.map(item => (
                      <MenuItem key={item?.id} value={item}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="select-change-url"
                    select
                    variant="outlined"
                    margin="normal"
                    label="Select cinema"
                    name="Select-Cinema"
                    value={selectedCinema}
                    onChange={e => setSelectedCinema(e.target.value)}
                    fullWidth
                  >
                    {selectedState.cinemas &&
                      selectedState?.cinemas.map(item => (
                        <MenuItem key={item?.id} value={item}>
                          {item?.cinema_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              style={{ marginRight: "18px", height: "100%" }}
              onClick={() => {
                onSelectRow({}, "add");
                // setSelectedPromotion({ data: null, type: "add" });
                // setOpenHeroBannerModal(true);
              }}
              //   className={classes.addBannerBtn}
              variant="outlined"
              color="primary"
            >
              + COMBO DEAL{" "}
            </Button>
            <CSVLink data={concessions} filename={"concessions.csv"}>
              <Button variant="contained" color="primary">
                <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          <DataGrid
            rows={concessions}
            columns={columns}
            pageSize={15}
            rowHeight={93}
            headerHeight={80}
            disableColumnMenu={true}
            autoHeight={true}
            className="concessions-table"
            loading={loading}
          />
        </div>
      </>
      <ComboDealForm
        open={showComboDealForm}
        close={() => setShowComboDealForm(false)}
        onClose={toggleDrawer(false)}
        jwt={jwt}
        data={selected.data}
        refreshList={refreshList}
        cinemaId={selectedCinema.id}
        type={selected.type}
      />
      <DeleteDialog
        handleClose={() =>
          handleOpenClose(setOpenDelete, { isOpen: false, id: null, name: "" })
        }
        handleDelete={async () => {
          setLoadingDelete(true);
          await handleDelete(openDelete.id).then(() => {
            setLoadingDelete(false);
          });
        }}
        loading={loadingDelete}
        open={openDelete.isOpen}
        title={`Delete ${openDelete.name} combo`}
      />
    </div>
  );
};

export default ComboDeals;
