import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reverse } from "lodash";
import TiersList from "./List";
import EditAdminSlideIn from "./EditAdminSlideIn";
import sheet from "./styles";
import api from "../../api/users-api";
import { Button, Grid } from "@material-ui/core";
import { CircularLoad } from "../../components/Loader";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Edit } from "@material-ui/icons";

const AdminListPage = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [panelTitle, setPanelTitle] = useState("Create new admin");
  const [btnTitle, setBtnTitle] = useState("Create");
  const [admins, setAdmins] = useState([]);
  const [editAdminData, setEditAdminData] = useState({});
  const [newTier, setNewTier] = useState();
  const [adminId, setAdminId] = useState();

  useEffect(() => {
    setLoading(true);
    api.getAdminList(`Bearer ${user.jwt}`).then(res => {
      setAdmins(
        res.data.data.map((item, i) => ({
          email: item?.email ?? "-",
          name: item?.firstName + " " + item?.lastName,
          id: item?.id ?? i,
          userType: item?.userType ?? "-",
          mobile: item?.mobile ?? "-",
          firstName: item?.firstName ?? "",
          lastName: item?.lastName ?? ""
        })) ?? []
      );
      setLoading(false);
    });
  }, [user.jwt]);

  const refreshList = () => {
    setLoading(true);
    api.getAdminList(`Bearer ${user.jwt}`).then(res => {
      setAdmins(
        res.data.data.map((item, i) => ({
          email: item?.email ?? "-",
          name: item?.firstName + " " + item?.lastName,
          id: item?.id ?? i,
          userType: item?.userType ?? "-",
          mobile: item?.mobile ?? "-",
          firstName: item?.firstName ?? "",
          lastName: item?.lastName ?? ""
        })) ?? []
      );
      setLoading(false);
    });
  };

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowPanel(open);
  };

  const editTiersSlideIn = data => {
    setNewTier(false);
    setEditAdminData(data);
    setAdminId(data.id);
    setPanelTitle("Edit Admin");
    setBtnTitle("Submit");
    setShowPanel(true);
  };

  const addAdminSlideIn = () => {
    setEditAdminData({});
    setNewTier(true);
    setBtnTitle("Create");
    setPanelTitle("Create new admin");
    setShowPanel(true);
  };

  const onSelectRow = params => {
    editTiersSlideIn(params.row);
  };

  const { classes } = sheet;

  const columns = [
    {
      field: "email",
      headerName: "Email",
      width: 400
    },
    {
      field: "name",
      headerName: "Name",
      width: 300
    },
    {
      field: "id",
      headerName: "ID",
      width: 100
    },
    {
      field: "userType",
      headerName: "Role",
      width: 160
    },
    {
      field: "",
      headerName: "Edit",
      width: 100,
      renderCell: params => {
        return (
          <Edit
            className={classes.editBtn}
            onClick={() => onSelectRow(params)}
          />
        );
      }
    }
  ];

  return (
    <div>
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs={6}></Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Grid justify="flex-end" alignItems="center" spacing={2} container>
            <Grid item>
              {user.details.userType.toLowerCase() === "superadmin" ? (
                <Button
                  onClick={addAdminSlideIn}
                  variant="outlined"
                  color="primary"
                >
                  + ADD Admin{" "}
                </Button>
              ) : null}
            </Grid>
            <Grid item>
              <CSVLink data={admins} filename={"admins-list.csv"}>
                <Button variant="contained" color="primary">
                  <DownloadIcon /> DOWNLOAD CSV
                </Button>
              </CSVLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
        <DataGrid
          rows={admins}
          columns={columns}
          pageSize={10}
          rowHeight={50}
          headerHeight={80}
          disableColumnMenu={true}
          autoHeight={true}
          loading={loading}
          className="hero-banners-table"
        />
      </div>
      <EditAdminSlideIn
        title={panelTitle}
        btnTitle={btnTitle}
        open={showPanel}
        close={() => setShowPanel(false)}
        onClose={toggleDrawer(false)}
        benefits={null}
        jwt={user.jwt}
        refreshList={refreshList}
        data={editAdminData}
        isNewTier={newTier}
        adminId={adminId}
        roles={[
          { value: "Admin", name: "ADMIN" },
          { vakue: "Super Admin", name: "SUPERADMIN" },
          { value: "Editor", name: "EDITOR" }
        ]}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user.user
});
export default connect(mapStateToProps)(AdminListPage);
