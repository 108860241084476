export default {
    type: [
        { name: "Filter by Type", value: "Filter by Type" },
        { name: "Food", value: "Food" },
        { name: "Drinks", value: "Drinks" },
        { name: "Glasses", value: "Glasses" }
    ],
    flavor: [
        { name: "Filter by Flavor", value: "Filter by Flavor" },
        { name: "Sweet", value: "Sweet" }
    ]
};