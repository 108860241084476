import React from "react";
import { Drawer } from "@material-ui/core";

const SlideIn = ({ open, onClose, from, children, minWidth = 400 }) => {
  return (
    <Drawer
      anchor={from}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "40%",
          minWidth,
          backgroundColor: "rgba(22, 22, 26, 0.92)"
        }
      }}
    >
      {children}
    </Drawer>
  );
};

export default SlideIn;
