import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import userApis from "../../api/users-api";
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";
import { Alert } from "@material-ui/lab";
import PaperedInput from "../../components/Inputs/PaperedInput";

const UnsubscriberUserList = ({ jwt }) => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [error, setError] = useState({
    isError: false,
    message: ""
  });
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    setLoading(true);
    setError({ isError: false, message: "" });
    userApis
      .getUsersType(`Bearer ${jwt}`, page + 1, "INACTIVE", searchInput)
      .then(res => {
        const { users = [], totalCount = 0 } = res.data.data;
        setLoading(false);
        setData(
          users.map(item => ({
            date: `${item?.createdAt.substring(
              8,
              10
            )}/${item?.createdAt.substring(5, 7)}/${item?.createdAt.substring(
              0,
              4
            )}`,
            email: item?.email,
            subscriptionPlan:
              item?.lastest_subscription?.subscriptionName || "Basic",
            name: item?.firstName + " " + item?.lastName,
            id: item?.userId
          }))
        );
        setTotalData(totalCount);
      })
      .catch(() => {
        setError({ isError: true, message: "There is an error" });
        setLoading(false);
      });
  }, [page, jwt, searchInput]);

  const columns = [
    { field: "name", headerName: "Name", width: 366 },
    { field: "email", headerName: "Email", width: 350 },
    {
      field: "reason",
      headerName: "Reason",
      width: 233
    },
    { field: "date", headerName: "Date", width: 104 }
  ];

  return (
    <div>
      <>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <PaperedInput
              onChange={e => setSearchInput(e.target.value)}
              placeholder="Search for User"
            />
          </Grid>
          <Grid item xs={6}>
            <Grid justify="flex-end" container>
              <CSVLink data={data} filename={"unsubscribed-users.csv"}>
                <Button variant="contained" color="primary">
                  <DownloadIcon className="c-user-icon" /> DOWNLOAD CSV
                </Button>
              </CSVLink>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          {error.isError ? (
            <Alert severity="error">{error.message}</Alert>
          ) : (
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={10}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              autoHeight={true}
              loading={loading}
              rowCount={totalData}
              page={page}
              onPageChange={page => {
                setPage(page);
              }}
              paginationMode="server"
            />
          )}
        </div>
      </>
    </div>
  );
};

const mapStateToProps = state => ({
  jwt: state.user.user.jwt
});

export default connect(mapStateToProps)(withRouter(UnsubscriberUserList));
