import axios from "axios";
import urls from "./urls";

const { movieDB } = urls;

export default {
  resetPasswordInitUser: email =>
    axios.post(`/auth/forgot-password`, { email }).then(res => res),
  getUserList: async (token, page, searchQuery) =>
    axios.get(
      `/subscriptions/user-type?perPage=10&page=${page}&search_query=${searchQuery}`,
      {
        headers: { Authorization: token }
      }
    ),
  getUsersType: async (token, page, activeStatus, searchQuery) =>
    axios.get(
      `/subscriptions/user-type?perPage=10&page=${page}&type=${activeStatus}&search_query=${searchQuery}`,
      {
        headers: { Authorization: token }
      }
    ),
  getGuestUsers: async (token, page, searchQuery) =>
    axios.get(
      `/purchase_history/guest-users?perPage=10&page=${page}&search=${searchQuery}`,
      {
        headers: { Authorization: token }
      }
    ),
  findUsers: async (token, keyword) =>
    axios.get(`/admin/find-users?keyword=${keyword}`, {
      headers: { Authorization: token }
    }),
  getUnsubscribersList: async token => {
    //get users and get tiers. creat an object from users list that has the name of their subscripstion tier assigned to it.
    const users = axios.get(`/churns?$include=user`, {
      headers: { Authorization: token }
    });
    const data = Promise.all([users]);

    return data;
  },
  getAdminList: async token => {
    //get admins
    const data = axios.get(`/admin/fetch-admins`, {
      headers: { Authorization: token }
    });

    return data;
  },
  getUserRoles: async token => {
    const res = await axios.get(`/roles?$include=permissions`, {
      headers: { Authorization: token }
    });
    return res;
  },
  getAllPermissions: async token => {
    const res = await axios.get(`/permissions`, {
      headers: { Authorization: token }
    });
    return res;
  },
  getRoleInfo: async (id, token) => {
    const res = await axios.get(`/roles/${id}?$include=permissions`, {
      headers: { Authorization: token }
    });
    return res;
  },
  getUserInfo: async (id, token) =>
    await axios.get(`/admin/get-customer/${id}`, {
      headers: { Authorization: token }
    }),
  getGuestUserInfo: async (email, page, token) =>
    await axios.get(
      `/purchase_history/guest-users/booking-activity/${email}?page=${page}&perPage=15`,
      {
        headers: { Authorization: token }
      }
    ),
  renewUserSubscription: async (id, token) =>
    await axios.post(
      `/subscriptions/renew-subscription/${id}`,
      {},
      {
        headers: { Authorization: token }
      }
    ),
  handleUploadPhoto: (body, id, token) =>
    axios
      .patch(`${movieDB}/admin/profile-picture/${id}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token
        }
      })
      .then(res => res),
  createUser: async (token, payload) => {
    const res = await axios.post(`/admin/create-customer`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  editUser: async (token, payload, id) => {
    const res = await axios.put(`/admin/edit-profile/${id}`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  clearUserToken: async (token, id) => {
    const res = await axios.delete(`/admin/customer/${id}`, {
      headers: { Authorization: token }
    });
    return res;
  },
  createAdmin: async (token, payload) => {
    const res = await axios.post(`/admin/create-admin`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  deleteUserCard: async (id, token) => {
    const res = await axios.delete(`/flutterwave/payment-token/${id}`, {
      headers: { Authorization: token }
    });
    return res;
  },
  editAdmin: async (token, payload, id) => {
    console.log("working");
    const res = await axios.put(`/admin/edit-admin/${id}`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  deleteAdmin: async (adminId, token) => {
    const res = await axios.delete(`/users/${adminId}`, {
      headers: { Authorization: token }
    });
    return res;
  },
  createRole: async (token, payload) => {
    const res = await axios.post(`/roles`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  patchRole: async (token, payload, id) => {
    const res = await axios.patch(`/roles/${id}`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  deleteRole: async (id, token) => {
    const res = await axios.delete(`/roles/${id}`, {
      headers: { Authorization: token }
    });
    return res;
  }
};
