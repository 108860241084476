import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import EntryTitle from "../../components/EntryTitle";
import Description from "./Description";
// import MovieTypes from "./MovieTypes";
//import VideoEntry from "./MediaSection/VideoEntry";
import Section from "../../components/Section";
import { Container, Button, Paper, CircularProgress } from "@material-ui/core";
import Btn from "../../components/Button";
import useStyles from "./styles";
import Genres from "./Genres";
import CastList from "../../components/CastList";
// import Tags from "./Tags";
import MovieDBDialog from "../../components/MovieDBDialog";
// import VodPrice from "./VodPrice";
import NewCast from "./NewCast";
import Binary from "./Featured";
// import Censoring from "./Censoring";
import Posters from "./Posters";
import { CircularLoad } from "../../components/Loader";
import ReleaseYear from "./ReleaseYear";
// import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
// import Brightcove from "./Brightcove";
import YoutubeTrailer from "./YoutubeTrailer";
import FeatureLength from "./FeatureLength";
// import movieEntryApi from "../../api/movie-entry-api";
import Switch from "../../components/Switch/Switch";
import GoBack from "../../components/Button/GoBack";
import moviesApi from "../../api/movie-entry-api";
import { getRequestError } from "../../utils/functions";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ConfirmShowtimeDialog from "./ConfirmShowtimeDialog";
import Reviews from "./Reviews";

const MovieEntryContainer = ({
  jwt,
  addCast,
  removeCast,
  backdropImage,
  cast,
  create,
  edit,
  description,
  featured,
  loading,
  onChange,
  onClick,
  handleApproveForDisplay,
  onTagChange,
  onSelect,
  posterImage,
  isPostingImage,
  selectedGenres,
  tags,
  title,
  type,
  upcoming,
  postImageUrl,
  movieLoading,
  releaseYear,
  isApproved,
  unfilledRequirements,
  uploadImageFileAPI,
  openingDate,
  handleDateChange,
  video,
  trailerURL,
  featureLength,
  posterImageSmall,
  backdropImageSmall,
  missing,
  storeMovieDetail,
  patcher,
  descReset,
  isSubmittable,
  isShowTimeAvailable
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [openShowtimeDialog, setOpenShowtimeDialog] = React.useState(false);
  const [openCast, setOpenCast] = React.useState(false);
  const [movies, setMovies] = useState([]);
  const [loadingSearchMovies, setLoadingSearchMovies] = useState(false);
  const [openReviews, setOpenReviews] = useState(false);
  const handleOpenClose = (setter, value) => {
    return setter(value);
  };

  const [
    isUpdateIsApprovedStatusLoading,
    setIsUpdateIsApprovedStatusLoading
  ] = useState(false);

  // const [genres, setGenres] = React.useState();
  // useEffect(() => {
  //   movieEntryApi
  //     .getGenres()
  //     .then(({ data }) => {
  //       const fetchedGenres = data.data.map(genre => ({ label: genre.name }));
  //       return setGenres(fetchedGenres);
  //     })
  //     .catch(err => {
  //       console.log("Genres request failed");
  //     });
  // }, []);

  /**
   *
   * check cast list.
   */
  const DescMemo = useMemo(
    () => (
      <Description
        value={description}
        missing={missing}
        onChange={onChange}
        reset={descReset}
      />
    ),
    [description, onChange, descReset, missing]
  );
  const GenresMemo = useMemo(
    () => (
      <Genres
        onTagChange={onTagChange}
        selected={selectedGenres}
        patcher={patcher}
        genres={[]}
      />
    ),
    [patcher, selectedGenres, onTagChange]
    // [patcher, selectedGenres, onTagChange, genres]
  );

  // const FeaturedMemo = useMemo(
  //   () => (
  //     <Binary
  //       name="featured"
  //       title="Featured ?"
  //       onChange={onChange}
  //       value={featured}
  //       create={!edit}
  //     />
  //   ),
  //   [onChange, featured, edit]
  // );
  const UpcomingMemo = useMemo(
    () => (
      <Binary
        name="upcoming"
        title="Upcoming ?"
        onChange={onChange}
        value={upcoming}
        create={!edit}
      />
    ),
    [onChange, upcoming, edit]
  );
  const PosterMemo = useMemo(
    () => (
      <Posters
        title="Poster"
        name="posterImage"
        imgURL={posterImage}
        missing={missing}
        placeholder="Poster image URL"
        onChange={onChange}
        postImageUrl={postImageUrl}
        isPostingImage={isPostingImage}
        uploadImageFileAPI={uploadImageFileAPI}
      />
    ),
    [
      onChange,
      posterImage,
      isPostingImage,
      postImageUrl,
      uploadImageFileAPI,
      missing
    ]
  );

  const PosterSmallMemo = useMemo(
    () => (
      <Posters
        title="Small Poster"
        name="posterImageSmall"
        imgURL={posterImageSmall}
        missing={missing}
        placeholder="Poster image (small) URL"
        onChange={onChange}
        postImageUrl={postImageUrl}
        isPostingImage={isPostingImage}
        uploadImageFileAPI={uploadImageFileAPI}
      />
    ),
    [
      onChange,
      posterImageSmall,
      isPostingImage,
      postImageUrl,
      uploadImageFileAPI,
      missing
    ]
  );
  const BgMemo = useMemo(
    () => (
      <Posters
        title="Background"
        name="backdropImage"
        onChange={onChange}
        placeholder="BG image URL"
        imgURL={backdropImage}
        postImageUrl={postImageUrl}
        isPostingImage={isPostingImage}
        uploadImageFileAPI={uploadImageFileAPI}
        missing={missing}
      />
    ),
    [
      onChange,
      backdropImage,
      isPostingImage,
      postImageUrl,
      uploadImageFileAPI,
      missing
    ]
  );

  const BgSmallMemo = useMemo(
    () => (
      <Posters
        title="Background (small)"
        name="backdropImageSmall"
        onChange={onChange}
        placeholder="BG image (small) URL"
        imgURL={backdropImageSmall}
        postImageUrl={postImageUrl}
        isPostingImage={isPostingImage}
        uploadImageFileAPI={uploadImageFileAPI}
        missing={missing}
      />
    ),
    [
      onChange,
      backdropImageSmall,
      isPostingImage,
      postImageUrl,
      uploadImageFileAPI,
      missing
    ]
  );

  const TrailerVideoMemo = useMemo(
    () => (
      <YoutubeTrailer
        title="Trailer Youtube URL"
        name="trailerURL"
        trailerURL={trailerURL}
        placeholder="Trailer Youtube URL"
        onChange={onChange}
        missing={missing}
      />
    ),
    [onChange, trailerURL, missing]
  );

  // const TypeMemo = useMemo(
  //   () => (
  //     <MovieTypes
  //       name="type"
  //       title="Movie Type"
  //       onChange={onChange}
  //       value={type}
  //     />
  //   ),
  //   [onChange, type]
  // );

  // const TagsMemo = useMemo(
  //   () => <Tags onTagChange={onTagChange} value={tags} />,
  //   [onTagChange, tags]
  // );
  // const CensoringMemo = useMemo(
  //   () => <Censoring value={censoring.value} onChange={onChange} />,
  //   [censoring, onChange]
  // );

  const OpeningDateMemo = useMemo(
    () => (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          variant="inline"
          fullWidth
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          value={
            openingDate !== ""
              ? openingDate
              : moment(new Date()).format("MM-DD-YYYY")
          }
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
        />
      </MuiPickersUtilsProvider>
    ),
    [openingDate, handleDateChange]
  );
  // const errorText =
  //   unfilledRequirements && unfilledRequirements.length > 0
  //     ? `Please add these fields: ${unfilledRequirements.join(", ").trim()}`
  //     : `All the required fields have been inputted, click on "Submit" to approve for display`;

  const handleUpdatingIsApprovedStatus = async status => {
    if (!isUpdateIsApprovedStatusLoading) {
      setIsUpdateIsApprovedStatusLoading(true);
      await handleApproveForDisplay(status);
      setIsUpdateIsApprovedStatusLoading(false);
    }
  };

  return movieLoading ? (
    <div>
      <CircularLoad show={true} />
    </div>
  ) : (
    <div>
      <ConfirmShowtimeDialog
        open={openShowtimeDialog}
        handleClose={() => setOpenShowtimeDialog(false)}
        handleContinue={() => {
          handleUpdatingIsApprovedStatus(true);
          setOpenShowtimeDialog(false);
        }}
      />
      {patcher && (
        <MovieDBDialog
          open={open}
          handleClose={() => handleOpenClose(setOpen, false)}
          title={title.value}
          storeMovieDetail={storeMovieDetail}
          movies={movies}
          loading={loadingSearchMovies}
          jwt={jwt}
        />
      )}
      <NewCast
        jwt={jwt}
        cast={cast}
        open={openCast}
        handleClose={() => handleOpenClose(setOpenCast, false)}
        add={addCast}
      />
      <Reviews
        open={openReviews}
        onClose={() => {
          setOpenReviews(false);
        }}
        jwt={jwt}
      />
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.topHeader}>
          <GoBack onClick={history.goBack} type="button" />
          <button
            onClick={() => setOpenReviews(true)}
            className={classes.reviewsBtn}
          >
            View Reviews
          </button>
        </div>
        <Paper
          className={
            isApproved ? classes.successInfoPane : classes.errorInfoPane
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <b>
            {isApproved
              ? "Movie Approved for display"
              : "Movie not Approved for display"}
          </b>
          <Switch
            defaultChecked={isApproved}
            checked={isApproved}
            onChange={() => {
              if (isApproved || isShowTimeAvailable) {
                isApproved
                  ? handleUpdatingIsApprovedStatus(false)
                  : handleUpdatingIsApprovedStatus(true);
              } else {
                setOpenShowtimeDialog(true);
              }
            }}
            disabled={isUpdateIsApprovedStatusLoading}
          />
        </Paper>
        <Section
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleOpenClose(setOpen, true);
                if (title.value) {
                  setLoadingSearchMovies(true);
                  moviesApi
                    .searchMovieDB(title.value)
                    .then(res => {
                      setMovies(res.data.data.results);
                      setLoadingSearchMovies(false);
                    })
                    .catch(err => console.log(getRequestError(err)));
                }
              }}
            >
              <span className={classes.movieDBBtnText}>Movie DB Update</span>
            </Button>
          }
          title="OVERVIEW"
        >
          <div className={classes.titleContainer}>
            {patcher && (
              <div className={classes.patcher}>
                <EntryTitle
                  onChange={onChange}
                  create
                  error={title.error}
                  title={title.value}
                  missing={missing}
                />
              </div>
            )}
          </div>
          {DescMemo}
          {GenresMemo}
          {/* {TypeMemo} */}
        </Section>
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Section title="MEDIA">
          <div className={classes.divider} />
          {PosterMemo}
          <div className={classes.divider} />
          {PosterSmallMemo}
          {BgMemo}
          {BgSmallMemo}
          {TrailerVideoMemo}
        </Section>
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Section
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenClose(setOpenCast, true)}
            >
              + ADD Cast
            </Button>
          }
          title="CAST"
        >
          <CastList edit={edit} removeCast={removeCast} cast={cast} />
        </Section>
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Section title="MOVIE INFO">
          <div className={classes.movieInfo}>
            <ReleaseYear
              onChange={onChange}
              releaseYear={releaseYear}
              missing={missing}
            />
            <FeatureLength
              onChange={onChange}
              featureLength={featureLength}
              missing={missing}
            />
          </div>
          {/* {ReleaseYearMemo} */}
          {/* {FeatureLengthMemo} */}
          {/* {CensoringMemo}
          {TagsMemo}
          {FeaturedMemo} */}
          {UpcomingMemo}
          {upcoming === "true" && OpeningDateMemo}
          {/* {OpeningDateMemo} */}
        </Section>
      </Container>
      <Container className={classes.btnContainer}>
        <Btn disabled={loading || !isSubmittable} onClick={onClick}>
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Btn>
      </Container>
    </div>
  );
};

export default MovieEntryContainer;
