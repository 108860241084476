import React from 'react'
import { Button, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    goBack: {
        color: "rgba(162,51,226,1)",
        "margin-bottom": "16px"
    }
}));
const Back = ({ onClick }) => {
    const {goBack} = useStyles()
    return (
        <Button className={goBack} onClick={onClick}>
            <ArrowBack /> Go back
        </Button>
    );
}

export default Back
