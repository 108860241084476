import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "20px",
    backgroundColor: "rgba(0,0,0,0.2)"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "0.95px solid rgba(255,255,255,0.1)",
    paddingBottom: "19px",
    marginBottom: "27.49px"
  },
  title: {
    color: "rgba(255,255,255,0.87)",
    fontSize: "23px"
  }
}));

export default useStyles;
