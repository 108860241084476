import React, { useState } from "react";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import list from "./menuLists";
import useStyles from "./styles";
import MainItem from "./MainItem";
import logo from "../../assets/fh-logo.svg";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { adminLogout } from "../../redux/actions/rxa_auth";

const SideMenu = ({ open, handleMenuClose }) => {
  const [loadingLogout, setLoadingLogout] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { jwt } = useSelector(state => state.user.user);

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <img src={logo} className={classes.fhLogo} alt="fh-logo" />
        <IconButton onClick={handleMenuClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {list.main.map(({ icon, path, primary, exact, dropdown }, i) => {
          return (
            <MainItem
              icon={icon}
              primary={primary}
              dropdown={dropdown}
              exact={exact}
              path={path}
              key={i}
            />
          );
        })}
      </List>
      <Divider />
      <List
        onClick={async () => {
          // setLoadingLogout(true);
          dispatch(adminLogout(jwt));
        }}
      >
        <ListItem button>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText>
            {loadingLogout ? "Logging out..." : "Logout"}
          </ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
};

SideMenu.defaultProps = {
  open: true
};

export default withRouter(SideMenu);
