import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import { Typography } from "@material-ui/core";

const ConfirmShowtimeDialog = ({ open, handleClose, handleContinue }) => {
  const { confirmShowtimeDialog } = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <div className={confirmShowtimeDialog}>
        <DialogTitle id="max-width-dialog-title">
          <Typography variant="h4">No showtime available</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            CANCEL
          </Button>
          <Button
            variant="contained"
            form="form-test"
            type="submit"
            color="secondary"
            onClick={handleContinue}
          >
            Continue
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ConfirmShowtimeDialog;
