import React from "react";
import HeroBanner from "./HeroBanner";

export default [
  {
    path: "/dashboard/hero-banners",
    exact: true,
    component: () => <HeroBanner />
  }
];
