import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import validator from "validator";
import Confirmpassword from "./ConfirmPassword";
import api from "../../api/auth-api";
import types from "../../redux/types";
import { getRequestError } from "../../utils/functions";

class ConfirmPassForm extends Component {
  state = {
    form: {
      password: "",
      confirmPassword: ""
    },
    status: {
      loading: false,
      pass: {
        error: false,
        msg: ""
      },
      cpass: {
        error: false,
        msg: ""
      }
    },
    user: {
      token: "",
      name: ""
    }
  };

  componentDidMount() {
    const { token } = this.props.match.params;
    let query = new URLSearchParams(this.props.location.search);
    this.setState({
      user: {
        token: token,
        name: query.get("name")
      }
    });
  }

  handleChange = e => {
    this.setState(
      {
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value
        }
      },
      () => {
        const { confirmPassword, password } = this.state.form;
        if (confirmPassword === password && (confirmPassword || password)) {
          this.setState({
            status: {
              ...this.state.status,
              pass: {
                error: false,
                msg: ""
              },
              cpass: {
                error: false,
                msg: ""
              }
            }
          });
        }
      }
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    const { confirmPassword, password } = this.state.form;
    const { token } = this.state.user;
    const post = { token, password };
    const isPassEmpty = validator.isEmpty(password);
    const isCPassEmpty = validator.isEmpty(confirmPassword);
    const { ALERT_OPEN } = types;
    const { dispatch } = this.props;

    if (confirmPassword !== password) {
      const msg = "Passwords don't match";
      this.setState({
        status: {
          ...this.state.status,
          pass: {
            error: true,
            msg: msg
          },
          cpass: {
            error: true,
            msg: msg
          }
        }
      });
    } else if (isPassEmpty || isCPassEmpty) {
      if (isPassEmpty) {
        this.setState({
          status: {
            ...this.state.status,
            pass: {
              error: true,
              msg: "Cannot have empty field"
            }
          }
        });
      }
      if (isCPassEmpty) {
        this.setState({
          status: {
            ...this.state.status,
            cpass: {
              error: true,
              msg: "Cannot have empty field"
            }
          }
        });
      }
    } else {
      this.setState({
        status: {
          ...this.state.status,
          loading: true
        }
      });

      api
        .resetPasswordComp(post)
        .then(res => {
          dispatch({
            type: ALERT_OPEN,
            payload: {
              severity: "success",
              message: "Password has been reset successfully"
            }
          });
          this.setState({
            status: {
              ...this.state.status,
              loading: false
            }
          });
          this.props.history.push("/login");
        })
        .catch(err => {
          dispatch({
            type: ALERT_OPEN,
            payload: {
              severity: "error",
              message: getRequestError(err)
            }
          });
          this.setState({
            status: {
              ...this.state.status,
              loading: false
              // pass: {
              //     error: true,
              //     msg: err.response.data.message
              // }
            }
          });
        });
    }
  };

  render() {
    const { pass, cpass, loading } = this.state.status;
    const errors = { pass, cpass };
    return (
      <Confirmpassword
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        errors={errors}
        isSubmitting={loading}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(null, mapDispatchToProps)(withRouter(ConfirmPassForm));
