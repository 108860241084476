import React from "react";

const ThumbsDown = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9991 2.00036H19.6691C20.235 1.99035 20.785 2.1885 21.2145 2.55718C21.644 2.92586 21.9232 3.43942 21.9991 4.00036V11.0004C21.9232 11.5613 21.644 12.0749 21.2145 12.4435C20.785 12.8122 20.235 13.0104 19.6691 13.0004H16.9991M9.99905 15.0004V19.0004C9.99905 19.796 10.3151 20.5591 10.8777 21.1217C11.4403 21.6843 12.2034 22.0004 12.9991 22.0004L16.9991 13.0004V2.00036H5.71905C5.23673 1.99491 4.76868 2.16396 4.40115 2.47636C4.03362 2.78875 3.79138 3.22346 3.71905 3.70036L2.33905 12.7004C2.29555 12.987 2.31488 13.2797 2.39571 13.5581C2.47655 13.8365 2.61695 14.0941 2.8072 14.3128C2.99744 14.5316 3.23297 14.7064 3.49748 14.8251C3.76199 14.9439 4.04915 15.0036 4.33905 15.0004H9.99905Z"
      stroke="white"
      strokeOpacity="0.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ThumbsDown;
