import { combineReducers } from "redux";
import search from "./search";
import entry from "./entry/index";
import forVista from "./forVista";
import lists from "./lists";

const combinedReducer = combineReducers({
  search,
  entry,
  forVista,
  lists
});

export default combinedReducer;
