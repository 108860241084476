import React from "react";
import {
  Button,
  makeStyles,
  Typography /*, Icon, Button*/
} from "@material-ui/core";
import defaultIcon from "../../assets/images/anon-user.png";

const useStyle = makeStyles(theme => ({
  root: {
    margin: 10,
    width: 154,
    minHeight: 241,
    backgroundColor: "#0A0A0E",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  image: {
    width: 122,
    height: 119,
    objectFit: "cover",
    marginBottom: "32px"
  },
  characterStyle: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    opacity: 0.5,
    margin: "10px 0"
  }
}));

const CastIcon = ({ value, imgURL, name, removeCast, character }) => {
  const { root, image, characterStyle } = useStyle();
  const deleteCastFn = () => {
    removeCast(value);
  };
  return (
    <div className={root}>
      <img className={image} src={imgURL ?? defaultIcon} alt="cast" />
      <div>
        <Typography variant="body1">{name}</Typography>
        <p className={characterStyle}>{character}</p>
        <Button variant="contained" color="secondary" onClick={deleteCastFn}>
          Delete
        </Button>
      </div>
    </div>
  );
};

export default CastIcon;
