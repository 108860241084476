import React from "react";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import validator from "validator";
import PasswordShowHide from "../PasswordShowHide/PasswordShowHide";

const SignIn = props => {
  const classes = useStyles();
  const {
    error,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleBlur,
    submittable
  } = props;

  return (
    <div>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField
          error={!validator.isEmpty(errors.email)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          type="text"
          label="Email Address"
          name="email"
          onBlur={handleBlur.email}
          onChange={handleChange.email}
          helperText={error && errors.email}
          inputProps={{
            autoComplete: "anyrandomstring"
          }}
        />

        <PasswordShowHide
          error={!validator.isEmpty(errors.password)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          onChange={handleChange.password}
          onBlur={handleBlur.password}
          helperText={error && errors.password}
          id="off"
          autoComplete="anyrandomstring"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting || !submittable}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/reset-password-request" variant="body2">
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SignIn;
