import { makeStyles } from "@material-ui/styles";

const vodPriceStyles = makeStyles(theme => ({
  textFieldPair: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

export default vodPriceStyles;
