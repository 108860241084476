import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EnhancedTable from "../../components/TableList";
import TableSearch from "./TableSearch";
import { CircularLoad } from "../../components/Loader";
import headCells from "./tableHead";
import sorting from "../../components/functions/sorting";
import { getAllMerchandise } from "../../redux/actions/rxa_merch";
import ShowForPermissionComponent from "../../utils/ShowForPermissionComponent";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Grid, Button } from "@material-ui/core";

class MerchandiseList extends React.Component {
  state = {
    query: "",
    loading: false,
    data: null,
    filteredData: [],
    error: ""
  };

  async componentDidMount() {
    const { jwt, merchandise } = this.props;
    await this.props.getAllMerchandise(`Bearer ${jwt}`);
    this.setState({
      data: merchandise && merchandise.data,
      filteredData: merchandise && merchandise.data
    });
  }

  handleSearch = ({ target }) => {
    const { value } = target;
    const { data } = this.state;

    const newData = data.filter(item => sorting.search(value, item));

    this.setState(
      {
        query: value
      },
      () => {
        this.setState({ filteredData: newData });
      }
    );
  };

  handleRowClick = (e, id) => {
    this.props.history.push(`/dashboard/merchandise/${id}`);
  };

  render() {
    const { loading, filteredData, error } = this.state;
    const TableVeiw = loading
      ? () => <CircularLoad show={true} />
      : () => (
          <EnhancedTable
            rowClickable={false}
            keys={["name", "type", "price", "units_sold", "size"]}
            rows={filteredData}
            headCells={headCells}
            timeKey="createdAt"
          />
        );
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}></Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <CSVLink data={filteredData} filename={"merchandise.csv"}>
              <Button variant="contained" color="primary">
                <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
        <div style={{ marginTop: "20px" }}>
          {Boolean(error) && <div className="error-txt">{error}</div>}
          <ShowForPermissionComponent
            permission="product:read"
            displayMessage="Permission denied"
          >
            <TableSearch onChange={this.handleSearch} />
            <TableVeiw />
          </ShowForPermissionComponent>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  jwt: state.user.user.jwt,
  merchandise: state.merch.merchandise
});

export default connect(mapStateToProps, {
  getAllMerchandise
})(withRouter(MerchandiseList));
