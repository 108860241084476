import React from "react";
import { Paper, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5),
    backgroundColor: "#18181E",
    borderRadius: 9,
    position: "relative"
  },
  title: {
    color: "#979797",
    opacity: 0.57
    // paddingBottom: theme.spacing(3)
  },
  sideNote: {
    fontSize: "14.15px",
    color: "#fff",
    marginLeft: "10px"
  },
  withSideNote: {
    display: "flex",
    alignItems: "center"
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

const Section = ({ title, children, sideNote, titleStyle, action }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography
          variant="h4"
          className={titleStyle ? titleStyle : classes.title}
        >
          {sideNote ? (
            <div className={classes.withSideNote}>
              {title}
              <small className={classes.sideNote}>
                <i>{sideNote}</i>
              </small>
            </div>
          ) : (
            title
          )}
        </Typography>
        {action}
      </div>
      <div>{children}</div>
    </Paper>
  );
};

export default Section;
