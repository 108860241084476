import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  castsDialog: {
    backgroundColor: "#1C1C22",
    padding: "20px 0"
  },
  fileInput: {
    display: "none"
  },
  pair: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "20px",
    gap: 21
  },
  fileNameContainer: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "180px"
  },
  imagePreview: {
    width: 120
  },
  imageSearchPreview: {
    width: 80,
    marginRight: "15px"
  },
  imageSearchPane: {
    marginTop: "15px",
    width: "auto",
    whiteSpace: "nowrap",
    // width: "1000px",
    height: "auto",
    overflow: "auto",
    overflowY: "hidden"
  },
  fileContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "auto 0"
  },
  characterField: {
    width: "100%"
  },
  submitBtn: {
    marginRight: "24px"
  }
}));

export default useStyles;
