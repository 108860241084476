import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import api from "../../../api/notifcations";
import { Alert } from "@material-ui/lab";
import types from "../../../redux/types";
import {
  applyEllipsis,
  getRequestError,
  toTitleCase
} from "../../../utils/functions";
import { useStyles } from "./styles";
import Clip from "../../../vectors/Clip";

const NotificationsList = () => {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState({ isError: false, message: "" });
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const { jwt } = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const classes = useStyles();

  const columns = [
    { field: "title", headerName: "Notification Title", width: 170 },
    {
      field: "body",
      headerName: "Notification Body",
      width: 200,
      renderCell: params => (
        <div className={classes.tablerowBody}>
          <p>{applyEllipsis(params.formattedValue, 45)} </p>
          <button
            onClick={() => {
              navigator.clipboard.writeText(params.formattedValue).then(
                function() {
                  dispatch({
                    type: ALERT_OPEN,
                    payload: {
                      severity: "success",
                      message: "Copied!"
                    }
                  });
                },
                function(err) {
                  console.error("Async: Could not copy text: ", err);
                }
              );
            }}
            className={classes.copyButton}
          >
            <Clip />
          </button>
        </div>
      )
    },
    { field: "totalReceived", headerName: "Total receivers", width: 150 },
    { field: "totalClicks", headerName: "Link clicks", width: 130 },
    { field: "purchases", headerName: "Purchases", width: 130 },
    { field: "schedule_date", headerName: "Date Scheduled", width: 160 },
    {
      field: "audience",
      headerName: "Audience",
      width: 150
    }
  ];

  useEffect(() => {
    setLoading(true);
    setError({ isError: false, message: "" });
    api
      .getPushNotifications(`Bearer ${jwt}`, page + 1)
      .then(res => {
        const {
          notifications: notificationsData = [],
          totalCount = 0
        } = res.data.data;
        setNotifications(
          notificationsData.map(
            ({
              audience,
              schedule_date,
              body,
              title,
              id,
              totalReceived,
              totalClicks,
              purchases
            }) => ({
              audience: toTitleCase(audience.replace("-", " ")),
              schedule_date,
              body,
              title,
              id,
              totalReceived,
              totalClicks,
              purchases
            })
          )
        );
        setTotalData(totalCount);
        setLoading(false);
      })
      .catch(e => setError({ isError: false, message: getRequestError(e) }));
  }, [page, jwt]);

  return (
    <div>
      <>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          {error.isError ? (
            <Alert severity="error">{error.message}</Alert>
          ) : (
            <DataGrid
              rows={notifications}
              columns={columns}
              pageSize={10}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              autoHeight={true}
              page={page}
              rowCount={totalData}
              onPageChange={page => {
                setPage(page);
              }}
              paginationMode="server"
              loading={loading}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default NotificationsList;
