import React from "react";
import CubeBookings from "./CubeBookings";

export default [
  {
    path: "/dashboard/cube-booking-logs",
    exact: true,
    component: () => <CubeBookings />
  }
];
