import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.background.default
    }
  },
  topHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px"
  },
  reviewsBtn: {
    color: "#A233E2",
    backgroundColor: "transparent",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Roboto",
    textTransform: "uppercase"
  },
  titleContainer: { marginBottom: 20 },
  patcher: {
    marginTop: "40px"
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  movieDBBtnText: {
    color: "#0E0200"
  },
  divider: {
    backgroundColor: "rgba(204, 204, 204, 0.5)",
    width: "100%",
    height: 0.5,
    margin: "48px 0"
  },
  movieInfo: {
    marginTop: "48px"
  },
  nomovies: {
    display: "flex",
    justifyContent: "space-between"
  },
  btnContainer: {
    marginBottom: theme.spacing(4)
  },
  errorInfoPane: {
    borderLeft: "6px solid crimson",
    padding: "20px",
    marginBottom: "20px",
    fontSize: "15px"
  },
  successInfoPane: {
    borderLeft: "6px solid green",
    padding: "20px",
    marginBottom: "20px",
    fontSize: "15px"
  },
  backBtnContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    marginBottom: "30px",
    boxShadow: "none"
  },
  backBtn: {
    color: "#A233E2",
    fontSize: "14px",
    fontWeight: 400,
    marginRight: "10px",
    backgroundColor: "transparent"
  }
}));

export default useStyles;
