import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardTable from "../../../containers/DashboardTable";
import Chart from "../../../components/Chart";
import statsApi from "../../../api/stats-api";
import generateChart, { stencil } from "./utils/graphs";
import useStyles from "./styles";
import { dateFromISO, getRequestError } from "../../../utils/functions";
import { generateMonths } from "./utils/time";
import { DataGrid } from "@material-ui/data-grid";
import Section from "../../../components/Section";
import Check from "../../../vectors/Check";
import Close from "../../../vectors/Close";
import DropDown from "../../../components/Inputs/Dropdown";
import moment from "moment";
import DateSelector from "../../../components/Inputs/DateSelector";

const PurchaseHistoryAnalyticsPage = ({ jwt }) => {
  const [
    isLoadingConcessionDiscountTable,
    setIsLoadingConcessionDiscountTable
  ] = useState(false);
  const [concessionDiscountTable, setConcessionDiscountTable] = useState(null);

  const [
    isLoadingConcessionDiscountGraph,
    setIsLoadingConcessionDiscountGraph
  ] = useState(false);
  const [concessionDiscountGraph, setConcessionDiscountGraph] = useState(
    stencil
  );
  const [concessionDiscountYear, setConcessionDiscountYear] = useState("2022");
  const [oneTimePurchaseData, setOneTimePurchaseData] = useState([]);

  const [
    isLoadingRedeemedTicketsTable,
    setIsLoadingRedeemedTicketsTable
  ] = useState(false);
  const [redeemedTicketsTable, setRedeemedTicketsTable] = useState(null);
  const [redeemedTicketsDate, setRedeemedTicketsDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [redeemedTicketsDuration, setRedeemedTicketsDuration] = useState(
    "WEEKLY"
  );

  const { dashboardSection, chartTitle, filterCont } = useStyles();

  useEffect(() => {
    setIsLoadingConcessionDiscountTable(true);
    setIsLoadingConcessionDiscountGraph(true);
    statsApi
      .getConcessionDiscount(jwt, concessionDiscountYear)
      .then(res => {
        const result = res.data?.data?.monthly_breakdown;
        setConcessionDiscountTable({
          totalPurchaseCountForTheYear:
            res.data?.data?.totalPurchaseCountForTheYear,
          totalRevenueForTheYear: `₦${res.data?.data?.totalRevenueForTheYear.toLocaleString()}`
        });
        setConcessionDiscountGraph(
          generateChart(
            result?.map(item => [item?.total, item?.count]),
            ["Amount", "Count"],
            generateMonths(result?.map(item => item?.month))
          )
        );
        setIsLoadingConcessionDiscountGraph(false);
        setIsLoadingConcessionDiscountTable(false);
      })
      .catch(e => getRequestError(e));
  }, [jwt, concessionDiscountYear]);

  useEffect(() => {
    setIsLoadingRedeemedTicketsTable(true);
    statsApi
      .getAverageNoOfRedeemedTickets(
        jwt,
        redeemedTicketsDate,
        redeemedTicketsDuration
      )
      .then(res => {
        const {
          year: startYear,
          month: startMonth,
          day: startDay
        } = dateFromISO(res.data?.data?.date?.start);
        const { year: endYear, month: endMonth, day: endDay } = dateFromISO(
          res.data?.data?.date?.end
        );
        setRedeemedTicketsTable({
          average: res.data?.data?.average.toFixed(2),
          startDate: `${startYear}-${startMonth}-${startDay}`,
          endDate: `${endYear}-${endMonth}-${endDay}`
        });
        setIsLoadingRedeemedTicketsTable(false);
      })
      .catch(e => getRequestError(e));
  }, [jwt, redeemedTicketsDate, redeemedTicketsDuration]);

  useEffect(() => {
    statsApi
      .getOneTimePurchase(jwt)
      .then(res => {
        setOneTimePurchaseData(
          res.data?.data.map(
            (
              {
                customer_name,
                customer_email,
                flutterwave_transactionId,
                ticket_purchase,
                concession_purchase
              },
              i
            ) => ({
              id: i,
              customer_name,
              customer_email,
              flutterwave_transactionId,
              ticket_purchase,
              concession_purchase
            })
          ) ?? []
        );
      })
      .catch(e => getRequestError(e));
  }, [jwt]);

  const columns = [
    {
      field: "customer_name",
      headerName: "Customer name",
      width: 450
    },
    {
      field: "customer_email",
      headerName: "Email Address",
      width: 400
    },
    {
      field: "flutterwave_transactionId",
      headerName: "Transaction ID",
      width: 160
    },
    {
      field: "ticket_purchase",
      headerName: "Ticket purchase ",
      width: 200,
      renderCell: params => {
        return params.row.ticket_purchase ? <Check /> : <Close />;
      }
    },
    {
      field: "concession_purchase",
      headerName: "Concession purchase",
      width: 200,
      renderCell: params => {
        return params.row.concession_purchase ? <Check /> : <Close />;
      }
    }
  ];

  return (
    <div>
      <DashboardTable
        data={[
          {
            cardName: "TOTAL PURCHASE COUNT",
            cardValue:
              concessionDiscountTable?.totalPurchaseCountForTheYear?.toLocaleString() ||
              "N/A",
            cardText: "0"
          },
          {
            cardName: "TOTAL REVENUE",
            cardValue:
              concessionDiscountTable?.totalRevenueForTheYear?.toLocaleString() ||
              "N/A",
            cardText: "0"
          }
        ]}
        loadingTable={isLoadingConcessionDiscountTable}
        tableTitle="Subscriber Concession discount usage "
      />
      <div className={dashboardSection}>
        <Chart
          data={concessionDiscountGraph ?? stencil}
          type="tickets"
          title="Subscriber Concession discount usage "
          height={80}
          xLabel="Months"
          yLabel="Value"
          hasFullReport={false}
          loading={isLoadingConcessionDiscountGraph}
          filterComponent={
            <DropDown
              menuItems={[
                "2022",
                "2021",
                "2020",
                "2019",
                "2018",
                "2017",
                "2016",
                "2015"
              ].map(item => ({ value: item, name: item }))}
              value={concessionDiscountYear}
              onChange={e => setConcessionDiscountYear(e.target.value)}
            />
          }
        />
      </div>
      <DashboardTable
        data={[
          {
            cardName: "AVERAGE NUMBER",
            cardValue: redeemedTicketsTable?.average || "N/A",
            cardText: "0"
          },
          {
            cardName: "START DATE",
            cardValue: redeemedTicketsTable?.startDate || "N/A",
            cardText: "0"
          },
          {
            cardName: "END DATE",
            cardValue: redeemedTicketsTable?.endDate || "N/A",
            cardText: "0"
          }
        ]}
        loadingTable={isLoadingRedeemedTicketsTable}
        tableTitle="Average Number of Redeemed Tickets"
        filterComponent={
          <div className={filterCont}>
            <DateSelector
              value={redeemedTicketsDate}
              onChange={e =>
                setRedeemedTicketsDate(moment(e).format("YYYY-MM-DD"))
              }
            />
            <DropDown
              menuItems={["Weekly", "Monthly", "Yearly"].map(item => ({
                value: item.toUpperCase(),
                name: item
              }))}
              value={redeemedTicketsDuration}
              onChange={e => setRedeemedTicketsDuration(e.target.value)}
            />
          </div>
        }
      />
      <Section title="One time Purchase" titleStyle={chartTitle}>
        {/* <div className={section}> */}
        <div style={{ width: "100%", marginTop: "20px", textAlign: "center" }}>
          {oneTimePurchaseData && oneTimePurchaseData.length > 0 ? (
            <DataGrid
              rows={oneTimePurchaseData}
              columns={columns}
              pageSize={15}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              autoHeight={true}
            />
          ) : (
            <p>No purchases made yet</p>
          )}
        </div>
        {/* }
            /> */}
        {/* </div> */}
      </Section>
    </div>
  );
};

export default connect(state => {
  return {
    jwt: state.user.user.jwt
  };
})(PurchaseHistoryAnalyticsPage);
