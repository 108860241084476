import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: "rgba(0, 0, 0, 0.2)"
    }
  },
  container: {
    textAlign: "center",
    border: "1px solid #222327"
  },
  mainStat: {
    display: "grid"
  },
  nameStyle: {
    textTransform: "uppercase",
    fontSize: "12.63px",
    fontWeight: 600,
    paddingTop: "20px",
    color: "rgba(255, 255, 255, 0.97)",
    backgroundColor: "#241839"
  },
  valueStyle: {
    padding: "20px",
    fontSize: "27.68px",
    backgroundColor: "#241839"
  },
  textStyle: {
    padding: "20px 0 20px",
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: "14.44px"
  }
}));

export default useStyles;
