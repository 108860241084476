import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CircularLoad } from "../../../components/Loader";
import { DataGrid } from "@material-ui/data-grid";
import ticketApi from "../../../api/tickets-api";
import { dateFromISO } from "../../../utils/functions";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Grid, Button } from "@material-ui/core";

const Tickets = () => {
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);

  const { jwt } = useSelector(state => state.user.user);

  useEffect(() => {
    setLoading(true);
    ticketApi.fetchTickets(`Bearer ${jwt}`).then(res => {
      setTickets(
        res.data.data.map(
          ({
            cinema,
            customerEmail,
            customer_name,
            fwTransactionRef,
            purchasDate,
            ticketType,
            movieName
          }) => {
            const { year, month, day } = dateFromISO(purchasDate);
            return {
              cinema,
              customerEmail,
              customer_name,
              fwTransactionRef,
              purchasDate: `${year}-${month}-${day}`,
              ticketType,
              id: fwTransactionRef,
              movieName
            };
          }
        )
      );
      setLoading(false);
    });
  }, [jwt]);

  const columns = [
    {
      field: "purchasDate",
      headerName: "Date Purchased",
      width: 150
    },
    {
      field: "customer_name",
      headerName: "Name",
      width: 200
    },
    {
      field: "customerEmail",
      headerName: "Email",
      width: 200
    },
    {
      field: "ticketType",
      headerName: "Ticket Type",
      width: 180
    },
    {
      field: "movieName",
      headerName: "Movie Title",
      width: 200
    },
    {
      field: "fwTransactionRef",
      headerName: "Transaction ID",
      width: 200
    },
    {
      field: "cinema",
      headerName: "Cinema",
      width: 150
    }
  ];

  return (
    <div>
      {loading ? (
        <div>
          <CircularLoad show={true} />
        </div>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <CSVLink data={tickets} filename={"ticket-logs.csv"}>
                <Button variant="contained" color="primary">
                  <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
                </Button>
              </CSVLink>
            </Grid>
          </Grid>
          <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
            <DataGrid
              rows={tickets}
              columns={columns}
              pageSize={15}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              autoHeight={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Tickets;
