import React from "react";
import useStyles from "./BreakdownText.styles";

const BreakdownText = ({ title, breakdownAmount, icon }) => {
  const { breakdown } = useStyles();

  return (
    <div className={breakdown}>
      <p className="breakdown-heading">{title}</p>
      <div className="breadkdown-analysis">
        <p className="breakdown-amount">{breakdownAmount}</p>
        <div className="breakdown-icon">{icon}</div>
      </div>
    </div>
  );
};

export default BreakdownText;
