import React from "react";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import TableSummary from "../../components/TableSummary";
import useStyles from "./styles";

const DashboardTable = ({
  tableTitle,
  data,
  loadingTable,
  filterComponent
}) => {
  const { root, title, header } = useStyles();
  return (
    <Paper elevation={0} className={root}>
      <div className={header}>
        <div className={title}>{tableTitle}</div>
        {filterComponent}
      </div>
      <TableSummary data={data} tableLoading={loadingTable} />
    </Paper>
  );
};

DashboardTable.propTypes = {
  tableTitle: PropTypes.string
};

export default DashboardTable;
