import React from "react";

const Clip = props => (
  <svg
    {...props}
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1466_15089)">
      <path
        d="M14.0714 3.841L14.0714 13.9348C14.0714 16.2302 12.2105 18.091 9.91511 18.091C7.61968 18.091 5.75886 16.2302 5.75886 13.9347L5.75886 3.841C5.75886 2.31071 6.99941 1.07017 8.5297 1.07017C10.06 1.07017 11.3005 2.31071 11.3005 3.841V13.5389C11.3005 14.3041 10.6803 14.9243 9.91511 14.9243C9.14997 14.9243 8.5297 14.3041 8.5297 13.5389V5.0285"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1466_15089">
        <rect width="19" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Clip;
