import types from "../../../types";

const { MAP_SELECTED_MOVIE_FOR_EDIT, CLEAR_MOVIE_DETAIL } = types;

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_MOVIE_DETAIL:
      return {};
    case MAP_SELECTED_MOVIE_FOR_EDIT:
      return { ...action.details };
    default:
      return state;
  }
};
