import { generateMonths } from "./time";

//base default values
export const stencil = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "My First dataset",
      fill: true,
      lineTension: 0,
      backgroundColor: "#39255B",
      borderColor: "#8E3AC5",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#8E3AC5",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 8,
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#8E3AC5",
      pointHoverBorderWidth: 3,
      pointRadius: 0,
      pointHitRadius: 10,
      legend: { display: false },
      data: [65, 59, 80, 81, 56, 55, 40]
    }
  ]
};

const dashBoardGraphsUI = [
  {
    data: stencil,
    type: "tickets",
    title: "Number of tickets sold breakdown",
    height: 80,
    filterItems: ["All", "IMAX", "3D", "2D", "SIGNATURE"],
    xLabel: "Months",
    yLabel: "Number of tickets",
    fullReport: false
  },
  {
    data: stencil,
    type: "revenue",
    title: "Revenue breakdown",
    height: 80,
    xLabel: "Months",
    yLabel: "Amount",
    fullReport: true
  },
  {
    data: stencil,
    type: "vod",
    title: "VOD subscribers breakdown",
    height: 80,
    filterItems: ["All", "Basic", "Silver", "Platinum"],
    xLabel: "Months",
    yLabel: "Number of subscribers",
    fullReport: true
  },
  {
    data: stencil,
    type: "app",
    title: "App users breakdown",
    height: 80,
    legends: ["Subscribers", "Non-Subscribers"],
    xLabel: "Months",
    yLabel: "Users",
    fullReport: true
  }
];

const labelBackgroundColors = [
  "#39255B",
  "#60CED1",
  "#340e74",
  "#150334",
  "#70569d",
  "#442183",
  "#5110c7",
  "#6008ff",
  "#625df5"
];
const labelborderColors = [
  "#8E3AC5",
  "#60CED1",
  "#5D5C5C",
  "#c185e9",
  "#220f2e",
  "#9177a1",
  "#3a075a",
  "#5f2285",
  "#2e0548"
];

//object creation for the different graphs.
function DashBoardGraph(x, y) {
  this.labels = x;
  this.data = y;

  this.drawGraph = function(labels) {
    return {
      labels: this.labels,
      datasets: labels.map((item, i) => ({
        label: item,
        fill: true,
        lineTension: 0,
        backgroundColor: "rgba(39, 29, 58, 0.5)",
        borderColor: labelborderColors[i] ?? labelborderColors[0],
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#fff",
        pointBackgroundColor: labelborderColors[i] ?? labelborderColors[0],
        pointBorderWidth: 1,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: labelborderColors[i] ?? labelborderColors[0],
        pointHoverBorderWidth: 3,
        pointRadius: 0,
        pointHitRadius: 10,
        legend: { display: false },
        data: [...this.data[i]]
      }))
    };
  };
}

const drawChart = (x, y, labels) => new DashBoardGraph(x, y).drawGraph(labels);

const parseChartData = data => {
  let YValues = [];
  data.forEach(datum => {
    datum.forEach((item, i) => {
      if (YValues[i]?.length) {
        YValues[i].push(item);
      } else {
        YValues[i] = [];
        YValues[i].push(item);
      }
    });
  });
  return { YValues };
};

export const generateChart = (rawData, labels, XValues) => {
  const parsedChartData = parseChartData(rawData);
  const drawnChartData = drawChart(XValues, parsedChartData.YValues, labels);
  return drawnChartData;
};

export const options = {
  aspectRatio: 1,
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false,
    position: "right",
    fullWidth: false,
    afterFit: function() {
      this.width -= 1000;
    },
    labels: {
      boxWidth: 5,
      padding: 20,
      usePointStyle: true
    }
  }
};

export const doughnutData1 = data => ({
  labels: ["Inactive Subscribers", "Active Subscribers"],
  datasets: [
    {
      data: [
        (data && data.inactiveSubscribers) || 0,
        (data && data.activeSubscribers) || 0
      ],
      backgroundColor: ["#4D2984", "#A233E2"],
      hoverBackgroundColor: ["#4D2984", "#A233E2"],
      borderColor: "transparent",
      pointStyle: "circle"
    }
  ]
});

export default generateChart;
