import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "./styles";

const ConfirmDialog = ({
  open,
  handleClose,
  btnText,
  btnType,
  extraLoading,
  title,
  message,
  handleBtnClick
}) => {
  const [loading, setLoading] = useState(false);

  const {
    confirmDialog,
    confirmDialogTitle,
    confirmDialogText,
    confirmDialogAction,
    confirmDialogBtn,
    dangerBtn,
    successBtn
  } = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <div className={confirmDialog}>
        <DialogTitle id="max-width-dialog-title">
          <span className={confirmDialogTitle}>{title}</span>
        </DialogTitle>
        <DialogContent>
          <span className={confirmDialogText}>{message}</span>
        </DialogContent>
        <DialogActions>
          <div className={confirmDialogAction}>
            <Button
              onClick={handleClose}
              className={confirmDialogBtn}
              variant="outlined"
              color="primary"
              disabled={loading || extraLoading}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={async () => {
                setLoading(true);
                await handleBtnClick();
                setLoading(false);
              }}
              className={`${confirmDialogBtn} ${
                btnType === "danger" ? dangerBtn : successBtn
              }`}
              disabled={loading}
            >
              {loading || extraLoading ? "LOADING..." : btnText}
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
