import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    padding: "111px 58px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "48px"
  },
  reviewContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px"
  },
  reviewTitle: {
    fontSize: "40px",
    fontWeight: 500,
    fontFamily: "Roboto",
    color: "#ffffff"
  },
  reviewStats: {
    display: "flex",
    gap: "16px",
    alignItems: "center"
  },
  reviewStat: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    backgroundColor: "#242426",
    padding: "12px 18px",
    borderRadius: "35px",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Roboto",
    color: "#ffffff"
  },
  reviewList: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    listStyle: "none"
  },
  reviewItem: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "14px 24px",
    backgroundColor: "#242426",
    borderRadius: "5px",
    border: "1px solid #4D4D52",
    fontSize: "12px",
    lineHeight: "21.04px",
    fontWeight: 400,
    color: "#ffffff"
  },
  reviewProfile: {
    width: "34px",
    height: "34px",
    borderRadius: "50%",
    border: "2px solid #1E1E1E1A",
    objectFit: "cover"
  }
}));

export default useStyles;
