import types from "../../../types";
import { combineReducers } from "redux";

const { STORE_DBMOVIE_FOR_VISTA, CLEAR_DBMOVIE_FOR_VISTA } = types;

const initialState = {};

const forVista = (state = initialState, action) => {
  switch (action.type) {
    case STORE_DBMOVIE_FOR_VISTA:
      return { ...state, movie: action.movie };
    case CLEAR_DBMOVIE_FOR_VISTA:
      return {};
    default:
      return state;
  }
};

export default combineReducers({ db: forVista });
