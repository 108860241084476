import Dashboard from "../pages/Dashboard";
import ConfirmPassPage from "../pages/ConfirmPassword";
import ResetPage from "../pages/Reset";
import Home from "../pages/Home";
import Mock from "../pages/Mock"


export default [
    {
        path: "/",
        component: Home,
        exact: true
    },
    {
        path: "/reset-password-request",
        component: ResetPage,
        exact: true
    },
    {
        path: "/reset-password/:token",
        component: ConfirmPassPage,
        exact: true
    },
    {
        path: "/dashboard",
        component: Dashboard,
        isPrivate: true
    },
    {
        path: "/mock",
        component: Mock,
        exact: true
    }
];
