import axios from "axios";

export default {
  login: credentials =>
    axios.post("/admin/login", { ...credentials }).then(res => res),
  logout: token =>
    axios
      .post(
        "/admin/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8"
          }
        }
      )
      .then(res => res),
  resetPasswordInit: email =>
    axios.post(`/admin/forgot-password`, { email }).then(res => res),
  resetPasswordComp: ({ password, token }) =>
    axios.post(`/admin/forgot-password/${token}`, { password }).then(res => res)
};
