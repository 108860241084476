import React from 'react'
import { Route, Redirect } from 'react-router-dom'


const PrivateRoute = ({ signedIn, component, ...routeProps }) => {
    const Component = component
    return (
        <Route
            exact
            render={() => (signedIn ? <Component /> : <Redirect to="/" />)}
            {...routeProps}
        />
    );
}

export default PrivateRoute
