import React, { useState, useEffect } from "react";
import api from "../../../api/concessions-api";
import sheet from "../../../containers/AdminList/styles";
import lodash from "lodash";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SlideIn from "../../../components/Drawer";
import {
  List,
  ListItem,
  Typography,
  Button,
  TextField,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import Back from "../../../components/Button/Back";
import {
  getDays,
  getYears,
  getMonths,
  getRequestError
} from "../../../utils/functions";
import moment from "moment";
import GoBack from "../../../components/Button/GoBack";
import { useDispatch } from "react-redux";
import types from "../../../redux/types";
import { useStyles } from "./styles";

const EditConcessions = ({
  onClose,
  handleChange,
  open,
  jwt,
  refreshList,
  close,
  data
}) => {
  const [error, setError] = useState("");
  const [editedValues, setEditedValues] = useState(data);
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [thumbnailImageFile, setThumbnailImageFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [isThumbnailUploading, setIsThumbnailUploading] = useState(false);
  const { ALERT_OPEN } = types;

  const { uploadedImage } = useStyles();

  const dispatch = useDispatch();

  const { classes } = sheet;

  const resetOnClose = e => {
    onClose(e);
  };

  useEffect(() => {
    console.log(data);
    if (Object.keys(data).length > 0) {
      const editableItems = {
        title: data.product_name,
        price: data.product_price
      };
      setEditedValues(editableItems);
      setImageFile(
        data?.image_url ? { name: data?.image_url, type: "uploaded" } : null
      );
      setLoading(false);
    }
  }, [data]);

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      setError({ msg: "", isError: false });

      if (!imageFile || imageFile.type === "uploaded") {
        setError({ msg: "Upload an image", isError: true });
        return;
      }
      setLoading(true);

      const formData = new FormData();
      formData.append("rosetta_product_id", data?.id);
      formData.append("file", imageFile);
      await api.uploadConcessionImage(formData, jwt);
      setLoading(false);
      refreshList();
      close();
    } catch (e) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(e)
        }
      });
      setLoading(false);
    }
    // let userRequest = api.editConcessionById(data.id, body, `Bearer ${jwt}`);

    // userRequest
    //   .then(res => {
    //     setLoading(false);
    //     close();
    //     refreshList();
    //   })
    //   .catch(err => {
    //     const response = err && err.response && err.response.data;
    //     let errMsg =
    //       response && response.errors[0] && response.errors[0].message
    //         ? response.errors[0].message
    //         : "Something went wrong :I";

    //     setError({
    //       errMsg
    //     });
    //     setLoading(false);
    //   });
  };

  const handleUploadImage = async (type, e) => {
    setImageFile(e.target.files[0]);
    setIsUploading(true);
    setIsUploading(false);
    e.preventDefault();
    // if (type === "thumbnail") {
    //   setIsThumbnailUploading(true);
    //   setThumbnailImageFile(e.target.files[0]);
    // } else {
    //   setIsUploading(true);
    //   setImageFile(e.target.files[0]);
    // }

    // if (type === "thumbnail") {
    //   setIsThumbnailUploading(false);
    // } else {
    //   setIsUploading(false);
    // }
  };

  return (
    <SlideIn open={open} onClose={resetOnClose} from="right">
      <List className={classes.mainContainer}>
        <ListItem className={classes.container}>
          <GoBack onClick={resetOnClose} type="button" />
        </ListItem>
        <form onSubmit={handleSubmit}>
          <ListItem className={classes.container}>
            <Typography variant="h3">Edit Image</Typography>
          </ListItem>
          <ListItem className={classes.container}>
            <div className={classes.section}>
              <Typography variant="h4" className={classes.title}>
                Title
              </Typography>
              <TextField
                id="outlined-password-input"
                name="ftitle"
                label="Title"
                type="text"
                margin="normal"
                variant="outlined"
                className={classes.txtField}
                onChange={e =>
                  setEditedValues({
                    ...editedValues,
                    title: e.target.value
                  })
                }
                value={editedValues.title}
                required
                disabled={true}
              />
              <Typography variant="h4" className={classes.title}>
                Price
              </Typography>
              <TextField
                name="lastName"
                label="Price"
                type="number"
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                className={classes.txtField}
                onChange={e =>
                  setEditedValues({ ...editedValues, price: e.target.value })
                }
                value={editedValues.price}
                required
                disabled={true}
              />

              <Typography variant="h4" className={classes.title}>
                Upload Image
              </Typography>

              <div className={classes.uploadImage}>
                <div className="inline-uploader">
                  <span className="btn-file">
                    Choose Product image
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={e => handleUploadImage("normal", e)}
                      accept="image/x-png,image/gif,image/jpeg,image/jpg"
                    />
                  </span>
                  <span className="side-text">
                    {isUploading
                      ? "Uploading..."
                      : (imageFile && imageFile.name) || "No file chosen"}
                  </span>
                </div>
                {imageFile && (
                  <img
                    className={uploadedImage}
                    alt=""
                    src={
                      imageFile.type === "uploaded"
                        ? imageFile.name
                        : URL.createObjectURL(imageFile)
                    }
                  />
                )}
              </div>
              {error.isError && (
                <div>
                  <div className={classes.error}>{error.msg}</div>
                </div>
              )}

              <div>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.createBtn}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </div>
            </div>
          </ListItem>
        </form>
      </List>
    </SlideIn>
  );
};

export default EditConcessions;
