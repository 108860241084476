import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  tabsContainer: {
    display: "flex"
  },
  tab: props => ({
    display: "flex",
    height: "48px",
    gap: "12px",
    alignItems: "center",
    justifyContent: "center",
    padding: "13px 24px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "19px",
    letterSpacing: "0em",
    border: "1px solid #3C2150",
    fontFamily: "Roboto",
    width: "182px"
  }),
  search: {
    width: "658px",
    margin: "32px 0"
  },
  add: {
    color: "#03AF62",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal",
    textDecoration: "underline"
  },
  change: {
    color: "#60CED1",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal",
    textDecoration: "underline"
  },
  view: {
    color: "#E55FFE",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal",
    textDecoration: "underline"
  },
  formDialog: {
    fontFamily: "Roboto",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "713px",
      width: "80%",
      padding: "32px"
    },
    "& .MuiPaper-root": {
      backgroundColor: "#1C1C22"
    }
  },
  historyDialog: {
    fontFamily: "Roboto",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "863px",
      width: "80%",
      padding: "32px"
    },
    "& .MuiPaper-root": {
      backgroundColor: "#1C1C22"
    }
  },
  dialogHeading: {
    fontSize: "40px",
    fontWeight: "500",
    color: "#FFFFFF",
    lineHeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  increment: {
    color: "#03AF62",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal"
  },
  decrement: {
    color: "#DF3840",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal"
  },
  userDetails: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "55px 0"
  },
  userDetailCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px"
  },
  userDetailTitle: {
    color: "#656569",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "normal"
  },
  userDetailValue: {
    color: "#FFF",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "normal",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "22px",
    marginBottom: "55px"
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    gap: "24px",
    "& > *": {
      width: "50%"
    }
  },
  fullWidthInput: {
    width: "100%"
  }
}));
