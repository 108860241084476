import React from "react";
import ErrorLogs from "./ErrorLogs";

export default [
  {
    path: "/dashboard/error-logs",
    exact: true,
    component: () => <ErrorLogs />
  }
];
