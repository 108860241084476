import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import useStyles from "./styles";

const MoviePoster = ({
  name,
  date,
  image,
  chosen,
  id,
  index,
  onClick,
  loading
}) => {
  const classes = useStyles();
  return (
    <Box
      className={chosen === id ? classes.selected : classes.root}
      onClick={e => onClick(e, id)}
    >
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.imageCont}>
        <img src={image} alt="movie" className={classes.poster} />
      </div>
      <div className={classes.typography}>
        <Typography component="p" variant="body1">
          {name}
        </Typography>
        <Typography component="p">{date}</Typography>
      </div>
    </Box>
  );
};

MoviePoster.defaultProps = {
  name: "Alita",
  date: "2018",
  image: require("../../assets/images/alita.jpg")
};

export default MoviePoster;
