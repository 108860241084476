import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CircularLoad } from "../../../components/Loader";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Grid, Button, TextField, MenuItem } from "@material-ui/core";
import Plus from "../../../vectors/Plus";
import { useStyles } from "./styles";
import PaidBookings from "./Bookings/PaidBookings";
import CreateBooking from "./CreateBooking";
import CancelledBookings from "./Bookings/CancelledBookings";
import RescheduledBookings from "./Bookings/RescheduledBookings";
import cubeApi from "../../../api/cube";
import { BOOKING_STATUS } from "./data";
import RedeemedBookings from "./Bookings/RedeemedBookings";
import EditBooking from "./EditBooking";
import useBookings from "./useBookings";
import ConfirmDialog from "./ConfirmDialog";
import RescheduleBooking from "./RescheduleBooking";

const CubeBookings = () => {
  const [activeTab, setActiveTab] = useState("paid");
  const [selectedCube, setSelectedCube] = useState({});
  const [cubes, setCubes] = useState([]);
  const [cubesLoading, setCubesLoading] = useState(false);
  const [showBookingForm, setShowBookingForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [rescheduleBooking, setRescheduleBooking] = useState(false);
  const [showRedeemDialog, setShowRedeemDialog] = useState(false);
  const [booking, setBooking] = useState({});
  const { data, loadBooking, loading: loadingData } = useBookings({
    selectedCubeId: selectedCube?.id,
    status: BOOKING_STATUS[activeTab]
  });

  const { jwt } = useSelector(state => state.user.user);

  const classes = useStyles();

  useEffect(() => {
    setCubesLoading(true);
    cubeApi.getCubes().then(res => {
      setCubes(res.data.data);
      setSelectedCube(res.data.data[0]);
      setCubesLoading(false);
    });
  }, []);

  const handleEditClick = booking => {
    setBooking(booking);
    setShowEditForm(true);
  };

  const handleCancelClick = booking => {
    setBooking(booking);
    setShowCancelDialog(true);
  };

  const handleRescheduleClick = booking => {
    setBooking(booking);
    setRescheduleBooking(true);
  };

  const handleRedeemClick = booking => {
    setBooking(booking);
    setShowRedeemDialog(true);
  };

  return (
    <div>
      <CreateBooking
        open={showBookingForm}
        handleClose={() => setShowBookingForm(false)}
        cubes={cubes}
        selectedCube={selectedCube}
      />
      <EditBooking
        open={showEditForm}
        handleClose={() => setShowEditForm(false)}
        cubes={cubes}
        booking={booking}
        loadBooking={loadBooking}
      />
      <ConfirmDialog
        btnText="YES, CANCEL"
        btnType="danger"
        handleBtnClick={async () => {
          const res = await cubeApi.editStatus(jwt, booking.booking_id, {
            status: BOOKING_STATUS["cancelled"][0]
          });
          loadBooking();
          setShowCancelDialog(false);
          return res;
        }}
        handleClose={() => setShowCancelDialog(false)}
        message={`Are you sure you want to cancel the cube booking for ${booking.email}`}
        open={showCancelDialog}
        title="Cube Booking Cancellation"
      />
      <RescheduleBooking
        booking={booking}
        open={rescheduleBooking}
        handleClose={() => setRescheduleBooking(false)}
        cubes={cubes}
        loadBooking={loadBooking}
      />
      <ConfirmDialog
        btnText="YES, REDEEM"
        btnType="success"
        handleBtnClick={async () => {
          const res = await cubeApi.editStatus(jwt, booking.booking_id, {
            status: BOOKING_STATUS["redeemed"][0]
          });
          loadBooking();
          setShowRedeemDialog(false);
          return res;
        }}
        handleClose={() => setShowRedeemDialog(false)}
        message={`Are you sure you want to redeem the cube booking for ${booking.email}`}
        open={showRedeemDialog}
        title="Cube Booking Redemption"
      />
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={3}>
          {cubesLoading ? (
            <CircularLoad show={true} />
          ) : (
            <TextField
              id="select-change-url"
              select
              variant="outlined"
              margin="normal"
              label="Select cube"
              name="Select-cube"
              value={selectedCube}
              onChange={e => {
                setSelectedCube(e.target.value);
              }}
              fullWidth
            >
              {cubes.map(item => (
                <MenuItem key={item?.id} value={item}>
                  {item?.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
        <Grid item xs={9} justify="flex-end">
          <Grid spacing={3} justify="flex-end" container>
            <Button
              className="create-user"
              variant="outlined"
              color="primary"
              style={{ marginRight: "15px" }}
              onClick={() => setShowBookingForm(true)}
            >
              <Plus className="c-user-icon" /> CUBE BOOKING
            </Button>
            <CSVLink data={data} filename={"cube-logs.csv"}>
              <Button variant="contained" color="primary">
                <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.tabsContainer}>
        {["Paid", "Cancelled", "Rescheduled", "Redeemed"].map(item => (
          <button
            onClick={() => setActiveTab(item.toLowerCase())}
            style={{
              backgroundColor:
                activeTab === item.toLowerCase() ? "#3C2150" : "transparent",
              color: activeTab === item.toLowerCase() ? "white" : "#656569"
            }}
            className={classes.tab}
            key={item}
          >
            {item} Bookings
          </button>
        ))}
      </div>
      <>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          {activeTab === "paid" ? (
            <PaidBookings
              onEditClick={handleEditClick}
              onCancelClick={handleCancelClick}
              onRescheduleClick={handleRescheduleClick}
              onRedeemClick={handleRedeemClick}
              loading={loadingData}
              data={data}
            />
          ) : activeTab === "cancelled" ? (
            <CancelledBookings
              selectedCubeId={selectedCube?.id}
              loading={loadingData}
              data={data}
              loadBooking={loadBooking}
            />
          ) : activeTab === "rescheduled" ? (
            <RescheduledBookings
              selectedCubeId={selectedCube?.id}
              loading={loadingData}
              data={data}
              loadBooking={loadBooking}
              onEditClick={handleEditClick}
              onCancelClick={handleCancelClick}
              onRedeemClick={handleRedeemClick}
            />
          ) : (
            <RedeemedBookings
              selectedCubeId={selectedCube?.id}
              loading={loadingData}
              data={data}
              loadBooking={loadBooking}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default CubeBookings;
