import axios from "axios";

export default {
  getList: (token, page, position) =>
    axios
      .get(
        `/promotions/admin?pageNo=${page}&pageSize=10&position=${position}`,
        {
          headers: { Authorization: token }
        }
      )
      .then(res => res),
  getPromotion: (token, id) =>
    axios
      .get(`/promotions/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  addPromotion: (token, payload) =>
    axios
      .post("/promotions", payload, {
        headers: { Authorization: token }
      })
      .then(res => res),
  editPromotion: (token, payload, id) =>
    axios
      .put(`/promotions/${id}`, payload, {
        headers: { Authorization: token }
      })
      .then(res => res),
  editPromotionStatus: (token, isActive, id) =>
    axios
      .patch(
        `/promotions/${id}/status`,
        {
          isActive
        },
        {
          headers: { Authorization: token }
        }
      )
      .then(res => res),
  deletePromotion: (token, id) =>
    axios
      .delete(`/promotions/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
