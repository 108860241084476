import React, { useState, useEffect } from "react";
import validator from "validator";
import SubInputContainer from "../SubInputContainer";
import ImageUploader from "./../../../components/ImageUploader";
import useStyles from "./styles";
import { CircularProgress, MenuItem, TextField } from "@material-ui/core";
import Check from "../../../vectors/Check";

const Poster = ({
  title,
  name,
  imgURL,
  onChange,
  uploadImageFileAPI,
  missing
}) => {
  const {
    imgContainer,
    inputSpacer,
    heading,
    thumbnailCont,
    thumbnailDetails,
    thumbnailImg,
    thumbnailName,
    thumbnailSize
  } = useStyles();
  const [changeURL, setChangeURL] = useState("upload");
  const [isImageValid, setIsImageValid] = useState();
  // const [isImageNewValid, setIsImageNewValid] = useState();
  const [poster, setPoster] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const uploadImage = async file => {
    setIsUploading(true);
    await uploadImageFileAPI(file, name);
    setIsUploading(false);
  };

  const handleChange = event => {
    setChangeURL(event.target.value);
  };

  useEffect(() => {
    if (imgURL.value !== undefined && imgURL.value !== null) {
      if (validator.isURL(imgURL.value)) {
        setIsImageValid(true);
        setPoster(imgURL.value);
      } else {
        setIsImageValid(false);
      }
    } else {
      setIsImageValid(false);
    }
  }, [imgURL]);

  return (
    <SubInputContainer title={title} media={true}>
      <div className={heading}>Current URL</div>
      <TextField
        error={imgURL.error.isError || missing.includes(name)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="poster-url"
        type="text"
        name={name}
        value={imgURL.value}
        onBlur={onChange}
        onChange={onChange}
        helperText={
          (imgURL.error.isError || missing.includes(name)) &&
          (imgURL.error.message || "URL is empty")
        }
        disabled={changeURL === "manual" ? false : true}
      />
      <div className={heading}>Change URL</div>
      <TextField
        id="select-change-url"
        select
        variant="outlined"
        margin="normal"
        label="Select Option"
        name="Select-Option"
        value={changeURL}
        onChange={handleChange}
        fullWidth
      >
        <MenuItem key="manual" value="manual">
          Change URL Manually
        </MenuItem>
        <MenuItem key="upload" value="upload">
          Upload from Computer
        </MenuItem>
      </TextField>
      <div className={inputSpacer}>
        {changeURL === "upload" && (
          <ImageUploader
            field={`${name}`}
            uploadImage={uploadImage}
            isUploading={isUploading}
          />
        )}
      </div>
      {poster && (
        <div className={thumbnailCont}>
          <div className={thumbnailDetails}>
            <img className={thumbnailImg} src={poster} alt="available poster" />
            <div>
              <p className={thumbnailName}>
                {imgURL.value.replace(
                  "https://filmhouse.s3.us-west-2.amazonaws.com/",
                  ""
                )}
              </p>
            </div>
          </div>
          {isUploading ? <CircularProgress /> : <Check />}
        </div>
      )}
    </SubInputContainer>
  );
};

export default Poster;
