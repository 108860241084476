import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from "@material-ui/core";
import { useStyles } from "./styles";
import validator from "validator";
import types from "../../../redux/types";
import {
  generateFormattedDates,
  getRequestError
} from "../../../utils/functions";
import { useEffect } from "react";
import moviesAPi from "../../../api/movie-entry-api";
import cubesApi from "../../../api/cube";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const initialState = {
  movie: { value: "", error: { isError: false, message: "" } },
  date: { value: "", error: { isError: false, message: "" } },
  time: { value: "", error: { isError: false, message: "" } },
  cube: { value: "", error: { isError: false, message: "" } }
};
const RescheduleBooking = ({
  open,
  handleClose,
  cubes,
  booking,
  loadBooking
}) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    ...initialState
  });
  const [status, setStatus] = useState({
    loading: false,
    required: ["cube", "date", "time", "movie"],
    missing: []
  });
  const [movies, setMovies] = useState([]);
  const [slots, setSlots] = useState([]);
  const [moviesLoading, setMoviesLoading] = useState(false);
  const [slotsLoading, setSlotsLoading] = useState(false);

  const { jwt } = useSelector(state => state.user.user);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const handleChange = ({ target }, _validator) => {
    const { name, value } = target;
    const checkedValidator = _validator
      ? _validator
      : value =>
          validator.isEmpty(value)
            ? { isError: true, message: "Field is empty" }
            : { isError: false, message: "" };
    if (!validator.isEmpty(value)) {
      setStatus(prevStatus => ({
        ...prevStatus,
        missing: prevStatus.missing.filter(item => name !== item)
      }));
    }
    setForm(prevForm => ({
      ...prevForm,
      [name]: { value: value, error: checkedValidator(value) }
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { cube, movie, time } = form;

    let missing = new Set();
    status.required.forEach(item => {
      if (validator.isEmpty(form[item].value)) {
        missing.add(item);
      }
    });
    setStatus(prevStatus => ({ ...prevStatus, missing: [...missing] }));
    if ([...missing].length) return;
    setStatus(prevStatus => ({ ...prevStatus, loading: true }));
    const currentTime = moment();
    const dateTime = moment(booking.date_time);
    const timeDiffInHours = dateTime.diff(currentTime, "hours");
    const test = currentTime.diff(dateTime, "hours");
    console.log(timeDiffInHours, test, currentTime, dateTime);
    const feePercent =
      timeDiffInHours >= 24 ? 0.1 : timeDiffInHours >= 12 ? 0.15 : 0.4;
    cubesApi
      .rescheduleBookings(
        jwt,
        {
          reschedule_date: time.value,
          movie_id: movie.value,
          payment_amount: `${feePercent * booking.payment_amount}`,
          cube_id: cube.value
        },
        booking.booking_id
      )
      .then(res => {
        console.log(res);
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "success",
            message: res.data.message ?? "Successful"
          }
        });
        loadBooking();
        handleClose();
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
      })
      .finally(() => {
        setStatus(prevStatus => ({ ...prevStatus, loading: false }));
      });
  };

  useEffect(() => {
    if (booking.movie_id && booking.cube_id && booking.date_time) {
      setForm(prevForm => ({
        ...prevForm,
        movie: {
          value: `${booking.movie_id}`,
          error: { isError: false, message: "" }
        },
        cube: {
          value: booking.cube_id,
          error: { isError: false, message: "" }
        },
        date: {
          value: booking.date_time.substring(0, 10),
          error: { isError: false, message: "" }
        },
        time: {
          value: `${booking.date_time}`,
          error: { isError: false, message: "" }
        }
      }));
    }
  }, [booking]);

  useEffect(() => {
    if (form.cube.value && form.date.value && cubes.length) {
      const cubeCinemaId = cubes.find(item => item.id === form.cube.value)
        ?.rosetta_cinema_id;
      setMoviesLoading(true);
      cubesApi
        .getCinemaMovies(cubeCinemaId, form.date.value, form.date.value, jwt)
        .then(res => {
          setMovies(res.data.data);
          if (res.data.data.length) {
            setForm(prevForm => ({
              ...prevForm,
              movie: {
                value: res.data.data[0].movie_id.toString(),
                error: { isError: false, message: "" }
              }
            }));
          }
        })
        .finally(() => setMoviesLoading(false));
    }
  }, [cubes, form.cube.value, form.date.value, jwt]);

  useEffect(() => {
    if (form.cube.value && form.date.value) {
      setSlotsLoading(true);
      cubesApi
        .getSlots(form.cube.value, form.date.value)
        .then(res => {
          setSlots(res.data.data ?? []);
          if (res.data?.data?.length) {
            setForm(prevForm => ({
              ...prevForm,
              time: {
                value: res.data.data[0].date_time,
                error: { isError: false, message: "" }
              }
            }));
          }
        })
        .finally(() => setSlotsLoading(false));
    }
  }, [form.cube.value, form.date.value]);

  const { cube, date, movie, time } = form;
  const { loading, missing } = status;
  console.log(time);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="max-width-dialog-title">
          <span className={classes.detailsHeading}>Reschedule Booking</span>
        </DialogTitle>
        <DialogContent>
          <form
            name="reschedule-bookings"
            onSubmit={handleSubmit}
            className={classes.bookingForm}
          >
            <div>
              <span className={classes.formHeading}>MOVIE INFO</span>
              <div className={classes.movieformContainer}>
                <FormControl variant="outlined">
                  <InputLabel>Select a Cube</InputLabel>
                  <Select
                    value={cube.value}
                    onChange={e => handleChange(e, null)}
                    onBlur={e => handleChange(e, null)}
                    name="cube"
                    error={cube.error.isError || missing.includes("cube")}
                    disabled={true}
                  >
                    {cubes.map((item, i) => {
                      return (
                        <MenuItem key={item?.id} value={item.id}>
                          {item?.cinema_name} Cube
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={6}>
                    <FormControl
                      className={classes.fullWidthInput}
                      variant="outlined"
                    >
                      <InputLabel>Anticipated Date</InputLabel>
                      <Select
                        value={date.value}
                        onChange={e => handleChange(e, null)}
                        onBlur={e => handleChange(e, null)}
                        name="date"
                        error={date.error.isError || missing.includes("date")}
                      >
                        {generateFormattedDates(7).map(({ name, value }, i) => {
                          return (
                            <MenuItem key={i} value={value}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      className={classes.fullWidthInput}
                      variant="outlined"
                    >
                      <InputLabel>Start Time</InputLabel>
                      <Select
                        value={time.value}
                        onChange={e => handleChange(e, null)}
                        onBlur={e => handleChange(e, null)}
                        name="time"
                        error={time.error.isError || missing.includes("time")}
                        disabled={slotsLoading}
                      >
                        {slots.map(({ label, date_time }, i) => {
                          return (
                            <MenuItem key={i} value={date_time}>
                              {label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormControl variant="outlined">
                  <InputLabel>Select a movie</InputLabel>
                  <Select
                    value={movie.value}
                    onChange={e => handleChange(e, null)}
                    onBlur={e => handleChange(e, null)}
                    name="movie"
                    error={movie.error.isError || missing.includes("movie")}
                    disabled={moviesLoading}
                  >
                    {movies?.map(({ movie_name, movie_id }, i) => {
                      return (
                        <MenuItem key={i} value={movie_id.toString()}>
                          {movie_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={classes.formAction}>
              <Button
                className={classes.formBtn}
                onClick={handleClose}
                color="primary"
                variant="outlined"
                disabled={loading}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                className={classes.formBtn}
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? "LOADING" : "PROCEED"}
              </Button>
            </div>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default RescheduleBooking;
