import React from "react";

const CircledClose = props => (
  <svg
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2 22.6667L16 17.8667L20.8 22.6667L22.6666 20.8L17.8666 16L22.6666 11.2L20.8 9.33332L16 14.1333L11.2 9.33332L9.33329 11.2L14.1333 16L9.33329 20.8L11.2 22.6667ZM16 29.3333C14.1555 29.3333 12.4222 28.9831 10.8 28.2827C9.17774 27.5822 7.76663 26.6324 6.56663 25.4333C5.36663 24.2333 4.41685 22.8222 3.71729 21.2C3.01774 19.5778 2.66751 17.8444 2.66663 16C2.66663 14.1555 3.01685 12.4222 3.71729 10.8C4.41774 9.17777 5.36752 7.76666 6.56663 6.56666C7.76663 5.36666 9.17774 4.41688 10.8 3.71732C12.4222 3.01777 14.1555 2.66755 16 2.66666C17.8444 2.66666 19.5777 3.01688 21.2 3.71732C22.8222 4.41777 24.2333 5.36755 25.4333 6.56666C26.6333 7.76666 27.5835 9.17777 28.284 10.8C28.9844 12.4222 29.3342 14.1555 29.3333 16C29.3333 17.8444 28.9831 19.5778 28.2826 21.2C27.5822 22.8222 26.6324 24.2333 25.4333 25.4333C24.2333 26.6333 22.8222 27.5835 21.2 28.284C19.5777 28.9844 17.8444 29.3342 16 29.3333Z"
      fill="#606064"
    />
  </svg>
);

export default CircledClose;
