import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: props => ({
    display: "flex",
    alignItems: "center",
    padding: "10px 15px 10px 16px",
    gap: "11px",
    backgroundColor: props.bgColor,
    fontSize: "12px",
    fontWeight: "500",
    lineheight: "14px",
    color: "#ffffff",
    borderRadius: "8px",
    "& p": { margin: 0 }
  }),
  tickOuter: props => ({
    width: "13px",
    height: "13px",
    border: `1px solid ${props.tickColor}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    cursor: "pointer"
  }),
  tickInner: props => ({
    width: "7px",
    height: "7px",
    border: `1px solid ${props.isTicked ? props.tickColor : "transparent"}`,
    backgroundColor: props.isTicked ? props.tickColor : "transparent",
    borderRadius: "50%"
  })
}));

export default useStyles;
