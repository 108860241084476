import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { adminLogout } from "../../redux/actions/rxa_auth";

const IdleDetector = ({ children, jwt, adminLogout }) => {
  const timer = useRef(null);

  const resetTimer = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (jwt) adminLogout(jwt);
    }, 1800000);
  };

  useEffect(() => {
    timer.current = setTimeout(() => {
      if (jwt) adminLogout(jwt);
    }, 1800000);
    return () => {
      clearTimeout(timer.current);
    };
  }, [adminLogout, jwt]);

  return (
    <div
      style={{ display: "flex", maxHeight: "100vh" }}
      onMouseMove={resetTimer}
      onKeyPress={resetTimer}
    >
      {children}
    </div>
  );
};

const mapStateToProps = state => ({
  jwt: state.user.user.jwt
});

export default connect(mapStateToProps, { adminLogout })(IdleDetector);
