import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import userApis from "../../api/users-api";
import sorting from "../../components/functions/sorting";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Button, Grid } from "@material-ui/core";
import "./userlist.css";
import PaperedInput from "../../components/Inputs/PaperedInput";
import { DataGrid } from "@material-ui/data-grid";
import { Alert } from "@material-ui/lab";
import Plus from "../../vectors/Plus";
import UserSlideIn from "./UserSlideIn";

class UserList extends React.Component {
  state = {
    query: "",
    loading: true,
    data: [],
    filteredData: [],
    error: {
      isError: false,
      message: ""
    },
    showPanel: false,
    page: 0,
    totalData: 0,
    searchInput: ""
  };

  toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ showPanel: open });
  };

  refreshList = () => {
    this.setState({
      loading: true
    });
    const { page, searchInput } = this.state;
    this.getUsers(page, searchInput);
  };

  getUsers = (page, searchQuery) => {
    const { user } = this.props;
    this.setState({
      error: { isError: false, message: "" },
      loading: true
    });
    userApis
      .getUsersType(`Bearer ${user.jwt}`, page + 1, "ACTIVE", searchQuery)
      .then(res => {
        const { users = [], totalCount = 0 } = res.data.data;
        console.log(res.data.data);

        this.setState({
          loading: false,
          data: users.map(item => ({
            date: `${item?.createdAt.substring(
              8,
              10
            )}/${item?.createdAt.substring(5, 7)} ${item?.createdAt.substring(
              0,
              4
            )}`,
            email: item?.email,
            subscriptionPlan:
              item?.lastest_subscription?.subscriptionName || "Basic",
            name: item?.firstName + " " + item?.lastName,
            id: item?.userId
          })),
          totalData: totalCount,
          // totalData: res.data.data.length,
          isUpdatingData: false
        });
      })
      .catch(() => {
        this.setState({
          error: { isError: true, message: "There is an error" },
          loading: false
        });
      });
  };

  findUsers = keyword => {
    const { user } = this.props;
    this.setState({
      error: { isError: false, message: "" },
      loading: true
    });
    userApis
      .findUsers(`Bearer ${user.jwt}`, keyword)
      .then(res => {
        // const { users = [], perPage = 0, totalPages = 0 } = res.data.data;
        console.log(res.data.data);

        // this.setState({
        //   loading: false,
        //   data: res.data.data.map(item => ({
        //     date: `${item?.createdAt.substring(
        //       8,
        //       10
        //     )}/${item?.createdAt.substring(5, 7)} ${item?.createdAt.substring(
        //       0,
        //       4
        //     )}`,
        //     email: item?.email,
        //     subscriptionPlan:
        //       item?.lastest_subscription?.subscriptionName || "Basic",
        //     name: item?.firstName + " " + item?.lastName,
        //     id: item?.userId
        //   })),
        //   // totalData: perPage * totalPages,
        //   totalData: res.data.data.length,
        //   isUpdatingData: false
        // });
      })
      .catch(() => {
        this.setState({
          error: { isError: true, message: "There is an error" },
          loading: false
        });
      });
  };

  componentDidMount() {
    const { page, searchInput } = this.state;
    this.getUsers(page, searchInput);
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, searchInput } = this.state;
    if (prevState.page !== page) {
      this.getUsers(page, searchInput);
    }
    if (prevState.searchInput !== searchInput) {
      this.getUsers(page, searchInput);
    }
  }

  handleSearch = ({ target }) => {
    const { value } = target;
    const { data } = this.state;

    const newData = data.filter(item => sorting.search(value, item));

    this.setState(
      {
        query: value
      },
      () => {
        this.setState({ filteredData: newData });
      }
    );
  };

  handleRowClick = (e, id) => {
    this.props.history.push(`/dashboard/users/${id}`);
  };

  render() {
    const { loading, showPanel, data, totalData, page, error } = this.state;
    // const { loading, error, showPanel, data } = this.state;
    const { history, user } = this.props;

    const columns = [
      { field: "name", headerName: "Name", width: 366 },
      { field: "email", headerName: "Email", width: 350 },
      {
        field: "subscriptionPlan",
        headerName: "Subscription Plan",
        width: 233
      },
      { field: "date", headerName: "Date", width: 104 }
    ];

    return (
      <div>
        <>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <PaperedInput
                onChange={e => this.setState({ searchInput: e.target.value })}
                placeholder="Search for User"
              />
            </Grid>
            <Grid item xs={6}>
              <Grid justify="flex-end" container>
                {user.details.userType.toLowerCase() === "superadmin" ? (
                  <Button
                    className="create-user"
                    variant="outlined"
                    color="primary"
                    onClick={this.toggleDrawer(true)}
                  >
                    <Plus className="c-user-icon" /> CREATE USER
                  </Button>
                ) : null}
                <CSVLink data={data} filename={"users.csv"}>
                  <Button variant="contained" color="primary">
                    <DownloadIcon className="c-user-icon" /> DOWNLOAD CSV
                  </Button>
                </CSVLink>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
            {error.isError ? (
              <Alert severity="error">{error.message}</Alert>
            ) : (
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={10}
                rowHeight={50}
                rowCount={totalData}
                headerHeight={60}
                disableColumnMenu={true}
                autoHeight={true}
                onRowClick={(params, e, details) =>
                  history.push(`/dashboard/users/${params.id}`)
                }
                className="user-list-table"
                page={page}
                onPageChange={page => {
                  this.setState({ page });
                }}
                paginationMode="server"
                loading={loading}
              />
            )}
          </div>

          <UserSlideIn
            open={showPanel}
            close={() => this.setState({ showPanel: false })}
            onClose={() => this.toggleDrawer(false)}
            jwt={user.jwt}
            refreshList={this.refreshList}
            profile={{
              firstName: "",
              lastName: "",
              email: "",
              mobile: "",
              dateOfBirth: new Date(),
              password: ""
            }}
            formTitle="Add Profile"
            isPassword
            type="add"
          />
        </>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user
});

export default connect(mapStateToProps)(withRouter(UserList));
