export default [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name"
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created On"
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: false,
    label: "Edit",
    notSortable: true
  },
  {
    id: "delete",
    numeric: false,
    disablePadding: false,
    label: "Delete",
    notSortable: true
  }
];
