import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: "rgba(255,255,255,0.03)"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,

    "&::placeholder": {
      color: "rgba(255,255,255,0.6)"
    }
  },
  iconButton: {
    padding: 10,
    color: "rgba(255,255,255,0.3)"
  },
  hideIcon: {
    display: "none"
  },
  inputbase: {
    "&::placeholder": {
      color: "rgba(255,255,255,0.6)"
    }
  }
}));

export default function PaperedInput(inputProps) {
  const classes = useStyles();
  const {
    inputRef = () => {},
    ref,
    showIcon,
    placeholder,
    ...other
  } = inputProps;

  return (
    <Paper elevation={0} className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        // inputProps={{
        //     inputRef: node => {
        //         ref(node);
        //         inputRef(node);
        //     },
        //     className: classes.inputbase,
        //     "aria-label": "Search for movie"
        // }}
        {...other}
      />
      {showIcon && (
        <IconButton
          className={showIcon ? classes.iconButton : classes.hideIcon}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      )}
    </Paper>
  );
}

PaperedInput.defaultProps = {
  showIcon: true,
  placeholder: "Search for movie"
};
