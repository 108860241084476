import { combineReducers } from "redux";
import db from "./db";
import dbEdit from "./dbEdit";

const combinedReducer = combineReducers({
  db,
  dbEdit
});

export default combinedReducer;
