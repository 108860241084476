import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from "@material-ui/core";
import { useStyles } from "./styles.js";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const ScheduleNotifications = ({
  open,
  handleClose,
  scheduleDate,
  setScheduleDate,
  handleScheduleNotification,
  loading,
  setLoading
}) => {
  const handleChange = newValue => {
    setScheduleDate(newValue);
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="max-width-dialog-title">
          <span className={classes.scheduleTitle}>Schedule Notification</span>
          <span className={classes.scheduleTimeStandard}>
            West african Standard Time
          </span>
        </DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Date "
              name="date"
              format="MM/dd/yyyy"
              id="date-picker"
              className={classes.date}
              value={scheduleDate}
              onChange={handleChange}
            />
            <TimePicker
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Time"
              name="time"
              id="time-picker"
              className={classes.date}
              value={scheduleDate}
              onChange={handleChange}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={handleClose}
              className={`${classes.btn} ${classes.cancelBtn}`}
              color="primary"
              variant="outlined"
            >
              CANCEL
            </Button>
            <Button
              disabled={loading}
              onClick={() => handleScheduleNotification(true, setLoading)}
              className={`${classes.btn} ${classes.cancelBtn}`}
              color="primary"
              variant="contained"
            >
              {loading ? <CircularProgress size={20} /> : "SCHEDULE NOW"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ScheduleNotifications;
