import React from "react";

const Subscription = props => (
  <svg
    {...props}
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.834 20.0013H16.2507V5.91797H10.834V20.0013ZM4.33398 20.0013H9.75065V5.91797H4.33398V20.0013ZM17.334 5.91797V20.0013H22.7507V5.91797H17.334Z"
      fill="white"
    />
  </svg>
);

export default Subscription;
