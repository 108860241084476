import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles( theme => ({
    root: {
        width: "100%",
        paddingTop: 40,
        justifyContent: "center"
    }
}))

const useLineStyles = makeStyles( theme => ({
    root: {
        width: "100%",
        top: "100px",
        position: "fixed"
    }
}))

const LineLoad = ({ show }) => {
    const classes = useLineStyles()
    return (
        <div style={{ display: show ? "block" : "none"}}>
            <LinearProgress className={classes.root} />
        </div>
    )
}

export const CircularLoad = ({ show }) => {
    const classes = useStyles()
    return (
        <div 
        style={{ display: show ? "flex" : "none"}}
        className={classes.root}
        >
            <CircularProgress />
        </div>
    )
}

export default LineLoad
