import types from "../../types";

// stores selected filter data in movies list page
const { STORE_CURRENT_MOVIES_FILTER } = types;

const initialState = {
  cinema: {
    stateID: null,
    cinemaID: null,
    startDate: null,
    endDate: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_CURRENT_MOVIES_FILTER:
      return {
        ...state,
        cinema: {
          ...state.cinema,
          stateID: action.payload.stateID,
          cinemaID: action.payload.cinemaID,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate
        }
      };
    default:
      return state;
  }
};
