import React from "react";
import EnhancedTable from "../../components/TableList";
import userListParser from "../../components/functions/parseList";
import headCells from "./tableHead";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Grid, Button } from "@material-ui/core";

const CategoryList = ({ data, showDeleteDialog, deleteTier, openSlideIn }) => {
  let safeIds;
  try {
    safeIds = userListParser.mockIDs(data, "id");
  } catch (error) {
    safeIds = null;
  }
  data &&
    data.map((item, id) => {
      const rolesList =
        item.roles &&
        item.roles.reduce((acc, item) => {
          acc += item.name + ", ";
          return acc.trim().slice(0, -1);
        }, "");
      item.rolesList = rolesList;
      return item;
    });
  return (
    <div style={{ marginTop: "20px" }}>
      <Grid container spacing={3}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <CSVLink data={data} filename={"categories.csv"}>
            <Button variant="contained" color="primary">
              <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
            </Button>
          </CSVLink>
        </Grid>
      </Grid>
      <EnhancedTable
        keys={["name", "createdAt", "delete", "edit"]}
        ids={safeIds}
        rows={data}
        editable={true}
        headCells={headCells}
        editClick={openSlideIn}
        timeKey="createdAt"
        deleteClick={showDeleteDialog}
      />
    </div>
  );
};

export default CategoryList;
