import types from "../types";
import api from "../../api/merch-api";

const { GET_MERCHANDISE, GET_SINGLE_MERCHANDISE } = types;

export const getAllMerch = merchandise => ({
  type: GET_MERCHANDISE,
  merchandise
});

export const getSingleMerchAction = detail => ({
  type: GET_SINGLE_MERCHANDISE,
  detail
});

export const getAllMerchandise = token => dispatch => {
  return api.getAllMerchandise(token).then(res => {
    dispatch(getAllMerch(res.data));
    return res;
  });
};

export const getSingleMerch = (id, token) => dispatch => {
  return api.getSingleMerch(id, token).then(res => {
    dispatch(getSingleMerchAction(res.data));
    return res;
  });
};
