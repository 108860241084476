import { makeStyles } from "@material-ui/styles";


const useStyles = makeStyles( theme => ({
    head: {
        display: "flex",
        justifyContent: "space-between"
    },
    dropDowns: {
        display: "flex"
    }
}))

export const itemStyles = makeStyles( theme => ({
    container: {
        paddingTop: 21
    }
}))

export default useStyles