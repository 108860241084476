import React, { useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useStyles } from "../styles";
import empty from "../../../../assets/images/empty-concessions.png";
import "../bookings.css";
import { useState } from "react";
import BookingDetails from "../BookingDetails";
import ConfirmDialog from "../ConfirmDialog";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { BOOKING_STATUS } from "../data";
import cubeApi from "../../../../api/cube";
import types from "../../../../redux/types";
import { applyEllipsis } from "../../../../utils/functions";
import Clip from "../../../../vectors/Clip";

const RescheduledBookings = ({
  onEditClick,
  selectedCubeId,
  loading,
  data,
  loadBooking,
  onCancelClick,
  onRedeemClick
}) => {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [booking, setBooking] = useState({});
  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const { jwt } = useSelector(state => state.user.user);

  const columns = [
    {
      field: "booking_id",
      headerName: "ID",
      width: 300,
      renderCell: params => (
        <div className={classes.copy}>
          <p>{applyEllipsis(params.row.booking_id, 25)} </p>
          <button
            onClick={() => {
              navigator.clipboard.writeText(params.row.booking_id).then(
                function() {
                  dispatch({
                    type: ALERT_OPEN,
                    payload: {
                      severity: "success",
                      message: "Copied!"
                    }
                  });
                },
                function(err) {
                  console.error("Async: Could not copy text: ", err);
                }
              );
            }}
            className={classes.copyButton}
          >
            <Clip />
          </button>
        </div>
      )
    },
    {
      field: "full_name",
      headerName: "Name",
      width: 200
    },
    {
      field: "phone_number",
      headerName: "Phone number",
      width: 150
    },
    {
      field: "email",
      headerName: "Email",
      width: 150
    },
    {
      field: "movie_title",
      headerName: "Movie",
      width: 280,
      renderCell: params => {
        return (
          <div className={classes.movieCol}>
            {/* <img
              className={classes.movieImg}
              alt={params.row.movieTitle}
              src={params.row.image_url || empty}
              width={34}
              height={34}
            /> */}
            <p>{params.row.movie_title}</p>
          </div>
        );
      }
    },
    {
      field: "guest_count",
      headerName: "Guest",
      width: 120
    },
    {
      field: "payment_amount",
      headerName: "Amount",
      width: 130
    },
    {
      field: "date_time",
      headerName: "Current Date",
      width: 250
    },
    {
      field: "reschedule_date",
      headerName: "Rescheduled Date",
      width: 250
    },
    {
      field: "",
      headerName: "Status",
      width: 120,
      renderCell: params =>
        params.row.status === BOOKING_STATUS["rescheduled"][0] ? (
          <button
            onClick={() => {
              setBooking(params.row);
              setShowApproveDialog(true);
            }}
            className={classes.approve}
          >
            Approve
          </button>
        ) : (
          <p className={classes.approve}>Rescheduled</p>
        )
    },
    {
      field: "view",
      headerName: "View Details",
      width: 200,
      renderCell: params => (
        <button
          onClick={() => {
            setBooking(params.row);
            setShowDetails(true);
          }}
          className={classes.view}
        >
          View more
        </button>
      )
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 120,
      renderCell: params => (
        <button
          onClick={() => {
            onEditClick(params.row);
          }}
          className={classes.approve}
        >
          Edit
        </button>
      )
    },
    {
      field: "cancel",
      headerName: "Cancel",
      width: 120,
      renderCell: params => (
        <button
          onClick={() => {
            onCancelClick(params.row);
          }}
          className={classes.cancel}
        >
          Cancel
        </button>
      )
    },
    {
      field: "redeem",
      headerName: "Redeem",
      width: 120,
      renderCell: params => (
        <button
          onClick={() => {
            onRedeemClick(params.row);
          }}
          className={classes.approve}
        >
          Redeem
        </button>
      )
    }
  ];

  const approveReschedulling = async () => {
    const res = await cubeApi.editStatus(jwt, booking.booking_id, {
      status: BOOKING_STATUS["rescheduled"][1]
    });
    loadBooking();
    setShowApproveDialog(false);
    return res;
  };

  return (
    <div>
      <BookingDetails
        booking={booking}
        open={showDetails}
        handleClose={() => setShowDetails(false)}
        action={
          booking.status === BOOKING_STATUS["cancelled"][1] ? null : (
            <Button
              onClick={() => setShowApproveDialog(true)}
              className={`${classes.formBtn} ${classes.successBtn}`}
              variant="outlined"
              color="primary"
            >
              YES, RESCHEDULE
            </Button>
          )
        }
      />
      <ConfirmDialog
        btnText="YES, RESCHEDULE"
        btnType="success"
        handleBtnClick={approveReschedulling}
        handleClose={() => setShowApproveDialog(false)}
        message={`Are you sure you want to approve the cube booking rescheduling for ${booking.email}`}
        open={showApproveDialog}
        title="Approve Cube Booking Rescheduling"
      />
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={15}
        rowHeight={50}
        headerHeight={60}
        disableColumnMenu={true}
        autoHeight={true}
        className="bookings-table"
        loading={loading}
      />
    </div>
  );
};

export default RescheduledBookings;
