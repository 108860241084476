import moment from 'moment'

/**
 * function is used to parse user 
 * @param {Array} arrayOfObjects - Array gotten from api.
 */

export default {
    ids: arrayOfObjects => {
        let array = [];
        arrayOfObjects.forEach(item => {
            return array.push(item.id);
        });
        return array;
    },
    mockIDs: (arrayOfObjects, key) => {
        let array = [];
        arrayOfObjects.forEach(item => {
            return array.push(item[key]);
        });
        return array;
    },
    modifyTime: list => {
        let ray = []
        list.forEach(item => {
            let time = moment(item.createdAt).format("MMM Do YY")
            item.createdAt = time
            ray.push(item)
        })
        return ray
    },
    unixTime: (list, timekey) => {
        let ray = [];
        list.forEach(item => {
            let time = moment(item[timekey]).valueOf();
            item[timekey] = time;
            ray.push(item);
        });
        return ray;
    }
};