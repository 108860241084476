import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import useStyles from "./styles";
import { CircularProgress } from "@material-ui/core";

const TableCard = ({ cardName, cardValue, cardText, tableLoading }) => {
  const { container, mainStat, nameStyle, valueStyle } = useStyles();
  // const { container, mainStat, nameStyle, valueStyle, textStyle } = useStyles();
  return (
    <div className={container}>
      {/* <div className={textStyle}>{cardName}</div> */}
      <div className={mainStat}>
        <div className={nameStyle}>{cardName}</div>
        <div className={valueStyle}>
          {tableLoading ? <CircularProgress /> : cardValue}
        </div>
        {/* <div className={textStyle}>{cardText}% from last month</div> */}
      </div>
    </div>
  );
};

TableCard.propTypes = {
  cardName: PropTypes.string,
  cardValue: PropTypes.string,
  cardText: PropTypes.string
};

TableCard.defaultProps = {
  cardValue: "N/A"
};

const TableSummaryContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-rows: 1fr;
`;
const TableSummary = ({ data, tableLoading }) => {
  return (
    <TableSummaryContainer>
      {data &&
        data.map(({ cardName, cardValue, cardText }, i) => {
          return (
            <TableCard
              cardName={cardName}
              cardValue={cardValue}
              cardText={cardText}
              tableLoading={tableLoading}
              key={i}
            />
          );
        })}
    </TableSummaryContainer>
  );
};

export default TableSummary;
