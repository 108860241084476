import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import movieEntryApi from "../../api/movie-entry-api";
import { Button, Grid } from "@material-ui/core";
import "./coming-soon-list.css";
import { CircularLoad } from "../../components/Loader";
import EmptyState from "../../components/EmptyState";
import DownloadIcon from "@material-ui/icons/GetApp";
import { DataGrid } from "@material-ui/data-grid";
import useComingSoonListColumn from "./useComingSoonListColumn";

class ComingSoonListPage extends Component {
  state = {
    movies: [],
    loading: true,
    page: 0,
    totalData: 0
  };

  getComingSoonMovieList(page) {
    this.setState({ loading: true });
    movieEntryApi
      .getComingSoon(this.props.jwt, page + 1)
      .then(res => {
        const { movies = [], perPage = 0, totalPages = 0 } = res.data.data;
        this.setState({ loading: false });
        this.setState({
          movies: movies.map((item, i) => ({
            id: item?.rosetta_movie_id ?? i,
            genre: item?.genre ?? "-",
            movieTitle: item?.movie_title ?? "-",
            is_available_for_display: item?.is_available_for_display
              ? "Approved"
              : "Not Approved",
            releaseDate: item?.is_coming_soon_date
              ? `${item?.is_coming_soon_date.substring(
                  8,
                  10
                )}/${item?.is_coming_soon_date.substring(
                  5,
                  7
                )} ${item?.is_coming_soon_date.substring(0, 4)}`
              : "-"
          })),
          totalData: perPage * totalPages
        });
      })
      .catch(err => {
        this.setState({ loading: false });
        return err;
      });
  }

  componentDidMount() {
    const { page } = this.state;
    this.getComingSoonMovieList(page);
  }

  componentDidUpdate(prevProps, prevState) {
    const { page } = this.state;
    if (prevState.page !== page) {
      this.getComingSoonMovieList(page);
    }
  }

  render() {
    const { movies, loading, totalData, page } = this.state;

    const { history } = this.props;
    const columns = useComingSoonListColumn();

    return (
      <>
        <Grid container alignItems="center" justify="space-between" spacing={3}>
          <Grid item xs={7}>
            {/* <PaperedInput
              // onChange={this.handleSearchChange}
              placeholder="Search for Movies"
            /> */}
            <Grid container alignItems="center" spacing={1}></Grid>
          </Grid>
          <Grid item xs={5} style={{ textAlign: "right" }}>
            <CSVLink data={movies} filename={"rosetta-movies.csv"}>
              <Button variant="contained" color="primary">
                <DownloadIcon className="c-user-icon" /> DOWNLOAD CSV
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          {loading ? (
            <CircularLoad show />
          ) : movies && movies.length ? (
            <DataGrid
              rows={movies}
              columns={columns}
              pageSize={15}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              autoHeight={true}
              onRowClick={(params, e, details) =>
                history.push({
                  pathname: `/dashboard/movies/edit-entry/${params.id}`,
                  state: { movieName: params.row.title, movieId: null }
                })
              }
              className="coming-soon-list-table"
              loading={loading}
              page={page}
              onPageChange={page => {
                this.setState({ page });
              }}
              paginationMode="server"
              rowCount={totalData}
            />
          ) : (
            <EmptyState
              title="Empty Movie List"
              subText="Please Filter for another movie"
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  jwt: state.user.user.jwt
});

export default connect(mapStateToProps)(withRouter(ComingSoonListPage));
