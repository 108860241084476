import React, { useState, useEffect } from "react";
import validator from "validator";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import { Typography, TextField, CircularProgress } from "@material-ui/core";
import fileApi from "../../../api/file";
import lodash from "lodash";
import { useDispatch } from "react-redux";
import types from "../../../redux/types";
import { getRequestError } from "../../../utils/functions";

const NewCast = ({ jwt, cast, open, handleClose, add }) => {
  const {
    castsDialog,
    pair,
    imagePreview,
    characterField,
    submitBtn
  } = useStyles();
  const [firstName, setFirstName] = useState({ value: "", error: false });
  const [lastName, setLastName] = useState({ value: "", error: false });
  const [character, setCharacter] = useState({ value: "", error: false });
  const [file, setFile] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [URLLoading, setURLLoading] = useState(false);
  const [imageURL, setImageUrl] = useState({ value: "", error: false });
  const [isValidURL, setIsValidURL] = useState(true);
  const [loading, setLoading] = useState();

  const dispatch = useDispatch();

  const { ALERT_OPEN } = types;

  useEffect(() => {
    setIsValidURL(true);
    setFirstName({ value: "", error: false });
    setLastName({ value: "", error: false });
    setCharacter({ value: "", error: false });
    setImageUrl({ value: "", error: false });
    setFile(null);
  }, []);

  const extHandleClose = () => {
    setIsValidURL(true);
    setFirstName({ value: "", error: false });
    setLastName({ value: "", error: false });
    setCharacter({ value: "", error: false });
    setImageUrl({ value: "", error: false });
    setFile(null);
    handleClose();
  };

  const handleChange = ({ target }, inputField, validator) => {
    const inputCheck = validator
      ? validator.isEmpty(target.value)
      : () => false;
    const { value } = target;
    if (inputCheck) {
      inputField({ value, error: true });
    } else {
      inputField({ value, error: false });
    }
  };

  const handleImageFile = e => {
    e.preventDefault();
    setFileLoading(true);
    const payload = new FormData();
    payload.append("file", e.target.files[0]);
    fileApi
      .uploadImageWithFile(payload)
      .then(res => {
        setFile(res.data.data);
        setFileLoading(false);
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        setFileLoading(false);
        return err;
      });
  };

  const handleImageURL = URL => {
    if (URL !== null && typeof URL !== "object") {
      if (validator.isURL(URL) && URL.match(/.(jpg|jpeg|png|gif)$/i)) {
        setIsValidURL(true);
        setURLLoading(true);
        fileApi
          .uploadImageWithLink({ downloadLink: URL })
          .then(res => {
            setFile(res.data.data);
            setURLLoading(false);
          })
          .catch(err => {
            dispatch({
              type: ALERT_OPEN,
              payload: {
                severity: "error",
                message: getRequestError(err)
              }
            });
            setURLLoading(false);
            return err;
          });
      } else {
        setIsValidURL(false);
      }
    } else {
      setIsValidURL(false);
    }
  };

  const getExistingLastCastOrder = formerCasts => {
    formerCasts = lodash.orderBy(formerCasts, ["order"], ["asc"]);

    let maximumOrder =
      (formerCasts &&
        formerCasts[formerCasts.length - 1] &&
        formerCasts[formerCasts.length - 1]["order"] + 1) ||
      0;
    return maximumOrder;
  };

  const uploadHandler = e => {
    e.preventDefault();
    setLoading(true);
    validator.isEmpty(firstName.value) &&
      setFirstName({ ...firstName, error: true });
    validator.isEmpty(lastName.value) &&
      setLastName({ ...lastName, error: true });
    validator.isEmpty(character.value) &&
      setCharacter({ ...character, error: true });
    if (
      validator.isEmpty(firstName.value) ||
      validator.isEmpty(lastName.value) ||
      validator.isEmpty(character.value)
    ) {
      setLoading(false);
      return;
    }
    const castMember = {
      character: character.value,
      name: `${firstName.value} ${lastName.value}`,
      profileImage: file,
      order: getExistingLastCastOrder(cast)
    };
    add(castMember);
    setLoading(false);
    extHandleClose();
  };

  return (
    <Dialog
      // fullWidth="xl"
      open={open}
      onClose={extHandleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <div className={castsDialog}>
        <DialogTitle id="max-width-dialog-title">
          <Typography variant="h4" component="span">
            Add cast member
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <form
              id="form-test"
              style={{ marginTop: "20px" }}
              onSubmit={uploadHandler}
            >
              <div className={pair}>
                <TextField
                  name="firstName"
                  onChange={e => handleChange(e, setFirstName, validator)}
                  onBlur={e => handleChange(e, setFirstName, validator)}
                  value={firstName.value}
                  variant="outlined"
                  label="First Name"
                  style={{ flex: 1 }}
                  error={firstName.error}
                  helperText={firstName.error && "Firstname is empty"}
                />
                <TextField
                  name="lastName"
                  onChange={e => handleChange(e, setLastName, validator)}
                  onBlur={e => handleChange(e, setLastName, validator)}
                  value={lastName.value}
                  variant="outlined"
                  label="Last Name"
                  style={{ flex: 1 }}
                  error={lastName.error}
                  helperText={lastName.error && "Lastname is empty"}
                />
              </div>
              <div className={pair}>
                <TextField
                  name="character"
                  onChange={e => handleChange(e, setCharacter, validator)}
                  onBlur={e => handleChange(e, setCharacter, validator)}
                  value={character.value}
                  variant="outlined"
                  label="Character"
                  className={characterField}
                  error={character.error}
                  helperText={character.error && "Character is empty"}
                />
              </div>

              <div className={pair}>
                <TextField
                  name="url"
                  onChange={e => {
                    setIsValidURL(true);
                    handleChange(e, setImageUrl, validator);
                  }}
                  value={imageURL.value}
                  variant="outlined"
                  label="Image URL from the internet"
                  className={characterField}
                  error={!isValidURL || imageURL.error}
                  helperText={
                    !isValidURL &&
                    "Invalid URL (*must end with jpg|jpeg|png|gif)"
                  }
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleImageURL(imageURL.value)}
                  disabled={URLLoading}
                >
                  {URLLoading ? <CircularProgress size="10" /> : "upload"}
                </Button>
              </div>
              <div>
                <p
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    marginBottom: "15px",
                    marginTop: "-10px"
                  }}
                >
                  Or upload from computer
                </p>
                <div className="inline-uploader">
                  <span className="btn-file">
                    Choose background image
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={handleImageFile}
                      accept="image/x-png,image/gif,image/jpeg,image/jpg"
                    />
                  </span>
                  <span className="side-text">
                    {fileLoading ? "Uploading..." : file || "No file chosen"}
                  </span>
                </div>

                {file ? (
                  <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                    <p style={{ margin: "0px" }}>Uploaded Cast Image</p>
                    <TextField
                      name="url"
                      value={file}
                      variant="outlined"
                      className={characterField}
                      disabled
                    />
                  </div>
                ) : null}
                {file && (
                  <img className={imagePreview} src={file} alt="upload" />
                )}
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={castsDialog}>
            <Button
              form="form-test"
              type="submit"
              color="primary"
              variant="outlined"
              disabled={loading}
              className={submitBtn}
            >
              {loading ? <CircularProgress /> : "Submit"}
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              color="secondary"
              disabled={loading}
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default NewCast;
