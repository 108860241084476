import React, { useState, useEffect, useCallback } from "react";
import api from "../../../api/concessions-api";
import sheet from "../../../containers/AdminList/styles";
import "react-datepicker/dist/react-datepicker.css";
import SlideIn from "../../../components/Drawer";
import {
  List,
  ListItem,
  Typography,
  Button,
  TextField,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import Back from "../../../components/Button/Back";
import { getRequestError } from "../../../utils/functions";
import GoBack from "../../../components/Button/GoBack";
import { useDispatch } from "react-redux";
import types from "../../../redux/types";
import { useStyles } from "./styles";
import { CircularLoad } from "../../../components/Loader";

const ComboDealForm = ({
  onClose,
  handleChange,
  open,
  jwt,
  refreshList,
  close,
  data,
  cinemaId,
  type
}) => {
  console.log(data);
  const [rosettaConcessions, setRosettaConcessions] = useState([]);
  const [loadingRosettaConcessions, setLoadingRosettaConcessions] = useState(
    false
  );
  const [error, setError] = useState("");
  const [editedValues, setEditedValues] = useState(data);
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const { ALERT_OPEN } = types;

  const { uploadedImage } = useStyles();

  const dispatch = useDispatch();

  const { classes } = sheet;

  const resetOnClose = e => {
    onClose(e);
  };

  const fetchRosettaConcessions = useCallback(() => {
    setLoadingRosettaConcessions(true);
    api
      .fetchRosettaConcessions(`Bearer ${jwt}`, cinemaId)
      .then(res => {
        console.log(res?.data?.data);
        setRosettaConcessions(
          res.data.data?.map(({ product_name, product_id, display_price }) => ({
            product_name,
            product_id,
            product_price: display_price
          }))
        );
        // setRosettaConcessions(
        //   res.data.data?.concessions
        //     ?.find(({ categoryName }) => categoryName === "ComboDeals")
        //     ?.products?.map(
        //       ({
        //         product_name,
        //         product_price,
        //         product_id,
        //         product_category_name,
        //         image_url,
        //       }) => ({
        //         product_name,
        //         product_price,
        //         product_id,
        //         product_category_name,
        //         id: product_id,
        //         image_url,
        //       })
        //     )
        // );
        setLoadingRosettaConcessions(false);
      })
      .catch(e => {});
  }, [jwt, cinemaId]);

  useEffect(() => {
    fetchRosettaConcessions();
  }, [jwt, fetchRosettaConcessions, cinemaId]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      console.log(data);
      const editableItems = {
        item: data.item.product_id ?? null,
        price: data.price,
        items: data?.items?.map(item => item.product_id) ?? []
      };
      setEditedValues(editableItems);
    } else {
      setEditedValues({
        item: null,
        price: "",
        items: []
      });
    }
    setLoading(false);
  }, [data]);

  console.log(editedValues);

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      setError({ msg: "", isError: false });

      if (!editedValues.item) {
        setError({ msg: "Choose a title", isError: true });
        return;
      }
      if (!editedValues.items.length) {
        setError({ msg: "Choose at least one item", isError: true });
        return;
      }
      setLoading(true);

      const formData = new FormData();
      //   formData.append("rosetta_product_id", data?.id);
      //   formData.append("file", imageFile);
      if (type === "edit") {
        await api.editComboDeal(
          {
            rosetta_product_ids: editedValues.items
          },
          editedValues.item,
          `Bearer ${jwt}`
        );
      } else {
        await api.createComboDeal(
          {
            rosetta_product_id: editedValues.item,
            combo_items: editedValues.items,
            cinema_id: cinemaId
          },
          `Bearer ${jwt}`
        );
      }
      setLoading(false);
      refreshList();
      close();
    } catch (e) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(e)
        }
      });
      setLoading(false);
    }
    // let userRequest = api.editConcessionById(data.id, body, `Bearer ${jwt}`);

    // userRequest
    //   .then(res => {
    //     setLoading(false);
    //     close();
    //     refreshList();
    //   })
    //   .catch(err => {
    //     const response = err && err.response && err.response.data;
    //     let errMsg =
    //       response && response.errors[0] && response.errors[0].message
    //         ? response.errors[0].message
    //         : "Something went wrong :I";

    //     setError({
    //       errMsg
    //     });
    //     setLoading(false);
    //   });
  };

  return (
    <SlideIn open={open} onClose={resetOnClose} from="right">
      <List className={classes.mainContainer}>
        <ListItem className={classes.container}>
          <GoBack onClick={resetOnClose} type="button" />
        </ListItem>
        <form onSubmit={handleSubmit}>
          <ListItem className={classes.container}>
            <Typography variant="h3">Combo Deal</Typography>
          </ListItem>
          <ListItem className={classes.container}>
            <div className={classes.section}>
              {loadingRosettaConcessions ? (
                <CircularLoad show={true} />
              ) : (
                <>
                  <Typography variant="h4" className={classes.title}>
                    Title
                  </Typography>
                  <TextField
                    className={classes.txtField}
                    id="title"
                    select
                    variant="outlined"
                    margin="normal"
                    label="Choose Concesssion"
                    name="Select-Title"
                    value={editedValues?.item}
                    onChange={e => {
                      console.log(e.target.value);
                      setEditedValues({
                        ...editedValues,
                        item: e.target.value,
                        price:
                          rosettaConcessions.find(
                            item => item.product_id === e.target.value
                          )?.product_price ?? ""
                      });
                    }}
                    disabled={type === "edit"}
                    fullWidth
                  >
                    {rosettaConcessions.map(item => (
                      <MenuItem key={item?.product_id} value={item?.product_id}>
                        {item.product_name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography variant="h4" className={classes.title}>
                    Items
                  </Typography>
                  <Select
                    className={classes.txtField}
                    labelId="mutiple-name-label"
                    id="mutiple-name"
                    multiple
                    variant="outlined"
                    margin="normal"
                    placeholder="Choose Concesssions"
                    name="Select-Items"
                    value={editedValues.items}
                    onChange={e => {
                      setEditedValues({
                        ...editedValues,
                        items: e.target.value
                      });
                    }}
                    fullWidth
                  >
                    {rosettaConcessions.map(item => (
                      <MenuItem key={item?.product_id} value={item?.product_id}>
                        {item.product_name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              <Typography variant="h4" className={classes.title}>
                Price
              </Typography>
              <TextField
                name="lastName"
                label=""
                type="number"
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                className={classes.txtField}
                onChange={e =>
                  setEditedValues({ ...editedValues, price: e.target.value })
                }
                value={editedValues.price}
                required
                disabled={true}
              />
              {error.isError && (
                <div>
                  <div className={classes.error}>{error.msg}</div>
                </div>
              )}

              <div>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.createBtn}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </div>
            </div>
          </ListItem>
        </form>
      </List>
    </SlideIn>
  );
};

export default ComboDealForm;
