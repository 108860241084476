import axios from "axios";

export default {
  getPushNotifications: (token, page) =>
    axios
      .get(`/notification/get-push-notifications?page=${page}&perPage=10`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  scheduleNotification: (token, body) =>
    axios
      .post(`/notification/send-push-notification`, body, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
