import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Section from "../../../components/Section";
import { Grid } from "@material-ui/core";
import Point from "../../../components/Section/Point";
import api from "../../../api/users-api";
import useStyles from "./styles";
import moment from "moment";
import GoBack from "../../../components/Button/GoBack";
import { purchaseColumns } from "./guestPurchaseTable";
import { CircularLoad } from "../../../components/Loader";
import { DataGrid } from "@material-ui/data-grid";
import { useDispatch } from "react-redux";
import types from "../../../redux/types";

const GuestProfilePage = ({ user, match }) => {
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [bookingActivities, setBookingActivities] = useState([]);
  const dispatch = useDispatch();

  const { ALERT_OPEN } = types;
  //
  const userEmail = match.params.id;
  const {
    backBtn,
    sectionContainer,
    titleStyle,
    section,
    profileTitle,
    profileValue,
    profileContent,
    profileContentDivider,
    profileHeading
  } = useStyles();

  const fetchGuest = async () => {
    setLoading(true);
    api
      .getGuestUserInfo(userEmail, page + 1, `Bearer ${user.jwt}`)
      .then(res => {
        const { bookingActivities = [], totalCount = 0 } = res.data.data;
        setBookingActivities(
          bookingActivities.map((item, index) => ({
            bookingDate: `${item?.movieDate} ${item?.movieTime}`,
            bookingId: item?.bookingId,
            item: item?.movieName,
            type: item?.purchaseType,
            quantity: item?.quantity,
            selectedLocation: item?.cinemaName,
            amount: `₦${item?.totalPriceAfterDiscount?.toLocaleString()}`,
            name: item?.customer_name,
            id: index
          }))
        );
        setTotalData(totalCount);
        // setPageLoading(false);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => setPageLoading(false));
  };

  useEffect(() => {
    fetchGuest();
  }, [userEmail, user.jwt, page]);

  return pageLoading ? (
    <div>
      <CircularLoad show={true} />
    </div>
  ) : (
    <>
      <div className={backBtn}>
        <GoBack link="/dashboard/users/guests" />
      </div>
      <div className={sectionContainer}>
        <Section>
          <div className={section}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Grid container justify="center" direction="column">
                  <h2 className={profileHeading}>Profile</h2>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={profileContent}
              spacing={10}
              container
              alignItems="center"
            >
              <Grid xs={6} item>
                <h3 className={profileTitle}>Email</h3>
                <p className={profileValue}>{userEmail ?? "Nil"}</p>
              </Grid>
              <div className={profileContentDivider} />
            </Grid>
          </div>
        </Section>
      </div>
      <div className={sectionContainer}>
        <Section title="Purchase History" titleStyle={titleStyle}>
          <div className={section}>
            <Point
              title="Purchases"
              contain
              component={
                <div style={{ width: "100%", marginTop: "20px" }}>
                  {bookingActivities && bookingActivities.length > 0 ? (
                    <DataGrid
                      rows={bookingActivities}
                      loading={loading || pageLoading}
                      rowCount={totalData}
                      columns={purchaseColumns}
                      pageSize={15}
                      rowHeight={50}
                      headerHeight={60}
                      disableColumnMenu={true}
                      autoHeight={true}
                      pagination="server"
                      page={page}
                      onPageChange={page => {
                        setPage(page);
                      }}
                    />
                  ) : (
                    <p>No bookings made yet</p>
                  )}
                </div>
              }
            />
          </div>
        </Section>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user.user
});

export default connect(mapStateToProps)(withRouter(GuestProfilePage));
