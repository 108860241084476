import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "10px 60px",
        fontSize: 21,
        backgroundColor: "rgba(162,51,226,1)",
        color: "#000"
    }
}));

export default useStyles