import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  head: {
    display: "flex",
    justifyContent: "flex-end"
  },
  dropDowns: {
    display: "flex"
  }
}));

export const itemStyles = makeStyles(theme => ({
  container: {
    paddingTop: 21
  }
}));

export default useStyles;
