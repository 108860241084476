import axios from "axios";
import urls from "./urls";

const { movieDB } = urls;

export default {
  getCategoryList: async token => {
    const categories = axios.get(`/categories`, {
      headers: { Authorization: token }
    });
    const data = Promise.all([categories]);

    return data;
  },
  getCategory: async (id, token) => {
    const categories = axios.get(`/categories/${id}`, {
      headers: { Authorization: token }
    });
    const data = Promise.all([categories]);

    return data;
  },
  createCategory: async (token, payload) => {
    const res = await axios.post(`/categories`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  patchCategory: async (token, payload, id) => {
    const res = await axios.patch(`/categories/${id}`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  deleteCategory: async (id, token) => {
    const res = await axios.delete(`/categories/${id}`, {
      headers: { Authorization: token }
    });
    return res;
  }
};
