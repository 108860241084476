import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import movieEntryApi from "../../api/movie-entry-api";
import cinemasApi from "../../api/cinemas";
import { Button, Grid } from "@material-ui/core";
import "./movie-list.css";
import { CircularLoad } from "../../components/Loader";
import EmptyState from "../../components/EmptyState";
import DownloadIcon from "@material-ui/icons/GetApp";
import { DataGrid } from "@material-ui/data-grid";
import useMovieListColumns from "./useMovieListColumns";
import DropDown from "../../components/Inputs/Dropdown";
import moment from "moment";
import DateSelector from "../../components/Inputs/DateSelector";
import types from "../../redux/types";

class MovieList extends Component {
  state = {
    movies: [],
    loading: true,
    cinemasLoading: true,
    selectedState: {},
    selectedCinema: {},
    states: [],
    startDate: this.props.cinema.startDate || moment().format("YYYY-MM-DD"),
    endDate: this.props.cinema.endDate || moment().format("YYYY-MM-DD")
  };

  getMovieList(cinema, startDate, endDate) {
    this.setState({ loading: true });
    if (cinema?.id && startDate && endDate) {
      movieEntryApi
        .getMovies(cinema.id, startDate, endDate, this.props.jwt)
        .then(res => {
          this.setState({ loading: false });
          this.setState({
            movies: res?.data?.data.map(
              (item, i) =>
                ({
                  id: item?.movie_id ?? i,
                  genre: item?.genre ?? "-",
                  likes: item?.reviewDetails?.metadata?.numberOfLikes ?? 0,
                  dislikes:
                    item?.reviewDetails?.metadata?.numberOfDislikes ?? 0,
                  reviews:
                    (item?.reviewDetails?.metadata?.numberOfDislikes ?? 0) +
                    (item?.reviewDetails?.metadata?.numberOfLikes ?? 0),
                  rosettaTitle: item?.movie_name ?? "-",
                  movieTitle: item?.movie_db_details?.db_movie_title ?? "-",
                  has_movie_db_details:
                    item?.movie_db_details?.has_movie_db_details,
                  is_available_for_display: item?.movie_db_details
                    ?.is_available_for_display
                    ? "Approved"
                    : "Not Approved"
                } ?? [])
            )
          });
        })
        .catch(err => {
          this.setState({ loading: false });
          return err;
        });
    } else {
      this.setState({
        loading: false
      });
    }
  }

  getCinemas() {
    const { jwt, cinema } = this.props;
    this.setState({
      cinemasLoading: true
    });
    cinemasApi.fetchCinemas(jwt).then(res => {
      console.log(cinema);
      const defaultState =
        res.data.data.find(c => c.id === cinema.stateID) ||
        res.data.data.find(c => c.name.includes("Lagos"));
      const defaultCinema = defaultState
        ? defaultState?.cinemas.find(c => c.id === cinema.cinemaID) ||
          defaultState?.cinemas.find(c => c.cinema_name.includes("Lekki"))
        : null;
      this.setState({
        states: res.data.data,
        selectedState: defaultState || res.data.data[0],
        selectedCinema: defaultCinema || res.data.data[0].cinemas[0],
        cinemasLoading: false
      });
    });
  }

  componentDidMount(prevProps, prevState) {
    this.getCinemas();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCinema, startDate, endDate } = this.state;
    if (
      prevState.selectedCinema !== selectedCinema ||
      prevState.startDate !== startDate ||
      prevState.endDate !== endDate
    ) {
      this.getMovieList(selectedCinema, startDate, endDate);
    }
  }

  render() {
    const {
      movies,
      cinemasLoading,
      selectedCinema,
      selectedState,
      states,
      loading,
      startDate,
      endDate
    } = this.state;

    const { history, dispatch } = this.props;
    const { STORE_CURRENT_MOVIES_FILTER } = types;
    const columns = useMovieListColumns();

    // let days = new Array(7).fill(0).map((_, index) => {
    //   const generatedDay = moment().add(index, "d");
    //   const today = generatedDay.day() === moment().day();

    //   return {
    //     value: generatedDay.format("YYYY-MM-DD"),
    //     name: today
    //       ? "Today"
    //       : `${generatedDay.format("MMM")}, ${generatedDay.format(
    //           "ddd"
    //         )} ${generatedDay.format("DD")}`
    //   };
    // });

    return cinemasLoading ? (
      <CircularLoad show />
    ) : (
      <>
        <Grid container alignItems="center" justify="space-between" spacing={3}>
          <Grid item xs={7}>
            {/* <PaperedInput
              // onChange={this.handleSearchChange}
              placeholder="Search for Movies"
            /> */}
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={3}>
                <DropDown
                  menuItems={states.map(item => ({
                    value: item,
                    name: item?.name
                  }))}
                  value={selectedState}
                  onChange={e => {
                    dispatch({
                      type: STORE_CURRENT_MOVIES_FILTER,
                      payload: {
                        stateID: e.target.value.id,
                        cinemaID: e.target.value.cinemas[0].id
                      }
                    });
                    this.setState({
                      selectedState: e.target.value,
                      selectedCinema: e.target.value.cinemas[0],
                      startDate,
                      endDate
                    });
                  }}
                  name={"name"}
                />
              </Grid>
              <Grid item xs={3}>
                <DropDown
                  menuItems={
                    selectedState.cinemas
                      ? selectedState.cinemas.map(item => ({
                          value: item,
                          name: item?.cinema_name
                        }))
                      : []
                  }
                  value={selectedCinema}
                  onChange={e => {
                    dispatch({
                      type: STORE_CURRENT_MOVIES_FILTER,
                      payload: {
                        stateID: selectedState.id,
                        cinemaID: e.target.value.id,
                        startDate,
                        endDate
                      }
                    });
                    this.setState({ selectedCinema: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <DateSelector
                  value={startDate}
                  onChange={e => {
                    dispatch({
                      type: STORE_CURRENT_MOVIES_FILTER,
                      payload: {
                        startDate: moment(e).format("YYYY-MM-DD"),
                        endDate,
                        stateID: selectedState.id,
                        cinemaID: selectedCinema.id
                      }
                    });
                    this.setState({
                      startDate: moment(e).format("YYYY-MM-DD")
                    });
                  }}
                />
                {/* <DropDown
                  menuItems={days}
                  value={date ?? days[0].value}
                  onChange={e => this.setState({date: e.target.value})}
                /> */}
              </Grid>
              <Grid item xs={3}>
                <DateSelector
                  value={endDate}
                  onChange={e => {
                    dispatch({
                      type: STORE_CURRENT_MOVIES_FILTER,
                      payload: {
                        endDate: moment(e).format("YYYY-MM-DD"),
                        stateID: selectedState.id,
                        cinemaID: selectedCinema.id,
                        startDate
                      }
                    });
                    this.setState({ endDate: moment(e).format("YYYY-MM-DD") });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} style={{ textAlign: "right" }}>
            <CSVLink data={movies} filename={"rosetta-movies.csv"}>
              <Button variant="contained" color="primary">
                <DownloadIcon className="c-user-icon" /> DOWNLOAD CSV
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          {loading ? (
            <CircularLoad show />
          ) : movies && movies.length ? (
            <DataGrid
              rows={movies}
              columns={columns}
              pageSize={15}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              autoHeight={true}
              onRowClick={(params, e, details) =>
                history.push({
                  pathname: `/dashboard/movies/edit-entry/${params.id}`,
                  state: { movieName: params.row.title, movieId: null }
                })
              }
              className="movie-list-table"
              loading={loading}
            />
          ) : (
            <EmptyState
              title="Empty Movie List"
              subText="Please Filter for another movie"
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  jwt: state.user.user.jwt,
  cinema: state.movies.cinema
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MovieList));
