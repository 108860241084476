import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const PasswordShowHide = props => {
  const [showPass, setShowPass] = useState(false);

  return (
    <TextField
      {...props}
      type={showPass ? "text" : "password"}
      inputProps={{ type: showPass ? "text" : "password" }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPass(!showPass)}>
              {showPass ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default PasswordShowHide;
