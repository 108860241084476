import React from "react";
import GuestUserList from "../../../containers/GuestUserList";

const GuestUserListPage = () => {
  return (
    <div>
      <GuestUserList />
    </div>
  );
};

export default GuestUserListPage;
