import React, { useState, useEffect } from "react";
import api from "../../../api/bulk-vouchers-api";
import EnhancedTable from "../../../components/TableList";
import parseUserList from "../../../components/functions/parseList";
import useStyles from "./styles";
import headCells from "./headCells";
import { Button, Snackbar, Grid } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import AddBulkVoucher from "../../../containers/BulkVouchers/AddBulkVoucher/index";
import { CircularLoad } from "../../../components/Loader";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";

const BulkVoucherList = ({ jwt }) => {
  const classes = useStyles();
  const [openAdd, setOpenAdd] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [failureMsg, setFailureMsg] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [ids, setIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleOpenClose = (setter, value) => {
    return setter(value);
  };

  useEffect(() => {
    fetchAllBulkVouchers();
  }, []);

  const fetchAllBulkVouchers = () => {
    api
      .getList(`Bearer ${jwt}`)
      .then(res => {
        const data = res.data.data.map((item, i) => {
          const generatedByName = item.generatedBy && item.generatedBy.name;
          return { generatedByName, ...item };
        });
        setFiltered(data);
        setIds(parseUserList.ids(data));
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        return err;
      });
  };

  const handleAdd = payload => {
    return api
      .addBulkVouchers(`Bearer ${jwt}`, payload)
      .then(res => {
        setOpenAdd(false);
        setOpenFailure(false);
        fetchAllBulkVouchers();
      })
      .catch(err => {
        setOpenFailure(true);
        if (!err.response) {
          setFailureMsg("Network Error, please try again");
        } else {
          setFailureMsg(
            err.response
              ? err.response.message
              : "An error occured, please try again"
          );
        }
        return err;
      });
  };

  const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  return (
    <div>
      <div className={classes.head}>
        <div
          className={classes.addTierBtn}
          style={{ width: "300px" }}
          onClick={() => setOpenAdd(true)}
        >
          <Button color="primary">+ CREATE VOUCHERS </Button>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={6}></Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <CSVLink data={filtered} filename={"bulk-vouchers.csv"}>
              <Button variant="contained" color="primary">
                <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
      </div>
      <AddBulkVoucher
        jwt={jwt}
        open={openAdd}
        add={handleAdd}
        handleClose={() => handleOpenClose(setOpenAdd, false)}
      />
      <Snackbar
        open={openFailure}
        autoHideDuration={6000}
        onClose={() => setOpenFailure(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Alert onClose={() => setOpenFailure(false)} severity="error">
          {failureMsg}
        </Alert>
      </Snackbar>
      {loading ? (
        <div>
          <CircularLoad show={true} />
        </div>
      ) : (
        <EnhancedTable
          rowClickable={false}
          ids={ids}
          editable={true}
          ellipsis={[false, false, false, false, false, true, false]}
          keys={[
            "companyName",
            "quantity",
            "createdAt",
            "generatedByName",
            "approvedBy",
            "comment",
            "downloadCSV"
          ]}
          rows={filtered}
          headCells={headCells}
          timeKey="createdAt"
          downloadClick={null}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  jwt: state.user.user.jwt
});
export default connect(mapStateToProps)(BulkVoucherList);
