import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.background.default
    }
  },
  container: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  logo: {
    alignItems: "center",
    marginBottom: theme.spacing(10)
  },
  logoImage: {
    width: 150
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

export default useStyles;
