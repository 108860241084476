import React from "react";

const EmptyState = ({ title, subText }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "400px"
    }}
  >
    <div style={{ textAlign: "center" }}>
      <h1 style={{ margin: 0 }}>{title}</h1>
      <p>{subText}</p>
    </div>
  </div>
);

export default EmptyState;
