import React from "react";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import PasswordShowHide from "../PasswordShowHide/PasswordShowHide";

const ConfirmPassword = props => {
  const classes = useStyles();
  const {
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleBlur
  } = props;

  return (
    <div>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <PasswordShowHide
          error={errors.pass.error}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="password"
          label="New Password"
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          autoFocus
          helperText={errors.pass.error && errors.pass.msg}
          inputProps={{
            autoComplete: "anyrandomstring"
          }}
        />
        <PasswordShowHide
          error={errors.cpass.error}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.cpass.error && errors.cpass.msg}
          id="off"
          autoComplete="anyrandomstring"
          inputProps={{ type: "password" }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting}
        >
          confirm password
        </Button>
      </form>
    </div>
  );
};

export default ConfirmPassword;
