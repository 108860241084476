export default [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Item"
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type"
  },
  {
    id: "purchaseDate",
    numeric: false,
    disablePadding: false,
    label: "Date"
  },
  {
    id: "totalPrice",
    numeric: false,
    disablePadding: false,
    label: "Order Amount"
  }
];
