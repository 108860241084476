import React from "react";
import PurchasedTickets from "./PurchasedTickets";

export default [
  {
    path: "/dashboard/purchased-tickets",
    exact: true,
    component: () => <PurchasedTickets />
  }
];
