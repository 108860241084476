import React from "react";
import SubInputContainer from "../SubInputContainer";
import MultiSelect from "../../../components/Inputs/DropdownSelect";
import defaultGenres from "./defaultGenres";

const Genres = ({ onTagChange, selected, genres }) => {
  let genreList = genres || defaultGenres.map(genre => ({ label: genre }));
  const parsedSelected = selected => {
    return selected === undefined
      ? []
      : selected.map(genre => ({ label: genre, value: genre }));
  };
  const tags = parsedSelected(selected);

  return (
    <SubInputContainer title="Genre">
      <MultiSelect
        fullList={genreList}
        from="Genres"
        selected={tags}
        placeholder="Select Genre"
        onTagChange={onTagChange}
        tagName="genres"
      />
    </SubInputContainer>
  );
};

export default Genres;
