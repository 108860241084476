import React from "react";
import { makeStyles } from "@material-ui/core";
import PaperedInput from "../../components/Inputs/PaperedInput";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  search: {
    width: 300
  }
}));

const TableSearch = ({ onChange }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div> </div>
      <div className={classes.search}>
        <PaperedInput
          onChange={onChange}
          placeholder="Search for merchandise"
        />
      </div>
    </div>
  );
};

export default TableSearch;
