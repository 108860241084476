import React from "react";
import BulkVoucher from "./BulkVoucherList";
import BulkVoucherItem from "./BulkVoucherItem";

export default [
  {
    path: "/dashboard/bulk-vouchers",
    exact: true,
    component: () => <BulkVoucher />
  },
  {
    path: "/dashboard/bulk-vouchers/:id",
    exact: true,
    component: () => <BulkVoucherItem />
  }
];
