import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles(theme => ({
  dropzone: {
    border: "6px dashed #24242C",
    borderRadius: "17px",
    background: "rgba(10,10,13,0.98)",
    padding: "30px",
    height: "190px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    fontSize: "16px",
    color: "#fff"
  },
  pane: {
    textAlign: "center",
    width: "100%"
  },
  browse: {
    height: "40px",
    background: "#1E1E26",
    fontSize: "14px",
    borderRadius: "24px",
    color: "#fff",
    marginTop: "5px",
    padding: "7px 30px"
  }
}));

const ImageUploader = ({ uploadImage, field, isUploading }) => {
  const { dropzone, browse, pane } = useStyle();

  return (
    <Dropzone
      multiple={false}
      accept="image/jpeg,image/jpg,image/tiff,image/gif"
      onDrop={acceptedFile => uploadImage(acceptedFile && acceptedFile[0])}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()} className={dropzone}>
            <input {...getInputProps()} />
            <div className="row">
              <div className={pane}>
                {isUploading ? (
                  "Uploading ..."
                ) : (
                  <>
                    <p>Drag and drop an image in the box or click to browse</p>
                    <button className={browse}>Browse</button>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default ImageUploader;
