import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import api from "../../../api/bulk-vouchers-api";
import { Typography } from "@material-ui/core";
import { itemStyles } from "./styles";
import parseConcessions from "../../../components/functions/parseConcessions";
import EnhancedTable from "../../../components/TableList";
import parseList from "../../../components/functions/parseList";
import GoBack from "../../../components/Button/GoBack";

const headCells = [
  {
    id: "location",
    numeric: false,
    disablePadding: true,
    label: "Location"
  },
  {
    id: "Price",
    numeric: false,
    disablePadding: false,
    label: "Price"
  }
];

const BulkVoucherItem = ({ match }) => {
  const { container } = itemStyles();
  const [title, setTitle] = useState("no title");
  const [hpok, setHpok] = useState();
  const [cinemas, setCinemas] = useState([]);
  const id = match.params.id;

  useEffect(() => {
    api
      .getCinemas()
      .then(res => {
        setCinemas(res.data.data);
        api.getItemDetail(id).then(res => {
          const { name, hpok } = res.data;
          setTitle(name);
          setHpok(hpok);
        });
      })
      .catch(err => err);
  }, [id]);
  const localPrice = parseConcessions(cinemas, hpok);

  return (
    <div className={container}>
      <GoBack link="/dashboard/fandb" />
      <Typography variant="h2">{title}</Typography>
      <div>
        <EnhancedTable
          keys={["location", "price"]}
          rows={localPrice}
          headCells={headCells}
          ids={parseList.ids(localPrice)}
          editable={true}
        />
      </div>
    </div>
  );
};

export default withRouter(BulkVoucherItem);
