import validator from "validator";
/**
 * Takes in the state of the movie form and checks if the required fields in the status section of the state are empty.
 *
 * @param {object} form form section of the movie entry state
 * @param {object} status status section of the movie entry state
 */

export default ({ form, status }) => {
  let count = 0;
  let missing = new Set();
  status.required.forEach(field => {
    if (
      typeof form[field].value !== "string" &&
      typeof form[field].value !== "number"
    ) {
      const movieGenres = form[field].value;
      let typeAltCount = 0;
      for (var genre in movieGenres) {
        if (movieGenres[genre]) typeAltCount++;
      }
      if (!typeAltCount) {
        missing.add(field);
        count++;
      }
    } else if (typeof form[field].value === "number") {
      const isNumber = validator.isNumeric(form[field].value.toString());
      const errored = form[field].error.isError;
      if (!isNumber || errored) {
        missing.add(field);
        count++;
      }
    } else {
      const empty = validator.isEmpty(form[field].value);
      const errored = form[field].error.isError;
      if (empty || errored) {
        missing.add(field);
        count++;
      }
    }
  });
  return {
    status: {
      ...status,
      isSubmittable: !count,
      missing: [...missing]
    }
  };
};
