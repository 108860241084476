import validator from 'validator'
export default {
    email: {
        test: email => validator.isEmail(email),
        error: "email is invalid"
    },
    password: {
        test: pass => !validator.isEmpty(pass),
        error: "password is required"
    },
    numeric: input => {
        if (!validator.isNumeric(input)){
                return { isError: true, message: "field is empty"}
            }
            return { isError: false, message: "" };
    },
    overview: {
        test: desc => {
            if (validator.isEmpty(desc)){
                return { isError: true, message: "field is empty"}
            }
            return { isError: false, message: "" };
        }
    },
    radios: value => {
        if ( value === '' || undefined ){
            return { isError: true, message: "there is an error" };
        } else {
            return { isError: false, message: "" };
        }
    }
}