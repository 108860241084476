import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Grid, Button, makeStyles } from "@material-ui/core";
import api from "../../../api/referral-links-api";
import Copy from "../../../vectors/Copy";
import {
  applyEllipsis,
  dateFromISO,
  debounce,
  getRequestError
} from "../../../utils/functions";
import types from "../../../redux/types";
import { Alert } from "@material-ui/lab";
import PaperedInput from "../../../components/Inputs/PaperedInput";
import AddReferralLink from "./AddReferralLink";
import DeleteReferralLink from "./DeleteReferralLink";

const useStyles = makeStyles(theme => ({
  copy: {
    width: "15px",
    height: "15px",
    cursor: "pointer"
  },
  button: {
    outline: "none",
    background: "none",
    border: "none",
    width: "16px",
    height: "16px"
  }
}));

const ReferralLinksList = () => {
  const [searchInput, setSearchInput] = useState("");
  const [openAddReferralModal, setOpenAddReferralModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [castMembers, setCastMembers] = useState([]);
  const [error, setError] = useState({ isError: false, message: "" });
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const { copy, button } = useStyles();
  const { jwt } = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;
  const [selectedReferral, setSelectedReferral] = useState({});
  const [selectedReferralId, setSelectedReferralId] = useState({});
  const [openDelete, setOpenDelete] = useState(false);

  const fetchCastMembers = (page, searchInput) => {
    setLoading(true);
    setError({ isError: false, message: "" });
    api
      .getCastMembers(`Bearer ${jwt}`, page + 1, searchInput)
      .then(res => {
        const {
          data: castMembersData = [],
          pagination: { totalCount = 0 }
        } = res.data;
        setCastMembers(
          castMembersData.map(item => {
            return {
              name: item?.name ?? "_",
              movieTitle: item?.movie?.movie_title ?? "_",
              movieId: item?.movie?.movie_id ?? "_",
              id: item?.id ?? "",
              longReferralLink: item?.long_referral_link ?? "",
              shortReferralLink: item?.short_referral_link ?? "",
              clicks: item?.clicks ?? "",
              ticketsPurchased: item?.ticketsPurchased ?? "",
              revenueGenerated: item?.revenueGenerated
                ? `₦${item?.revenueGenerated}`
                : "",
              referralCode: item?.referral_code ?? ""
            };
          })
        );
        setTotalData(totalCount);
        setLoading(false);
      })
      .catch(e => setError({ isError: false, message: getRequestError(e) }));
  };

  useEffect(() => {
    fetchCastMembers(page, searchInput);
  }, [page, jwt, searchInput]);

  const columns = [
    { headerName: "Referral link name", field: "name", width: 180 },
    { headerName: "Movie", field: "movieTitle", width: 200 },
    { headerName: "Referral link", field: "longReferralLink", width: 250 },
    {
      headerName: "Ticket buys",
      field: "ticketsPurchased",
      width: 130
    },
    {
      headerName: "Revenue",
      field: "revenueGenerated",
      width: 120
    },
    {
      headerName: "",
      field: "Copy link",
      width: 130,
      renderCell: params => (
        <button
          onClick={() => {
            navigator.clipboard.writeText(params.row.shortReferralLink).then(
              function() {
                dispatch({
                  type: ALERT_OPEN,
                  payload: {
                    severity: "success",
                    message: "Copied!"
                  }
                });
              },
              function(err) {
                console.error("Async: Could not copy text: ", err);
              }
            );
          }}
          className={button}
        >
          <Copy className={copy} />
        </button>
      )
    },
    {
      field: "",
      headerName: "",
      width: 110,
      renderCell: params => {
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={e => handleOpenDeleteModal(e, params.row.id)}
          >
            Delete
          </Button>
        );
      }
    }
  ];

  const handleOpenClose = (setter, value) => {
    return setter(value);
  };

  const handleAdd = async payload => {
    try {
      const res = await api.addCastMember(`Bearer ${jwt}`, payload);
      setOpenAddReferralModal(false);
      fetchCastMembers(0, "");
      return res;
    } catch (err) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(err)
        }
      });
      return err;
    }
  };

  const handleEdit = async payload => {
    // return api
    //   .editCastMember(`Bearer ${jwt}`, payload, selectedReferral.data.id)
    //   .then(res => {
    //     setOpenAddReferralModal(false);
    //     fetchCastMembers(0, "");
    //   })
    //   .catch(err => {
    //     dispatch({
    //       type: ALERT_OPEN,
    //       payload: {
    //         severity: "error",
    //         message: getRequestError(err)
    //       }
    //     });
    //     return err;
    //   });
  };

  const handleDelete = id => {
    return api
      .removeCastMember(`Bearer ${jwt}`, id)
      .then(res => {
        setOpenDelete(false);
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "success",
            message: "Deleted successfully"
          }
        });
        fetchCastMembers(page, "");
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  const handleOpenDeleteModal = (e, id) => {
    e.preventDefault();
    setSelectedReferralId(id);
    setOpenDelete(true);
  };

  return (
    <div>
      <>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <PaperedInput
              onChange={e => {
                e.persist();
                debounce(() => setSearchInput(e.target.value), 2000);
              }}
              placeholder="Search"
            />
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right", alignSelf: "center" }}>
            <Button
              onClick={() => {
                setSelectedReferral({ data: null, type: "add" });
                setOpenAddReferralModal(true);
              }}
              variant="contained"
              color="primary"
            >
              NEW REFERRAL LINK
            </Button>
          </Grid>
        </Grid>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          {error.isError ? (
            <Alert severity="error">{error.message}</Alert>
          ) : (
            <DataGrid
              rows={castMembers}
              columns={columns}
              pageSize={10}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              autoHeight={true}
              page={page}
              rowCount={totalData}
              onPageChange={page => {
                setPage(page);
              }}
              paginationMode="server"
              loading={loading}
              // onRowClick={(params, e, details) => {
              //   setSelectedReferral({ data: params.row, type: "edit" });
              //   setOpenAddReferralModal(true);
              // }}
            />
          )}
        </div>
        <AddReferralLink
          jwt={jwt}
          open={openAddReferralModal}
          handleClose={() => handleOpenClose(setOpenAddReferralModal, false)}
          add={handleAdd}
          edit={handleEdit}
          type={selectedReferral.type}
          data={selectedReferral.data}
        />

        <DeleteReferralLink
          jwt={jwt}
          recordId={selectedReferralId}
          open={openDelete}
          handleClose={() => handleOpenClose(setOpenDelete, false)}
          handleDelete={handleDelete}
        />
      </>
    </div>
  );
};

export default ReferralLinksList;
