import React from "react";
import TierListPage from './TiersList'

export default [
    {
        path: "/dashboard/tiers",
        exact: true,
        component: () => <TierListPage />
    },
];
