import jss from "jss";

const sheet = jss
  .createStyleSheet({
    tableHead: {
      display: "flex",
      width: "100%",
      "justify-content": "space-between"
    },
    mainContainer: {
      "padding-top": "32px"
    },
    title: {
      color: "rgba(151,151,151,0.67)",
      "margin-bottom": "0px",
      "margin-top": "20px",
      "font-size": "30px"
    },
    subTier: {
      margin: "auto 0"
    },
    inputTitle: {
      color: "rgba(151,151,151,0.95)",
      "margin-top": "20px"
    },
    container: {
      "padding-left": "60px"
    },
    containerLast: {
      "padding-left": "60px",
      height: "400px"
    },
    section: {
      padding: "20px 0",
      width: "100%"
    },
    form: {
      overflow: "scroll"
    },
    txtField: {
      width: "80%",
      "min-width": "247px"
    },
    addTier: {
      display: "flex",
      "justify-content": "space-between",
      "margin-bottom": "20px"
    },
    addTierBtn: {
      color: "rgba(162,51,226,1)",
      cursor: "pointer"
    },
    createBtn: {
      "margin-top": "30px",
      padding: "10px 30px",
      color: "rgba(0,0,0,1)"
    },
    circularProgressBox: {
      width: "100%",
      display: "flex",
      "justify-content": "center",
      "margin-top": "50%"
    },
    error: {
      color: "#f55",
      "font-style": "italic"
    },
    dropDown: {
      width: "80%",
      "min-width": "247px",
      "margin-top": "16px",
      "margin-bottom": "8px"
    }
  })
  .attach();

export default sheet;
