import axios from "axios";

export default {
  uploadImageWithLink: (body, token) =>
    axios
      .post(`/file/link-upload`, body, {
        headers: { Authorization: token }
      })
      .then(res => res),
  uploadImageWithFile: (body, token) =>
    axios
      .post(`/file/upload`, body, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
