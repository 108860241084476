import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  title: {
    display: "flex",
    gap: "30px",
    alignItems: "center"
  },
  editButton: {
    cursor: "pointer"
  },
  image: {
    objectFit: "cover"
  },
  uploadedImage: {
    objectFit: "cover",
    width: "100px",
    height: "100px",
    marginTop: "10px",
    backgroundColor: "#242426"
  },
  tooltip: {
    padding: "18px 13px",
    // maxWidth: "222px",
    "box-sizing": "border-box",
    border: "0.85px solid rgba(151,151,151,0.25)",
    transform: "rotate(180deg)",
    "border-radius": "4.58px",
    "background-color": "#16161A",
    color: "rgba(255,255,255,0.6)",
    fontWeight: 500,
    fontSize: "11px",
    letterSpacing: "0.08px",
    lineHeight: "20px"
  },
  ellipsisContainer: {
    position: "relative",
    width: "100%"
  },
  ellipsis: {
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90%",
    display: "block"
  }
}));
