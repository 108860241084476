import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

export default function RadioInput({ options, val, handleChange, name }) {
    
    return (
        <FormControl component="fieldset">
            <RadioGroup
                aria-label="position"
                name={name}
                value={val}
                onChange={handleChange}
                row
            >
                { 
                    options.map((option, i) => {
                        return (
                            <FormControlLabel
                                value={option.value}
                                control={<Radio color="primary" />}
                                label={option.label}
                                labelPlacement="end"
                                key={i}
                            />
                        );
                    })
                }
            </RadioGroup>
        </FormControl>
    );
}

RadioInput.defaultProps = {
    options: [{label: "x"}, {label: "y"}]
}