import axios from "axios";

export default {
  getSalesBreakdown: (token, startDate, endDate) =>
    axios
      .get(
        `/purchase_history/sales-and-revenue-breakdown?end_date=${endDate}&start_date=${startDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getChurnRate: (token, startDate, endDate) =>
    axios
      .get(
        `/admin/analytics/churn-rate?end_date=${endDate}&start_date=${startDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getRedemptionRate: (token, startDate, endDate) =>
    axios
      .get(
        `/purchase_history/analytics/redemption-rate?end_date=${endDate}&start_date=${startDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getUsers: (token, startDate, endDate) =>
    axios
      .get(
        `/admin/analytics/users?end_date=${endDate}&start_date=${startDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  // not in use
  getNoOfTicketsSold: (token, year) =>
    axios
      .get(`/purchase_history/number-of-sold-tickets?year=${year}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getTicketsSoldCount: (token, month, year) =>
    axios
      .get(`/purchase_history/ticket-count?year=${year}&monthIndex=${month}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getTicketsRevenue: (token, month, year) =>
    axios
      .get(
        `/purchase_history/ticket-revenue?year=${year}&monthIndex=${month}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getRevenue: (token, year) =>
    axios
      .get(`/admin/monthly-recurring-revenue?year=${year}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getUsersStats: (token, year) =>
    axios
      .get(`/admin/new-signups-analytics?year=${year}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getRedemptionRateTwo: (token, year) =>
    axios
      .get(`/subscriptions/redemption-rate?date=${year}-01-01`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getChurnMRR: (token, year) =>
    axios
      .get(`/admin/cancelled-recurring-revenue?year=${year}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getConcessionDiscount: (token, year) =>
    axios
      .get(`/purchase_history/subscribed-concession-purchase?year=${year}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getStats: token =>
    axios
      .get("/admin/dashboard", {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getOneTimePurchase: token =>
    axios
      .get("/purchase_history/one-time-purchase", {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getUsersVisit: (token, month, year) =>
    axios
      .get(`/admin/get-users-visit?date=${year}-${month}-01`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getSubscriptionConversionRate: (token, startDate, endDate) =>
    axios
      .get(
        `/subscriptions/conversion-rate?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getAverageNoOfRedeemedTickets: (token, date, weekly) =>
    axios
      .get(
        `/purchase_history/average-ticket-redemption?date=${date}&duration=${weekly}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getCustomerChurnRate: (token, year) =>
    axios
      .get(`/admin/customer-churn-rate?year=${year}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res)
};
