import React from "react";
import ThumbsDown from "../../vectors/ThumbsDown";
import ThumbsUp from "../../vectors/ThumbsUp";

const useMovieListColumns = () => {
  const movieListColumns = [
    { field: "id", headerName: "ID", width: 80 },
    { field: "movieTitle", headerName: "Database Title", width: 200 },
    { field: "rosettaTitle", headerName: "Rosetta Title", width: 200 },
    { field: "genre", headerName: "Genre", width: 120 },
    {
      field: "has_movie_db_details",
      headerName: "Database",
      width: 120,
      renderCell: params => {
        return params.row.has_movie_db_details ? <ThumbsUp /> : <ThumbsDown />;
      }
    },
    { field: "likes", headerName: "Likes", width: 100 },
    { field: "dislikes", headerName: "Dislikes", width: 130 },
    { field: "reviews", headerName: "Reviews", width: 120 },
    {
      field: "is_available_for_display",
      headerName: "Status",
      width: 110,
      renderCell: params => {
        return params.row.is_available_for_display === "Approved" ? (
          <p className="approved">Approved</p>
        ) : (
          <p className="not-approved">Not approved</p>
        );
      }
    }
  ];

  return movieListColumns;
};

export default useMovieListColumns;
