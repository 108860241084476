import React, { useEffect, useState } from "react";
import SlideIn from "../../../components/Drawer";
import Comment from "../../../vectors/Comment";
import Like from "../../../vectors/Like";
import useStyles from "./styles";
import logo from "../../../assets/images/profile.jpeg";
import Back from "../../../components/Button/Back";
import api from "../../../api/movie-entry-api";
import { useParams } from "react-router-dom";

const Reviews = ({ open, onClose, jwt }) => {
  const params = useParams();
  const [reviewsData, setReviewsData] = useState(null);
  const [page, setPage] = useState(1);
  console.log(params);
  const {
    mainContainer,
    header,
    reviewContainer,
    reviewTitle,
    reviewStats,
    reviewStat,
    reviewList,
    reviewItem,
    reviewProfile
  } = useStyles();

  useEffect(() => {
    api.getMovieReviews(params.id, 50, page, `Bearer ${jwt}`).then(res => {
      console.log(res.data.data);
      const reviewsData = res.data.data;
      setReviewsData({
        likes: reviewsData?.metadata?.numberOfLikes,
        dislikes: reviewsData?.metadata?.numberOfDislikes,
        // reviews: reviewsData.medataData.numberOfReviews,
        reviews: reviewsData?.reviews
      });
    });
  }, []);

  return (
    <SlideIn open={open} onClose={onClose} from="right" minWidth={690}>
      <div className={mainContainer}>
        <div className={header}>
          <Back onClick={onClose} />
          <p className={reviewStat}>Reviews</p>
        </div>
        <div className={reviewContainer}>
          <h2 className={reviewTitle}>DC League of Super-Pets</h2>
          <div className={reviewStats}>
            <p className={reviewStat}>
              {reviewsData?.likes ?? 0} Likes
              <Like />
            </p>
            <p className={reviewStat}>
              {reviewsData?.dislikes ?? 0} Dislikes
              <Like
                style={{
                  transform: "rotate(180deg)"
                }}
              />
            </p>
            <p className={reviewStat}>
              {(reviewsData?.likes ?? 0) + (reviewsData?.dislikes ?? 0)} Reviews
              <Comment />
            </p>
          </div>
          <ul className={reviewList}>
            {reviewsData?.reviews
              ?.filter(({ review }) => review)
              ?.map((review, index) => (
                <li key={index} className={reviewItem}>
                  <img src={logo} alt="profile" className={reviewProfile} />
                  <p>{review?.review ?? ""}</p>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </SlideIn>
  );
};

export default Reviews;
