import urls from "./urls";
import axios from "axios";
import lodash from "lodash";

const { movieDB, genreCategoryId, tagCategoryId } = urls;

export default {
  getMovies: (cinemaId, startDate, endDate, token) =>
    axios
      .get(
        `/movie/admin/list?cinema_id=${cinemaId}&start_date=${startDate}&end_date=${endDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  getComingSoon: (token, page) =>
    axios
      .get(`/movie/admin/coming-soon-movies?page=${page}&perPage=10`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  searchMovieDB: search =>
    axios.get(`/moviedb/search?query=${search}`).then(res => res),
  getSingleMovieDB: id => axios.get(`/moviedb/movie/${id}`).then(res => res),
  createFHMovie: (token, body) =>
    axios.post(`/movie/create`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  searchMovieFHDB: (search, token) =>
    axios.get(`/movie/search?title=${search}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getSingleMovieFHDB: (id, token) =>
    axios
      .get(`/movie/fetch-movie/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  getMovieReviews: (id, pageSize, pageNo, token) =>
    axios
      .get(`/movie-ratings/${id}?pageSize=${pageSize}&pageNo=${pageNo}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res),
  setMovieVisibility: (token, id) =>
    axios.post(`/movie/visiblity/${id}`, {
      headers: { Authorization: token }
    }),
  pullVistaMoviesToDB: () =>
    axios.get("/vista/get-movies-from-vista").then(res => res),
  search: query =>
    axios.get(`${movieDB}/moviedb/search?query=${query}`).then(res => res),
  searchExistingCast: (token, name) =>
    axios
      .get(`/film-casts?$q=${name}&$searchFields=name`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  postMovieDetails: (movie, token) =>
    axios
      .post(`${movieDB}/films`, movie, {
        headers: { Authorization: token }
      })
      .then(res => res),
  getMovieDetails: id =>
    axios
      .get(`${movieDB}/moviedb/${id}?$include=images,casts`)
      .then(res => res),
  getStoredMovieDetails: id =>
    axios
      .get(
        `${movieDB}/films/${id}?$include=film_casts,film_images,film_categories.category,film_public_contents,film_vod_contents`
      )
      .then(res => res),
  deletMovie: (id, token) =>
    axios
      .delete(`/films/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  patchMovie: (id, payload, token) =>
    axios
      .patch(`/films/${id}?$include=film_casts`, payload, {
        headers: { Authorization: token }
      })
      .then(res => res),
  postCastIcon: imgURL =>
    axios
      .post(`${movieDB}/aws/s3/uploadWithUrl`, {
        url: imgURL,
        type: "movieImage"
      })
      .then(res => res),
  postCastIconFile: payload =>
    axios
      .post(`${movieDB}/aws/s3/uploadFileImage`, payload, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(res => res),
  formatMovie: async movie => {
    const { backdropImage, posterImage, casts, images, genres, tags } = movie;
    if (backdropImage) {
      const response = await axios.post(`${movieDB}/aws/s3/uploadWithUrl`, {
        url: backdropImage,
        type: "movieImage"
      });
      movie.backdropImage = response.data.url;
    }
    if (posterImage) {
      const response = await axios.post(`${movieDB}/aws/s3/uploadWithUrl`, {
        url: posterImage,
        type: "movieImage"
      });
      movie.posterImage = response.data.url;
    }

    if (casts) {
      await Promise.all(
        casts.map(async cast => {
          const { gender, profileImage } = cast;
          if (!gender) {
            delete cast.gender;
          }
          if (profileImage) {
            const response = await axios.post(
              `${movieDB}/aws/s3/uploadWithUrl`,
              {
                url: profileImage,
                type: "movieImage"
              }
            );
            cast["profileImage"] = response.data.url;
          }
        })
      );
      movie.film_casts = {
        add: casts
      };
    }

    if (images) {
      await Promise.all(
        images.map(async image => {
          if (image.url) {
            const response = await axios.post(
              `${movieDB}/aws/s3/uploadWithUrl`,
              {
                url: image.url,
                type: "movieImage"
              }
            );
            image.url = response.data.url;
          }
        })
      );

      movie.film_images = {
        add: images
      };
    }

    movie.film_categories = {
      add: []
    };

    if (genres) {
      genres.forEach(genre => {
        movie.film_categories.add.push({
          categoryId: genreCategoryId,
          field: genre
        });
      });
    }

    if (tags) {
      tags.forEach(tag => {
        movie.film_categories.add.push({
          categoryId: tagCategoryId,
          field: tag
        });
      });
    }
    return movie;
  },
  formatMoviePatch: async movie => {
    const { casts, film_casts, images, backdropImage, posterImage } = movie;

    if (backdropImage) {
      const response = await axios.post(`${movieDB}/aws/s3/uploadWithUrl`, {
        url: backdropImage,
        type: "movieImage"
      });
      movie.backdropImage = response.data.url;
    }
    if (posterImage) {
      const response = await axios.post(`${movieDB}/aws/s3/uploadWithUrl`, {
        url: posterImage,
        type: "movieImage"
      });
      movie.posterImage = response.data.url;
    }

    let formerCasts = film_casts;
    formerCasts = lodash.orderBy(formerCasts, ["order"], ["asc"]);

    let maximumOrder =
      (formerCasts &&
        formerCasts[formerCasts.length - 1] &&
        formerCasts[formerCasts.length - 1]["order"] + 1) ||
      0;
    if (casts) {
      await Promise.all(
        casts.map(async (cast, i) => {
          const { gender, profileImage } = cast;
          if (!gender) {
            delete cast.gender;
          }
          cast.order = maximumOrder + i;
          if (profileImage) {
            const response = await axios.post(
              `${movieDB}/aws/s3/uploadWithUrl`,
              {
                url: profileImage,
                type: "movieImage"
              }
            );
            cast["profileImage"] = response.data.url;
          }
        })
      );
      movie.film_casts = {
        add: casts,
        remove: formerCasts.map((cast, i) => {
          return cast.id;
        })
      };
    }

    if (images) {
      await Promise.all(
        images.map(async image => {
          if (image.url) {
            const response = await axios.post(
              `${movieDB}/aws/s3/uploadWithUrl`,
              {
                url: image.url,
                type: "movieImage"
              }
            );
            image.url = response.data.url;
          }
        })
      );

      movie.film_images = {
        add: images
      };
    }
    return movie;
  },
  formatMovieDBImage: async image => {
    if (image) {
      const response = await axios.post(`${movieDB}/file/link-upload`, {
        downloadLink: `https://image.tmdb.org/t/p/original${image}`
      });
      return response.data.data;
    }
  },
  getGenres: () => axios.get(`${movieDB}/genres`).then(res => res),
  getCensorRatings: () =>
    axios.get(`${movieDB}/censor-ratings`).then(res => res),
  getCategories: token =>
    axios
      .get(`${movieDB}/film-categories`, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
