import { combineReducers } from "redux";
import user from "./user";
import movieEntry from "./movieEntry";
import general from "./general";
import merch from "./merch";
import alert from "./alert";
import movies from "./movies";

const combinedReducer = combineReducers({
  general,
  movieEntry,
  user,
  merch,
  alert,
  movies
});

export default combinedReducer;
