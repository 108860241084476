import React from "react";
import { Typography, Box } from "@material-ui/core";
import useStyles from "./styles";

const SubInputContainer = ({ title, media, children }) => {
    const classes = useStyles()
    return (
        <div className={classes.root} >
            <Typography variant={ media ? "h3" : "h5"} component="h5" className={ media ? classes.mediaTitle : classes.title } >{title}</Typography>
            <Box className={classes.content}>{children}</Box>
        </div>
    );
};

export default SubInputContainer;
