import React from "react";
import ReferralLinksList from "./ReferralLinksList";

export default [
  {
    path: "/dashboard/referral-links",
    exact: true,
    component: () => <ReferralLinksList />
  }
];
