import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import { useStyles } from "./styles";
import moment from "moment";

const BookingDetails = ({ open, handleClose, booking, action }) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="max-width-dialog-title">
          <div className={classes.detailsHeader}>
            <span className={classes.detailsHeading}>Cube Booking Details</span>
            {action}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.detailsContentContainer}>
            <span className={classes.detailsMovieTitle}>MOVIE INFO</span>
            <div className={classes.detailsMovieContainer}>
              <img
                className={classes.detailsMovieImg}
                src="https://filmhouse.s3.us-west-2.amazonaws.com/EAAQQzZz.jpg"
                alt=""
              />
              <div>
                <span className={classes.detailsMovieTitle}>
                  {booking.movie_title}
                </span>
                <div className={classes.detailsMovieMetaContainer}>
                  <span className={classes.detailsMovieMeta}>
                    Location: {booking.state}
                  </span>
                  <span className={classes.detailsMovieMeta}>
                    Cinema: {booking.cinema_name}
                  </span>
                  <span className={classes.detailsMovieMeta}>
                    Date: {moment(booking.ISODate).format("ddd, D MMM")} | Time:{" "}
                    {moment(booking.ISODate).format("h:mm A")}
                  </span>
                  <span className={classes.detailsMovieMeta}>
                    {booking.guest_count} Guests
                  </span>
                </div>
              </div>
            </div>
            <div className={classes.detailsDivider} />
            <div className={classes.detailsUserRow}>
              <div className={classes.detailsUserColumn}>
                <span className={classes.detailsUserTitle}>Full Name</span>
                <span className={classes.detailsUserValue}>
                  {booking.full_name}
                </span>
              </div>
              <div className={classes.detailsUserColumn}>
                <span className={classes.detailsUserTitle}>Email</span>
                <span className={classes.detailsUserValue}>
                  {booking.email}
                </span>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              className={classes.detailsClose}
              onClick={handleClose}
              color="primary"
              variant="contained"
            >
              CLOSE
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BookingDetails;
