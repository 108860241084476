import React from "react";
import SubInputContainer from "../SubInputContainer";
import { TextField } from "@material-ui/core";
import useStyles from "./styles";
import { RestoreTwoTone } from "@material-ui/icons";
import validator from "../../../components/functions/validator";

const Description = ({ value, onChange, reset, missing }) => {
  const { isError, message } = value.error;
  const classes = useStyles();
  return (
    <SubInputContainer title="Description">
      <TextField
        id="outlined-multiline-flexible"
        label="Description Editor"
        name="overview"
        multiline
        rowsMax="4"
        value={value.value}
        onChange={e => onChange(e, validator.overview.test)}
        onBlur={e => onChange(e, validator.overview.test)}
        className={classes.textField}
        margin="normal"
        variant="outlined"
        error={isError || missing.includes("overview")}
        helperText={
          (isError || missing.includes("overview")) &&
          (message || "Description is empty")
        }
        inputProps={{
          className: classes.innerInput
        }}
      />
      <div className={classes.reset}>
        <RestoreTwoTone className={classes.icon} onClick={reset} />
      </div>
    </SubInputContainer>
  );
};

Description.defaultProps = {
  description:
    "Brixton Lorr is a cybernetically enhanced soldier who possesses superhuman strength, a brilliant mind and a lethal pathogen that could wipe out half of the world's population. It's now up to hulking lawman Luke Hobbs and lawless operative Deckard Shaw to put aside their past differences and work together to prevent the seemingly indestructible Lorr from destroying humanity."
};

export default Description;
