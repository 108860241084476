import React from 'react'
import { Button } from '@material-ui/core'
import useStyles from './styles'


const Btn = ({ onClick , children, ...props}) => {
    const classes = useStyles()
    return (
        <Button className={classes.root} onClick={onClick} {...props}>
            {children}
        </Button>
    )
}

export default Btn
