import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import { Typography } from "@material-ui/core";

const DeleteDialog = ({ open, handleClose, handleDelete, loading, title }) => {
  const { deleteDialog } = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <div className={deleteDialog}>
        <DialogTitle id="max-width-dialog-title">
          <Typography variant="h4">{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form
              id="form-test"
              style={{ marginTop: "20px" }}
              onSubmit={e => {
                e.preventDefault();
                handleDelete();
              }}
            ></form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            form="form-test"
            type="submit"
            color="secondary"
            disabled={loading}
          >
            {loading ? "DELETING..." : "DELETE"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
