import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Section from "../../../components/Section";
import { Typography } from "@material-ui/core";
import Point from "../../../components/Section/Point";
import api from "../../../api/merch-api";
import Avatar from "../../../components/Avatar";
import useStyles from "./styles";
import moment from "moment";
import GoBack from "../../../components/Button/GoBack";
import EnhancedTable from "../../../components/TableList";
import parseUserList from "../../../components/functions/parseList";
import headCells from "./purchaseHeadCells";
import { flatMap } from "lodash";
import { CircularLoad } from "../../../components/Loader";

const ids = parseUserList.mockIDs([], "ownerEmail");

const MerchandiseDetailsPage = ({ jwt, match }) => {
  const [merch, setMerch] = useState({});
  const [pageLoading, setPageLoading] = useState(true);

  const merchID = match.params.id;
  const {
    backBtn,
    sectionContainer,
    titleStyle,
    section,
    profileHead,
    userName,
    userNameTypo,
    pair,
    avatarSection,
    changePicture
  } = useStyles();

  useEffect(() => {
    api
      .getSingleMerch(merchID, `Bearer ${jwt}`)
      .then(res => {
        setPageLoading(false);
        setMerch({ ...res.data });
      })
      .catch(err => err);
  }, [merchID, jwt]);

  const rows = flatMap(merch.purchaseHistory, n => n);
  return pageLoading ? (
    <div>
      <CircularLoad show={true} />
    </div>
  ) : (
    <>
      <div className={backBtn}>
        <GoBack link="/dashboard/users" />
      </div>
      <div className={sectionContainer}>
        <Section title="Profile" titleStyle={titleStyle}>
          <div className={section}>
            <div className={profileHead}>
              <div className={avatarSection}>
                <Avatar />
                <p className={changePicture}>Change picture</p>
              </div>
              <div className={userName}>
                <Typography variant="h2" className={userNameTypo}>
                  {merch.name}
                </Typography>
              </div>
            </div>
            <Point title="Email" value={merch.email} />
            <Point title="Phone Number" value={merch.mobile} />
            <div className={pair}>
              <Point title="Subscription Plan" value={""} />
              <Point title="Benefits used most often" value={merch.email} />
            </div>
            <Point
              title="Date Created"
              value={moment(merch.createdAt).format("DD/MM/YYYY")}
            />
            <Point title="Preferences" value={merch.preferredGenres || "N/A"} />
            <Point
              title="Default Cinema"
              value={merch.defaultCinema || "N/A"}
            />
            <Point
              title="Most visited cinema"
              value={merch.mostVisitedCinema || "N/A"}
            />
            <Point title="Benefits redeemed" value={merch.email} />
            <Point title="Status" value={merch.status} />
          </div>
        </Section>
      </div>
      <div className={sectionContainer}>
        <Section
          title="Activities in the last 6 months"
          titleStyle={titleStyle}
        >
          <div className={section}>
            <Point
              title="Purchases"
              contain
              component={
                <div>
                  <EnhancedTable
                    keys={["name", "type", "purchaseDate", "totalPrice"]}
                    ids={ids}
                    headCells={headCells}
                    rows={rows}
                    timeKey="purchaseDate"
                  />
                </div>
              }
            />
          </div>
        </Section>
      </div>
      <div className={sectionContainer}>
        <Section title="VOD" titleStyle={titleStyle}>
          <div className={section}>
            <Point title="Top watched genres" value={merch.email} />
            <Point
              title="Number of minutes watched/runtime"
              value={merch.email}
            />
            <Point
              title="Number of trailers/ previews watched"
              value={merch.trailersWatched}
            />
          </div>
        </Section>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  jwt: state.user.user.jwt
});

export default connect(mapStateToProps)(withRouter(MerchandiseDetailsPage));
