import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@material-ui/core";
import { useStyles } from "./styles";
import React, { useState } from "react";
import CircledClose from "../../../vectors/CircledClose";
import validator from "validator";

const initialState = {
  points: { value: "", error: { isError: false, message: "" } },
  reason: { value: "", error: { isError: false, message: "" } }
};

const AwardUserPoints = ({ open, handleClose }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    ...initialState
  });
  const [status, setStatus] = useState({
    loading: false,
    required: ["points", "reason"],
    missing: []
  });

  const { points, reason } = form;

  const handleChange = ({ target }, _validator) => {
    const { name, value } = target;
    const checkedValidator = _validator
      ? _validator
      : value =>
          validator.isEmpty(value)
            ? { isError: true, message: "Field is empty" }
            : { isError: false, message: "" };
    if (!validator.isEmpty(value)) {
      setStatus(prevStatus => ({
        ...prevStatus,
        missing: prevStatus.missing.filter(item => name !== item)
      }));
    }
    setForm(prevForm => ({
      ...prevForm,
      [name]: { value: value, error: checkedValidator(value) }
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    // const { points, reason } = form;

    let missing = new Set();
    status.required.forEach(item => {
      if (validator.isEmpty(form[item].value)) {
        missing.add(item);
      }
    });
    setStatus(prevStatus => ({ ...prevStatus, missing: [...missing] }));
    if ([...missing].length) return;
    setStatus(prevStatus => ({ ...prevStatus, loading: true }));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.formDialog}
      >
        <DialogTitle id="max-width-dialog-title">
          <span className={classes.dialogHeading}>
            Award Points
            <button onClick={handleClose}>
              <CircledClose />
            </button>
          </span>
        </DialogTitle>
        <DialogContent>
          <div className={classes.userDetails}>
            <div className={classes.userDetailCol}>
              <p className={classes.userDetailTitle}>Name</p>
              <p className={classes.userDetailValue}>Emmanuel Biolatiri</p>
            </div>
            <div className={classes.userDetailCol}>
              <p className={classes.userDetailTitle}>Current Tier</p>
              <p className={classes.userDetailValue}>Fan</p>
            </div>
            <div className={classes.userDetailCol}>
              <p className={classes.userDetailTitle}>Life Point</p>
              <p className={classes.userDetailValue}>52</p>
            </div>
            <div className={classes.userDetailCol}>
              <p className={classes.userDetailTitle}>Available points</p>
              <p className={classes.userDetailValue}>20</p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className={classes.inputContainer}>
              <TextField
                className={classes.fullWidthInput}
                name="points"
                label="Specify Point amount"
                type="text"
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleChange}
                value={points.value}
                error={points.error.isError}
              />
              <TextField
                className={classes.fullWidthInput}
                multiline
                rowsMax="4"
                name="reason"
                label="Add a reason/comment"
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleChange}
                value={reason.value}
                error={reason.error.isError}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                type="button"
                onClick={handleClose}
                variant="outlined"
                color="primary"
              >
                CLOSE
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={status.loading}
              >
                {status.loading ? "LOADING" : "CONFIRM"}
              </Button>
            </div>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default AwardUserPoints;
