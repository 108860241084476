import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	container: {
		marginTop: 18,
		marginBottom: 36,
		marginRight: theme.spacing(4)
	},
	title: {
		color: "rgba(255,255,255,0.87)"
	},
	value: {
		color: "#777"
	}
}));

const Point = ({ title, value, component, contain }) => {
    const classes = useStyles()
    return (
        <div className={classes.container} >
            <div>
                <Typography variant="h5" className={classes.title} >{title}</Typography>
            </div>
            <div>
                { contain ? component : <Typography className={classes.value} variant="body1">{value}</Typography> }
            </div>
        </div>
    );
}

Point.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string,
    contain: PropTypes.bool,
    component: PropTypes.func,
}
export default Point
