import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    "@global": {
        "*::-webkit-scrollbar": {
            width: "0.4em"
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)"
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey"
        }
    },
    content: {
        color: "rgba(255,255,255,0.87)",
        fontSize: 17,
        letterSpacing: 0.3,
        lineHeight: "27px"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: "rgba(255,255,255,0.87)",
        fontSize: 17,
        letterSpacing: 0.3,
        lineHeight: "27px",
        width: "100%"
    },
    innerInput: {
		"overflow-y": "scroll",
        "&::-webkit-scrollbar-thumb": {
            background: "#A233E2 !important"
        },
        "&::-webkit-scrollbar-track": {
            background: "transparent"
        }
    },
    reset: {
        marginLeft: theme.spacing(1)
    },
    icon: {
        cursor: "pointer"
    }
}));

export default useStyles