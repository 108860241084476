import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  chart: {
    flex: 1,
    // padding: "20px",
    backgroundColor: "transparent",
    height: "100%"
  },
  topSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 17px",
    marginBottom: "10px",
    flexWrap: "wrap"
  },
  chartTitle: {
    // color: "rgba(255,255,255,0.87)",
    // fontSize: "23px",
    display: "flex",
    gap: "32px",
    alignItems: "center",
    fontFamily: "'Roboto', sans-serif",
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#FFFFFF",
    "@media screen and (min-width: 1440px)": {
      fontSize: "28px"
    },
    "&.medium": {
      fontWeight: "500"
    },
    "&.regular": {
      fontWeight: "400"
    }
  },
  fullReport: {
    color: "#7E599C",
    padding: "20px 10px",
    fontSize: "14px",
    fontWeight: 600,
    textDecoration: "underline",
    marginBottom: "10px"
  },
  filterTagList: {
    width: "84px",
    height: "30px"
  },
  filterTag: {
    padding: "5px 12px",
    borderRadius: "10px",
    fontSize: "9px",
    fontWeight: 600,
    marginLeft: "10px",
    background: "#1F2327",
    cursor: "pointer"
  }
}));

export default useStyles;
