import React, { Component } from "react";
import api from "../../api/auth-api";
import ResetPass from "./ResetPassword";
// import SnackBar from "../Snackbar";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import types from "../../redux/types";
import { getRequestError } from "../../utils/functions";

class ResetPassForm extends Component {
  state = {
    form: {
      email: ""
    },
    status: {
      loading: false,
      error: null
    },
    snackbar: {
      show: false,
      message: "",
      variant: "error"
    }
  };

  handleChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        email: e.target.value
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.state;
    const { dispatch } = this.props;
    const { ALERT_OPEN } = types;

    this.setState({ status: { loading: true } });
    api
      .resetPasswordInit(form.email)
      .then(res => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "success",
            message: "Email sent!"
          }
        });
        // this.setState({
        //     status: { loading: false },
        //     snackbar: {
        //         show: true,
        //         message: "Email sent!",
        //         variant: "success"
        //     }
        //  });
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        // this.setState({
        //     status: { error: err },
        //     snackbar: {
        //         show: true,
        //         message: "Invalid email",
        //         variant: "error"
        //     }
        // });
      });
  };

  // handleSnackBarClose = e => {
  //     this.setState({
  //         snackbar: {
  //             ...this.state.snackbar,
  //             show: false,
  //             message: ""
  //         }
  //     });
  // }

  render() {
    // const { show, message, variant } = this.state.snackbar
    return (
      <Box width="100%">
        <ResetPass
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
        />
        {/* <SnackBar open={show} variant={variant} msg={message} handleClose={ this.handleSnackBarClose } /> */}
      </Box>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(null, mapDispatchToProps)(ResetPassForm);
