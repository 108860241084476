import React from 'react'
import TiersList from '../../../containers/TierList'


class TiersListPage extends React.Component{
    render(){
        return <TiersList />
    }
}


export default TiersListPage
