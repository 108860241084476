import React from "react";
import AdminListPage from "./AdminList";
// import RolesListPage from "./Roles";

export default [
  {
    path: "/dashboard/admin",
    exact: true,
    component: () => <AdminListPage />
  }
  // {
  //   path: "/dashboard/admin/roles",
  //   exact: true,
  //   component: () => <RolesListPage />
  // }
];
