import axios from "axios";

export default {
  fetchErrors: (token, page) =>
    axios
      .get(`/error-log?page=${page}&perPage=10`, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
