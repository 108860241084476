import React from 'react'
import CreatableSelect from "react-select/creatable";
import { NoSsr } from '@material-ui/core';
import { useTheme } from "@material-ui/core/styles";
import useStyles from './styles'
import Control from './Control';
import Option from './Option';
import Placeholder from './Placeholder';
import SingleValue from './SingleValue';
import ValueContainer from './ValueContainer';
import Menu from './Menu';
import NoOptionsMessage from './NoOptionMessage';
import MultiValue from './MultiValue';


const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    MultiValue,
    Placeholder,
    SingleValue,
    ValueContainer
};

const MultiSelect = ({ placeholder, selected, fullList, label, onTagChange, tagName }) =>{
    const classes = useStyles();
    const theme = useTheme();

    const suggested = Array.isArray(fullList) ? fullList.map( suggestion => ({
        value: suggestion.label,
        label: suggestion.label
    })) : null
    
    function handleChangeMulti(value) {
        onTagChange && onTagChange(tagName, value);
    }

  const selectStyles = {
      input: base => ({
          ...base,
          color: theme.palette.text.primary,
          "& input": {
              font: "inherit"
          }
      })
  };

    return (
        <div className={classes.root}>
            <NoSsr>
                <CreatableSelect
                    classes={classes}
                    styles={selectStyles}
                    inputId="react-select-multiple"
                    TextFieldProps={{
                        label: label,
                        InputLabelProps: {
                            htmlFor: "react-select-multiple",
                            shrink: true
                        }
                    }}
                    placeholder={placeholder}
                    options={suggested}
                    components={components}
                    value={selected}
                    onChange={handleChangeMulti}
                    isMulti
                />
            </NoSsr>
        </div>
    );
}

MultiSelect.defaultProps = {
    selected: []
}

export default MultiSelect