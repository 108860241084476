export const MINIMUM_GUEST_NUMBER = 8;

export const FLAT_FEE = 50;
// 250000;

export const CORKAGE_FEE = 10;
// 150000;

export const GUEST_FEE = 20;
// 25000;

export const BOOKING_STATUS = {
  paid: ["Booked"],
  cancelled: ["Pending Cancellation Approval", "Approved Cancellation"],
  rescheduled: ["Pending Reschedule Approval", "Approved Reschedule"],
  redeemed: ["Redeemed"],
  refunded: ["Refunded"]
};
