import React from "react";
import useStyles from "./styles";
import { Paper } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import "./chart.css";
import { CircularLoad } from "../Loader";

const Chart = ({
  type,
  data,
  height,
  title,
  hasFullReport,
  setYear,
  year,
  xLabel,
  yLabel,
  loading,
  leftFilterComponent,
  filterComponent,
  labelCb
}) => {
  const { chart, chartTitle, fullReport, topSection } = useStyles();

  const options = {
    legend: { display: false },
    scales: {
      xAxes: [
        {
          gridLines: { color: "#2E2E33" },
          scaleLabel: {
            display: true,
            fontSize: 14,
            labelString: xLabel
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: "#2E2E33",
            borderDash: [8, 4]
          },
          scaleLabel: {
            display: true,
            fontSize: 14,
            labelString: yLabel
          }
        }
      ]
    }
  };
  if (labelCb) {
    options["tooltips"] = {
      callbacks: {
        label: function(item, data) {
          return [
            `${data.datasets[item.datasetIndex].label}: ${
              data.datasets[item.datasetIndex].data[
                data.labels.indexOf(item.xLabel)
              ]
            }`,

            ...labelCb(
              data.labels.indexOf(item.xLabel),
              data.datasets[item.datasetIndex].label
            )
          ];
        }
      }
    };
  }

  return (
    <div style={{ height: "100%" }}>
      <Paper elevation={0} className={chart}>
        <div className={topSection}>
          <div className={chartTitle}>
            {title}
            {hasFullReport ? (
              <Link
                to={
                  type === "app"
                    ? "/dashboard/users/churn"
                    : `/dashboard/${type}-stats`
                }
                className={fullReport}
              >
                <small>Full Report</small>
              </Link>
            ) : null}
            {leftFilterComponent}
          </div>
          {/* <div className="select"> */}
          {/* {filterItems &&
            filterItems.map((item, i) => {
              return <span className={filterTag}>{item}</span>;
            })} */}
          {filterComponent}

          {/* <TextField
              id="select-change-url"
              select
              variant="outlined"
              name="dashboard-year"
              value={year}
              onChange={e => setYear(e.target.value)}
              fullWidth
            >
              {[
                "2022",
                "2021",
                "2020",
                "2019",
                "2018",
                "2017",
                "2016",
                "2015"
              ].map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField> */}
          {/* </div> */}
        </div>
        {loading ? (
          <CircularLoad show={true} />
        ) : (
          <Line data={data} height={height} options={options} />
        )}
      </Paper>
    </div>
  );
};

Chart.defautProps = {
  xLabel: "X label",
  yLabel: "Y label"
};
export default Chart;
