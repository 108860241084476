import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#1F2327",
    borderRadius: "8px",
    position: "relative",
    cursor: "pointer",
    "& .MuiInputBase-input": {
      padding: "10px 26px 10px 12px",
      minHeight: "1.1876em",
      lineHeight: "1.5",
      height: "auto",
      cursor: "pointer"
    },
    "& .MuiInput-underline:after": {
      display: "none"
    },
    "& .MuiInput-underline:before": {
      position: "absolute",
      right: "0",
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.66667 0.763672V3.43034M4.33333 0.763672V3.43034M1 6.097H13M2.33333 2.09701H11.6667C12.403 2.09701 13 2.69396 13 3.43034V12.7637C13 13.5001 12.403 14.097 11.6667 14.097H2.33333C1.59695 14.097 1 13.5001 1 12.7637V3.43034C1 2.69396 1.59695 2.09701 2.33333 2.09701Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
      backgroundRepeat: "no-repeat",
      top: "50%",
      transform: "translateY(-50%)",
      border: "none",
      marginRight: "10px",
      left: "auto",
      height: "fit-content",
      width: "18px",
      cursor: "pointer"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
      cursor: "pointer"
    }
  }
}));

export default useStyles;
