import React from "react";
import useStyles from "./styles";

const Tickbox = ({ bgColor, tickColor, text, onChange, isTicked }) => {
  const classes = useStyles({ bgColor, tickColor, isTicked });

  return (
    <div className={classes.root}>
      <div onClick={onChange} className={classes.tickOuter}>
        <div className={classes.tickInner}></div>
      </div>
      <p>{text}</p>
    </div>
  );
};

export default Tickbox;
