import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SideMenu from "../SideMenu";
import Navigation from "../Navigation";
import DashboardContent from "./DashboardContent";
import Idleness from "../Idleness";
import pathnamer from "../functions/locationParser";

class DashboardContainer extends Component {
  state = {
    showMenu: true,
    location: "Dashboard"
  };

  handleMenuToggle = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  render() {
    const { showMenu } = this.state;
    const { children, location } = this.props;

    return (
      <Idleness>
        <Navigation
          location={pathnamer(location.pathname)}
          open={showMenu}
          handleOpen={this.handleMenuToggle}
        />
        <SideMenu open={showMenu} handleMenuClose={this.handleMenuToggle} />
        <DashboardContent>{children}</DashboardContent>
      </Idleness>
    );
  }
}

export default withRouter(DashboardContainer);
