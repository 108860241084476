import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  graphAnalysis: {
    display: "flex",
    alignItems: "stretch",
    gap: "24px",
    marginBottom: "24px",
    paddingBottom: "44px",
    borderBottom: "1px solid #323236",
    "& .graph": {
      flexGrow: 1,
      width: "90%",
      "@media screen and (min-width: 1440px)": {
        width: "73%"
      },
      "& .graph-chart": {
        width: "100%",
        height: "fit-content",
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        position: "relative",
        "& .loader": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          border: "1px solid #323236",
          "& > div": {
            padding: "0"
          }
        },
        "& .label": {
          fontWeight: "500",
          fontSize: "12px",
          lineHeight: "18px",
          color: "#5D5D5D"
        },
        "& .y-label": {
          position: "absolute",
          left: "-16px",
          top: "50%",
          transform: "translateY(-50%) rotate(-90deg)"
        },
        "& .x-label": {
          position: "absolute",
          bottom: "-20px",
          left: "calc(56%)",
          transform: "translateX(-50%)"
        }
      }
    },
    "& .breakdown": {
      width: "264px",
      display: "flex",
      flexDirection: "column",
      gap: "30px",
      justifyContent: "center"
    }
  },
  graphHeader: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    margin: "16px 0"
  },
  graphReportOverview: {
    display: "flex",
    gap: "16px",
    "& .total-data": {
      fontWeight: "600",
      fontSize: "30px",
      lineHeight: "44px",
      color: "#FFFFFF",
      flexGrow: 1
    },
    "& .export-btn": {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      border: "none",
      outline: "none",
      background: "none",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "16px",
      textDecorationLine: "underline",
      color: " #A233E2"
    }
  }
}));

export default useStyles;
