export default function(e, validator) {
  return function(state) {
    const { name, value } = e.target;
    const { form } = state;
    return {
      form: {
        ...form,
        [name]: {
          value: value,
          error: validator(value)
        }
      }
    };
  };
}
