import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ShowForPermissionComponent = props => {
  const { permissions } = props.details || {};
  const couldShow = permissions
    ? permissions.includes("*") || permissions.includes(props.permission)
    : false;
  return couldShow ? (
    props.children
  ) : (
    <div>
      <p>{props.displayMessage}</p>
    </div>
  );
};

ShowForPermissionComponent.propTypes = {
  permission: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  details: state.user.user.details
});

export default connect(mapStateToProps)(ShowForPermissionComponent);
