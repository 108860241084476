import React from "react";

const Copy = props => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16602 12.5013C3.38945 12.5013 3.00116 12.5013 2.69488 12.3744C2.2865 12.2053 1.96204 11.8808 1.79288 11.4724C1.66602 11.1662 1.66602 10.7779 1.66602 10.0013V4.33464C1.66602 3.40121 1.66602 2.9345 1.84767 2.57798C2.00746 2.26438 2.26243 2.00941 2.57603 1.84962C2.93255 1.66797 3.39926 1.66797 4.33268 1.66797H9.99935C10.7759 1.66797 11.1642 1.66797 11.4705 1.79484C11.8789 1.96399 12.2033 2.28845 12.3725 2.69683C12.4993 3.00311 12.4993 3.3914 12.4993 4.16797M10.166 18.3346H15.666C16.5994 18.3346 17.0661 18.3346 17.4227 18.153C17.7363 17.9932 17.9912 17.7382 18.151 17.4246C18.3327 17.0681 18.3327 16.6014 18.3327 15.668V10.168C18.3327 9.23455 18.3327 8.76784 18.151 8.41132C17.9912 8.09771 17.7363 7.84275 17.4227 7.68296C17.0661 7.5013 16.5994 7.5013 15.666 7.5013H10.166C9.23259 7.5013 8.76588 7.5013 8.40936 7.68296C8.09576 7.84275 7.84079 8.09771 7.681 8.41132C7.49935 8.76784 7.49935 9.23455 7.49935 10.168V15.668C7.49935 16.6014 7.49935 17.0681 7.681 17.4246C7.84079 17.7382 8.09576 17.9932 8.40936 18.153C8.76588 18.3346 9.23259 18.3346 10.166 18.3346Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export default Copy;
