import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useStyles } from "../styles";
import "../bookings.css";
import { useState } from "react";
import BookingDetails from "../BookingDetails";
import { useDispatch } from "react-redux";
import { applyEllipsis } from "../../../../utils/functions";
import types from "../../../../redux/types";
import Clip from "../../../../vectors/Clip";

const PaidBookings = ({
  onEditClick,
  onCancelClick,
  onRescheduleClick,
  onRedeemClick,
  loading,
  data
}) => {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const [booking, setBooking] = useState({});

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;
  console.log(data);

  const columns = [
    {
      field: "booking_id",
      headerName: "ID",
      width: 300,
      renderCell: params => (
        <div className={classes.copy}>
          <p>{applyEllipsis(params.row.booking_id, 25)} </p>
          <button
            onClick={() => {
              navigator.clipboard.writeText(params.row.booking_id).then(
                function() {
                  dispatch({
                    type: ALERT_OPEN,
                    payload: {
                      severity: "success",
                      message: "Copied!"
                    }
                  });
                },
                function(err) {
                  console.error("Async: Could not copy text: ", err);
                }
              );
            }}
            className={classes.copyButton}
          >
            <Clip />
          </button>
        </div>
      )
    },
    {
      field: "full_name",
      headerName: "Name",
      width: 200
    },
    {
      field: "phone_number",
      headerName: "Phone number",
      width: 150
    },
    {
      field: "email",
      headerName: "Email",
      width: 150
    },
    {
      field: "movie_title",
      headerName: "Movie",
      width: 280,
      renderCell: params => {
        return (
          <div className={classes.movieCol}>
            <p>{params.row.movie_title}</p>
          </div>
        );
      }
    },
    {
      field: "payment_amount",
      headerName: "Amount",
      width: 120
    },
    {
      field: "date_time",
      headerName: "Date",
      width: 200
    },
    {
      field: "view",
      headerName: "View Details",
      width: 200,
      renderCell: params => (
        <button
          onClick={() => {
            setShowDetails(true);
            setBooking(params.row);
          }}
          className={classes.view}
        >
          View more
        </button>
      )
    },
    {
      field: "guest_count",
      headerName: "Guest",
      width: 120
    },
    {
      field: "reschedule",
      headerName: "Reschedule",
      width: 150,
      renderCell: params => (
        <button
          onClick={() => {
            onRescheduleClick(params.row);
          }}
          className={classes.approve}
        >
          Reschedule
        </button>
      )
    },
    {
      field: "redeem",
      headerName: "Redeem",
      width: 120,
      renderCell: params => (
        <button
          onClick={() => {
            onRedeemClick(params.row);
          }}
          className={classes.approve}
        >
          Redeem
        </button>
      )
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 120,
      renderCell: params => (
        <button
          onClick={() => {
            onEditClick(params.row);
          }}
          className={classes.approve}
        >
          Edit
        </button>
      )
    },
    {
      field: "cancel",
      headerName: "Cancel",
      width: 120,
      renderCell: params => (
        <button
          onClick={() => {
            onCancelClick(params.row);
          }}
          className={classes.cancel}
        >
          Cancel
        </button>
      )
    }
  ];

  return (
    <div>
      <BookingDetails
        booking={booking}
        open={showDetails}
        handleClose={() => setShowDetails(false)}
      />
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={15}
        rowHeight={50}
        headerHeight={60}
        disableColumnMenu={true}
        autoHeight={true}
        className="bookings-table"
        loading={loading}
        // onRowClick={
        //   (params, e, details) => {
        //   }
        //   //   history.push(`/dashboard/users/${params.id}`)
        // }
      />
    </div>
  );
};

export default PaidBookings;
