import React, { useState, useEffect } from "react";
import sheet from "../AdminList/styles";
import FormControl from "@material-ui/core/FormControl";
import "./edituser.css";
import SlideIn from "../../components/Drawer";
import {
  List,
  ListItem,
  Typography,
  Button,
  TextField,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import Back from "../../components/Button/Back";
import moment from "moment";
import userApis from "../../api/users-api";
import subscriptionApis from "../../api/subscriptions";
import { getRequestError } from "../../utils/functions";
import { useDispatch } from "react-redux";
import types from "../../redux/types";
import GoBack from "../../components/Button/GoBack";
import PasswordShowHide from "../../components/Forms/PasswordShowHide/PasswordShowHide";
import DateSelector from "../../components/Inputs/DateSelector";
//passwords might have been updated to: Password1!

const UserSlideIn = ({
  profile,
  onClose,
  handleChange,
  btnTitle,
  open,
  title,
  jwt,
  refreshList,
  close,
  userRequest,
  formTitle,
  isPassword = false,
  type,
  userID
}) => {
  const dispatch = useDispatch();
  const [editedValues, setEditedValues] = useState(profile);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);

  const { classes } = sheet;

  const { ALERT_OPEN } = types;

  const resetOnClose = e => {
    close();
  };

  useEffect(() => {
    if (Object.keys(profile).length > 0) {
      const editableItems = {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        mobile: profile.mobile,
        dateOfBirth: profile.dateOfBirth,
        paymentType: "Cash"
      };
      setEditedValues(editableItems);
    }
  }, [profile]);

  useEffect(() => {
    setSubscriptionsLoading(true);
    subscriptionApis.fetchSubscriptions(jwt).then(res => {
      setEditedValues(prevEditedValues => ({
        ...prevEditedValues,
        subPlan: res.data.data[0]?.product_id
      }));
      setSubscriptions(res.data.data);
      setSubscriptionsLoading(false);
    });
  }, [jwt]);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const chosenPlan = subscriptions?.find(
      item => String(item?.product_id) === String(editedValues.subPlan)
    );
    const initBody = {
      dateOfBirth: moment(editedValues.dateOfBirth).format("YYYY-MM-DD"),
      email: editedValues.email,
      first_name: editedValues.firstName,
      last_name: editedValues.lastName,
      mobile: editedValues.mobile
    };
    const editPayload = {
      ...initBody,
      subscriptionId: chosenPlan?.product_id,
      subscripitionName: chosenPlan?.product_name,
      subStartDate: editedValues.subStartDate,
      subEndDate: editedValues.subEndDate,
      rosettaCardNumber: editedValues.rosettaCardNumber,
      rosettaCardPin: editedValues.rosettaCardPin
    };
    const addPayload = {
      ...initBody,
      password: editedValues.password,
      subscriptionId: chosenPlan?.product_id,
      numberOfMonths: editedValues.numberOfMonths,
      amount: chosenPlan?.product_price,
      subscripitionName: chosenPlan?.product_name,
      paymentType: editedValues.paymentType.toUpperCase()
    };

    if (type === "add") {
      userApis
        .createUser(`Bearer ${jwt}`, addPayload)
        .then(res => {
          setLoading(false);
          close();
          refreshList();
        })
        .catch(err => {
          dispatch({
            type: ALERT_OPEN,
            payload: {
              severity: "error",
              message: getRequestError(err)
            }
          });
          setLoading(false);
        });
    } else if (type === "edit") {
      userApis
        .editUser(`Bearer ${jwt}`, editPayload, userID)
        .then(res => {
          setLoading(false);
          close();
          refreshList();
        })
        .catch(err => {
          dispatch({
            type: ALERT_OPEN,
            payload: {
              severity: "error",
              message: getRequestError(err)
            }
          });
          setLoading(false);
        });
    }
  };

  return (
    <SlideIn open={open} onClose={resetOnClose} from="right">
      <List className={classes.mainContainer}>
        <ListItem className={classes.container}>
          <GoBack onClick={resetOnClose} type="button" />
        </ListItem>
        <form onSubmit={handleSubmit}>
          <ListItem className={classes.container}>
            <Typography variant="h3">{formTitle}</Typography>
          </ListItem>
          <ListItem className={classes.container}>
            <div className={classes.section}>
              <Typography variant="h4" className={classes.title}>
                First Name
              </Typography>
              <TextField
                id="outlined-password-input"
                name="firstName"
                label="First Name"
                type="text"
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                className={classes.txtField}
                onChange={e =>
                  setEditedValues({
                    ...editedValues,
                    firstName: e.target.value
                  })
                }
                value={editedValues.firstName}
                required
              />
              <Typography variant="h4" className={classes.title}>
                Last Name
              </Typography>
              <TextField
                name="lastName"
                label="Last Name"
                type="text"
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                className={classes.txtField}
                onChange={e =>
                  setEditedValues({ ...editedValues, lastName: e.target.value })
                }
                value={editedValues.lastName}
                required
              />
              <Typography variant="h4" className={classes.title}>
                Email
              </Typography>
              <TextField
                name="email"
                label="Email"
                type="email"
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                className={classes.txtField}
                onChange={e =>
                  setEditedValues({ ...editedValues, email: e.target.value })
                }
                value={editedValues.email}
                required
              />
              <Typography variant="h4" className={classes.title}>
                Phone Number
              </Typography>
              <TextField
                name="phoneNumber"
                label="Phone Number"
                type="phoneNumber"
                margin="normal"
                variant="outlined"
                className={classes.txtField}
                onChange={e =>
                  setEditedValues({ ...editedValues, mobile: e.target.value })
                }
                value={editedValues.mobile}
                required
              />
              {isPassword ? (
                <>
                  <Typography variant="h4" className={classes.title}>
                    Password
                  </Typography>
                  <PasswordShowHide
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    margin="normal"
                    variant="outlined"
                    className={classes.txtField}
                    onChange={e =>
                      setEditedValues({
                        ...editedValues,
                        password: e.target.value
                      })
                    }
                    value={editedValues.password}
                    required
                  />
                  {/* <TextField
                  /> */}
                </>
              ) : null}
              <>
                <Typography variant="h4" className={classes.title}>
                  Date of Birth
                </Typography>
                <div style={{ marginTop: "20px" }}>
                  <TextField
                    id="date"
                    label="Date of birthday"
                    type="date"
                    value={moment(editedValues.dateOfBirth).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={e =>
                      setEditedValues({
                        ...editedValues,
                        dateOfBirth: e.target.value
                      })
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    className={classes.txtField}
                    required
                  />
                </div>
              </>
              <>
                <Typography variant="h4" className={classes.title}>
                  Subscription type
                </Typography>

                <div>
                  <FormControl>
                    {subscriptionsLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <RadioGroup
                        color="primary"
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={String(editedValues.subPlan)}
                        onChange={e => {
                          setEditedValues({
                            ...editedValues,
                            subPlan: e.target.value
                          });
                        }}
                      >
                        {subscriptions.map(item => (
                          <FormControlLabel
                            key={item?.product_id}
                            value={String(item?.product_id)}
                            control={<Radio color="primary" />}
                            label={item?.product_name}
                            color="primary"
                          />
                        ))}
                      </RadioGroup>
                    )}
                  </FormControl>
                </div>
              </>
              {type !== "edit" ? (
                <>
                  <Typography variant="h4" className={classes.title}>
                    Payment type
                  </Typography>

                  <div>
                    <FormControl>
                      <RadioGroup
                        color="primary"
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={editedValues.paymentType}
                        onChange={e => {
                          setEditedValues({
                            ...editedValues,
                            paymentType: e.target.value
                          });
                        }}
                      >
                        {["Cash", "Card"].map(item => (
                          <FormControlLabel
                            key={item}
                            value={item}
                            control={<Radio color="primary" />}
                            label={item}
                            color="primary"
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </>
              ) : null}
              {type !== "edit" ? null : (
                <>
                  <Typography variant="h4" className={classes.title}>
                    Start Date
                  </Typography>
                  <div className={classes.dateField}>
                    <DateSelector
                      value={editedValues.subStartDate}
                      onChange={e => {
                        console.log(moment(e).toISOString());
                        setEditedValues({
                          ...editedValues,
                          subStartDate: moment(e).toISOString()
                        });
                        // this.setState({ endDate: moment(e).format("YYYY-MM-DD") });
                      }}
                    />
                  </div>
                </>
              )}
              {type !== "edit" ? null : (
                <>
                  <Typography variant="h4" className={classes.title}>
                    End Date
                  </Typography>
                  <div className={classes.dateField}>
                    <DateSelector
                      value={editedValues.subEndDate}
                      onChange={e => {
                        console.log(moment(e).toISOString());
                        setEditedValues({
                          ...editedValues,
                          subEndDate: moment(e).toISOString()
                        });
                        // this.setState({ endDate: moment(e).format("YYYY-MM-DD") });
                      }}
                    />
                  </div>
                </>
              )}
              {type !== "edit" ? null : (
                <>
                  <Typography variant="h4" className={classes.title}>
                    Rosetta Card Number
                  </Typography>
                  <TextField
                    name="rosettaCardNumber"
                    label="Rosetta Card Number"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    className={classes.txtField}
                    onChange={e =>
                      setEditedValues({
                        ...editedValues,
                        rosettaCardNumber: e.target.value
                      })
                    }
                    value={editedValues.rosettaCardNumber}
                    required
                  />
                </>
              )}
              {type !== "edit" ? null : (
                <>
                  <Typography variant="h4" className={classes.title}>
                    Rosetta Pin
                  </Typography>
                  <TextField
                    name="rosettaCardPin"
                    label="Rosetta Pin"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    className={classes.txtField}
                    onChange={e =>
                      setEditedValues({
                        ...editedValues,
                        rosettaCardPin: e.target.value
                      })
                    }
                    value={editedValues.rosettaCardPin}
                    required
                  />
                </>
              )}
              {type === "edit" ? null : (
                <>
                  <Typography variant="h4" className={classes.title}>
                    Number of months
                  </Typography>
                  <TextField
                    name="numberofMonths"
                    label="Number of months"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    className={classes.txtField}
                    onChange={e =>
                      setEditedValues({
                        ...editedValues,
                        numberOfMonths: e.target.value
                      })
                    }
                    value={editedValues.numberOfMonths}
                    required
                  />
                </>
              )}
              <div style={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.createBtn}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </div>
            </div>
          </ListItem>
        </form>
      </List>
    </SlideIn>
  );
};

export default UserSlideIn;
