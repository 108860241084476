import axios from "axios";

export default {
  fetchPayments: (token, userType, page) =>
    axios
      .get(
        `/flutterwave/payment-logs?page=${page}&perPage=10&userType=${userType}`,
        {
          headers: { Authorization: token }
        }
      )
      .then(res => res),
  searchPayments: (token, searchInput) =>
    axios
      .get(`/flutterwave/search-payment-logs?searchInput=${searchInput}`, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
