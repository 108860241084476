import React from "react";
import PaymentsLogs from "./PaymentsLogs";

export default [
  {
    path: "/dashboard/payment-logs",
    exact: true,
    component: () => <PaymentsLogs />
  }
];
