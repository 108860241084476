import React from "react";
import useStyles from "./styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

const SingleFormPageTemplate = ({ caption, children }) => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Typography
          component="p"
          variant="caption"
          className={classes.captioned}
        >
          {caption}
        </Typography>
        {children}
      </div>
    </Container>
  );
};

export default SingleFormPageTemplate;
