import types from "../../types";

const { SET_LOCATION } = types;

const initialState = ""

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LOCATION:
            return action.location;
        default:
            return initialState;
    }
};
