import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paperRoot: {
    flex: 1,
    padding: "30px",
    backgroundColor: "rgba(0,0,0,0.2)"
  },
  dashboardSection: {
    margin: "40px 0"
  },
  filterCont: {
    display: "flex",
    gap: "20px",
    alignItems: "center"
  },
  ticketFilterCont: {
    display: "flex",
    gap: "12px",
    alignItems: "center"
  },
  backBtn: {
    display: "flex"
  },
  section: {
    paddingLeft: 40
  },
  sectionContainer: {
    marginBottom: 30
  },
  titleStyle: {
    paddingLeft: 40,
    paddingTop: 13,
    color: "#979797",
    opacity: 0.57,
    paddingBottom: theme.spacing(3)
  },
  profileHead: {
    display: "flex",
    alignItems: "center"
  },
  avatarSection: {
    textAlign: "center",
    marginRight: "20px"
  },
  userName: {
    textAlign: "center",
    marginTop: "-30px"
  },
  changePicture: {
    color: "#7E599C",
    fontSize: "14px",
    margin: 0,
    letterSpacing: "0.48px",
    lineHeight: "33px"
  },
  userNameTypo: {
    margin: "auto",
    marginTop: "-5px",
    color: "rgba(255,255,255,0.87)"
  },
  pair: {
    display: "flex",
    marginTop: " -18px"
  },
  row: {
    display: "flex"
  },
  listItem: {
    display: "flex",
    minHeight: "64px",
    justifyContent: "space-between"
  },
  mainListText: {
    fontSize: "16px",
    letterSpacing: "0.5px",
    lineHeight: "24px",
    color: "rgba(255,255,255,0.87)"
  },
  subListText: {
    fontSize: "14.4px",
    letterSpacing: "0.14px",
    lineHeight: "21.66px",
    textAlign: "right",
    color: "rgba(255,255,255,0.6)"
  },
  marginTopSm: {
    marginTop: "20px"
  },
  root: {
    flex: 1,
    padding: "0px",
    backgroundColor: "rgba(0,0,0,0.2)"
  },
  title: {
    color: "rgba(255,255,255,0.87)",
    padding: "20px 17px",
    marginBottom: "-10px",
    fontSize: "23px"
  },
  chart: {
    flex: 1,
    padding: "20px",
    backgroundColor: "rgba(0,0,0,0.2)"
  },
  chartTitle: {
    color: "rgba(255,255,255,0.87)",
    padding: "20px 17px",
    fontSize: "23px",
    marginBottom: "10px"
  },
  fullReport: {
    color: "#7E599C",
    padding: "20px 10px",
    fontSize: "14px",
    fontWeight: 600,
    textDecoration: "underline",
    marginBottom: "10px"
  },
  filterTagList: {
    marginBottom: "20px",
    textAlign: "right"
  },
  filterTag: {
    padding: "5px 12px",
    borderRadius: "10px",
    fontSize: "9px",
    fontWeight: 600,
    marginLeft: "10px",
    background: "#1F2327",
    cursor: "pointer"
  },
  activeTag: {
    border: "1px solid #7E599C",
    padding: "5px 12px",
    borderRadius: "10px",
    fontSize: "9px",
    fontWeight: 600,
    marginLeft: "10px",
    background: "#1F2327"
  },
  doughnutTitle: {
    color: "rgba(255,255,255,0.87)",
    // padding: "0px 23px",
    marginBottom: "-10px",
    fontSize: "22px"
  },
  bigTitle: {
    fontSize: "50px",
    color: "rgba(255,255,255,0.87)"
    // padding: "0px 23px"
  },
  chartContainer: {
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    padding: "30px"
  },
  legendStyle: {
    marginBottom: "10px",
    fontSize: "12px",
    display: "flex",
    gap: "16px",
    flexWrap: "wrap",
    alignItems: "center"
  },
  inActivelegendBullet: {
    width: "10px",
    height: "10px",
    backgroundColor: "#4D2984",
    borderRadius: "50%"
  },
  activelegendBullet: {
    width: "10px",
    height: "10px",
    backgroundColor: "#A233E2",
    borderRadius: "50%"
  }
}));

export default useStyles;
