import React from "react";
import CastIcon from "../CastIcon";
import useStyles from "./style";

const CastList = ({ cast, removeCast, edit }) => {
  const { root } = useStyles();
  const empty = "There are no cast members";
  if (!!cast && cast.length) {
    let truncatedCastList = cast.slice(0, 8);
    return (
      <div className={root}>
        {truncatedCastList.map(
          ({ id, profileImage, name, order, character }, i) => (
            <CastIcon
              key={i}
              id={id}
              value={edit ? id : order}
              imgURL={profileImage}
              name={name}
              removeCast={removeCast}
              character={character}
            />
          )
        )}
      </div>
    );
  } else {
    return (
      <div className={root}>
        <div>{empty}</div>
      </div>
    );
  }
};
export default CastList;
