import React from 'react'
import PropTypes from 'prop-types'
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";


const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name"
    },
    { id: "email", numeric: false, disablePadding: false, label: "Email Address" },
    { id: "subscription plan", numeric: false, disablePadding: false, label: "Subscription Plan" },
    { id: "date created", numeric: false, disablePadding: false, label: "Date Created" }
];

export default function EnhancedTableHead(props) {
    const {
        classes,
        order,
        orderBy,
        onRequestSort,
        headCells
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        { !headCell.notSortable && <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>}
                        { headCell.notSortable && headCell.label }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.defaultProps = {
    headCells: headCells
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};
