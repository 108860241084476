export default (genres, tags) => ({ form }, { db }) => ({
  form: {
    ...form,
    title: {
      value: db.title,
      error: { isError: false, message: "" }
    },
    overview: {
      ...form.overview,
      value: db.overview
    },
    genres: {
      value: [...genres],
      error: { isError: false, message: "" }
    },
    tags: {
      value: [...tags],
      error: { isError: false, message: "" }
    },
    type: {
      value: db.type,
      error: { isError: false, message: "" }
    },
    censorRating: {
      value: db.censorRatingId,
      error: { isError: false, message: "" }
    },
    upcoming: {
      value: db.upcoming ? "true" : "false",
      error: { isError: false, message: "" }
    },
    featured: {
      value: db.featured ? "true" : "false",
      error: { isError: false, message: "" }
    },
    tvodHdBuyPrice: {
      value: db.tvodHdBuyPrice,
      error: { isError: false, message: "" }
    },
    tvodHdRentPrice: {
      value: db.tvodHdRentPrice,
      error: { isError: false, message: "" }
    },
    tvodSdBuyPrice: {
      value: db.tvodSdBuyPrice,
      error: { isError: false, message: "" }
    },
    tvodSdRentPrice: {
      value: db.tvodSdRentPrice,
      error: { isError: false, message: "" }
    },
    posterImage: {
      value: db.posterImage,
      error: { isError: false, message: "" }
    },
    backdropImage: {
      value: db.backdropImage,
      error: { isError: false, message: "" }
    },
    casts: {
      value: db.casts || db.film_casts,
      error: { isError: false, message: "" }
    },
    releaseYear: {
      value: db.releaseYear,
      error: { isError: false, message: "" }
    },
    openingDate: {
      value: db.openingDate,
      error: { isError: false, message: "" }
    },
    trailerVideo: {
      value:
        db.film_public_contents &&
        db.film_public_contents[0] &&
        db.film_public_contents[0].brightcoveId,
      error: { isError: false, message: "" }
    },
    video: {
      value:
        db.film_vod_contents &&
        db.film_vod_contents[0] &&
        db.film_vod_contents[0].brightcoveId,
      error: { isError: false, message: "" }
    },
    featureLength: {
      value: db.featureLength,
      error: { isError: false, message: "" }
    },
    isApproved: {
      value: db.isApproved,
      error: { isError: false, message: "" }
    }
  }
});

export const populateForm = ({ db, form, genres, tags }) => {
  const newForm = {
    ...form,
    isApproved: {
      value: db.isApproved,
      error: { isError: false, message: "" }
    },
    title: {
      value: db.title,
      error: { isError: false, message: "" }
    },
    overview: {
      ...form.overview,
      value: db.overview
    },
    genres: {
      value: [...genres],
      error: { isError: false, message: "" }
    },
    tags: {
      value: [...tags],
      error: { isError: false, message: "" }
    },
    type: {
      value: db.type,
      error: { isError: false, message: "" }
    },
    censorRating: {
      value: db.censorRatingId,
      error: { isError: false, message: "" }
    },
    upcoming: {
      value: db.upcoming ? "true" : "false",
      error: { isError: false, message: "" }
    },
    featured: {
      value: db.featured ? "true" : "false",
      error: { isError: false, message: "" }
    },
    tvodHdBuyPrice: {
      value: db.tvodHdBuyPrice,
      error: { isError: false, message: "" }
    },
    tvodHdRentPrice: {
      value: db.tvodHdRentPrice,
      error: { isError: false, message: "" }
    },
    tvodSdBuyPrice: {
      value: db.tvodSdBuyPrice,
      error: { isError: false, message: "" }
    },
    tvodSdRentPrice: {
      value: db.tvodSdRentPrice,
      error: { isError: false, message: "" }
    },
    posterImage: {
      value: db.posterImage,
      error: { isError: false, message: "" }
    },
    posterImageSmall: {
      value: db.posterImageSmall,
      error: { isError: false, message: "" }
    },
    posterImageSmallNew: {
      value: db.posterImageSmallNew,
      error: { isError: false, message: "" }
    },
    backdropImage: {
      value: db.backdropImage,
      error: { isError: false, message: "" }
    },
    backdropImageSmall: {
      value: db.backdropImageSmall,
      error: { isError: false, message: "" }
    },
    backdropImageSmallNew: {
      value: db.backdropImageSmallNew,
      error: { isError: false, message: "" }
    },
    casts: {
      value: db.casts || db.film_casts,
      error: { isError: false, message: "" }
    },
    releaseYear: {
      value: db.releaseYear,
      error: { isError: false, message: "" }
    },
    openingDate: {
      value: db.openingDate,
      error: { isError: false, message: "" }
    },
    trailerVideo: {
      value:
        db.film_public_contents &&
        db.film_public_contents[0] &&
        db.film_public_contents[0].brightcoveId,
      error: { isError: false, message: "" }
    },
    video: {
      value:
        db.film_vod_contents &&
        db.film_vod_contents[0] &&
        db.film_vod_contents[0].brightcoveId,
      error: { isError: false, message: "" }
    },
    featureLength: {
      value: db.featureLength,
      error: { isError: false, message: "" }
    },
    isApproved: {
      value: db.isApproved,
      error: { isError: false, message: "" }
    }
  };

  return newForm;
};
