import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularLoad } from "../../../components/Loader";
import { DataGrid } from "@material-ui/data-grid";
import concessionsApi from "../../../api/concessions-api";
import cinemasApi from "../../../api/cinemas";
import EditConcessions from "./EditConcessions";
import { Edit } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Grid, Button, TextField, MenuItem } from "@material-ui/core";
import { useStyles } from "./styles";
import "./concessions.css";
import { useRef } from "react";
import { useCallback } from "react";
import empty from "../../../assets/images/empty-concessions.png";
import { getRequestError } from "../../../utils/functions";
import types from "../../../redux/types";
import Switch from "../../../components/Switch/Switch";

const Concessions = () => {
  const [loading, setLoading] = useState(true);
  const [cinemasLoading, setCinemasLoading] = useState(false);
  const [concessions, setConcessions] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [selectedCinema, setSelectedCinema] = useState({});
  const [states, setStates] = useState([]);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const secondRender = useRef(false);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const { jwt } = useSelector(state => state.user.user);

  const [showEdit, setShowEdit] = useState(false);

  const { title, editButton, image } = useStyles();

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowEdit(open);
  };

  useEffect(() => {
    setCinemasLoading(true);
    cinemasApi.fetchCinemas(jwt).then(res => {
      setStates(res.data.data);
      setSelectedState(res.data.data[0]);
      setSelectedCinema(res.data.data[0].cinemas[0]);
      setCinemasLoading(false);
    });
  }, [jwt]);

  const fetchConcessions = useCallback(() => {
    setLoading(true);
    concessionsApi
      .fetchConcessions(`Bearer ${jwt}`, selectedCinema.id)
      .then(res => {
        const allConcessions =
          [].concat.apply(
            [],
            res.data.data?.concessions.map(({ products }) => products)
          ) || [];
        setConcessions(
          allConcessions.map(
            ({
              product_name,
              display_price,
              product_id,
              product_category_name,
              image_url,
              isDeactivated
            }) => ({
              product_name,
              product_price: display_price,
              product_id,
              product_category_name,
              id: product_id,
              image_url,
              isActive: !isDeactivated
            })
          )
        );
        setLoading(false);
      })
      .catch(e => {});
  }, [jwt, selectedCinema.id]);

  useEffect(() => {
    if (secondRender.current) {
      fetchConcessions();
      return;
    }
    secondRender.current = true;
  }, [jwt, selectedCinema, fetchConcessions]);

  const refreshList = () => {
    fetchConcessions();
  };

  const onSelectRow = params => {
    setSelected(params.row);
    setShowEdit(true);
  };

  const handleDeactivate = id => {
    if (!isActiveLoading) {
      setIsActiveLoading(true);
      // conc(selectedCinema.id);
      concessionsApi
        .deactivate(`Bearer ${jwt}`, id, selectedCinema.id)
        .then(res => {
          setIsActiveLoading(false);
          fetchConcessions();
        })
        .catch(err => {
          dispatch({
            type: ALERT_OPEN,
            payload: {
              severity: "error",
              message: getRequestError(err)
            }
          });
          return err;
        });
    }
  };

  const handleActivate = id => {
    if (!isActiveLoading) {
      setIsActiveLoading(true);
      concessionsApi
        .activate(`Bearer ${jwt}`, id)
        .then(res => {
          setIsActiveLoading(false);
          fetchConcessions();
        })
        .catch(err => {
          dispatch({
            type: ALERT_OPEN,
            payload: {
              severity: "error",
              message: getRequestError(err)
            }
          });
          return err;
        });
    }
  };

  const columns = [
    {
      field: "product_name",
      headerName: "Title",
      width: 400,
      renderCell: params => {
        return (
          <div className={title}>
            <img
              className={image}
              alt={params.row.product_name}
              src={params.row.image_url || empty}
              width={77}
              height={77}
            />
            <p>{params.row.product_name}</p>
          </div>
        );
      }
    },
    {
      field: "product_price",
      headerName: "Price (₦)",
      width: 160
    },
    {
      field: "product_id",
      headerName: "Product ID",
      width: 160
    },
    {
      field: "product_category_name",
      headerName: "Category",
      width: 240
    },
    {
      field: "",
      headerName: "Edit",
      width: 100,
      renderCell: params => {
        return (
          <Edit className={editButton} onClick={() => onSelectRow(params)} />
        );
      }
    },
    {
      field: "isActive",
      headerName: "Activate",
      width: 110,
      renderCell: params => {
        return (
          <Switch
            defaultChecked={params.row.isActive}
            checked={params.row.isActive}
            onChange={() => {
              params.row.isActive
                ? handleDeactivate(params.row.id)
                : handleActivate(params.row.id);
            }}
            disabled={isActiveLoading}
          />
        );
      }
    }
  ];

  return (
    <div>
      <>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={6}>
            {cinemasLoading ? (
              <CircularLoad show={true} />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    id="select-change-url"
                    select
                    variant="outlined"
                    margin="normal"
                    label="Select state"
                    name="Select-State"
                    value={selectedState}
                    onChange={e => {
                      setSelectedState(e.target.value);
                      setSelectedCinema(e.target.value.cinemas[0]);
                    }}
                    fullWidth
                  >
                    {states.map(item => (
                      <MenuItem key={item?.id} value={item}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="select-change-url"
                    select
                    variant="outlined"
                    margin="normal"
                    label="Select cinema"
                    name="Select-Cinema"
                    value={selectedCinema}
                    onChange={e => setSelectedCinema(e.target.value)}
                    fullWidth
                  >
                    {selectedState.cinemas &&
                      selectedState?.cinemas.map(item => (
                        <MenuItem key={item?.id} value={item}>
                          {item?.cinema_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <CSVLink data={concessions} filename={"concessions.csv"}>
              <Button variant="contained" color="primary">
                <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          <DataGrid
            rows={concessions}
            columns={columns}
            pageSize={15}
            rowHeight={93}
            headerHeight={80}
            disableColumnMenu={true}
            autoHeight={true}
            className="concessions-table"
            loading={loading}
          />
        </div>
      </>
      <EditConcessions
        open={showEdit}
        close={() => setShowEdit(false)}
        onClose={toggleDrawer(false)}
        jwt={jwt}
        data={selected}
        refreshList={refreshList}
      />
    </div>
  );
};

export default Concessions;
