import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import useStyles from "./styles";
import SignInForm from "../../components/Forms/SignInForm";
import logo from "../../assets/fh-logo.svg";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Copyright © Filmhouse {new Date().getFullYear()}.
    </Typography>
  );
}

const Home = props => {
  const classes = useStyles();
  return (
    <Container className={classes.container} component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.logo}>
          <img src={logo} alt="fh-logo" className={classes.logoImage} />
        </div>
        <Typography component="h1" variant="h4">
          Sign in
        </Typography>
        <SignInForm />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Home;
