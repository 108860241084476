import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  breakdown: {
    width: "100%",
    "& .breakdown-heading": {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#667085",
      marginBottom: "8px"
    },
    "& .breadkdown-analysis": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "16px"
    },
    "& .breakdown-amount": {
      fontFamily: "'Roboto', sans-serif",
      fontSize: "30px",
      fontWeight: "600",
      lineHeight: "44px",
      color: " #FFFFFF",
      letterSpacing: "-0.02em"
    },
    "& .breakdown-icon": {
      width: "44px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#312644",
      borderRadius: "50%"
    }
  }
}));

export default useStyles;
