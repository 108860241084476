import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    borderRadius: "50%",
    width: 200,
    height: 200,
    objectFit: "cover",
    border: "4px solid #FFFFFF",
    position: "relative"
  },
  blur: {
    position: "absolute",
    width: "192px",
    height: "100px",
    left: "4px",
    bottom: "8px",
    backgroundColor: "rgba(217, 217, 217, 0.2)",
    backdropFilter: "blur(2px)",
    borderRadius: "0 0 200px 200px"
  },
  container: {
    position: "relative"
  }
});

const Avatar = ({ src }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img
        className={classes.root}
        src={src || require("../../assets/images/anon-user.png")}
        alt="profile"
      />
      <div className={classes.blur} />
    </div>
  );
};

Avatar.defaultProps = {
  src: require("../../assets/images/anon-user.png")
};

export default Avatar;
