import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  head: {
    display: "flex",
    justifyContent: "flex-end"
  },
  dropDowns: {
    display: "flex"
  },
  referralLinkDialog: {
    backgroundColor: "#1C1C22",
    padding: "47px 0"
  },
  pair: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "20px"
  },
  characterField: {
    width: "100%",
    marginBottom: "22px"
  },
  movieList: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontSize: "14px"
  }
}));

export const itemStyles = makeStyles(theme => ({
  container: {
    paddingTop: 21
  }
}));

export default useStyles;
