import React from "react";
import useStyles from "./styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import ArrowDown from "../../../vectors/ArrowDown";

export default function DateSelector({ value, onChange, ...props }) {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        autoOk
        variant="inline"
        fullWidth
        {...props}
        format="yyyy-MM-dd"
        id="date-picker-inline"
        value={value}
        onChange={onChange}
        // KeyboardButtonProps={{
        //   "aria-label": "change date"
        // }}
        className={classes.root}
        openPickerIcon={() => <ArrowDown />}
        showToolbar
      />
    </MuiPickersUtilsProvider>
  );
}
