import React from "react";

const Ticket = props => (
  <svg
    {...props}
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2507 4.83203C11.4623 4.83203 7.58398 8.71036 7.58398 13.4987C7.58398 18.287 11.4623 22.1654 16.2507 22.1654C21.039 22.1654 24.9173 18.287 24.9173 13.4987C24.9173 8.71036 21.039 4.83203 16.2507 4.83203ZM16.2507 19.9987C12.6648 19.9987 9.75065 17.0845 9.75065 13.4987C9.75065 9.91286 12.6648 6.9987 16.2507 6.9987C19.8365 6.9987 22.7506 9.91286 22.7506 13.4987C22.7506 17.0845 19.8365 19.9987 16.2507 19.9987ZM3.25065 13.4987C3.25065 10.6712 5.05982 8.2662 7.58398 7.37786V5.1137C3.84648 6.07786 1.08398 9.45786 1.08398 13.4987C1.08398 17.5395 3.84648 20.9195 7.58398 21.8837V19.6195C5.05982 18.7312 3.25065 16.3262 3.25065 13.4987Z"
      fill="white"
    />
  </svg>
);

export default Ticket;
