import React from "react";
import SubInputContainer from "../SubInputContainer";
import { TextField } from "@material-ui/core";

const Youtube = ({ title, name, trailerURL, onChange, missing }) => {
  return (
    <SubInputContainer title={title}>
      <TextField
        error={trailerURL.error.isError || missing.includes(name)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="poster-url"
        type="text"
        name={name}
        value={trailerURL.value}
        onBlur={onChange}
        onChange={onChange}
        helperText={
          (trailerURL.error.isError || missing.includes(name)) &&
          (trailerURL.error.message || "URL is empty")
        }
      />
    </SubInputContainer>
  );
};

export default Youtube;
