import React from 'react'
import ConfirmPassForm from '../../components/Forms/ConfirmPassForm';
import SingleFormPageTemplate from '../Template/SingleForm';

const ConfirmPassPage = () => (
    <SingleFormPageTemplate>
        <ConfirmPassForm />
    </SingleFormPageTemplate>
)

export default ConfirmPassPage
