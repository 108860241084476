import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  analyticsContainer: {
    padding: "24px 28px",
    backgroundColor: "#16161A",
    fontFamily: "'Roboto', sans-serif",
    marginBottom: "20px"
  },
  salesOverview: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    paddingBottom: "24px",
    marginBottom: "24px"
  },
  salesOverviewMain: {
    flexGrow: "1",
    display: "flex",
    alignItems: "center",
    gap: "16px",
    "@media screen and (min-width: 1440px)": {
      gap: "48px"
    }
  },
  overviewTab: props => ({
    display: "flex",
    height: "40px",
    filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))",
    "& > button": {
      padding: "10px 16px",
      border: "none",
      outline: "none",
      background: "none",
      display: "flex",
      alignItems: "center",
      gap: "9px",
      fontFamily: "'Inter', sans-serif",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "20px",
      backgroundColor: "#1F2327",
      "@media screen and (min-width: 1440px)": {
        fontSize: "14px"
      }
    },
    "& > button.all": {
      borderRadius: "8px 0px 0px 8px",
      border: `1px solid ${props.active === "all" ? "#FFFFFF" : "#323236"}`,
      color: props.active === "all" ? "#FFFFFF" : "#667085"
    },
    "& > button.first": {
      // borderRadius: "0px",
      border: `1px solid ${props.active === "first" ? "#FFFFFF" : "#323236"}`,
      color: props.active === "first" ? "#FFFFFF" : "#667085"
    },
    "& > button.second": {
      borderRadius: "0px 8px 8px 0px",
      border: `1px solid ${props.active === "second" ? "#FFFFFF" : "#323236"}`,
      color: props.active === "second" ? "#FFFFFF" : "#667085"
    },
    "& .dot": {
      display: "inline-flex",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      "&.second": {
        backgroundColor: "#60CED1"
      },
      "&.first": {
        backgroundColor: "#A233E2"
      }
    }
  }),
  salesOverviewFilter: {
    width: "148px"
    // height: "40px",
  },
  filter: {
    width: "148px"
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& .export-btn": {
      display: "flex",
      gap: "2px",
      alignItems: "center",
      border: "none",
      outline: "none",
      background: "none",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "16px",
      textDecorationLine: "underline",
      color: " #A233E2",
      marginRight: "20px"
    }
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  mainHeading: {
    fontFamily: "'Roboto', sans-serif",
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#FFFFFF",
    "@media screen and (min-width: 1440px)": {
      fontSize: "28px"
    },
    "&.medium": {
      fontWeight: "500"
    },
    "&.regular": {
      fontWeight: "400"
    }
  },
  subHeading: {
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#667085"
  }
}));

export default useStyles;
