import types from "../../types";
import defaultGenres from "../../../containers/MovieEntry/Genres/defaultGenres";
import defaultRatings from "../../../containers/MovieEntry/Censoring/defaultRatings";
import movieEntryApi from "../../../api/movie-entry-api";

const { GET_GENRES, GET_CENSOR_RATING } = types;

const initialState = {
  genres: [...defaultGenres],
  censorRatings: [...defaultRatings]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GENRES:
      return {
        ...state,
        genres: [...action.genres]
      };
    case GET_CENSOR_RATING:
      return {
        ...state,
        censorRatings: [...action.censorRatings]
      };
    default:
      return state;
  }
};

const storeGenres = genres => ({
  type: GET_GENRES,
  genres
});

const storeCensorRatings = censorRatings => ({
  type: GET_CENSOR_RATING,
  censorRatings
});

export const getGenres = () => dispatch =>
  movieEntryApi.getGenres().then(({ data }) => {
    const fetchedGenres = data.data.map(genre => ({
      name: genre.name,
      value: genre.name
    }));
    dispatch(storeGenres(fetchedGenres));
  });

export const getCensorRatings = () => dispatch =>
  movieEntryApi.getCensorRatings().then(({ data }) => {
    const fetchedCensorRatings = data.data.map(rating => ({
      name: rating.id,
      value: rating.id
    }));
    dispatch(storeCensorRatings(fetchedCensorRatings));
  });
