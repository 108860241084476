import React from "react";
import MovieList from "../../../containers/MovieList";

const MovieListPage = () => {
  return (
    <>
      <div>
        <MovieList />
      </div>
    </>
  );
};

export default MovieListPage;
