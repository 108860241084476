import types from "../../types";

const { ALERT_OPEN, ALERT_CLOSE } = types;

const initialState = {
  alert: {
    showAlert: false,
    message: "",
    severity: undefined
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALERT_OPEN:
      return {
        alert: {
          showAlert: true,
          message: action.payload.message,
          severity: action.payload.severity
        }
      };
    case ALERT_CLOSE:
      return initialState;
    default:
      return state;
  }
};
