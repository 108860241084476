import React from "react";

import useStyles from "./styles";
import DashboardContainer from "../../components/Dashboard";
import Container from "@material-ui/core/Container";
import Section from "../../components/Section";
import CustomizedSelects from "../../components/Inputs/Dropdown";



const Dashboard = ({ location, match }) => {
    const classes = useStyles();

    return (
        <div className={classes.interim}>
            <DashboardContainer location={location} match={match}>
                <Container maxWidth="lg" className={classes.container}>
                    <Section title="Custom Dropdown">
                        <div style={{ height: 1000 }}>x</div>
                        <CustomizedSelects />
                    </Section>
                    <div style={{ height: 1000 }}>x</div>
                </Container>
            </DashboardContainer>
        </div>
    );
};

export default Dashboard;
