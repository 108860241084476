export default {
  //auth  types
  ADMIN_LOGIN: "ADMIN_LOGIN",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  //search types
  SEARCH_QUERY: "SEARCH_QUERY",
  SEARCH_RESULTS: "SEARCH_RESULTS",
  SEARCH_START: "SEARCH_START",
  SEARCH_STOP: "SEARCH_STOP",
  CLEAR_SEARCH_RESULTS: "CLEAR_SEARCH_RESULTS",
  //edit-movie-entry types
  MAP_SELECTED_MOVIE: "MAP_SELECTED_MOVIE",
  MAP_SELECTED_MOVIE_FOR_EDIT: "MAP_SELECTED_MOVIE_FOR_EDIT",
  CLEAR_MOVIE_DETAIL: "CLEAR_MOVIE_DETAIL",
  GET_MOVIE_DETAIL: "GET_MOVIE_DETAIL",
  SELECT_POSTER: "SELECT_POSTER",
  SELECT_BACKDROP: "SELECT_BACKDROP",
  STORE_GENRES: "STORE_GENRES",
  GET_CATEGORIES: "GET_CATEGORIES",
  //merchandise types
  GET_MERCHANDISE: "GET_MERCHANDISE",
  GET_SINGLE_MERCHANDISE: "GET_SINGLE_MERCHANDISE",
  //app general state (status)
  START_LOAD_PAGE: "START_LOAD_PAGE",
  STOP_LOAD_PAGE: "STOP_LOAD_PAGE",
  SET_LOCATION: "SET_LOCATION",
  STORE_DBMOVIE_FOR_VISTA: "STORE_DBMOVIE_FOR_VISTA",
  CLEAR_DBMOVIE_FOR_VISTA: "CLEAR_DBMOVIE_FOR_VISTA",
  //lists
  GET_GENRES: "GET_GENRES",
  GET_CENSOR_RATING: "GET_CENSOR_RATING",
  ALERT_OPEN: "ALERT_OPEN",
  ALERT_CLOSE: "ALERT_CLOSE",
  STORE_CURRENT_MOVIES_FILTER: "STORE_CURRENT_MOVIES_FILTER"
};
