import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  deleteDialog: {
    backgroundColor: "#1C1C22",
    padding: "32px 41px"
  }
}));

export default useStyles;
