import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";

const ResetPass = props => {
    const classes = useStyles();
    const {
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleSubmit,
        handleBlur
    } = props

    let emailError;
    try {
        if (Object.keys(errors).length > 0){
            emailError = errors.email && touched.email && errors.email;
        }
    } catch (error) {
        
    }
    
    return (
        <div>
            <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    type="email"
                    label="Email Address"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autoFocus
                    helperText={emailError}
                    inputProps={{
                        autoComplete: "anyrandomstring"
                    }}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting}
                >
                    Send Link
                </Button>
            </form>
        </div>
    );
};

export default ResetPass;
