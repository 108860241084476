import React from "react";

const Comment = props => (
  <svg
    {...props}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33333 14.5V11.7287C2.5 10.7553 2 9.516 2 8.16667C2 5.03733 4.68667 2.5 8 2.5C11.3133 2.5 14 5.03733 14 8.16667C14 11.296 11.3133 13.8333 8 13.8333C6.892 13.8369 5.80307 13.5451 4.84533 12.988L3.33333 14.5ZM8 12.5C10.5773 12.5 12.6667 10.56 12.6667 8.16667C12.6667 5.77333 10.5773 3.83333 8 3.83333C5.42267 3.83333 3.33333 5.77333 3.33333 8.16667C3.33333 10.56 5.42267 12.5 8 12.5ZM6.33333 9.16667C6.06812 9.16667 5.81376 9.06131 5.62623 8.87377C5.43869 8.68624 5.33333 8.43188 5.33333 8.16667C5.33333 7.90145 5.43869 7.6471 5.62623 7.45956C5.81376 7.27202 6.06812 7.16667 6.33333 7.16667C6.59855 7.16667 6.8529 7.27202 7.04044 7.45956C7.22798 7.6471 7.33333 7.90145 7.33333 8.16667C7.33333 8.43188 7.22798 8.68624 7.04044 8.87377C6.8529 9.06131 6.59855 9.16667 6.33333 9.16667ZM9.66667 9.16667C9.40145 9.16667 9.1471 9.06131 8.95956 8.87377C8.77202 8.68624 8.66667 8.43188 8.66667 8.16667C8.66667 7.90145 8.77202 7.6471 8.95956 7.45956C9.1471 7.27202 9.40145 7.16667 9.66667 7.16667C9.93188 7.16667 10.1862 7.27202 10.3738 7.45956C10.5613 7.6471 10.6667 7.90145 10.6667 8.16667C10.6667 8.43188 10.5613 8.68624 10.3738 8.87377C10.1862 9.06131 9.93188 9.16667 9.66667 9.16667Z"
      fill="white"
    />
  </svg>
);

export default Comment;
