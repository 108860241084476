import React from "react";
import validator from "validator";
import SubInputContainer from "../SubInputContainer";
import { TextField } from "@material-ui/core";
import vodPriceStyles from "./style";

const FeatureLength = ({ onChange, featureLength, missing }) => {
  const { textFieldPair } = vodPriceStyles();
  const handleNumberInput = e => {
    const { value } = e.target;
    if (validator.isNumeric(value) || value === "") {
      if (value) {
        e.target.value = parseInt(value, 10);
      }
      onChange(e);
    }
  };
  return (
    <div>
      <SubInputContainer title="Movie Runtime (mins)">
        <div className={textFieldPair}>
          <TextField
            id="outlined-password-input"
            name="featureLength"
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
            type="text"
            value={featureLength && featureLength.value}
            error={
              featureLength.error.isError || missing.includes("featureLength")
            }
            helperText={
              (featureLength.error.isError && featureLength.error.message) ||
              (missing.includes("featureLength") && "Runtime is empty")
            }
            onBlur={e => handleNumberInput(e)}
            onChange={e => handleNumberInput(e)}
            inputProps={{
              style: {
                paddingLeft: 10
              }
            }}
            fullWidth
            InputProps={{
              startAdornment: ""
            }}
            label="Movie Runtime (mins)"
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
      </SubInputContainer>
    </div>
  );
};

export default FeatureLength;
