import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "./routes";
//import EnhancedTable from '../../../components/TableList';

import { Container } from "@material-ui/core";
//import { Button } from "@material-ui/core";

import useStyles from "../styles";

const DashboardHome = () => {
  const { container } = useStyles();
  return (
    <Container maxWidth="lg" className={container}>
      <Switch>
        {routes.map((route, i) => (
          <Route
            path={route.path}
            exact={route.exact}
            component={route.component}
            key={i}
          />
        ))}
        <Redirect to={routes[0].path} />
      </Switch>
    </Container>
  );
};

export default DashboardHome;
