import types from "../../types";

const { ADMIN_LOGIN, ADMIN_LOGOUT } = types;

const initialState = {
    user: {
        signedIn: false,
        details: {},
        jwt:''
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_LOGIN:
            return {
                user: {
                    signedIn: true,
                    details: { ...action.user.info },
                    jwt: action.user.jwt
                }
            };
        case ADMIN_LOGOUT:
            return initialState
        default:
            return state;
    }
};
