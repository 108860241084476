import React from "react";
import AdminList from "../../../containers/AdminList";

const AdminListPage = () => {
  return (
    <div>
      <AdminList />
    </div>
  );
};

export default AdminListPage;
