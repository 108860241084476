import React from "react";

const Info = props => (
  <svg
    {...props}
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_418_2153)">
      <g opacity="0.95924">
        <g opacity="0.95924">
          <g opacity="0.95924">
            <g opacity="0.95924">
              <g opacity="0.95924">
                <g opacity="0.95924">
                  <g opacity="0.95924">
                    <path
                      opacity="0.690546"
                      d="M9.5 18C14.4706 18 18.5 13.9706 18.5 9C18.5 4.02944 14.4706 0 9.5 0C4.52944 0 0.5 4.02944 0.5 9C0.5 13.9706 4.52944 18 9.5 18Z"
                      fill="#999999"
                    />
                    <path
                      opacity="0.95924"
                      d="M10.7496 3.85164C11.3189 4.24036 11.4897 4.98029 11.1311 5.50437C10.7725 6.02844 10.0203 6.13816 9.45097 5.74943C8.88166 5.36071 8.71086 4.62078 9.06948 4.0967C9.42809 3.57263 10.1803 3.46296 10.7496 3.85164Z"
                      fill="#010101"
                    />
                    <g opacity="0.95924">
                      <g opacity="0.95924" filter="url(#filter0_d_418_2153)">
                        <path
                          d="M8.80409 13.0063L10.2707 9.35994C10.3796 9.08872 10.2869 8.79847 10.029 8.60243C9.77151 8.40632 9.39636 8.3435 9.05711 8.43727L8.48727 8.65097C8.29957 8.72136 8.20448 8.93057 8.27487 9.11827C8.2771 9.12422 8.27949 9.13011 8.28203 9.13593C8.36791 9.33282 8.59239 9.4286 8.79395 9.35438L9.36829 9.14288L7.90159 12.7889C7.77507 13.1042 7.86571 13.4388 8.14429 13.6833C8.42287 13.9278 8.83523 14.0354 9.24796 13.9708L10.0731 13.6466C10.2731 13.5681 10.3845 13.354 10.3341 13.145C10.2918 12.9697 10.1153 12.8618 9.93996 12.9041C9.9254 12.9076 9.91109 12.9121 9.89715 12.9176L9.07203 13.2418C8.95352 13.2603 8.87864 13.2078 8.85229 13.1846C8.82541 13.1611 8.76755 13.097 8.80409 13.0063Z"
                          fill="black"
                        />
                      </g>
                      <path
                        opacity="0.95924"
                        d="M8.80409 13.0063L10.2707 9.35994C10.3796 9.08872 10.2869 8.79847 10.029 8.60243C9.77151 8.40632 9.39636 8.3435 9.05711 8.43727L8.48727 8.65097C8.29957 8.72136 8.20448 8.93057 8.27487 9.11827C8.2771 9.12422 8.27949 9.13011 8.28203 9.13593C8.36791 9.33282 8.59239 9.4286 8.79395 9.35438L9.36829 9.14288L7.90159 12.7889C7.77507 13.1042 7.86571 13.4388 8.14429 13.6833C8.42287 13.9278 8.83523 14.0354 9.24796 13.9708L10.0731 13.6466C10.2731 13.5681 10.3845 13.354 10.3341 13.145C10.2918 12.9697 10.1153 12.8618 9.93996 12.9041C9.9254 12.9076 9.91109 12.9121 9.89715 12.9176L9.07203 13.2418C8.95352 13.2603 8.87864 13.2078 8.85229 13.1846C8.82541 13.1611 8.76755 13.097 8.80409 13.0063Z"
                        fill="black"
                        stroke="black"
                        strokeWidth="0.6"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_418_2153"
        x="-76.1543"
        y="-75.6055"
        width="182.5"
        height="185.594"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="6" dy="6" />
        <feGaussianBlur stdDeviation="45" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.052314 0 0 0 0 0.0393209 0 0 0 0 0.185405 0 0 0 0.480305 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_418_2153"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_418_2153"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_418_2153">
        <rect width="18" height="18" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Info;
