import axios from "axios";

export default {
  fetchSubscriptions: token =>
    axios
      .get("/subscriptions", {
        headers: { Authorization: token }
      })
      .then(res => res)
};
