import React from "react";
import ComingSoonListPage from "./ComingSoon.js";
import MovieListPage from "./List.js";
// import AddMoviePage from "./AddMovie.js";
// import MovieEntryPage from "./MovieEntry.js";
// import MovieEntryPatch from "./MovieEntryPatch.js";
import MovieEntryPatch2 from "./MovieEntryPatch2.js";

// import MoviesStats from "./MoviesStats.js";

export default [
  {
    path: "/dashboard/movies",
    exact: true,
    component: () => <MovieListPage />
  },
  {
    path: "/dashboard/movies/coming-soon",
    exact: true,
    component: () => <ComingSoonListPage />
  },
  // {
  //   path: "/dashboard/movies/add-movie",
  //   exact: true,
  //   component: () => <AddMoviePage />
  // },
  // {
  //   path: "/dashboard/movies/edit-entry",
  //   exact: true,
  //   component: () => <MovieEntryPage edit_entry />
  // },
  // {
  //   path: "/dashboard/movies/new-entry",
  //   exact: true,
  //   component: () => <MovieEntryPage create />
  // },
  // {
  //   path: "/dashboard/movies/entry-success",
  //   exact: true,
  //   component: () => <div>movie has been uploaded</div>
  // },
  // {
  //   path: "/dashboard/movies/edit-entry/:id",
  //   exact: true,
  //   component: () => <MovieEntryPatch />
  // },
  {
    path: "/dashboard/movies/edit-entry/:id",
    exact: true,
    component: () => <MovieEntryPatch2 />
  }
  // {
  //   path: "/dashboard/movies/stats",
  //   exact: true,
  //   component: () => <MoviesStats />
  // }
];
