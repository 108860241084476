import React from 'react'
import { Link } from 'react-router-dom'
import ResetPassForm from '../../components/Forms/ResetPassForm';
import SingleFormPageTemplate from '../Template/SingleForm';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles( theme => ({
    loginLink: {
        color: theme.palette.primary.main
    }
}))
const ResetPage = () => {
    const classes = useStyles()
    return (
        <SingleFormPageTemplate
            caption="If you have forgotten your password, enter the email
                    associated with your account to initiate the reset
                    procedure."
        >
            <ResetPassForm />
            <div>
                Back to{" "}
                <Link to="/" className={ classes.loginLink } >
                    Login
                </Link>
            </div>
        </SingleFormPageTemplate>
    );
};

export default ResetPage
