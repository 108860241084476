import jss from "jss";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    width: 150,
    cursor: "pointer",
    minHeight: 221,
    backgroundColor: "#0A0A0E",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0"
  },
  poster: {
    width: 122,
    minHeight: 119,
    objectFit: "cover",
    marginBottom: "32px",
    alignSelf: "center"
  },
  typography: {
    width: 122
  },
  selected: {
    position: "relative",
    width: 150,
    border: "2px solid rgb(162, 51, 226)",
    cursor: "pointer",
    minHeight: 221,
    backgroundColor: "#0A0A0E",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0"
  },
  imageCont: {
    position: "relative"
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%)",
    zIndex: "10"
  }
}));

export const sheet = jss
  .createStyleSheet({
    list: { display: "flex", "flex-wrap": "wrap" }
  })
  .attach();

export default useStyles;
