import React, { useEffect, useState } from "react";
import moment from "moment";
import useStyles from "./styles";
import rows from "./mock_data";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import EnhancedTableHead from "./EnhancedTableHead";
import { CSVLink } from "react-csv";

import fx from "./sorting-functions";
import listParser from "../functions/parseList";
import StyledTableRow from "./StyledTableRow";
import { Button, Tooltip } from "@material-ui/core";
import { Delete, Edit, GetApp } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  // KeyboardDatePicker,
  DatePicker
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

/**
 * Enhanced table component used for rendering data that that can be sorted and clicked on to link to sub-profile changes.
 * @param {function} rowClick This a function that is passed `row[0]`. `row[0]` should be the array position that holds the id of a row.
 */

export default function EnhancedTable({
  keys,
  timeKey,
  ids,
  fullData,
  ellipsis,
  rows,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  headCells,
  editable,
  rowClickable,
  rowClick,
  editClick,
  deleteClick,
  downloadClick,
  dateFilterable,
  csvDownloadTitle
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const editBtn = editClick
    ? editClick
    : () => {
        console.log(" no editClick function");
      };
  const deleteBtn = deleteClick
    ? deleteClick
    : () => {
        console.log(" no deleteClick function");
      };
  const downloadBtn = downloadClick
    ? downloadClick
    : () => {
        console.log(" no downloadClick function");
      };

  const csvHeaders = keys.map(key => {
    const label = key.toUpperCase();
    return { label, key };
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px"
          }}
        >
          {/* <div>
            {csvDownloadTitle && (
              <CSVLink
                data={rows}
                headers={csvHeaders}
                filename={`${csvDownloadTitle}.csv`}
              >
                <Button> Download csv</Button>
              </CSVLink>
            )}
          </div> */}
          <div>
            {dateFilterable && (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils} clearable>
                  <DatePicker
                    autoOk
                    clearable
                    variant="dialog"
                    format="dd/MM/yyyy"
                    margin="normal"
                    placeholder="Start Date"
                    id="date-picker-inline"
                    style={{ marginRight: "10px" }}
                    maxDate={endDate || new Date("2100-01-01")}
                    value={startDate !== "" ? startDate : null}
                    onChange={date => setStartDate(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    clearable
                    variant="dialog"
                    format="dd/MM/yyyy"
                    margin="normal"
                    placeholder="End Date"
                    id="date-picker-inline"
                    minDate={startDate || new Date("1900-01-01")}
                    value={endDate !== "" ? endDate : null}
                    onChange={date => setEndDate(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </>
            )}
          </div>
        </div>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {fx
                .stableSort(rows, fx.getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <StyledTableRow
                      hover
                      onClick={
                        rowClickable
                          ? e =>
                              fullData
                                ? rowClick(e, row)
                                : rowClick(e, row["id"])
                          : null
                      }
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      style={{
                        cursor: editable ? "default" : "pointer"
                      }}
                    >
                      {keys.map((key, i) => {
                        const hasEllipsis = ellipsis && ellipsis[i];
                        if (editable && key === "thumbnailImage") {
                          return (
                            <TableCell
                              className={classes.thumbnail}
                              align="left"
                              key={i}
                            >
                              <img
                                className={classes.thumbnailImage}
                                alt="thumbnail"
                                src={row[key]}
                              />
                            </TableCell>
                          );
                        } else if (i === 0) {
                          return (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              key={i}
                            >
                              {row[key]}
                            </TableCell>
                          );
                        } else if (editable && key === "downloadCSV") {
                          return (
                            <TableCell align="left" key={i}>
                              <CSVLink
                                data={row["vouchers"]}
                                filename={"bulk-vouchers.csv"}
                              >
                                <Button
                                  className={classes.downloads}
                                  onClick={e => downloadBtn(e, row["id"])}
                                >
                                  <GetApp className={classes.downloadIcon} />
                                  Download
                                </Button>
                              </CSVLink>
                            </TableCell>
                          );
                        } else if (editable && key === "deleteBtn") {
                          return (
                            <TableCell align="left" key={i}>
                              <Button
                                className={classes.downloads}
                                onClick={e => deleteBtn(e, row["id"])}
                              >
                                <Delete className={classes.downloadIcon} />
                                Delete
                              </Button>
                            </TableCell>
                          );
                        } else if (editable && key === "edit") {
                          return (
                            <TableCell align="left" key={i}>
                              <Button
                                className={classes.edits}
                                onClick={e => deleteBtn(e, row["id"])}
                              >
                                <Delete />
                              </Button>
                            </TableCell>
                          );
                        } else if (editable && key === "delete") {
                          return (
                            <TableCell align="left" key={i}>
                              <Button
                                className={classes.edits}
                                classes={{ text: classes.edits }}
                                onClick={e => editBtn(e, row["id"])}
                              >
                                <Edit />
                              </Button>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              className={hasEllipsis && classes.ellipsis}
                              key={i}
                              align="left"
                            >
                              {key === timeKey &&
                                moment(row[key]).format("DD/MM/YYYY")}
                              {!(key === timeKey) && row[key]}
                              {hasEllipsis && (
                                <Tooltip
                                  classes={{
                                    tooltip: classes.tooltip,
                                    popper: { boxShadow: "none" }
                                  }}
                                  title={row[key]}
                                  arrow
                                >
                                  <img
                                    alt="info"
                                    style={{ position: "absolute", right: 0 }}
                                    src={require("./../../assets/images/Info.svg")}
                                  />
                                </Tooltip>
                              )}
                            </TableCell>
                          );
                        }
                      })}
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={keys.length} />
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "previous page"
          }}
          nextIconButtonProps={{
            "aria-label": "next page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense View"
      />
      {/* <div className={classes.pagesCont}>
				<CustomPagination
					rows={rows}
					page={page}
					rowsPerPage={rowsPerPage}
					setPage={setPage}
				/>
			</div> */}
    </div>
  );
}

EnhancedTable.defaultProps = {
  rows: rows,
  ids: listParser.mockIDs(rows, "name"),
  keys: ["userName", "email", "subPlan", "dateCreated"]
};
