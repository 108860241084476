import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.background.default
        }
    },
    interim: {
        margin: 0,
        color: "#fff"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    spacer: {
        marginBottom: theme.spacing(4)
    },
    btnContainer: {
        padding: theme.spacing(4)
    }
}));

export default useStyles;
