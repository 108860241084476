import React from "react";
import api from "../../api/category-api";
import sheet from "./styles";
import lodash from "lodash";
import FormControl from "@material-ui/core/FormControl";

import SlideIn from "../../components/Drawer";
import {
  List,
  ListItem,
  Typography,
  Button,
  TextField,
  CircularProgress
} from "@material-ui/core";
import Back from "../../components/Button/Back";

//passwords might have been updated to: Password1!

class EditCategorySlideIn extends React.Component {
  state = {
    form: {
      name: { value: "", error: { isError: false, message: "" } }
    },
    status: {
      loading: true,
      error: {
        isError: false,
        msg: ""
      }
    }
  };

  componentDidMount() {
    this.baseState = lodash.cloneDeep(this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    const { open, isNewCategory, data } = this.props;
    if (prevProps.open !== open) {
      if (isNewCategory) {
        this.setState({
          status: {
            ...this.state.status,
            loading: false
          }
        });
      }
      if (!open) {
        this.setState({
          status: {
            ...this.state.status,
            loading: true
          }
        });
      }
    }

    if (prevProps.data !== data) {
      const { data } = this.props;
      this.setState({
        form: {
          ...this.state.form,
          name: {
            value: data.name,
            error: { isError: false, message: "" }
          }
        },
        status: {
          loading: false,
          error: {
            isError: false,
            msg: ""
          }
        }
      });
    }
  }

  handleChange = ({ target }, validator) => {
    const { name, value } = target;
    const checkedValidator = validator
      ? validator
      : value => ({ isError: false, message: "" });
    this.setState({
      form: {
        ...this.state.form,
        [name]: { value: value, error: checkedValidator(value) }
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { name } = this.state.form;
    const { close, jwt, refreshList, isNewCategory, categoryId } = this.props;

    this.setState({ status: { ...this.state.status, loading: true } });

    const createPayload = () => {
      const payload = {
        name: name.value
      };
      return payload;
    };

    let categoryRequest = isNewCategory
      ? api.createCategory(`Bearer ${jwt}`, createPayload())
      : api.patchCategory(`Bearer ${jwt}`, createPayload(), categoryId);

    categoryRequest
      .then(res => {
        this.setState({
          ...this.baseState,
          status: {
            ...this.state.status,
            loading: false
          }
        });
        close();
        refreshList();
      })
      .catch(err => {
        const response = err && err.response && err.response.data;
        let errMsg =
          response && response.errors[0] && response.errors[0].message
            ? response.errors[0].message
            : "Something went wrong :I";

        this.setState({
          status: {
            ...this.state.status,
            loading: false,
            error: {
              isError: true,
              msg: errMsg
            }
          }
        });
      });
  };

  resetOnClose = e => {
    const { onClose } = this.props;
    this.setState({ ...this.baseState });
    onClose(e);
  };

  resetOnClose = e => {
    const { onClose } = this.props;
    this.setState({ ...this.baseState });
    onClose(e);
  };

  render() {
    const { name } = this.state.form;
    const { loading, error } = this.state.status;
    const { btnTitle, open, title } = this.props;
    const { classes } = sheet;

    return (
      <SlideIn open={open} onClose={this.resetOnClose} from="right">
        <List className={classes.mainContainer}>
          <ListItem className={classes.container}>
            <Back onClick={this.resetOnClose} />
          </ListItem>
          {loading && (
            <div className={classes.circularProgressBox}>
              <CircularProgress />
            </div>
          )}
          {!loading && (
            <form onSubmit={this.handleSubmit}>
              <ListItem className={classes.container}>
                <Typography variant="h3">{title}</Typography>
              </ListItem>
              {error.isError && (
                <div className={classes.container}>
                  <div className={classes.error}>{error.msg}</div>
                </div>
              )}
              <ListItem className={classes.container}>
                <div className={classes.section}>
                  <Typography variant="h4" className={classes.title}>
                    Category Name
                  </Typography>
                  <TextField
                    id="outlined-password-input"
                    name="name"
                    label="Category Name"
                    type="text"
                    autoComplete="current-password"
                    margin="normal"
                    variant="outlined"
                    className={classes.txtField}
                    onChange={this.handleChange}
                    value={name.value}
                    required
                  />

                  <div>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={classes.createBtn}
                      disabled={loading}
                    >
                      {btnTitle}
                    </Button>
                    {loading && <CircularProgress size={24} />}
                  </div>
                </div>
              </ListItem>
            </form>
          )}
        </List>
      </SlideIn>
    );
  }
}

export default EditCategorySlideIn;
