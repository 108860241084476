import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const itemStyles = makeStyles(theme => ({
  backBtn: {
    color: "rgba(162,51,226,1)",
    marginBottom: 20,
    display: "flex",
    "&:hover svg": {
      transform: "translateX(-5px)"
    }
  },
  arrowBlock: {
    display: "flex",
    alignItems: "center"
  },
  btnTypo: {
    paddingLeft: 5
  },
  button: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    "&:hover svg": {
      transform: "translateX(-7px)"
    }
  },
  arrowBack: {
    transition: "0.3s ease"
  }
}));

const GoBack = ({ link, type = "link", onClick }) => {
  const { backBtn, arrowBlock, btnTypo, button, arrowBack } = itemStyles();
  return type === "link" ? (
    <Link to={link}>
      <div className={backBtn}>
        <div className={arrowBlock}>
          <ArrowBack className={arrowBack} />
        </div>
        <div className={btnTypo}>
          <Typography variant="h6">Go back</Typography>
        </div>
      </div>
    </Link>
  ) : type === "button" ? (
    <button className={button} onClick={onClick}>
      <div className={backBtn}>
        <div className={arrowBlock}>
          <ArrowBack className={arrowBack} />
        </div>
        <div className={btnTypo}>
          <Typography variant="h6">Go back</Typography>
        </div>
      </div>
    </button>
  ) : (
    <></>
  );
};

export default GoBack;
