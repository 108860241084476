import axios from "axios";

export default {
  fetchTickets: token =>
    axios
      .get("/ticket/ticket-breakdown", {
        headers: { Authorization: token }
      })
      .then(res => res)
};
