import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import userApis from "../../api/users-api";
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";
import { Alert } from "@material-ui/lab";
import PaperedInput from "../../components/Inputs/PaperedInput";

const GuestUserList = ({ jwt }) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [error, setError] = useState({
    isError: false,
    message: ""
  });
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    setLoading(true);
    setError({ isError: false, message: "" });
    userApis
      .getGuestUsers(`Bearer ${jwt}`, page + 1, searchInput)
      .then(res => {
        const { guestUsers = [], totalCount = 0 } = res.data.data;
        setLoading(false);
        setData(
          guestUsers.map((item, index) => ({
            date: `${item?.lastPurchaseDate?.substring(
              0,
              10
            )} ${item?.lastPurchaseDate?.substring(11, 19)}`,
            email: item?.customer_email,
            name: item?.customer_name,
            id: index
          }))
        );
        setTotalData(totalCount);
      })
      .catch(e => {
        setError({ isError: true, message: "There is an error" });
        setLoading(false);
      });
  }, [page, jwt, searchInput]);

  const columns = [
    { field: "name", headerName: "Name", width: 350 },
    { field: "email", headerName: "Email", width: 350 },
    { field: "date", headerName: "Last Purchase", width: 360 }
  ];

  return (
    <div>
      <>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <PaperedInput
              onChange={e => setSearchInput(e.target.value)}
              placeholder="Search for User"
            />
          </Grid>
          <Grid item xs={6}>
            <Grid justify="flex-end" container>
              <CSVLink data={data} filename={"guest-users.csv"}>
                <Button variant="contained" color="primary">
                  <DownloadIcon className="c-user-icon" /> DOWNLOAD CSV
                </Button>
              </CSVLink>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          {error.isError ? (
            <Alert severity="error">{error.message}</Alert>
          ) : (
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={10}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              autoHeight={true}
              loading={loading}
              rowCount={totalData}
              page={page}
              onPageChange={page => {
                setPage(page);
              }}
              paginationMode="server"
              className="user-list-table"
              onRowClick={params =>
                history.push(`/dashboard/users/guests/${params.row.email}`)
              }
            />
          )}
        </div>
      </>
    </div>
  );
};

const mapStateToProps = state => ({
  jwt: state.user.user.jwt
});

export default connect(mapStateToProps)(withRouter(GuestUserList));
