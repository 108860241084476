import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#1C1C22"
    },
    appBarSpacer: theme.mixins.toolbar
}));


const DashboardContent = ({ children }) => {
    const {content, appBarSpacer} = useStyles();

    return (
        <>
        <CssBaseline />
        <main className={content}>
            <div className={appBarSpacer} />
            {children}
        </main>
        </>
    );
}

export default DashboardContent
