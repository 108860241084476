import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import types from "../../../redux/types";
import { useEffect } from "react";
import cubeApi from "../../../api/cube";
import { dateFromISO } from "../../../utils/functions";

const useBookings = ({ selectedCubeId, status }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { jwt } = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;
  const loadBooking = () => {
    setLoading(true);
    if (selectedCubeId) {
      cubeApi
        .getBookings(jwt, selectedCubeId, status.join(","))
        .then(res => {
          const newData =
            res.data.data.map(({ date_time, reschedule_date, ...rest }) => {
              const { hour, minute, second, day, month, year } = dateFromISO(
                date_time ?? ""
              );
              console.log(reschedule_date);
              const {
                hour: rescheduleHour,
                minute: rescheduleMinute,
                second: rescheduleSecond,
                day: rescheduleDay,
                month: rescheduleMonth,
                year: rescheduleYear
              } = dateFromISO(reschedule_date ?? "");
              return {
                date_time: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
                reschedule_date: `${rescheduleYear}-${rescheduleMonth}-${rescheduleDay} ${rescheduleHour}:${rescheduleMinute}:${rescheduleSecond}`,
                ISODate: date_time,
                cancel: "",
                reschedule: "",
                redeem: "",
                edit: "",
                view: "",
                ...rest
              };
            }) ?? [];
          // if (areArraysEqual(data, newData)) return;
          setData(newData);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    loadBooking();
  }, [jwt, selectedCubeId, status]);

  return {
    data,
    loading,
    loadBooking
  };
};

export default useBookings;
