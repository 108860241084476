import types from "../types";
import api from "../../api/auth-api";

const { ADMIN_LOGIN, ADMIN_LOGOUT, ALERT_OPEN } = types;

export const login = user => ({
  type: ADMIN_LOGIN,
  user
});

export const logout = () => ({
  type: ADMIN_LOGOUT
});

export const adminLogin = user => dispatch =>
  api.login(user).then(res => {
    const resData = res.data;
    const userData = {
      info: { ...resData.data.user },
      jwt: resData.data.token
    };
    if (userData.info.userType) {
      dispatch(login(userData));
      sessionStorage.setItem("filmhouseAdminJWT", res.data.jwt);
      localStorage.setItem("filmhouseAdminUser", JSON.stringify(res.data.user));
      return res;
    } else {
      const err = new Error("No permissions");
      throw err;
    }
  });

export const adminLogout = token => dispatch => dispatch(logout());
// api
//   .logout(token)
//   .then(() => dispatch(logout()))
//   .catch(() =>
//     dispatch({
//       type: ALERT_OPEN,
//       payload: {
//         severity: "error",
//         message: "Something went wrong, try again"
//       }
//     })
//   );

export const passResetLogin = tokenated => dispatch =>
  api.resetPasswordComp(tokenated).then(res => {
    const userData = { ...res.data.user, jwt: res.data.jwt };
    dispatch(login(userData));
    return res;
  });
