import React from "react";
import PointsSystem from "./PointsSystem";

export default [
  {
    path: "/dashboard/points",
    exact: true,
    component: () => <PointsSystem />
  }
];
