import React from "react";
import validator from "validator";
import SubInputContainer from "../SubInputContainer";
import { TextField } from "@material-ui/core";
import vodPriceStyles from "./style";

const ReleaseYear = ({ onChange, releaseYear, missing }) => {
  const { textFieldPair } = vodPriceStyles();
  const handleNumberInput = (e, range) => {
    const { value } = e.target;
    if (
      (validator.isNumeric(value) && value < range && value > 0) ||
      value === ""
    ) {
      if (value) {
        e.target.value = parseInt(value, 10);
      }
      if (value.toString() === "0") {
        onChange(e, () => ({
          isError: true,
          message: "Release year cannot be 0"
        }));
      } else {
        onChange(e);
      }
    }
  };

  return (
    <div>
      <SubInputContainer title="Release Year">
        {/* <div className={textFieldPair}> */}
        <TextField
          id="outlined-password-input"
          name="releaseYear"
          autoComplete="current-password"
          margin="normal"
          variant="outlined"
          type="text"
          fullWidth
          required
          value={releaseYear && releaseYear.value}
          error={releaseYear.error.isError || missing.includes("releaseYear")}
          helperText={
            (releaseYear.error.isError && releaseYear.error.message) ||
            (missing.includes("releaseYear") && "Release year is empty")
          }
          onChange={e => handleNumberInput(e, 10000)}
          onBlur={e => handleNumberInput(e, 10000)}
          inputProps={{
            style: {
              paddingLeft: 10,
              width: "100%"
            }
          }}
          // InputProps={{
          //   startAdornment: ""
          // }}
          label="Release Year"
          // InputLabelProps={{
          //   shrink: true
          // }}
        />
        {/* </div> */}
      </SubInputContainer>
    </div>
  );
};

export default ReleaseYear;
