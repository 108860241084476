export default function (cinemas, identifier, field="hpok") {
    let list = [];
    cinemas.forEach((cinema, i) => {
        const avail = cinema.concessions.filter(
            concession => concession[field] === identifier
        );
        if (avail.length > 0) {
            list.push({ id: i , location: cinema.city, price: avail[0].price });
        }
    });
    return list;
}