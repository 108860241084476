import axios from "axios";

export default {
  fetchCinemas: token =>
    axios
      .get(`/cinema`, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
