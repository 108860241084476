import React from "react";

const Concession = props => (
  <svg
    {...props}
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5656 25.4045H21.364C22.274 25.4045 23.0215 24.7112 23.1298 23.8229L24.9173 5.96953H19.5007V1.58203H17.3665V5.96953H11.9823L12.3073 8.50453C14.1598 9.0137 15.8932 9.93453 16.9332 10.9529C18.4932 12.4912 19.5656 14.0837 19.5656 16.6837V25.4045ZM1.08398 24.3212V23.2487H17.3665V24.3212C17.3665 24.917 16.879 25.4045 16.2723 25.4045H2.17815C1.57148 25.4045 1.08398 24.917 1.08398 24.3212ZM17.3665 16.7379C17.3665 8.0712 1.08398 8.0712 1.08398 16.7379H17.3665ZM1.10565 18.9154H17.3556V21.082H1.10565V18.9154Z"
      fill="white"
    />
  </svg>
);

export default Concession;
