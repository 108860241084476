import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    type: "dark",
    primary: { main: "#A233E2" },
    background: { default: "#16161A" },
    secondary: {
      main: "#FF4D4F"
    },
    success: {
      main: "#039855"
    }
  },
  mixins: {
    toolbar: {
      height: 100
    }
  }
});
