import React from "react";
import NotificationsList from "./NotificationsList";
import SendNotifications from "./SendNotifications";
// import RolesListPage from "./Roles";

export default [
  {
    path: "/dashboard/notifications",
    exact: true,
    component: () => <NotificationsList />
  },
  {
    path: "/dashboard/notifications/send",
    exact: true,
    component: () => <SendNotifications />
  }
  // {
  //   path: "/dashboard/admin/roles",
  //   exact: true,
  //   component: () => <RolesListPage />
  // }
];
