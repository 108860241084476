import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "rgba(35,35,39,1)"
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: "auto"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  edits: {
    padding: "0 !important",
    justifyContent: "start !important",
    minWidth: "0 !important"
  },
  downloads: {
    // fontSize: 12,
    color: "#BB86FC",
    padding: "8px 6px",
    fontWeight: "600",
    fontSize: "10.89px",
    letterSpacing: "0.97px",
    lineHeight: "12.44px",
    border: "0.78px solid rgba(255,255,255,0.12)",
    borderRadius: "3.11px"
  },
  thumbnail: {
    width: "120px"
  },
  thumbnailImage: {
    width: "120px",
    height: "67px",
    objectFit: "cover"
  },
  tooltip: {
    padding: "18px 13px",
    maxWidth: "222px",
    "box-sizing": "border-box",
    border: "0.85px solid rgba(151,151,151,0.25)",
    transform: "rotate(180deg)",
    "border-radius": "4.58px",
    "background-color": "#16161A",
    color: "rgba(255,255,255,0.6)",
    fontWeight: 500,
    fontSize: "11px",
    letterSpacing: "0.08px",
    lineHeight: "20px"
  },
  ellipsis: {
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "289px"
  },
  downloadIcon: {
    marginRight: "10px",
    fontSize: 11
  },
  pages: {
    padding: "10px 15px",
    margin: 5,
    backgroundColor: "rgba(48,48,48,1)",
    borderRadius: 2,
    cursor: "pointer"
  },
  pagesCont: { display: "flex", justifyContent: "center" },
  pagesInner: { display: "flex", margin: "10px auto 0" }
}));

export default useStyles;
