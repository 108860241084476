import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import api from "../../../api/fandb-api";
import EnhancedTable from "../../../components/TableList";
import parseUserList from "../../../components/functions/parseList";
import PaperedInput from "../../../components/Inputs/PaperedInput";
import useStyles from "./styles";
import DropDown from "../../../components/Inputs/Dropdown";
import headCells from "./headCells";
import filters from "./listFilters";
import ShowForPermissionComponent from "../../../utils/ShowForPermissionComponent";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Grid, Button } from "@material-ui/core";

const FandBList = ({ history }) => {
  const classes = useStyles();
  const { type, flavor } = filters;
  const [list, setList] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [ids, setIds] = useState([]);
  const [typeFilter, setTypeFilter] = useState({
    name: "Filter by Type",
    value: "Filter by Type"
  });
  const [flavorFilter, setFlavorFilter] = useState({
    name: "Filter by Flavor",
    value: "Filter by Flavor"
  });

  const byType = value => list.filter(item => item.type === value);
  const byFlavor = value => list.filter(item => item.flavour === value);

  useEffect(() => {
    setTypeFilter(type[0].value);
    setFlavorFilter(flavor[0].value);
    api
      .getList()
      .then(res => {
        setList(res.data.data);
        setFiltered(res.data.data);
        setIds(parseUserList.ids(res.data.data));
      })
      .catch(err => err);
  }, [type, flavor]);

  const handleChange = ({ target }, setter) => {
    setter(target.value);
    if (
      target.value !== "Filter by Type" &&
      target.value !== "Filter by Flavor"
    ) {
      if (setter === setTypeFilter) {
        setFiltered(byType(target.value));
      } else {
        setFiltered(byFlavor(target.value));
      }
    } else {
      setFiltered(list);
    }
  };

  const handleSearch = ({ target }) => {
    const { value } = target;
    setFiltered(
      list.filter(item => item.type.toLowerCase().includes(value.toLowerCase()))
    );
  };

  const rowClick = (e, id) => {
    history.push(`/dashboard/fandb/${id}`);
  };

  return (
    <div>
      <div className={classes.head}>
        <div className={classes.dropDowns}>
          <DropDown
            menuItems={type}
            value={typeFilter}
            onChange={e => handleChange(e, setTypeFilter)}
          />
          <DropDown
            menuItems={flavor}
            value={flavorFilter}
            onChange={e => handleChange(e, setFlavorFilter)}
          />
        </div>
        <div>
          <PaperedInput onChange={handleSearch} placeholder="Search by type" />
        </div>
      </div>
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}></Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <CSVLink data={filtered} filename={"food and bevarages.csv"}>
              <Button variant="contained" color="primary">
                <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
      </>
      <ShowForPermissionComponent
        permission="edible:read"
        displayMessage="Permission denied"
      >
        <EnhancedTable
          rowClick={rowClick}
          rowClickable={true}
          ids={ids}
          keys={["name", "type", "flavour", "size"]}
          rows={filtered}
          headCells={headCells}
          timeKey="createdAt"
        />
      </ShowForPermissionComponent>
    </div>
  );
};

export default withRouter(FandBList);
