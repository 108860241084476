import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { adminLogin } from "../../redux/actions/rxa_auth";
import SignIn from "./SignIn";
import signInValidator from "../functions/validator";
import types from "../../redux/types";
import { getRequestError } from "../../utils/functions";

class SignInForm extends Component {
  state = {
    form: {
      email: "",
      password: ""
    },
    status: {
      loading: false,
      error: null,
      errors: {
        email: "",
        password: ""
      },
      validEmail: false,
      validPassword: false
    }
  };

  handleEmailChange = e => {
    this.setState(
      {
        form: {
          ...this.state.form,
          email: e.target.value
        }
      },
      () => {
        if (signInValidator.email.test(this.state.form.email)) {
          this.setState({
            status: {
              ...this.state.status,
              error: false,
              validEmail: true,
              errors: {
                ...this.state.status.errors,
                email: ""
              }
            }
          });
        }
      }
    );
  };

  handlePasswordChange = e => {
    this.setState(
      {
        form: {
          ...this.state.form,
          password: e.target.value
        }
      },
      () => {
        if (signInValidator.password.test(this.state.form.password)) {
          this.setState({
            status: {
              ...this.state.status,
              error: false,
              validPassword: true,
              errors: {
                ...this.state.status.errors,
                password: ""
              }
            }
          });
        } else {
          this.setState({
            status: {
              ...this.state.status,
              error: true,
              validPassword: false,
              errors: {
                ...this.state.status.errors,
                password: "empty pasword"
              }
            }
          });
        }
      }
    );
  };

  handleEmailBlur = e => {
    if (e.target.name === "email") {
      if (signInValidator.email.test(this.state.form.email)) {
        this.setState({
          status: {
            ...this.state.status,
            error: false,
            validEmail: true
          }
        });
      } else {
        this.setState({
          status: {
            ...this.state.status,
            error: true,
            validEmail: false,
            errors: {
              ...this.state.status.errors,
              email: "invalid email"
            }
          }
        });
      }
    }
  };

  handlePasswordBlur = e => {
    if (signInValidator.password.test(this.state.form.password)) {
      this.setState({
        status: {
          ...this.state.status,
          error: false,
          validPassword: true
        }
      });
    } else {
      this.setState({
        status: {
          ...this.state.status,
          error: true,
          validPassword: false,
          errors: {
            ...this.state.status.errors,
            password: "invalid password"
          }
        }
      });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    if (signInValidator.password.test(this.state.form.password)) {
      this.setState({
        status: {
          ...this.state.status,
          error: false,
          validPassword: true
        }
      });
    } else {
      this.setState({
        status: {
          ...this.state.status,
          error: true,
          validPassword: false,
          errors: {
            ...this.state.status.errors,
            password: "invalid password"
          }
        }
      });
      return;
    }
    if (signInValidator.email.test(this.state.form.email)) {
      this.setState({
        status: {
          ...this.state.status,
          error: false,
          validEmail: true
        }
      });
    } else {
      this.setState({
        status: {
          ...this.state.status,
          error: true,
          validEmail: false,
          errors: {
            ...this.state.status.errors,
            email: "invalid email"
          }
        }
      });
      return;
    }
    const { form } = this.state;
    const { dispatch } = this.props;
    const { ALERT_OPEN } = types;

    this.setState({
      status: {
        ...this.state.status,
        loading: true
      }
    });
    dispatch(adminLogin(form))
      .then(res => {
        return this.setState({
          status: {
            ...this.state.status,
            loading: false
          }
        });
      })
      .then(res => {
        this.props.history.push("/dashboard");
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        this.setState({
          status: {
            ...this.state.status,
            loading: false
            // errors: {
            //   ...this.state.status.errors,
            //   email: "invalid email or password"
            // }
          }
        });
      });
  };
  handleFocus = e => {
    this.setState({});
  };
  render() {
    const {
      error,
      loading,
      validEmail,
      validPassword,
      errors
    } = this.state.status;
    const submittable = validEmail && validPassword;
    return (
      <div>
        <SignIn
          handleChange={{
            email: this.handleEmailChange,
            password: this.handlePasswordChange
          }}
          handleSubmit={this.handleSubmit}
          error={!!error}
          errors={errors}
          isSubmitting={loading}
          submittable={submittable}
          onFocus={this.handleFocus}
          handleBlur={{
            email: this.handleEmailBlur,
            password: this.handlePasswordBlur
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(null, mapDispatchToProps)(withRouter(SignInForm));
