import types from "../../types";

const { GET_MERCHANDISE } = types;

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MERCHANDISE:
      return { ...state, merchandise: action.merchandise };
    default:
      return state;
  }
};
