import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  imgContainer: {
    maxWidth: "100%",
    marginTop: theme.spacing(3)
  },
  inputSpacer: {
    marginTop: theme.spacing(3)
  },
  postURL: {
    marginLeft: theme.spacing(2),
    "&:disabled": {
      opacity: 0.5
    }
  },
  heading: {
    fontWeight: "400",
    fontSize: "28px",
    lineHeight: "33px",
    color: "#606063",
    margin: "35px 0 16px 0"
  },
  pair: {
    display: "flex"
  },
  thumbnailCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 54px 16px 16px",
    background: "#0A0A0E",
    marginTop: "32px"
  },
  thumbnailDetails: {
    display: "flex",
    alignItems: "center",
    gap: "32px"
  },
  thumbnailImg: {
    width: 122,
    height: 119,
    objectFit: "cover"
  },
  thumbnailName: {
    fontWeight: "400",
    fontSize: "17px",
    lineHeight: "24px",
    color: "#FFFFFF",
    marginBottom: "10px"
  },
  thumbnailSize: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    opacity: "0.5"
  }
}));

export default useStyles;
