import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  backBtn: {
    display: "flex"
  },
  section: {
    paddingLeft: 40
  },
  sectionContainer: {
    marginBottom: 30
  },
  titleStyle: {
    paddingLeft: 40,
    paddingTop: 13,
    color: "#979797",
    opacity: 0.57,
    paddingBottom: theme.spacing(3)
  },
  profileHead: {
    display: "flex",
    alignItems: "center"
  },
  avatarSection: {
    textAlign: "center",
    marginRight: "20px"
  },
  userName: {
    textAlign: "center",
    marginTop: "-30px"
  },
  changePicture: {
    color: "#7E599C",
    fontSize: "14px",
    margin: 0,
    letterSpacing: "0.48px",
    lineHeight: "33px"
  },
  userNameTypo: {
    margin: "auto",
    marginTop: "-5px",
    color: "rgba(255,255,255,0.87)"
  },
  pair: {
    display: "flex",
    marginTop: " -18px"
  }
}));

export default useStyles;
