import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import BootstrapInput from "./BootstrapInput";
import useStyles from "./styles";
import "./style.css";
import ArrowDown from "../../../vectors/ArrowDown";

export default function DropDown({ value, menuItems, onChange, name }) {
  const classes = useStyles();
  return (
    <form
      onSubmit={e => e.preventDefault()}
      className={classes.root}
      autoComplete="off"
    >
      <FormControl className={classes.margin}>
        <Select
          value={value}
          onChange={onChange}
          name={name}
          input={
            <BootstrapInput
              name={name}
              id="age-customized-select"
              placeholder="int"
            />
          }
          IconComponent={() => null}
        >
          {menuItems.map((item, i) =>
            item.value === "" ? (
              <MenuItem key={i} value="">
                <em>{item.name}</em>
                <button className="custom-arrow-down">
                  <ArrowDown focusable="false" />
                </button>
              </MenuItem>
            ) : (
              <MenuItem key={i} value={item.value}>
                {item.name}
                <button className="custom-arrow-down">
                  <ArrowDown focusable="false" />
                </button>
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </form>
  );
}

DropDown.defaultProps = {
  name: "age",
  value: "",
  menuItems: [
    { name: "empty", value: "" },
    { name: "Ten", value: 10 },
    { name: "Twenty", value: 20 },
    { name: "Thirty", value: 30 }
  ]
};
