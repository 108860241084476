import React, { useState, useEffect } from "react";
import api from "../../../api/new-promotions";
import useStyles from "./styles";
import { Button, Grid, Tooltip } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import "./promotions.css";
import types from "../../../redux/types";
import { getRequestError } from "../../../utils/functions";
import { Delete, Edit } from "@material-ui/icons";
import DeletePromotion from "./DeletePromotion";
import Info from "../../../vectors/Info";
import Switch from "../../../components/Switch/Switch";
import ManagePromotion from "./ManagePromotion";
import { useHistory } from "react-router-dom";

const HeroBanner = ({ user }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [selectedPromotionId, setSelectedPromotionId] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const history = useHistory();

  const handleOpenClose = (setter, value) => {
    return setter(value);
  };

  const fetchPromotions = _page => {
    setLoading(true);
    api
      .getList(`Bearer ${user.jwt}`, _page + 1, "footer")
      .then(res => {
        setFiltered(
          res?.data?.data?.map((item, index) => ({
            isActive: item?.isActive,
            type: item.isMovie ? "Movie" : "Advert",
            id: item?.id ?? index,
            webMedia: item?.web_media,
            mobileMedia: item?.mobile_media,
            thumbnailImage: item?.thumbnail,
            destinationUrl: item?.url ?? "_"
          })) ?? []
        );
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchPromotions(page);
  }, [page]);

  const handleDelete = id => {
    return api
      .deletePromotion(`Bearer ${user.jwt}`, id)
      .then(res => {
        setOpenDelete(false);
        fetchPromotions(0);
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  const handleUpdatingIsActive = ({ id, status }) => {
    if (!isActiveLoading) {
      setIsActiveLoading(true);
      api
        .editPromotionStatus(`Bearer ${user.jwt}`, status, id)
        .then(res => {
          setIsActiveLoading(false);
          fetchPromotions(0);
        })
        .catch(err => {
          dispatch({
            type: ALERT_OPEN,
            payload: {
              severity: "error",
              message: getRequestError(err)
            }
          });
          return err;
        });
    }
  };

  const handleOpenDeleteModal = (e, id) => {
    e.preventDefault();
    setSelectedPromotionId(id);
    setOpenDelete(true);
  };

  const columns = [
    {
      field: "title",
      headerName: "Image",
      width: 500,
      renderCell: params => {
        return (
          <div className={classes.title}>
            {params?.row?.thumbnailImage ? (
              <img
                className={classes.image}
                alt={params.row.title}
                src={params.row.thumbnailImage}
                width={120}
                height={67}
              />
            ) : null}
            <span>{params.row.title}</span>
          </div>
        );
      }
    },
    {
      field: "destinationUrl",
      headerName: "URL",
      width: 317,
      renderCell: params => {
        return (
          <div className={classes.ellipsisContainer}>
            <span className={classes.ellipsis}>
              {params.row.destinationUrl}
            </span>
          </div>
        );
      }
    },
    {
      field: "id",
      headerName: " ",
      width: 125,
      renderCell: params => {
        return (
          <Edit
            color="primary"
            className={classes.editButton}
            onClick={() => {
              history.push(`/dashboard/promotions/${params.row.id}`);
            }}
          />
        );
      }
    },
    {
      field: "movieID",
      headerName: " ",
      width: 160,
      renderCell: params => {
        return (
          <button onClick={e => handleOpenDeleteModal(e, params.row.id)}>
            <Delete color="secondary" />
          </button>
        );
      }
    },
    {
      field: "isActive",
      headerName: "Activate",
      width: 160,
      renderCell: params => {
        return (
          <Switch
            defaultChecked={params.row.isActive}
            checked={params.row.isActive}
            onChange={() => {
              params.row.isActive
                ? handleUpdatingIsActive({
                    id: params.row.id,
                    status: false
                  })
                : handleUpdatingIsActive({
                    id: params.row.id,
                    status: true
                  });
            }}
            disabled={isActiveLoading}
          />
        );
      }
    }
  ];

  console.log(filtered);

  return (
    <div>
      <div className={classes.head}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={6}></Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Grid justify="flex-end" container>
              {user.details.userType.toLowerCase() === "superadmin" ? (
                <Button
                  onClick={() => {
                    history.push(`/dashboard/promotions/add`);
                  }}
                  className={classes.addBannerBtn}
                  variant="outlined"
                  color="primary"
                >
                  + ADD PROMOTION{" "}
                </Button>
              ) : null}
              <CSVLink data={filtered} filename={"hero-banners.csv"}>
                <Button variant="contained" color="primary">
                  <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
                </Button>
              </CSVLink>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <DeletePromotion
        jwt={user.jwt}
        recordId={selectedPromotionId}
        open={openDelete}
        handleClose={() => handleOpenClose(setOpenDelete, false)}
        handleDelete={handleDelete}
      />
      <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
        <DataGrid
          rows={filtered}
          columns={columns}
          pageSize={10}
          rowHeight={93}
          headerHeight={80}
          disableColumnMenu={true}
          autoHeight={true}
          page={page}
          rowCount={filtered.length}
          onPageChange={page => {
            setPage(page);
          }}
          paginationMode="server"
          loading={loading}
          className="promotions-table"
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user.user
});
export default connect(mapStateToProps)(HeroBanner);
