import React from "react";
import SubInputContainer from "../SubInputContainer";
import RadioInput from "../../../components/Inputs/Radio";
import validator from "../../../components/functions/validator";

const Binary = ({ name, title, value, onChange, create }) => {
  return (
    <SubInputContainer title={title}>
      <RadioInput
        name={name}
        handleChange={e => onChange(e, validator.radios)}
        val={create ? value : value && value.value && value.value.toString()}
        options={[
          {
            label: "Yes",
            value: "true"
          },
          {
            label: "No",
            value: "false"
          }
        ]}
      />
    </SubInputContainer>
  );
};

export default Binary;
