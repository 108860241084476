import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField
} from "@material-ui/core";
import { useStyles } from "./styles";
import validator from "validator";
import types from "../../../redux/types";
import {
  generateCubeHourlyTimes,
  generateFormattedDates,
  getRequestError
} from "../../../utils/functions";
import { useEffect } from "react";
import moviesAPi from "../../../api/movie-entry-api";
import cubesApi from "../../../api/cube";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  movie: { value: "", error: { isError: false, message: "" } },
  fullName: { value: "", error: { isError: false, message: "" } },
  email: { value: "", error: { isError: false, message: "" } },
  cube: { value: "", error: { isError: false, message: "" } },
  date: {
    value: generateFormattedDates(7)[0].value,
    error: { isError: false, message: "" }
  },
  time: {
    value: generateCubeHourlyTimes()[0].value,
    error: { isError: false, message: "" }
  },
  phoneNumber: {
    value: "",
    error: { isError: false, message: "" }
  },
  numberOfGuests: { value: "", error: { isError: false, message: "" } }
};
const EditBooking = ({ open, handleClose, cubes, booking, loadBooking }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    ...initialState
  });
  const [status, setStatus] = useState({
    loading: false,
    required: [
      "fullName",
      "email",
      "cube",
      "date",
      "time",
      "movie",
      "numberOfGuests"
    ],
    missing: []
  });
  const [movies, setMovies] = useState([]);
  const [moviesLoading, setMoviesLoading] = useState(false);

  const { jwt } = useSelector(state => state.user.user);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const handleChange = ({ target }, _validator) => {
    const { name, value } = target;
    const checkedValidator = _validator
      ? _validator
      : value =>
          validator.isEmpty(value)
            ? { isError: true, message: "Field is empty" }
            : { isError: false, message: "" };
    if (!validator.isEmpty(value)) {
      setStatus(prevStatus => ({
        ...prevStatus,
        missing: prevStatus.missing.filter(item => name !== item)
      }));
    }
    setForm(prevForm => ({
      ...prevForm,
      [name]: { value: value, error: checkedValidator(value) }
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { fullName, email, movie, numberOfGuests, phoneNumber } = form;

    let missing = new Set();
    status.required.forEach(item => {
      if (validator.isEmpty(form[item].value)) {
        missing.add(item);
      }
    });
    setStatus(prevStatus => ({ ...prevStatus, missing: [...missing] }));
    if ([...missing].length) return;
    setStatus(prevStatus => ({ ...prevStatus, loading: true }));
    cubesApi
      .editBookings(
        jwt,
        {
          guest_count: Number(numberOfGuests.value),
          full_name: fullName.value,
          email: email.value,
          has_corkage: false,
          phone_number: phoneNumber.value,
          movie_id: Number(movie.value)
        },
        booking?.booking_id
      )
      .then(res => {
        console.log(res);
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "success",
            message: res.data.message ?? "Successful"
          }
        });
        loadBooking();
        handleClose();
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
      })
      .finally(() =>
        setStatus(prevStatus => ({ ...prevStatus, loading: false }))
      );
  };

  useEffect(() => {
    if (cubes.length) {
      setForm(prevForm => ({
        ...prevForm,
        cube: {
          value: cubes[0].id,
          error: { isError: false, message: "" }
        }
      }));
    }
  }, [cubes]);

  useEffect(() => {
    if (form.cube.value && form.date.value && cubes.length) {
      const cubeCinemaId = cubes.find(item => item.id === form.cube.value)
        ?.rosetta_cinema_id;
      setMoviesLoading(true);
      cubesApi
        .getCinemaMovies(cubeCinemaId, form.date.value, form.date.value, jwt)
        .then(res => {
          setMovies(res.data.data);
          if (res.data.data.length && !form.movie.value) {
            setForm(prevForm => ({
              ...prevForm,
              movie: {
                value: res.data.data[0].movie_id.toString(),
                error: { isError: false, message: "" }
              }
            }));
          }
        })
        .finally(() => setMoviesLoading(false));
    }
  }, [cubes, form.cube.value, form.date.value, jwt]);

  useEffect(() => {
    console.log(booking?.date_time?.substring(0, 10));
    if (
      booking.movie_id &&
      booking.cube_id &&
      booking.date_time &&
      booking.email &&
      booking.full_name &&
      booking.guest_count
    ) {
      setForm(prevForm => ({
        ...prevForm,
        movie: {
          value: `${booking.movie_id}`,
          error: { isError: false, message: "" }
        },
        fullName: {
          value: booking.full_name,
          error: { isError: false, message: "" }
        },
        email: {
          value: booking.email,
          error: { isError: false, message: "" }
        },
        cube: {
          value: booking.cube_id,
          error: { isError: false, message: "" }
        },
        numberOfGuests: {
          value: `${booking.guest_count}`,
          error: { isError: false, message: "" }
        },
        date: {
          value: booking.date_time.substring(0, 10),
          error: { isError: false, message: "" }
        },
        phoneNumber: {
          value: (booking.phone_number ?? "").substring(0, 10),
          error: { isError: false, message: "" }
        }
      }));
    }
  }, [booking]);

  const {
    fullName,
    email,
    cube,
    date,
    movie,
    numberOfGuests,
    phoneNumber
  } = form;
  const { loading, missing } = status;
  console.log(date.value);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="max-width-dialog-title">
          <span className={classes.detailsHeading}>Edit Booking</span>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} className={classes.bookingForm}>
            <div>
              <span className={classes.formHeading}>MOVIE INFO</span>
              <div className={classes.movieformContainer}>
                <FormControl variant="outlined">
                  <InputLabel>Select a Cube</InputLabel>
                  <Select
                    value={cube.value}
                    onChange={e => handleChange(e, null)}
                    onBlur={e => handleChange(e, null)}
                    name="cube"
                    error={cube.error.isError || missing.includes("cube")}
                    disabled={true}
                  >
                    {cubes.map((item, i) => {
                      return (
                        <MenuItem key={item?.id} value={item.id}>
                          {item?.cinema_name} Cube
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12}>
                    <FormControl
                      className={classes.fullWidthInput}
                      variant="outlined"
                    >
                      <InputLabel>Anticipated Date</InputLabel>
                      <Select
                        value={date.value}
                        onChange={e => handleChange(e, null)}
                        onBlur={e => handleChange(e, null)}
                        name="date"
                        error={date.error.isError || missing.includes("date")}
                      >
                        {generateFormattedDates(7).map(({ name, value }, i) => {
                          return (
                            <MenuItem key={i} value={value}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormControl variant="outlined">
                  <InputLabel>Select a movie</InputLabel>
                  <Select
                    value={movie.value}
                    onChange={e => handleChange(e, null)}
                    onBlur={e => handleChange(e, null)}
                    name="movie"
                    error={movie.error.isError || missing.includes("movie")}
                    disabled={moviesLoading}
                  >
                    {movies?.map(({ movie_name, movie_id }, i) => {
                      return (
                        <MenuItem key={i} value={movie_id.toString()}>
                          {movie_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      className={classes.fullWidthInput}
                      name="numberOfGuests"
                      label="Number of Guests"
                      type="number"
                      margin="normal"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={numberOfGuests.value}
                      error={
                        numberOfGuests.error.isError ||
                        missing.includes("numberOfGuests")
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div>
              <span className={classes.formHeading}>CONTACT INFO</span>
              <div className={classes.contactformContainer}>
                <TextField
                  className={classes.fullWidthInput}
                  name="fullName"
                  label="Full Name"
                  type="fullName"
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={fullName.value}
                  error={fullName.error.isError || missing.includes("fullName")}
                />
                <TextField
                  name="email"
                  label="Email"
                  type="email"
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={email.value}
                  error={email.error.isError || missing.includes("email")}
                />
                <TextField
                  name="phoneNumber"
                  label="Phone number"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={phoneNumber.value}
                  error={
                    phoneNumber.error.isError || missing.includes("phoneNumber")
                  }
                />
              </div>
            </div>
            <div className={classes.formAction}>
              <Button
                className={classes.formBtn}
                onClick={handleClose}
                color="primary"
                variant="outlined"
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                className={classes.formBtn}
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? "LOADING" : "PROCEED"}
              </Button>
            </div>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default EditBooking;
