import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import { Alert as MUIAlert } from "@material-ui/lab";
import types from "../../redux/types";

const Alert = () => {
  const { ALERT_CLOSE } = types;
  const { showAlert, message, severity } = useSelector(
    state => state.alert.alert
  );
  const dispatch = useDispatch();

  const handleAlertClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: ALERT_CLOSE });
  };

  return (
    showAlert && (
      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
      >
        <MUIAlert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </MUIAlert>
      </Snackbar>
    )
  );
};

export default Alert;
