export default [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name"
    },
    {
        id: "type",
        numeric: false,
        disablePadding: false,
        label: "Type"
    },
    {
        id: "flavour",
        numeric: false,
        disablePadding: false,
        label: "Available Flavors"
    },
    {
        id: "size",
        numeric: false,
        disablePadding: false,
        label: "Sizes"
    }
]