import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useStyles } from "../styles";
import empty from "../../../../assets/images/empty-concessions.png";
import "../bookings.css";
import { useState } from "react";
import BookingDetails from "../BookingDetails";
import { useDispatch, useSelector } from "react-redux";
import { applyEllipsis } from "../../../../utils/functions";
import types from "../../../../redux/types";
import Clip from "../../../../vectors/Clip";

const RedeemedBookings = ({ selectedCubeId, loading, data, loadBooking }) => {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const [booking, setBooking] = useState({});

  const { jwt } = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const columns = [
    {
      field: "booking_id",
      headerName: "ID",
      width: 300,
      renderCell: params => (
        <div className={classes.copy}>
          <p>{applyEllipsis(params.row.booking_id, 25)} </p>
          <button
            onClick={() => {
              navigator.clipboard.writeText(params.row.booking_id).then(
                function() {
                  dispatch({
                    type: ALERT_OPEN,
                    payload: {
                      severity: "success",
                      message: "Copied!"
                    }
                  });
                },
                function(err) {
                  console.error("Async: Could not copy text: ", err);
                }
              );
            }}
            className={classes.copyButton}
          >
            <Clip />
          </button>
        </div>
      )
    },
    {
      field: "full_name",
      headerName: "Name",
      width: 200
    },
    {
      field: "phone_number",
      headerName: "Phone number",
      width: 150
    },
    {
      field: "email",
      headerName: "Email",
      width: 150
    },
    {
      field: "movie_title",
      headerName: "Movie",
      width: 280,
      renderCell: params => {
        return (
          <div className={classes.movieCol}>
            {/* <img
              className={classes.movieImg}
              alt={params.row.movieTitle}
              src={params.row.image_url || empty}
              width={34}
              height={34}
            /> */}
            <p>{params.row.movie_title}</p>
          </div>
        );
      }
    },
    {
      field: "guest_count",
      headerName: "Guest",
      width: 120
    },
    {
      field: "payment_amount",
      headerName: "Amount",
      width: 130
    },
    {
      field: "date_time",
      headerName: "Date",
      width: 250
    },
    {
      field: "view",
      headerName: "View Details",
      width: 200,
      renderCell: params => (
        <button
          onClick={() => {
            setShowDetails(true);
            setBooking(params.row);
          }}
          className={classes.view}
        >
          View more
        </button>
      )
    }
  ];

  return (
    <div>
      <BookingDetails
        booking={booking}
        open={showDetails}
        handleClose={() => setShowDetails(false)}
      />
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={15}
        rowHeight={50}
        headerHeight={60}
        disableColumnMenu={true}
        autoHeight={true}
        className="bookings-table"
        loading={loading}
      />
    </div>
  );
};

export default RedeemedBookings;
