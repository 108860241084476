import React, { useState, useEffect } from "react";
import api from "../../../api/hero-banners-api";
import EnhancedTable from "../../../components/TableList";
import parseUserList from "../../../components/functions/parseList";
import useStyles from "./styles";
import headCells from "./headCells";
import { Button, Snackbar, Grid, Tooltip } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { connect, useDispatch } from "react-redux";
import AddHeroBanner from "../../../containers/HeroBanner/AddHeroBanner/index";
import { CircularLoad } from "../../../components/Loader";
import DeleteHeroBanner from "../../../containers/HeroBanner/DeleteHeroBanner";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { heroBannersDummyData } from "./heroBannersTable";
import Info from "../../../vectors/Info";
import "./hero-banners.css";
import Delete from "../../../vectors/Delete";
import types from "../../../redux/types";
import { getRequestError, slugify } from "../../../utils/functions";
import { Edit } from "@material-ui/icons";

const HeroBannersList = ({ user }) => {
  const classes = useStyles();
  const [openHeroBannerModal, setOpenHeroBannerModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [selectedHeroBannerId, setSelectedHeroBannerId] = useState({});
  const [selectedHeroBanner, setSelectedHeroBanner] = useState({});
  const [openDelete, setOpenDelete] = useState(false);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const handleOpenClose = (setter, value) => {
    return setter(value);
  };

  const fetchHeroBanners = _page => {
    setLoading(true);
    api
      .getList(`Bearer ${user.jwt}`, _page + 1)
      .then(res => {
        const { banners: bannersList = [], totalCount = 0 } = res.data.data;
        setFiltered(
          bannersList.map((item, index) => ({
            title: item?.title ?? "_",
            description: item?.description ?? "_",
            destinationUrl:
              item?.rosetta_movie_id && item?.title
                ? `https://filmhouseng.com/buy-ticket/${slugify(item.title)}/${
                    item.rosetta_movie_id
                  }`
                : "_",
            image: item?.web_image || item?.mobile_image || "",
            id: item?.id ?? index,
            youtubeTrailerURL: item?.youtube_trailer,
            movieID: item?.movie_db_info_id,
            webImage: item?.web_image,
            mobileImage: item?.mobile_image,
            thumbnailImage: item?.thumbnail,
            rosettaId: item?.rosetta_movie_id ?? "_"
          }))
        );
        setTotalData(totalCount);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchHeroBanners(page);
  }, [page]);

  const handleAdd = payload => {
    return api
      .addHeroBanner(`Bearer ${user.jwt}`, payload)
      .then(res => {
        setOpenHeroBannerModal(false);
        fetchHeroBanners(0);
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  const handleEdit = payload => {
    return api
      .editHeroBanner(`Bearer ${user.jwt}`, payload, selectedHeroBanner.data.id)
      .then(res => {
        setOpenHeroBannerModal(false);
        fetchHeroBanners(0);
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  const handleDelete = id => {
    return api
      .deleteHeroBanner(`Bearer ${user.jwt}`, id)
      .then(res => {
        setOpenDelete(false);
        fetchHeroBanners(0);
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  const handleOpenDeleteModal = (e, id) => {
    e.preventDefault();
    setSelectedHeroBannerId(id);
    setOpenDelete(true);
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 318,
      renderCell: params => {
        return (
          <div className={classes.title}>
            <img
              className={classes.image}
              alt={params.row.title}
              src={params.row.image}
              width={120}
              height={67}
            />
            <span>{params.row.title}</span>
          </div>
        );
      }
    },
    {
      field: "description",
      headerName: "Description",
      width: 317,
      renderCell: params => {
        return (
          <div className={classes.ellipsisContainer}>
            <span className={classes.ellipsis}>{params.row.description}</span>
            <Tooltip
              classes={{
                tooltip: classes.tooltip
              }}
              title={params.row.description}
              arrow
            >
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  display: "flex"
                }}
              >
                <Info />
              </div>
            </Tooltip>
          </div>
        );
      }
    },
    {
      field: "destinationUrl",
      headerName: "Destination Url",
      width: 317,
      renderCell: params => {
        return (
          <div className={classes.ellipsisContainer}>
            <span className={classes.ellipsis}>
              {params.row.destinationUrl}
            </span>
            <Tooltip
              classes={{
                tooltip: classes.tooltip
                // popper: { boxShadow: "none" }
              }}
              title={params.row.destinationUrl}
              arrow
            >
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  display: "flex"
                }}
              >
                <Info />
              </div>
            </Tooltip>
          </div>
        );
      }
    },
    {
      field: "id",
      headerName: "Edit",
      width: 100,
      renderCell: params => {
        return (
          <Edit
            className={classes.editButton}
            onClick={() => {
              setSelectedHeroBanner({ data: params.row, type: "edit" });
              setOpenHeroBannerModal(true);
            }}
          />
        );
      }
    },
    {
      field: "movieID",
      headerName: "Delete",
      width: 110,
      renderCell: params => {
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={e => handleOpenDeleteModal(e, params.row.id)}
          >
            Delete
          </Button>
        );
      }
    }
  ];

  return (
    <div>
      <div className={classes.head}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={6}></Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Grid justify="flex-end" container>
              {user.details.userType.toLowerCase() === "superadmin" ? (
                <Button
                  onClick={() => {
                    setSelectedHeroBanner({ data: null, type: "add" });
                    setOpenHeroBannerModal(true);
                  }}
                  className={classes.addBannerBtn}
                  variant="outlined"
                  color="primary"
                >
                  + ADD BANNER{" "}
                </Button>
              ) : null}
              <CSVLink data={filtered} filename={"hero-banners.csv"}>
                <Button variant="contained" color="primary">
                  <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
                </Button>
              </CSVLink>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <AddHeroBanner
        jwt={user.jwt}
        open={openHeroBannerModal}
        handleClose={() => handleOpenClose(setOpenHeroBannerModal, false)}
        add={handleAdd}
        edit={handleEdit}
        type={selectedHeroBanner.type}
        data={selectedHeroBanner.data}
      />
      <DeleteHeroBanner
        jwt={user.jwt}
        recordId={selectedHeroBannerId}
        open={openDelete}
        handleClose={() => handleOpenClose(setOpenDelete, false)}
        handleDelete={handleDelete}
      />
      {/* {loading ? (
        <div>
          <CircularLoad show={true} />
        </div>
      ) : ( */}
      <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
        <DataGrid
          rows={filtered}
          columns={columns}
          pageSize={10}
          rowHeight={93}
          headerHeight={80}
          disableColumnMenu={true}
          autoHeight={true}
          page={page}
          rowCount={totalData}
          onPageChange={page => {
            setPage(page);
          }}
          paginationMode="server"
          loading={loading}
          className="hero-banners-table"
        />
      </div>
      {/* )} */}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user.user
});
export default connect(mapStateToProps)(HeroBannersList);
