import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({ signedIn, component, ...routeProps }) => {
  const Component = component;
  return (
    <Route
      exact
      render={() => (!signedIn ? <Component /> : <Redirect to="/dashboard" />)}
      {...routeProps}
    />
  );
};

export default AuthRoute;
