import { Button } from "@material-ui/core";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { useStyles } from "./styles";
import React, { useState } from "react";
import PaperedInput from "../../../components/Inputs/PaperedInput";
import { DataGrid } from "@material-ui/data-grid";
import TransactionHistory from "./TransactionHistory";
import AwardUserPoints from "./AwardUser";
import AddUserTier from "./AddUserTier";

const dummyData = [
  {
    id: 12345,
    full_name: "Emmanuel Adeshina",
    email: "useremail.example@email.com",
    lifetime_points: 43,
    available_points: 3,
    view: "",
    add: "",
    change: ""
  },
  {
    id: 12346,
    full_name: "Mide Alongea",
    email: "useremail.example@email.com",
    lifetime_points: 43,
    available_points: 3,
    view: "",
    add: "",
    change: ""
  },
  {
    id: 12347,
    full_name: "Ojo Kingsley",
    email: "useremail.example@email.com",
    lifetime_points: 43,
    available_points: 3,
    view: "",
    add: "",
    change: ""
  },
  {
    id: 12349,
    full_name: "Olawale Chukudi",
    email: "useremail.example@email.com",
    lifetime_points: 423,
    available_points: 33,
    view: "",
    add: "",
    change: ""
  },
  {
    id: 12340,
    full_name: "Cole Nonso",
    email: "useremail.example@email.com",
    lifetime_points: 43,
    available_points: 31,
    view: "",
    add: "",
    change: ""
  },
  {
    id: 12338,
    full_name: "Adewole Oluwaseunfunmi",
    email: "useremail.example@email.com",
    lifetime_points: 52,
    available_points: 32,
    view: "",
    add: "",
    change: ""
  }
];

const PointsSystem = () => {
  const [activeTab, setActiveTab] = useState("fan");
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [isOpenAwardPoints, setIsOpenAwardPoints] = useState(false);
  const [isOpenAddUserTier, setIsOpenAddUserTier] = useState(false);

  const classes = useStyles();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100
    },
    {
      field: "full_name",
      headerName: "Name",
      width: 200
    },
    {
      field: "email",
      headerName: "Email",
      width: 200
    },
    {
      field: "lifetime_points",
      headerName: "Lifetime points",
      width: 200
    },
    {
      field: "available_points",
      headerName: "Available points ",
      width: 200
    },
    {
      field: "add",
      headerName: "Add point",
      width: 150,
      renderCell: params => (
        <button
          onClick={() => {
            setIsOpenAwardPoints(true);
          }}
          className={classes.add}
        >
          Add point
        </button>
      )
    },
    {
      field: "change",
      headerName: "Change Tier",
      width: 150,
      renderCell: params => (
        <button
          onClick={() => {
            setIsOpenAddUserTier(true);
          }}
          className={classes.change}
        >
          Change Tier
        </button>
      )
    },
    {
      field: "view",
      headerName: "View History",
      width: 150,
      renderCell: params => (
        <button
          onClick={() => {
            setIsOpenHistory(true);
          }}
          className={classes.view}
        >
          View History
        </button>
      )
    }
  ];

  return (
    <>
      <TransactionHistory
        handleClose={() => setIsOpenHistory(false)}
        open={isOpenHistory}
      />
      <AwardUserPoints
        handleClose={() => setIsOpenAwardPoints(false)}
        open={isOpenAwardPoints}
      />
      <AddUserTier
        handleClose={() => setIsOpenAddUserTier(false)}
        open={isOpenAddUserTier}
      />
      <div className={classes.headerContainer}>
        <div className={classes.tabsContainer}>
          {["Fan", "Buff", "Fanatic"].map(item => (
            <button
              onClick={() => setActiveTab(item.toLowerCase())}
              style={{
                backgroundColor:
                  activeTab === item.toLowerCase() ? "#3C2150" : "transparent",
                color: activeTab === item.toLowerCase() ? "white" : "#656569"
              }}
              className={classes.tab}
              key={item}
            >
              {item}
            </button>
          ))}
        </div>
        <CSVLink data={[]} filename={"points-system.csv"}>
          <Button variant="contained" color="primary">
            <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
          </Button>
        </CSVLink>
      </div>
      <div className={classes.search}>
        <PaperedInput
          // onChange={e => this.setState({ searchInput: e.target.value })}
          placeholder="Search for User"
        />
      </div>
      <DataGrid
        rows={dummyData}
        columns={columns}
        pageSize={15}
        rowHeight={50}
        headerHeight={60}
        disableColumnMenu={true}
        autoHeight={true}
        loading={false}
      />
    </>
  );
};

export default PointsSystem;
