import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  movieContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2)
  },
  dialogContainer: {
    backgroundColor: "#1C1C22"
  },
  dialogDetails: {
    marginTop: "22px",
    marginBottom: "22px"
  },
  emptyMovies: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#FFFFFF"
  }
}));

export default useStyles;
