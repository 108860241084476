import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from "react-router-dom";

import useStyles from './styles'
import DashboardContainer from '../../components/Dashboard';
import routes from './routes'
import LineLoad from '../../components/Loader';


const Dashboard = ({ loading }) => {
    const classes = useStyles();

    return (
        <div className={classes.interim}>
            <DashboardContainer>
            <LineLoad show={loading} />
                <Switch>
                    {routes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            />
                        );
                    })}
                    <Redirect to={routes[0].path} />
                </Switch>
            </DashboardContainer>
        </div>
    );
}

const mapStateToProps = state => ({
    loading: state.general.loading
})
export default connect(mapStateToProps)(Dashboard)
