import moment from "moment";
import { split } from "lodash";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const generateMonths = arrayOfMonths => {
  const generatedMonths = arrayOfMonths.map(period => {
    //period shoud have a format of YYYY-MM
    const monthNum = parseInt(split(period, "-")[1]) - 1;
    return months[monthNum] || period;
  });
  return generatedMonths;
};

export default function lastXMonths(numberOfPrevMonths) {
  let lastSevenMonths = [];
  const currentMonth = moment().month();
  for (let i = currentMonth; i > currentMonth - numberOfPrevMonths; i--) {
    if (i < 0) {
      lastSevenMonths.push(months[months.length + i]);
    } else {
      lastSevenMonths.push(months[i]);
    }
  }
  return lastSevenMonths;
}
