import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import { reverse } from "lodash";
// import EditTierSlideIn from "./EditTierSlideIn";
// import sheet from "./styles";
import api from "../../api/subscriptions";
import {
  Button,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogActions,
  Grid
} from "@material-ui/core";
// import userListParser from "../../components/functions/parseList";
// import sorting from "../../components/functions/sorting";
// import { CircularLoad } from "../../components/Loader";
import { DataGrid } from "@material-ui/data-grid";
import { Alert } from "@material-ui/lab";
import { getRequestError } from "../../utils/functions";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";

const TiersListPage = ({ jwt }) => {
  const [error, setError] = useState({ isError: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [tiersList, setTiersList] = useState([]);

  const columns = [
    { field: "product_name", headerName: "Subscription Name", width: 480 },
    {
      field: "product_price",
      headerName: "Subscription Fee",
      width: 350
    },
    {
      field: "product_id",
      headerName: "Subscription ID",
      width: 240
    }
  ];

  useEffect(() => {
    setLoading(true);
    api
      .fetchSubscriptions()
      .then(res => {
        setTiersList(
          res.data.data.map(
            ({ product_id = "", product_name = "", product_price = "" }) => ({
              product_id,
              product_name,
              product_price: `₦${product_price}`,
              id: product_id
            })
          )
        );
        console.log(res.data.data);
        setLoading(false);
      })
      .catch(e => setError({ isError: false, message: getRequestError(e) }));
  }, []);

  // const { classes } = sheet;

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={8}></Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <CSVLink data={tiersList} filename={"subscription-tiers.csv"}>
            <Button variant="contained" color="primary">
              <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
            </Button>
          </CSVLink>
        </Grid>
      </Grid>
      <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
        {error.isError ? (
          <Alert severity="error">{error.message}</Alert>
        ) : (
          <DataGrid
            rows={tiersList}
            columns={columns}
            pageSize={10}
            rowHeight={50}
            headerHeight={60}
            disableColumnMenu={true}
            autoHeight={true}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  jwt: state.user.user.jwt
});
export default connect(mapStateToProps)(TiersListPage);
