import { combineReducers } from "redux";
import location from "./location";
import loading from "./loading";

const combinedReducer = combineReducers({
    location,
    loading
});

export default combinedReducer;
