import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField
} from "@material-ui/core";
import { useStyles } from "./styles";
import validator from "validator";
import types from "../../../redux/types";
import {
  generateFormattedDates,
  getRequestError
} from "../../../utils/functions";
import { useEffect } from "react";
import moviesAPi from "../../../api/movie-entry-api";
import cubesApi from "../../../api/cube";
import { useDispatch, useSelector } from "react-redux";
import { FLAT_FEE, GUEST_FEE, MINIMUM_GUEST_NUMBER } from "./data";

const initialState = {
  movie: { value: "", error: { isError: false, message: "" } },
  firstName: { value: "", error: { isError: false, message: "" } },
  lastName: { value: "", error: { isError: false, message: "" } },
  email: { value: "", error: { isError: false, message: "" } },
  date: {
    value: generateFormattedDates(7)[0].value,
    error: { isError: false, message: "" }
  },
  time: {
    value: "",
    error: { isError: false, message: "" }
  },
  phoneNumber: {
    value: "",
    error: { isError: false, message: "" }
  },
  numberOfGuests: { value: "", error: { isError: false, message: "" } }
};
const CreateBooking = ({ open, handleClose, cubes }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    ...initialState,
    cube: {
      value: cubes[0]?.id ?? "",
      error: { isError: false, message: "" }
    }
  });
  const [status, setStatus] = useState({
    loading: false,
    required: [
      "firstName",
      "lastName",
      "email",
      "cube",
      "date",
      "time",
      "movie",
      "numberOfGuests",
      "phoneNumber"
    ],
    missing: []
  });
  const [movies, setMovies] = useState([]);
  const [slots, setSlots] = useState([]);
  const [moviesLoading, setMoviesLoading] = useState(false);
  const [slotsLoading, setSlotsLoading] = useState(false);

  const { jwt } = useSelector(state => state.user.user);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const handleChange = ({ target }, _validator) => {
    const { name, value } = target;
    const checkedValidator = _validator
      ? _validator
      : value =>
          validator.isEmpty(value)
            ? { isError: true, message: "Field is empty" }
            : { isError: false, message: "" };
    if (!validator.isEmpty(value)) {
      setStatus(prevStatus => ({
        ...prevStatus,
        missing: prevStatus.missing.filter(item => name !== item)
      }));
    }
    setForm(prevForm => ({
      ...prevForm,
      [name]: { value: value, error: checkedValidator(value) }
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      cube,
      movie,
      numberOfGuests,
      time,
      phoneNumber
    } = form;

    let missing = new Set();
    status.required.forEach(item => {
      if (validator.isEmpty(form[item].value)) {
        missing.add(item);
      }
    });
    setStatus(prevStatus => ({ ...prevStatus, missing: [...missing] }));
    if ([...missing].length) return;
    setStatus(prevStatus => ({ ...prevStatus, loading: true }));
    const cubeCinemaId = cubes?.find(item => item.id === form.cube.value)
      ?.rosetta_cinema_id;
    const amount =
      FLAT_FEE +
      (Number(numberOfGuests.value) - MINIMUM_GUEST_NUMBER) * GUEST_FEE;
    cubesApi
      .createBookings(jwt, {
        cinema_id: cubeCinemaId.toString() ?? "",
        date_time: time.value,
        guest_count: numberOfGuests.value,
        full_name: `${firstName.value} ${lastName.value}`,
        email: email.value,
        has_corkage: "false",
        movie_id: movie.value,
        payment_amount: `${amount}`,
        cube_id: cube.value,
        phone_number: phoneNumber.value
      })
      .then(res => {
        console.log(res);
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "success",
            message: res.data.message ?? "Successful"
          }
        });
        window.location.reload();
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
      })
      .finally(() =>
        setStatus(prevStatus => ({ ...prevStatus, loading: false }))
      );
  };

  useEffect(() => {
    if (cubes.length) {
      setForm(prevForm => ({
        ...prevForm,
        cube: {
          value: cubes[0].id,
          error: { isError: false, message: "" }
        }
      }));
    }
  }, [cubes]);

  useEffect(() => {
    if (form.cube.value && form.date.value && cubes.length) {
      const cubeCinemaId = cubes.find(item => item.id === form.cube.value)
        ?.rosetta_cinema_id;
      setMoviesLoading(true);
      cubesApi
        .getCinemaMovies(cubeCinemaId, form.date.value, form.date.value, jwt)
        .then(res => {
          setMovies(res.data.data);
          if (res.data.data.length) {
            setForm(prevForm => ({
              ...prevForm,
              movie: {
                value: res.data.data[0].movie_id.toString(),
                error: { isError: false, message: "" }
              }
            }));
          }
        })
        .finally(() => setMoviesLoading(false));
    }
  }, [cubes, form.cube.value, form.date.value, jwt]);

  useEffect(() => {
    if (form.cube.value && form.date.value) {
      setSlotsLoading(true);
      cubesApi
        .getSlots(form.cube.value, form.date.value)
        .then(res => {
          setSlots(res.data.data ?? []);
          if (res.data.data?.length) {
            setForm(prevForm => ({
              ...prevForm,
              time: {
                value: res.data.data[0].date_time,
                error: { isError: false, message: "" }
              }
            }));
          }
        })
        .finally(() => setSlotsLoading(false));
    }
  }, [form.cube.value, form.date.value]);

  const {
    firstName,
    lastName,
    email,
    cube,
    date,
    movie,
    numberOfGuests,
    time,
    phoneNumber
  } = form;
  const { loading, missing } = status;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="max-width-dialog-title">
          <span className={classes.detailsHeading}>Cube Booking</span>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} className={classes.bookingForm}>
            <div>
              <span className={classes.formHeading}>MOVIE INFO</span>
              <div className={classes.movieformContainer}>
                <FormControl variant="outlined">
                  <InputLabel>Select a Cube</InputLabel>
                  <Select
                    value={cube.value}
                    onChange={e => handleChange(e, null)}
                    onBlur={e => handleChange(e, null)}
                    name="cube"
                    error={cube.error.isError || missing.includes("cube")}
                  >
                    {cubes.map((item, i) => {
                      return (
                        <MenuItem key={item?.id} value={item.id}>
                          {item?.cinema_name} Cube
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={6}>
                    <FormControl
                      className={classes.fullWidthInput}
                      variant="outlined"
                    >
                      <InputLabel>Anticipated Date</InputLabel>
                      <Select
                        value={date.value}
                        onChange={e => handleChange(e, null)}
                        onBlur={e => handleChange(e, null)}
                        name="date"
                        error={date.error.isError || missing.includes("date")}
                      >
                        {generateFormattedDates(7).map(({ name, value }, i) => {
                          return (
                            <MenuItem key={i} value={value}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      className={classes.fullWidthInput}
                      variant="outlined"
                    >
                      <InputLabel>Start Time</InputLabel>
                      <Select
                        value={time.value}
                        onChange={e => handleChange(e, null)}
                        onBlur={e => handleChange(e, null)}
                        name="time"
                        error={time.error.isError || missing.includes("time")}
                        disabled={slotsLoading}
                      >
                        {slots.map(({ label, date_time }, i) => {
                          return (
                            <MenuItem key={i} value={date_time}>
                              {label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormControl variant="outlined">
                  <InputLabel>Select a movie</InputLabel>
                  <Select
                    value={movie.value}
                    onChange={e => handleChange(e, null)}
                    onBlur={e => handleChange(e, null)}
                    name="movie"
                    error={movie.error.isError || missing.includes("movie")}
                    disabled={moviesLoading}
                  >
                    {movies?.map(({ movie_name, movie_id }, i) => {
                      return (
                        <MenuItem key={i} value={movie_id.toString()}>
                          {movie_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      className={classes.fullWidthInput}
                      name="numberOfGuests"
                      label="Number of Guests"
                      type="text"
                      margin="normal"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={numberOfGuests.value}
                      error={
                        numberOfGuests.error.isError ||
                        missing.includes("numberOfGuests")
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div>
              <span className={classes.formHeading}>CONTACT INFO</span>
              <div className={classes.contactformContainer}>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      className={classes.fullWidthInput}
                      name="firstName"
                      label="First Name"
                      type="text"
                      margin="normal"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={firstName.value}
                      error={
                        firstName.error.isError || missing.includes("firstName")
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      className={classes.fullWidthInput}
                      name="lastName"
                      label="Last Name"
                      type="lastName"
                      margin="normal"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={lastName.value}
                      error={
                        lastName.error.isError || missing.includes("lastName")
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      className={classes.fullWidthInput}
                      name="email"
                      label="Email"
                      type="email"
                      margin="normal"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={email.value}
                      error={email.error.isError || missing.includes("email")}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      className={classes.fullWidthInput}
                      name="phoneNumber"
                      label="Phone number"
                      type="text"
                      margin="normal"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={phoneNumber.value}
                      error={
                        phoneNumber.error.isError ||
                        missing.includes("phoneNumber")
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className={classes.formAction}>
              <Button
                className={classes.formBtn}
                onClick={handleClose}
                color="primary"
                variant="outlined"
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                className={classes.formBtn}
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? "LOADING" : "PROCEED"}
              </Button>
            </div>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateBooking;
