import React from "react";
import { Switch, Redirect } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import routes from "./routes";

const ContainerOutlet = ({ signedIn }) => (
  <Switch>
    {routes.map(({ isPrivate, ...routeProps }, index) =>
      isPrivate ? (
        <PrivateRoute key={index} signedIn={signedIn} {...routeProps} />
      ) : (
        <AuthRoute key={index} {...routeProps} signedIn={signedIn} />
      )
    )}
    <Redirect to="/" />
  </Switch>
);

export default ContainerOutlet;
