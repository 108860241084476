import React, { useState } from "react";
import DateSelector from "../../../../components/Inputs/DateSelector";
import DropDown from "../../../../components/Inputs/Dropdown";
import useStyles from "../MainDashboard/MainDashboard.styles";
import moment from "moment";
import GraphBreakdown from "../GraphBreakdown/GraphBreakdown";
import { useEffect } from "react";
import statsApi from "../../../../api/stats-api";
import { getRequestError } from "../../../../utils/functions";
import Chart from "../../../../components/Chart";
import generateChart from "../utils/graphs";

const SalesChart = ({ jwt }) => {
  const [activeFilter, setActiveFilter] = useState("all");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [dateFilter, setDatefilter] = useState(moment().format("YYYY-MM-DD"));
  const [isLoadingSalesBreakdown, setIsLoadingSalesBreakdown] = useState(false);
  const [salesBreakDown, setSalesBreakdown] = useState([]);
  const {
    salesOverview,
    salesOverviewMain,
    overviewTab,
    salesOverviewFilter,
    filterContainer,
    mainHeading
  } = useStyles({
    active:
      activeFilter === "web"
        ? "first"
        : activeFilter === "mobile"
        ? "second"
        : "all"
  });

  useEffect(() => {
    setIsLoadingSalesBreakdown(true);
    statsApi
      .getSalesBreakdown(jwt, startDate, endDate)
      .then(res => {
        setSalesBreakdown(res.data.data);
        setIsLoadingSalesBreakdown(false);
      })
      .catch(e => getRequestError(e));
  }, [endDate, jwt, startDate]);

  const salesBreakdownChart = salesBreakDown?.revenue?.data?.length
    ? generateChart(
        salesBreakDown?.sales?.data?.map(item => [
          item?.web_total,
          item?.mobile_total,
          item?.web_tickets,
          item?.web_concessions,
          item?.web_subscriptions,
          item?.mobile_tickets,
          item?.mobile_concessions,
          item?.mobile_subscriptions
        ]),
        [
          "Web sales",
          "Mobile sales",
          "Web tickets",
          "Web concessions",
          "Web subscriptions",
          "Mobile tickets",
          "Mobile concessions",
          "Mobile subscriptions"
        ],
        salesBreakDown?.revenue?.data?.map(
          (item, i) => `${item?.date?.substring(0, 10)}`
        )
      )
    : null;

  const salesRevenueChart = salesBreakDown?.revenue?.data?.length
    ? generateChart(
        salesBreakDown?.revenue?.data?.map(item => [
          item?.web_total,
          item?.mobile_total,
          item?.web_tickets,
          item?.web_concessions,
          item?.web_subscriptions,
          item?.mobile_tickets,
          item?.mobile_concessions,
          item?.mobile_subscriptions
        ]),
        [
          "Web sales (₦)",
          "Mobile sales (₦)",
          "Web tickets (₦)",
          "Web concessions (₦)",
          "Web subscriptions (₦)",
          "Mobile tickets (₦)",
          "Mobile concessions (₦)",
          "Mobile subscriptions (₦)"
        ],
        salesBreakDown?.revenue?.data?.map(
          (item, i) => `${item?.date?.substring(0, 10)}`
        )
      )
    : null;

  console.log(salesBreakDown);

  return (
    <>
      <div className={salesOverview}>
        <div className={salesOverviewMain}>
          <h1 className={`${mainHeading} medium`}>Sales Overview</h1>
          <article className={overviewTab}>
            <button className="all" onClick={() => setActiveFilter("all")}>
              All
            </button>
            <button className="first" onClick={() => setActiveFilter("web")}>
              <span className="dot first"></span>Web App
            </button>
            <button
              className="second"
              onClick={() => setActiveFilter("mobile")}
            >
              <span className="dot second"></span>Mobile App
            </button>
          </article>
        </div>
        <div className={salesOverviewFilter}>
          <DropDown
            menuItems={[
              {
                name: "Last 7 days",
                value: moment()
                  .subtract(7, "days")
                  .format("YYYY-MM-DD")
              },
              {
                name: "Yesterday",
                value: moment()
                  .subtract(1, "days")
                  .format("YYYY-MM-DD")
              },
              {
                name: "Today",
                value: moment().format("YYYY-MM-DD")
              }
            ]}
            value={dateFilter}
            onChange={e => {
              setDatefilter(e.target.value);
              setStartDate(e.target.value);
              setEndDate(moment().format("YYYY-MM-DD"));
            }}
          />
        </div>
        <div className={filterContainer}>
          <div className={salesOverviewFilter}>
            <DateSelector
              value={startDate}
              onChange={e => setStartDate(moment(e).format("YYYY-MM-DD"))}
            />
          </div>
          <div className={salesOverviewFilter}>
            <DateSelector
              value={endDate}
              onChange={e => setEndDate(moment(e).format("YYYY-MM-DD"))}
            />
          </div>
        </div>
      </div>
      <GraphBreakdown
        chart={
          <Chart
            data={
              salesRevenueChart
                ? {
                    labels: salesRevenueChart?.labels,
                    datasets: salesRevenueChart?.datasets
                      .filter(
                        item =>
                          item.label === "Web sales (₦)" ||
                          item.label === "Mobile sales (₦)"
                      )
                      .filter(item =>
                        activeFilter === "all"
                          ? true
                          : item?.label
                              .toLowerCase()
                              .includes(activeFilter.toLowerCase())
                      )
                  }
                : null
            }
            type="tickets"
            title=""
            height={120}
            xLabel="Days"
            yLabel="Revenue (₦)"
            hasFullReport={false}
            loading={isLoadingSalesBreakdown}
            labelCb={(index, label) =>
              label.toLowerCase().includes("web")
                ? [
                    `Web tickets (₦): ${
                      salesRevenueChart.datasets.find(
                        item => item?.label === "Web tickets (₦)"
                      )?.data[index]
                    }`,
                    `Web concessions (₦): ${
                      salesRevenueChart.datasets.find(
                        item => item?.label === "Web concessions (₦)"
                      )?.data[index]
                    }`,
                    `Web subscriptions (₦): ${
                      salesRevenueChart.datasets.find(
                        item => item?.label === "Web subscriptions (₦)"
                      )?.data[index]
                    }`
                  ]
                : label.toLowerCase().includes("mobile")
                ? [
                    `Mobile tickets (₦): ${
                      salesRevenueChart.datasets.find(
                        item => item?.label === "Mobile tickets (₦)"
                      )?.data[index]
                    }`,
                    `Mobile concessions (₦): ${
                      salesRevenueChart.datasets.find(
                        item => item?.label === "Mobile concessions (₦)"
                      )?.data[index]
                    }`,
                    `Mobile subscriptions (₦): ${
                      salesRevenueChart.datasets.find(
                        item => item?.label === "Mobile subscriptions (₦)"
                      )?.data[index]
                    }`
                  ]
                : ""
            }
          />
        }
        breakdown={salesBreakDown?.revenue?.data}
        breakdownOne={`₦ ${
          activeFilter === "all"
            ? salesBreakDown?.revenue?.subscriptionTotal?.toLocaleString()
            : activeFilter === "mobile"
            ? salesBreakDown?.revenue?.mobileSubscriptionTotal?.toLocaleString()
            : salesBreakDown?.revenue?.webSubscriptionTotal?.toLocaleString() ||
              0
        }`}
        breakdownTwo={`₦ ${
          activeFilter === "all"
            ? salesBreakDown?.revenue?.concessionTotal?.toLocaleString()
            : activeFilter === "mobile"
            ? salesBreakDown?.revenue?.mobileConcessionTotal?.toLocaleString()
            : salesBreakDown?.revenue?.webConcessionTotal?.toLocaleString() || 0
        }`}
        breakdownThree={`₦ ${
          activeFilter === "all"
            ? salesBreakDown?.revenue?.ticketTotal?.toLocaleString()
            : activeFilter === "mobile"
            ? salesBreakDown?.revenue?.mobileTicketTotal?.toLocaleString()
            : salesBreakDown?.revenue?.webTicketTotal?.toLocaleString() || 0
        }`}
        subTitle="Total Online Revenue"
        title="Sales Revenue breakdown"
        totalBreakdown={`₦ ${
          activeFilter === "all"
            ? salesBreakDown?.revenue?.total?.toLocaleString()
            : activeFilter === "mobile"
            ? salesBreakDown?.revenue?.mobileTotal?.toLocaleString()
            : salesBreakDown?.revenue?.webTotal?.toLocaleString() || 0
        }`}
        subscriptionTitle="Subscription Revenue"
        concessionTitle="Concession Revenue"
        ticketTitle="Ticket Revenue Revenue"
        activeFilter={activeFilter}
        isLoading={isLoadingSalesBreakdown}
        extra="₦"
      />
      <GraphBreakdown
        extra=""
        chart={
          <Chart
            data={
              salesBreakdownChart
                ? {
                    labels: salesBreakdownChart?.labels,
                    datasets: salesBreakdownChart?.datasets
                      .filter(
                        item =>
                          item.label === "Web sales" ||
                          item.label === "Mobile sales"
                      )
                      .filter(item =>
                        activeFilter === "all"
                          ? true
                          : item?.label
                              .toLowerCase()
                              .includes(activeFilter.toLowerCase())
                      )
                  }
                : null
            }
            type="tickets"
            title=""
            height={120}
            xLabel="Days"
            yLabel="Amount"
            hasFullReport={false}
            loading={isLoadingSalesBreakdown}
            labelCb={(index, label) =>
              label.toLowerCase().includes("web")
                ? [
                    `Web tickets: ${
                      salesBreakdownChart.datasets.find(
                        item => item?.label === "Web tickets"
                      )?.data[index]
                    }`,
                    `Web concessions: ${
                      salesBreakdownChart.datasets.find(
                        item => item?.label === "Web concessions"
                      )?.data[index]
                    }`,
                    `Web subscriptions: ${
                      salesBreakdownChart.datasets.find(
                        item => item?.label === "Web subscriptions"
                      )?.data[index]
                    }`
                  ]
                : label.toLowerCase().includes("mobile")
                ? [
                    `Mobile tickets: ${
                      salesBreakdownChart.datasets.find(
                        item => item?.label === "Mobile tickets"
                      )?.data[index]
                    }`,
                    `Mobile concessions: ${
                      salesBreakdownChart.datasets.find(
                        item => item?.label === "Mobile concessions"
                      )?.data[index]
                    }`,
                    `Mobile subscriptions: ${
                      salesBreakdownChart.datasets.find(
                        item => item?.label === "Mobile subscriptions"
                      )?.data[index]
                    }`
                  ]
                : ""
            }
          />
        }
        breakdown={salesBreakDown?.sales?.data}
        breakdownOne={`${
          activeFilter === "all"
            ? salesBreakDown?.sales?.subscriptionTotal?.toLocaleString()
            : activeFilter === "mobile"
            ? salesBreakDown?.sales?.mobileSubscriptionTotal?.toLocaleString()
            : salesBreakDown?.sales?.webSubscriptionTotal?.toLocaleString() || 0
        }`}
        breakdownTwo={`${
          activeFilter === "all"
            ? salesBreakDown?.sales?.concessionTotal?.toLocaleString()
            : activeFilter === "mobile"
            ? salesBreakDown?.sales?.mobileConcessionTotal?.toLocaleString()
            : salesBreakDown?.sales?.webConcessionTotal?.toLocaleString() || 0
        }`}
        breakdownThree={`${
          activeFilter === "all"
            ? salesBreakDown?.sales?.ticketTotal?.toLocaleString()
            : activeFilter === "mobile"
            ? salesBreakDown?.sales?.mobileTicketTotal?.toLocaleString()
            : salesBreakDown?.sales?.webTicketTotal?.toLocaleString() || 0
        }`}
        subTitle="No. of Online Revenue"
        title="Sales breakdown"
        totalBreakdown={`${
          activeFilter === "all"
            ? salesBreakDown?.sales?.total?.toLocaleString()
            : activeFilter === "mobile"
            ? salesBreakDown?.sales?.mobileTotal?.toLocaleString()
            : salesBreakDown?.sales?.webTotal?.toLocaleString() || 0
        }`}
        subscriptionTitle="No. of Subscription"
        concessionTitle="No. of Concession"
        ticketTitle="No. Ticket Revenue"
        activeFilter={activeFilter}
        isLoading={isLoadingSalesBreakdown}
      />
    </>
  );
};

export default SalesChart;
